import React from "react";
import ReactDOM from "react-dom";
import "@fontsource/muli";
import Main from "./pages/Main";
import datadog from "./analytics/datadog";

datadog.init();

ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById("root")
);
