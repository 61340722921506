import { createAction } from "@reduxjs/toolkit";

export const setUserSession = createAction<{
  userId: number;
}>("SET_USER_SESSION");

export const setNetworkSession = createAction<{
  networkId: number;
}>("SET_NETWORK_SESSION");

export const clearSession = createAction<void>("CLEAR_SESSION");
