import type { Options as KyOptions } from "ky";
import { HTTPError } from "ky";
import { API_BASE_URL } from "../../constants";
import { store } from "../../store";
import { logout } from "../../store/auth/auth.thunk";
import {
  SencropAPIError,
  SencropAPIRequest,
} from "../../utils/errors/sencrop-api-error";
import { getKeys } from "../../utils/typescript";
import { RenewError } from "../RenewError";
import API from "./infrastructure-sdk";
import AuthTokenManager from "../../helpers/AuthTokenManager";

const controller = new AbortController();
const { signal } = controller;

export const cancelAllSdkCall = () => controller.abort();

export const cancelAll = () => {
  cancelAllSdkCall();
};

const sencropApi = getKeys(API).reduce(
  (FinalAPI, operationId) => {
    // @ts-ignore
    FinalAPI[operationId] = async (
      params = {},
      options: Partial<KyOptions> = {}
    ) => {
      const state = store.getState();

      try {
        const { userId } = state.session;
        const accessToken = AuthTokenManager.getInstance().getToken();

        return await API[operationId](
          // @ts-ignore
          {
            ...(accessToken && { authorization: `Bearer ${accessToken}` }),
            ...(userId && { userId }),
            ...params,
          },
          {
            prefixUrl: API_BASE_URL,
            signal,
            ...options,
          }
        );
      } catch (error) {
        if (error instanceof HTTPError) {
          const errorResponse = (await error.response.json()) as {
            code: string;
          };

          // Response params
          const code: string = errorResponse.code || "unknown-code";
          const message = error.message;
          const httpStatus = error.response.status;
          const requestData = await error.request.json();

          // Request params
          const request: SencropAPIRequest = {
            action: operationId,
            url: error.request.url,
            data: requestData,
            method: error.request.method,
          };

          const sencropAPIError = new SencropAPIError(
            error,
            code,
            message,
            httpStatus,
            request
          );

          throw sencropAPIError;
        } else if (error instanceof RenewError) {
          // Renew issue, lets logout
          store.dispatch(logout());
        } else {
          throw error;
        }
      }
    };

    return FinalAPI;
  },
  {} as {
    [P in keyof typeof API]: (
      input?: Omit<Parameters<typeof API[P]>[0], "userId">,
      options?: Partial<KyOptions>
    ) => Promise<ReturnType<typeof API[P]>>;
  }
);

export default sencropApi;
