export type PickRename<T, K extends keyof T, R extends PropertyKey> = Omit<
  T,
  K
> & { [P in R]: T[K] };

// eslint-disable-next-line @typescript-eslint/ban-types
export const getKeys = <A extends object>(obj: A) =>
  Object.keys(obj) as (keyof A)[];

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export function partition<T>(array: T[], isValid: (elem: T) => boolean) {
  return array.reduce(
    ([pass, fail], elem) => {
      return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
    },
    [[] as T[], [] as T[]]
  );
}
