import { TestingSession, TestingState } from "../models/testingSessions";
import sencropApi from "./helpers/sencrop";

export async function putTestingSessions({
  testingSessions,
}: {
  testingSessions: TestingSession[];
}) {
  const testingSessionsBody = testingSessions.map((testingSession) => {
    const {
      serial,
      identification,
      activationCode,
      modelId,
      testingValues,
      testingErrors,
      testingState,
      sensorId,
    } = testingSession;

    return {
      serial,
      identification,
      modelId,
      ...(activationCode && { activationCode }),
      testingState,
      testingValues,
      testingErrors,
      irradianceSensorId: sensorId,
    };
  });

  const { body } = await sencropApi.putUserTestingSessions(
    {
      body: testingSessionsBody,
    },
    { timeout: 60000 }
  );
  return body;
}

export async function terminateTestingSessions({
  testingSessions,
}: {
  testingSessions: TestingSession[];
}) {
  const testingSessionsBody = testingSessions.map((testingSession) => {
    const {
      serial,
      identification,
      activationCode,
      modelId,
      testingValues,
      testingErrors,
      sensorId,
    } = testingSession;

    return {
      serial,
      identification,
      ...(activationCode && { activationCode }),
      modelId,
      testingState: (testingSession.testingErrors.length
        ? "UNDER_TESTING"
        : "PASSED_TESTING") as TestingState,
      testingValues,
      testingErrors,
      irradianceSensorId: sensorId,
    };
  });

  const { body } = await sencropApi.postUserTerminatedSessions(
    {
      body: testingSessionsBody,
    },
    { timeout: 60000 }
  );

  return body;
}

export async function getSerialsMeasures(serials: string[]) {
  if (!serials || !serials.length) {
    return {};
  }

  const { body } = await sencropApi.getFactoryMeasures(
    {
      serials,
    },
    { timeout: 60000 }
  );

  return body;
}

export async function getCalibrations(sensorIds: string[]) {
  if (!sensorIds || !sensorIds.length) {
    return [];
  }

  const { body } = await sencropApi.getLatestIrrCalibrations(
    {
      sensorIds,
    },
    { timeout: 60000 }
  );

  return body;
}
