import { createGlobalStyle } from "styled-components";
import { media } from "@sencrop/ui-components";

export const GlobalStyle = createGlobalStyle`
  html {
    hyphens: auto;
    body {
      &.modal-opened {
        /* For ios */
        ${media.lessThan("tablet")`
          position: fixed;
          width: 100%;
        `}
      }
    }
  }
  .sortableHelper {
    z-index: 10000 !important;
    transition: box-shadow 500ms ease-in-out;
    box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.15);
  }
`;

export default GlobalStyle;
