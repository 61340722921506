import { API_BASE_URL } from "../constants";
import API from "./helpers/infrastructure-sdk";
import AuthTokenManager from "../helpers/AuthTokenManager";

type CurrentUser = {
  userId: number;
  isAdmin: boolean;
};

export const getCurrentUser = async (): Promise<CurrentUser> => {
  const accessToken = AuthTokenManager.getInstance().getToken();
  const { body } = await API.getMySelf(
    { accessToken },
    { baseURL: API_BASE_URL }
  );

  const user = body.users[body.item];
  const { roles } = user;

  const isAdmin = roles?.includes("ROLE_TEAM") ?? false;
  return { userId: body.item, isAdmin };
};
