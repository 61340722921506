// App
export const VERSION = process.env.REACT_APP_VERSION || "0.0.0";
export const ENV = process.env.REACT_APP_ENV;

// Api
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_NESTJS_BASE_URL = process.env.REACT_APP_API_NESTJS_BASE_URL;

// Analytics
export const SENTRY_URL = process.env.REACT_APP_SENTRY_URL || "";
