import { createReducer } from "@reduxjs/toolkit";
import { TestingSession } from "../../models/testingSessions";
import * as actions from "../actions";

export type TestingSessionState = {
  isFetching: boolean;
  testingSessions: TestingSession[];
  error: string | undefined;
};

const initialState: TestingSessionState = {
  isFetching: false,
  testingSessions: [],
  error: undefined,
};

const identity = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.addTestingSessionsRequest, (state) => {
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    })
    .addCase(actions.addTestingSessionsSuccess, (state, { payload }) => {
      return {
        ...state,
        testingSessions: [...state.testingSessions, ...payload],
        error: undefined,
        isFetching: false,
      };
    })
    .addCase(actions.addTestingSessionsError, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    })
    .addCase(actions.refreshDataRequest, (state) => {
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    })
    .addCase(actions.refreshDataSuccess, (state, { payload }) => {
      return {
        ...state,
        error: undefined,
        testingSessions: payload,
        isFetching: false,
      };
    })
    .addCase(actions.refreshDataError, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    })
    .addCase(actions.removeTestingSessionsRequest, (state) => {
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    })
    .addCase(actions.removeTestingSessionsSuccess, (state, { payload }) => {
      return {
        ...state,
        error: undefined,
        testingSessions: payload,
        isFetching: false,
      };
    })
    .addCase(actions.removeTestingSessionsError, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    })
    .addCase(actions.clearTestingSessionsRequest, (state) => {
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    })
    .addCase(actions.clearTestingSessionsSuccess, () => {
      return initialState;
    })
    .addCase(actions.clearTestingSessionsError, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    })
    .addCase(actions.terminateSessionsRequest, (state) => {
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    })
    .addCase(actions.terminateSessionsSuccess, (state, { payload }) => {
      return {
        ...state,
        error: undefined,
        testingSessions: payload,
        isFetching: false,
      };
    })
    .addCase(actions.terminateSessionsError, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    })
    .addCase(actions.retrieveCurrentSessionsRequest, (state) => {
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    })
    .addCase(actions.retrieveCurrentSessionsSuccess, (state, { payload }) => {
      return {
        ...state,
        testingSessions: payload,
        error: undefined,
        isFetching: false,
      };
    })
    .addCase(actions.retrieveCurrentSessionsError, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    })

    .addCase(actions.clearSession, () => initialState)
);

export default identity;
