import { datadogRum } from "@datadog/browser-rum";

export const VERSION = process.env.REACT_APP_VERSION || "0.0.0";
export const ENV = process.env.REACT_APP_ENV;
export const DATADOG_APPLICATION_ID =
  process.env.REACT_APP_DATADOG_APPLICATION_ID || "";
export const DATADOG_CLIENT_TOKEN =
  process.env.REACT_APP_DATADOG_CLIENT_TOKEN || "";
export const BALI_BASE_URL = process.env.REACT_APP_API_NESTJS_BASE_URL;

class DatadogService {
  initialized = false;

  init() {
    datadogRum.init({
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      site: "datadoghq.eu",
      service: "factory-monitoring",
      env: ENV,
      version: VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      startSessionReplayRecordingManually: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      allowedTracingUrls: [BALI_BASE_URL ?? "", "https://api.sencrop.com"],
    });

    this.initialized = true;
  }

  identify(email: string) {
    datadogRum.setUser({
      email: email,
    });
  }

  reset() {
    datadogRum.clearUser();
  }
}

const datadog = new DatadogService();
export default datadog;
