import { Locale as UiLocale } from "@sencrop/ui-components";

import en from "../i18n/en.json";

export const LOCALES = ["en-GB"] as const;

export type Locale = Extract<UiLocale, typeof LOCALES[number]>;

export const DICTIONARY: Record<Locale, any> = {
  "en-GB": en,
};

export const DEFAULT_LOCALE = "en-GB" as const;

export const LOCALES_STRING: Record<Locale, string> = {
  "en-GB": "English",
};

function isLocale(localeString: string): localeString is Locale {
  return (LOCALES as readonly string[]).includes(localeString);
}

export function toLocale(localeString: string): Locale {
  return isLocale(localeString) ? localeString : DEFAULT_LOCALE;
}
