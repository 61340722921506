import { useAuth } from "react-oidc-context";
import React, { useEffect, useState } from "react";
import LazyLoader from "./LazyLoader";
import { Outlet } from "react-router";
import { PATH_LOGIN, PATH_LOGOUT } from "../utils/path";
import { Navigate, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { Button, Text, useI18n } from "@sencrop/ui-components";
import styled from "styled-components";
import AuthTokenManager from "../helpers/AuthTokenManager";

function oryTokenNeedRefresh() {
  const oryToken = AuthTokenManager.getInstance().getToken();
  if (oryToken) {
    try {
      const decoded = jwtDecode(oryToken);
      return decoded.exp !== undefined && decoded.exp < Date.now() / 1000;
    } catch (error) {
      return false;
    }
  }
  return false;
}

const AuthOutlet = () => {
  const auth = useAuth();
  const { isAuthenticated, isLoading, signinSilent } = useAuth();
  const navigate = useNavigate();
  const [needTokenRefresh, setNeedTokenRefresh] = useState(
    isAuthenticated && oryTokenNeedRefresh()
  );
  const { t } = useI18n();

  useEffect(() => {
    if (needTokenRefresh) {
      signinSilent()
        .then((user) => {
          if (user) {
            AuthTokenManager.getInstance().setToken(user.id_token);
            setNeedTokenRefresh(false);
          } else {
            navigate(PATH_LOGOUT);
          }
        })
        .catch(() => navigate(PATH_LOGOUT));
    }
  }, [needTokenRefresh, navigate, signinSilent]);

  useEffect(() => {
    if (isAuthenticated && oryTokenNeedRefresh()) {
      setNeedTokenRefresh(true);
    } else {
      setNeedTokenRefresh(false);
    }
  }, [isAuthenticated]);

  if (needTokenRefresh || isLoading || auth.activeNavigator) {
    return <LazyLoader />;
  }

  if (auth.error) {
    return (
      <>
        <ErrorPanel>
          <Text size="l">{auth?.error?.message}</Text>
          <Button
            variant="primary"
            size="l"
            full
            onClick={() => navigate(PATH_LOGOUT)}
          >
            {t("form.login.submit")}
          </Button>
        </ErrorPanel>
      </>
    );
  }
  if (!isAuthenticated) {
    return <Navigate to={PATH_LOGIN} />;
  }

  AuthTokenManager.getInstance().setToken(auth.user?.id_token);
  return <Outlet />;
};

export default AuthOutlet;

const ErrorPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;
