import { Component } from "react";
import { ENV } from "../../constants";
import ErrorComponent from "./Error";

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      if (ENV === "development") return null;
      return <ErrorComponent />;
    }
    return children;
  }
}

export default ErrorBoundary;
