import { AppThunk } from "..";
import * as actions from "../actions";
import * as API from "../../api";

export const login = (): AppThunk => async (dispatch) => {
  const { userId, isAdmin } = await API.getCurrentUser();

  dispatch(actions.loginSuccess({ userId, isAdmin }));
  dispatch(actions.setUserSession({ userId }));
};

export const logout = (): AppThunk => async (dispatch) => {
  dispatch(actions.clearSession());
  dispatch(actions.logoutSuccess());
};
