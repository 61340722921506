/* tslint:disable */
/* eslint-disable */
// WARNING: This file is automatically generated
// do not change it in place it would be overridden
// by the next build

import ky from "ky";
import type { Options as KyOptions, SearchParamsOption } from "ky";

export type Method =
  | "options"
  | "get"
  | "head"
  | "post"
  | "put"
  | "patch"
  | "delete";
export type QueryParams = {
  [name: string]: string | number | string[] | number[] | boolean | undefined;
};
export type Headers = Record<string, string>;
export type URIBuilderOptions = {
  baseURL?: string;
};
export type InputBuilderOptions = URIBuilderOptions & {
  headers?: Headers;
};
export type URIData = {
  baseURL: string;
  path: string;
  params: SearchParamsOption;
};
export type HTTPRequest<T> = URIData & {
  method: Method;
  headers: Headers;
  body: T;
};
export type { SencropTypes, Components };

declare namespace SencropTypes {
  export namespace DeleteBucketDeviceSupport {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly bucketDeviceId: Parameters.BucketDeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.DeleteBucketDeviceSupport1;
      export type AccessToken =
        Components.Parameters.DeleteBucketDeviceSupport2;
      export type BucketDeviceId = Components.Parameters.BucketDeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutBucketDeviceSupport {
    export type Body =
      Components.RequestBodies.PutBucketDeviceSupportRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly bucketDeviceId: Parameters.BucketDeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PutBucketDeviceSupport1;
      export type AccessToken = Components.Parameters.PutBucketDeviceSupport2;
      export type BucketDeviceId = Components.Parameters.BucketDeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace DeleteDeviceAccessSupport {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly accessId: Parameters.AccessId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.DeleteDeviceAccessSupport1;
      export type AccessToken =
        Components.Parameters.DeleteDeviceAccessSupport2;
      export type AccessId = Components.Parameters.AccessId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutDeviceAccessSupport {
    export type Body =
      Components.RequestBodies.PutDeviceAccessSupportRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly accessId: Parameters.AccessId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PutDeviceAccessSupport1;
      export type AccessToken = Components.Parameters.PutDeviceAccessSupport2;
      export type AccessId = Components.Parameters.AccessId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace DeleteDeviceAccessesSupport {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.DeleteDeviceAccessesSupport1;
      export type AccessToken =
        Components.Parameters.DeleteDeviceAccessesSupport2;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutDeviceAccessesSupport {
    export type Body =
      Components.RequestBodies.PutDeviceAccessesSupportRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PutDeviceAccessesSupport1;
      export type AccessToken = Components.Parameters.PutDeviceAccessesSupport2;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace DeleteExpiredTokens {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.DeleteExpiredTokens1;
      export type AccessToken = Components.Parameters.DeleteExpiredTokens2;
      export type Date = Components.Parameters.Date;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace DeleteOrganisationChargebeeId {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.DeleteOrganisationChargebeeId1;
      export type AccessToken =
        Components.Parameters.DeleteOrganisationChargebeeId2;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostOrganisationChargebeeId {
    export type Body =
      Components.RequestBodies.PostOrganisationChargebeeIdRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostOrganisationChargebeeId1;
      export type AccessToken =
        Components.Parameters.PostOrganisationChargebeeId2;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace DeleteOrganisationDevice {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.DeleteOrganisationDevice2;
      export type AccessToken = Components.Parameters.DeleteOrganisationDevice3;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisationDevice {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getOrganisationDeviceResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetOrganisationDevice2;
      export type AccessToken = Components.Parameters.GetOrganisationDevice3;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutOrganisationDevice {
    export type Body =
      Components.RequestBodies.PutOrganisationDeviceRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.putOrganisationDeviceResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PutOrganisationDevice2;
      export type AccessToken = Components.Parameters.PutOrganisationDevice3;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace DeleteOrganisationMemberSupport {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly memberId: Parameters.MemberId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.DeleteOrganisationMemberSupport2;
      export type AccessToken =
        Components.Parameters.DeleteOrganisationMemberSupport3;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type MemberId = Components.Parameters.MemberId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutOrganisationMemberSupport {
    export type Body =
      Components.RequestBodies.PutOrganisationMemberSupportRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly memberId: Parameters.MemberId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.putOrganisationMemberSupportResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PutOrganisationMemberSupport2;
      export type AccessToken =
        Components.Parameters.PutOrganisationMemberSupport3;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type MemberId = Components.Parameters.MemberId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace DeleteOrganisationNetworkDeviceAccess {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly networkId: Parameters.NetworkId;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.DeleteOrganisationNetworkDeviceAccess3;
      export type AccessToken =
        Components.Parameters.DeleteOrganisationNetworkDeviceAccess4;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type NetworkId = Components.Parameters.NetworkId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutOrganisationNetworkDeviceAccess {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly networkId: Parameters.NetworkId;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PutOrganisationNetworkDeviceAccess3;
      export type AccessToken =
        Components.Parameters.PutOrganisationNetworkDeviceAccess4;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type NetworkId = Components.Parameters.NetworkId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace DeleteSencropMeasures {
    export type Output = unknown;
    export type Input = {
      readonly startDate: Parameters.StartDate;
      readonly endDate: Parameters.EndDate;
      readonly measureType: Parameters.MeasureType;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type StartDate = Components.Parameters.DeleteSencropMeasures1;
      export type EndDate = Components.Parameters.DeleteSencropMeasures2;
      export type MeasureType = Components.Parameters.DeleteSencropMeasures3;
      export type Authorization = Components.Parameters.DeleteSencropMeasures4;
      export type AccessToken = Components.Parameters.DeleteSencropMeasures5;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace DeleteUserOrganisationAggregation {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly aggregationId: Parameters.AggregationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.DeleteUserOrganisationAggregation3;
      export type AccessToken =
        Components.Parameters.DeleteUserOrganisationAggregation4;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type AggregationId = Components.Parameters.AggregationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutUserOrganisationAggregation {
    export type Body =
      Components.RequestBodies.PutUserOrganisationAggregationRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly aggregationId: Parameters.AggregationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.putUserOrganisationAggregationResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PutUserOrganisationAggregation3;
      export type AccessToken =
        Components.Parameters.PutUserOrganisationAggregation4;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type AggregationId = Components.Parameters.AggregationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace DeleteUserOrganisationAlert {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly alertId: Parameters.AlertId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.DeleteUserOrganisationAlert3;
      export type AccessToken =
        Components.Parameters.DeleteUserOrganisationAlert4;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type AlertId = Components.Parameters.AlertId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutUserOrganisationAlert {
    export type Body =
      Components.RequestBodies.PutUserOrganisationAlertRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly alertId: Parameters.AlertId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.putUserOrganisationAlertResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PutUserOrganisationAlert3;
      export type AccessToken = Components.Parameters.PutUserOrganisationAlert4;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type AlertId = Components.Parameters.AlertId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace DeleteUserOrganisationDeviceModule {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly deviceId: Parameters.DeviceId;
      readonly moduleId: Parameters.ModuleId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.DeleteUserOrganisationDeviceModule4;
      export type AccessToken =
        Components.Parameters.DeleteUserOrganisationDeviceModule5;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type ModuleId = Components.Parameters.ModuleId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutUserOrganisationDeviceModule {
    export type Body =
      Components.RequestBodies.PutUserOrganisationDeviceModuleRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly deviceId: Parameters.DeviceId;
      readonly moduleId: Parameters.ModuleId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.putUserOrganisationDeviceModuleResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PutUserOrganisationDeviceModule4;
      export type AccessToken =
        Components.Parameters.PutUserOrganisationDeviceModule5;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type ModuleId = Components.Parameters.ModuleId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace DeleteUserOrganisationInvitation {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly invitationId: Parameters.InvitationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.DeleteUserOrganisationInvitation3;
      export type AccessToken =
        Components.Parameters.DeleteUserOrganisationInvitation4;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type InvitationId = Components.Parameters.InvitationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutUserOrganisationInvitation {
    export type Body =
      Components.RequestBodies.PutUserOrganisationInvitationRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly invitationId: Parameters.InvitationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.putUserOrganisationInvitationResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PutUserOrganisationInvitation3;
      export type AccessToken =
        Components.Parameters.PutUserOrganisationInvitation4;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type InvitationId = Components.Parameters.InvitationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace DeleteUserOrganisationMember {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly memberId: Parameters.MemberId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.DeleteUserOrganisationMember3;
      export type AccessToken =
        Components.Parameters.DeleteUserOrganisationMember4;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type MemberId = Components.Parameters.MemberId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutUserOrganisationMember {
    export type Body =
      Components.RequestBodies.PutUserOrganisationMemberRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly memberId: Parameters.MemberId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.putUserOrganisationMemberResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PutUserOrganisationMember3;
      export type AccessToken =
        Components.Parameters.PutUserOrganisationMember4;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type MemberId = Components.Parameters.MemberId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace DeleteUserOrganisationOpenNetworkMembership {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly networkId: Parameters.NetworkId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.DeleteUserOrganisationOpenNetworkMembership3;
      export type AccessToken =
        Components.Parameters.DeleteUserOrganisationOpenNetworkMembership4;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type NetworkId = Components.Parameters.NetworkId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutUserOrganisationOpenNetworkMembership {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly networkId: Parameters.NetworkId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PutUserOrganisationOpenNetworkMembership3;
      export type AccessToken =
        Components.Parameters.PutUserOrganisationOpenNetworkMembership4;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type NetworkId = Components.Parameters.NetworkId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace DeleteUserOrganisationShare {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly shareId: Parameters.ShareId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.DeleteUserOrganisationShare3;
      export type AccessToken =
        Components.Parameters.DeleteUserOrganisationShare4;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type ShareId = Components.Parameters.ShareId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutUserOrganisationShare {
    export type Body =
      Components.RequestBodies.PutUserOrganisationShareRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly shareId: Parameters.ShareId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.putUserOrganisationShareResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PutUserOrganisationShare3;
      export type AccessToken = Components.Parameters.PutUserOrganisationShare4;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type ShareId = Components.Parameters.ShareId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOpenAPI {
    export type Output = Responses.$200;
    export type Input = {
      readonly mutedParameters?: Parameters.MutedParameters;
      readonly mutedMethods?: Parameters.MutedMethods;
      readonly tags?: Parameters.Tags;
      readonly authenticatedHandlers?: Parameters.AuthenticatedHandlers;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getOpenAPIResponse200<200>;
    }
    export namespace Parameters {
      export type MutedParameters = Components.Parameters.GetOpenAPI0;
      export type MutedMethods = Components.Parameters.GetOpenAPI1;
      export type Tags = Components.Parameters.GetOpenAPI2;
      export type AuthenticatedHandlers = Components.Parameters.GetOpenAPI3;
      export type Authorization = Components.Parameters.GetOpenAPI4;
      export type AccessToken = Components.Parameters.GetOpenAPI5;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetAgritelPrices {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetAgritelPrices1;
      export type AccessToken = Components.Parameters.GetAgritelPrices2;
      export type Date = Components.Parameters.Date;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetChargebeeCustomer {
    export type Output = Responses.$200;
    export type Input = {
      readonly customerEmail?: Parameters.CustomerEmail;
      readonly customerId?: Parameters.CustomerId;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getChargebeeCustomerResponse200<200>;
    }
    export namespace Parameters {
      export type CustomerEmail = Components.Parameters.GetChargebeeCustomer0;
      export type CustomerId = Components.Parameters.GetChargebeeCustomer1;
      export type Authorization = Components.Parameters.GetChargebeeCustomer2;
      export type AccessToken = Components.Parameters.GetChargebeeCustomer3;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetChargebeeResourcesPublic {
    export type Output = Responses.$200;
    export type Input = {
      readonly currency: Parameters.Currency;
      readonly zone: Parameters.Zone;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getChargebeeResourcesPublicResponse200<200>;
    }
    export namespace Parameters {
      export type Currency = Components.Parameters.Currency;
      export type Zone = Components.Parameters.Zone;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetChargebeeRessources {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly currency: Parameters.Currency;
      readonly zone: Parameters.Zone;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getChargebeeRessourcesResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetChargebeeRessources2;
      export type AccessToken = Components.Parameters.GetChargebeeRessources3;
      export type Currency = Components.Parameters.Currency;
      export type Zone = Components.Parameters.Zone;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetCotations {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getCotationsResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetCotations0;
      export type AccessToken = Components.Parameters.GetCotations1;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetCrops {
    export type Output = Responses.$200;
    export type Input = {
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getCropsResponse200<200>;
    }
    export namespace Parameters {
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetCustomerByEmail {
    export type Output = Responses.$200;
    export type Input = {
      readonly customerEmail?: Parameters.CustomerEmail;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getCustomerByEmailResponse200<200>;
    }
    export namespace Parameters {
      export type CustomerEmail = Components.Parameters.GetCustomerByEmail0;
      export type Authorization = Components.Parameters.GetCustomerByEmail1;
      export type AccessToken = Components.Parameters.GetCustomerByEmail2;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetData {
    export type Output = Responses.$200;
    export type Input = {
      readonly interval: Parameters.Interval;
      readonly baseDeviceSerial?: Parameters.BaseDeviceSerial;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly startDate: Parameters.StartDate;
      readonly endDate: Parameters.EndDate;
      readonly measures: Parameters.Measures;
      readonly ranges: Parameters.Ranges;
      readonly latitude: Parameters.Latitude;
      readonly longitude: Parameters.Longitude;
      readonly withCalibration?: Parameters.WithCalibration;
      readonly timeZone?: Parameters.TimeZone;
      readonly minimumFiability?: Parameters.MinimumFiability;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getDataResponse200<200>;
    }
    export namespace Parameters {
      export type Interval = Components.Parameters.GetData4;
      export type BaseDeviceSerial = Components.Parameters.GetData10;
      export type Authorization = Components.Parameters.GetData11;
      export type AccessToken = Components.Parameters.GetData12;
      export type StartDate = Components.Parameters.StartDate;
      export type EndDate = Components.Parameters.EndDate;
      export type Measures = Components.Parameters.Measures;
      export type Ranges = Components.Parameters.Ranges;
      export type Latitude = Components.Parameters.Latitude;
      export type Longitude = Components.Parameters.Longitude;
      export type WithCalibration = Components.Parameters.WithCalibration;
      export type TimeZone = Components.Parameters.TimeZone;
      export type MinimumFiability = Components.Parameters.MinimumFiability;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetDeviceData {
    export type Output = Responses.$200;
    export type Input = {
      readonly interval: Parameters.Interval;
      readonly key: Parameters.Key;
      readonly deviceId: Parameters.DeviceId;
      readonly startDate: Parameters.StartDate;
      readonly endDate: Parameters.EndDate;
      readonly measures: Parameters.Measures;
      readonly timeZone?: Parameters.TimeZone;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getDeviceDataResponse200<200>;
    }
    export namespace Parameters {
      export type Interval = Components.Parameters.GetDeviceData5;
      export type Key = Components.Parameters.GetDeviceData6;
      export type DeviceId = Components.Parameters.DeviceId;
      export type StartDate = Components.Parameters.StartDate;
      export type EndDate = Components.Parameters.EndDate;
      export type Measures = Components.Parameters.Measures;
      export type TimeZone = Components.Parameters.TimeZone;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetDeviceRawData {
    export type Output = Responses.$200;
    export type Input = {
      readonly size: Parameters.Size;
      readonly measures: Parameters.Measures;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly deviceId: Parameters.DeviceId;
      readonly beforeDate: Parameters.BeforeDate;
      readonly includeHistory?: Parameters.IncludeHistory;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getDeviceRawDataResponse200<200>;
    }
    export namespace Parameters {
      export type Size = Components.Parameters.GetDeviceRawData3;
      export type Measures = Components.Parameters.GetDeviceRawData4;
      export type Authorization = Components.Parameters.GetDeviceRawData5;
      export type AccessToken = Components.Parameters.GetDeviceRawData6;
      export type DeviceId = Components.Parameters.DeviceId;
      export type BeforeDate = Components.Parameters.BeforeDate;
      export type IncludeHistory = Components.Parameters.IncludeHistory;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetDevices {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly devicesIds: Parameters.DevicesIds;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getDevicesResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetDevices1;
      export type AccessToken = Components.Parameters.GetDevices2;
      export type DevicesIds = Components.Parameters.DevicesIds;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetFactoryMeasures {
    export type Output = Responses.$200;
    export type Input = {
      readonly serials: Parameters.Serials;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getFactoryMeasuresResponse200<200>;
    }
    export namespace Parameters {
      export type Serials = Components.Parameters.GetFactoryMeasures0;
      export type Authorization = Components.Parameters.GetFactoryMeasures1;
      export type AccessToken = Components.Parameters.GetFactoryMeasures2;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetFactorySigfoxStatus {
    export type Output = Responses.$200;
    export type Input = {
      readonly serial: Parameters.Serial;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getFactorySigfoxStatusResponse200<200>;
    }
    export namespace Parameters {
      export type Serial = Components.Parameters.GetFactorySigfoxStatus0;
      export type Authorization = Components.Parameters.GetFactorySigfoxStatus1;
      export type AccessToken = Components.Parameters.GetFactorySigfoxStatus2;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetHubSpotContact {
    export type Output = Responses.$200;
    export type Input = {
      readonly email: Parameters.Email;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getHubSpotContactResponse200<200>;
    }
    export namespace Parameters {
      export type Email = Components.Parameters.GetHubSpotContact0;
      export type Authorization = Components.Parameters.GetHubSpotContact1;
      export type AccessToken = Components.Parameters.GetHubSpotContact2;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetHubSpotProperties {
    export type Output = Responses.$200;
    export type Input = {
      readonly type?: Parameters.Type;
      readonly name?: Parameters.Name;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getHubSpotPropertiesResponse200<200>;
    }
    export namespace Parameters {
      export type Type = Components.Parameters.GetHubSpotProperties0;
      export type Name = Components.Parameters.GetHubSpotProperties1;
      export type Authorization = Components.Parameters.GetHubSpotProperties2;
      export type AccessToken = Components.Parameters.GetHubSpotProperties3;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetHubSpotQuotatorCard {
    export type Output = Responses.$200;
    export type Input = {
      readonly userId?: Parameters.UserId;
      readonly userEmail?: Parameters.UserEmail;
      readonly associatedObjectId?: Parameters.AssociatedObjectId;
      readonly associatedObjectType?: Parameters.AssociatedObjectType;
      readonly portalId?: Parameters.PortalId;
      readonly hsObjectId?: Parameters.HsObjectId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getHubSpotQuotatorCardResponse200<200>;
    }
    export namespace Parameters {
      export type UserId = Components.Parameters.GetHubSpotQuotatorCard0;
      export type UserEmail = Components.Parameters.GetHubSpotQuotatorCard1;
      export type AssociatedObjectId =
        Components.Parameters.GetHubSpotQuotatorCard2;
      export type AssociatedObjectType =
        Components.Parameters.GetHubSpotQuotatorCard3;
      export type PortalId = Components.Parameters.GetHubSpotQuotatorCard4;
      export type HsObjectId = Components.Parameters.GetHubSpotQuotatorCard5;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetHubSpotTableauCard {
    export type Output = Responses.$200;
    export type Input = {
      readonly userId?: Parameters.UserId;
      readonly userEmail?: Parameters.UserEmail;
      readonly associatedObjectId?: Parameters.AssociatedObjectId;
      readonly associatedObjectType?: Parameters.AssociatedObjectType;
      readonly portalId?: Parameters.PortalId;
      readonly hsObjectId?: Parameters.HsObjectId;
      readonly email?: Parameters.Email;
      readonly appId?: Parameters.AppId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getHubSpotTableauCardResponse200<200>;
    }
    export namespace Parameters {
      export type UserId = Components.Parameters.GetHubSpotTableauCard0;
      export type UserEmail = Components.Parameters.GetHubSpotTableauCard1;
      export type AssociatedObjectId =
        Components.Parameters.GetHubSpotTableauCard2;
      export type AssociatedObjectType =
        Components.Parameters.GetHubSpotTableauCard3;
      export type PortalId = Components.Parameters.GetHubSpotTableauCard4;
      export type HsObjectId = Components.Parameters.GetHubSpotTableauCard5;
      export type Email = Components.Parameters.GetHubSpotTableauCard6;
      export type AppId = Components.Parameters.GetHubSpotTableauCard7;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetInvitation {
    export type Output = Responses.$200;
    export type Input = {
      readonly invitationId: Parameters.InvitationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getInvitationResponse200<200>;
    }
    export namespace Parameters {
      export type InvitationId = Components.Parameters.InvitationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetInvitationDeviceLiveAggregations {
    export type Output = Responses.$201;
    export type Input = {
      readonly timeZone: Parameters.TimeZone;
      readonly invitationId: Parameters.InvitationId;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.getInvitationDeviceLiveAggregationsResponse201<201>;
    }
    export namespace Parameters {
      export type TimeZone =
        Components.Parameters.GetInvitationDeviceLiveAggregations2;
      export type InvitationId = Components.Parameters.InvitationId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetLatestIrrCalibration {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly sensorId: Parameters.SensorId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getLatestIrrCalibrationResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.GetLatestIrrCalibration1;
      export type AccessToken = Components.Parameters.GetLatestIrrCalibration2;
      export type SensorId = Components.Parameters.SensorId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetLatestIrrCalibrations {
    export type Output = Responses.$200;
    export type Input = {
      readonly sensorIds: Parameters.SensorIds;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getLatestIrrCalibrationsResponse200<200>;
    }
    export namespace Parameters {
      export type SensorIds = Components.Parameters.GetLatestIrrCalibrations0;
      export type Authorization =
        Components.Parameters.GetLatestIrrCalibrations1;
      export type AccessToken = Components.Parameters.GetLatestIrrCalibrations2;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetModules {
    export type Output = Responses.$200;
    export type Input = {
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getModulesResponse200<200>;
    }
    export namespace Parameters {
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetMySelf {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getMySelfResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetMySelf0;
      export type AccessToken = Components.Parameters.GetMySelf1;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetNetwork {
    export type Output = Responses.$200;
    export type Input = {
      readonly networkId: Parameters.NetworkId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getNetworkResponse200<200>;
    }
    export namespace Parameters {
      export type NetworkId = Components.Parameters.NetworkId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetNetworkDeviceLiveAggregations {
    export type Output = Responses.$201;
    export type Input = {
      readonly timeZone: Parameters.TimeZone;
      readonly networkId: Parameters.NetworkId;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.getNetworkDeviceLiveAggregationsResponse201<201>;
    }
    export namespace Parameters {
      export type TimeZone =
        Components.Parameters.GetNetworkDeviceLiveAggregations2;
      export type NetworkId = Components.Parameters.NetworkId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetNetworkDevices {
    export type Output = Responses.$200;
    export type Input = {
      readonly networkId: Parameters.NetworkId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getNetworkDevicesResponse200<200>;
    }
    export namespace Parameters {
      export type NetworkId = Components.Parameters.NetworkId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOAuth2Authorize {
    export type Output = unknown;
    export type Input = {
      readonly responseType: Parameters.ResponseType;
      readonly clientId: Parameters.ClientId;
      readonly redirectUri?: Parameters.RedirectUri;
      readonly scope?: Parameters.Scope;
      readonly state: Parameters.State;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type ResponseType = Components.Parameters.GetOAuth2Authorize0;
      export type ClientId = Components.Parameters.GetOAuth2Authorize1;
      export type RedirectUri = Components.Parameters.GetOAuth2Authorize2;
      export type Scope = Components.Parameters.GetOAuth2Authorize3;
      export type State = Components.Parameters.GetOAuth2Authorize4;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrders {
    export type Output = Responses.$200;
    export type Input = {
      readonly orderIdsToBypass?: Parameters.OrderIdsToBypass;
      readonly status: Parameters.Status;
      readonly updateOrders: Parameters.UpdateOrders;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getOrdersResponse200<200>;
    }
    export namespace Parameters {
      export type OrderIdsToBypass = Components.Parameters.GetOrders0;
      export type Status = Components.Parameters.GetOrders1;
      export type UpdateOrders = Components.Parameters.GetOrders2;
      export type Authorization = Components.Parameters.GetOrders3;
      export type AccessToken = Components.Parameters.GetOrders4;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostOrder {
    export type Body = Components.RequestBodies.PostOrderRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 = Components.Responses.postOrderResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostOrder0;
      export type AccessToken = Components.Parameters.PostOrder1;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrdersAsXLSX {
    export type Output = Responses.$200;
    export type Input = {
      readonly orderIdsToBypass?: Parameters.OrderIdsToBypass;
      readonly status: Parameters.Status;
      readonly updateOrders: Parameters.UpdateOrders;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getOrdersAsXLSXResponse200<200>;
    }
    export namespace Parameters {
      export type OrderIdsToBypass = Components.Parameters.GetOrdersAsXLSX0;
      export type Status = Components.Parameters.GetOrdersAsXLSX1;
      export type UpdateOrders = Components.Parameters.GetOrdersAsXLSX2;
      export type Authorization = Components.Parameters.GetOrdersAsXLSX3;
      export type AccessToken = Components.Parameters.GetOrdersAsXLSX4;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrdersByUserEmail {
    export type Output = Responses.$200;
    export type Input = {
      readonly email: Parameters.Email;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getOrdersByUserEmailResponse200<200>;
    }
    export namespace Parameters {
      export type Email = Components.Parameters.GetOrdersByUserEmail0;
      export type Authorization = Components.Parameters.GetOrdersByUserEmail1;
      export type AccessToken = Components.Parameters.GetOrdersByUserEmail2;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisation {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getOrganisationResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetOrganisation1;
      export type AccessToken = Components.Parameters.GetOrganisation2;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutOrganisation {
    export type Body = Components.RequestBodies.PutOrganisationRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 = Components.Responses.putOrganisationResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PutOrganisation1;
      export type AccessToken = Components.Parameters.PutOrganisation2;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisationChargebeePortalSession {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getOrganisationChargebeePortalSessionResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.GetOrganisationChargebeePortalSession1;
      export type AccessToken =
        Components.Parameters.GetOrganisationChargebeePortalSession2;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisationDevices {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly limit: Parameters.Limit;
      readonly start: Parameters.Start;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getOrganisationDevicesResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetOrganisationDevices3;
      export type AccessToken = Components.Parameters.GetOrganisationDevices4;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type Limit = Components.Parameters.Limit;
      export type Start = Components.Parameters.Start;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisationDevicesLiveAggregations {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly devicesIds: Parameters.DevicesIds;
      readonly aggregations: Parameters.Aggregations;
      readonly parameters?: Parameters.Parameters;
      readonly intervals: Parameters.Intervals;
      readonly timeZone?: Parameters.TimeZone;
      readonly date?: Parameters.Date;
      readonly patched?: Parameters.Patched;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getOrganisationDevicesLiveAggregationsResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.GetOrganisationDevicesLiveAggregations8;
      export type AccessToken =
        Components.Parameters.GetOrganisationDevicesLiveAggregations9;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type DevicesIds = Components.Parameters.DevicesIds;
      export type Aggregations = Components.Parameters.Aggregations;
      export type Parameters = Components.Parameters.Parameters;
      export type Intervals = Components.Parameters.Intervals;
      export type TimeZone = Components.Parameters.TimeZone;
      export type Date = Components.Parameters.StatusDate;
      export type Patched = Components.Parameters.Patched;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisationHostedPage {
    export type Output = Responses.$201;
    export type Input = {
      readonly redirectUrl?: Parameters.RedirectUrl;
      readonly planId: Parameters.PlanId;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.getOrganisationHostedPageResponse201<201>;
    }
    export namespace Parameters {
      export type RedirectUrl =
        Components.Parameters.GetOrganisationHostedPage1;
      export type PlanId = Components.Parameters.GetOrganisationHostedPage2;
      export type Authorization =
        Components.Parameters.GetOrganisationHostedPage3;
      export type AccessToken =
        Components.Parameters.GetOrganisationHostedPage4;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisationHostedPageBattery {
    export type Output = Responses.$201;
    export type Input = {
      readonly redirectUrl?: Parameters.RedirectUrl;
      readonly raincrop?: Parameters.Raincrop;
      readonly windcrop?: Parameters.Windcrop;
      readonly leafcrop?: Parameters.Leafcrop;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.getOrganisationHostedPageBatteryResponse201<201>;
    }
    export namespace Parameters {
      export type RedirectUrl =
        Components.Parameters.GetOrganisationHostedPageBattery1;
      export type Raincrop =
        Components.Parameters.GetOrganisationHostedPageBattery2;
      export type Windcrop =
        Components.Parameters.GetOrganisationHostedPageBattery3;
      export type Leafcrop =
        Components.Parameters.GetOrganisationHostedPageBattery4;
      export type Authorization =
        Components.Parameters.GetOrganisationHostedPageBattery5;
      export type AccessToken =
        Components.Parameters.GetOrganisationHostedPageBattery6;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisationHostedPageUpgradePlanPlus {
    export type Output = Responses.$201;
    export type Input = {
      readonly redirectUrl?: Parameters.RedirectUrl;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.getOrganisationHostedPageUpgradePlanPlusResponse201<201>;
    }
    export namespace Parameters {
      export type RedirectUrl =
        Components.Parameters.GetOrganisationHostedPageUpgradePlanPlus1;
      export type Authorization =
        Components.Parameters.GetOrganisationHostedPageUpgradePlanPlus2;
      export type AccessToken =
        Components.Parameters.GetOrganisationHostedPageUpgradePlanPlus3;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisationInvitation {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly invitationId: Parameters.InvitationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getOrganisationInvitationResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.GetOrganisationInvitation2;
      export type AccessToken =
        Components.Parameters.GetOrganisationInvitation3;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type InvitationId = Components.Parameters.InvitationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisationInvitations {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly limit: Parameters.Limit;
      readonly start: Parameters.Start;
      readonly withTypes?: Parameters.WithTypes;
      readonly withStatuses?: Parameters.WithStatuses;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getOrganisationInvitationsResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.GetOrganisationInvitations5;
      export type AccessToken =
        Components.Parameters.GetOrganisationInvitations6;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type Limit = Components.Parameters.Limit;
      export type Start = Components.Parameters.Start;
      export type WithTypes = Components.Parameters.InvitationWithTypesFilter;
      export type WithStatuses =
        Components.Parameters.InvitationWithStatusesFilter;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisationMember {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly memberId: Parameters.MemberId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getOrganisationMemberResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetOrganisationMember2;
      export type AccessToken = Components.Parameters.GetOrganisationMember3;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type MemberId = Components.Parameters.MemberId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisationMembers {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getOrganisationMembersResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetOrganisationMembers1;
      export type AccessToken = Components.Parameters.GetOrganisationMembers2;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisationNetworkDevices {
    export type Output = Responses.$200;
    export type Input = {
      readonly limit: Parameters.Limit;
      readonly geohash?: Parameters.Geohash;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly networkId: Parameters.NetworkId;
      readonly start: Parameters.Start;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getOrganisationNetworkDevicesResponse200<200>;
    }
    export namespace Parameters {
      export type Limit = Components.Parameters.GetOrganisationNetworkDevices2;
      export type Geohash =
        Components.Parameters.GetOrganisationNetworkDevices4;
      export type Authorization =
        Components.Parameters.GetOrganisationNetworkDevices5;
      export type AccessToken =
        Components.Parameters.GetOrganisationNetworkDevices6;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type NetworkId = Components.Parameters.NetworkId;
      export type Start = Components.Parameters.Start;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisationNetworks {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly limit: Parameters.Limit;
      readonly start: Parameters.Start;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getOrganisationNetworksResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.GetOrganisationNetworks3;
      export type AccessToken = Components.Parameters.GetOrganisationNetworks4;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type Limit = Components.Parameters.Limit;
      export type Start = Components.Parameters.Start;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisationPlace {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly placeId: Parameters.PlaceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getOrganisationPlaceResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetOrganisationPlace2;
      export type AccessToken = Components.Parameters.GetOrganisationPlace3;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type PlaceId = Components.Parameters.PlaceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisationPlaces {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getOrganisationPlacesResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetOrganisationPlaces1;
      export type AccessToken = Components.Parameters.GetOrganisationPlaces2;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostOrganisationPlace {
    export type Body =
      Components.RequestBodies.PostOrganisationPlaceRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.postOrganisationPlaceResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostOrganisationPlace1;
      export type AccessToken = Components.Parameters.PostOrganisationPlace2;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisationPreferences {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getOrganisationPreferencesResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.GetOrganisationPreferences1;
      export type AccessToken =
        Components.Parameters.GetOrganisationPreferences2;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutOrganisationPreferences {
    export type Body =
      Components.RequestBodies.PutOrganisationPreferencesRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.putOrganisationPreferencesResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PutOrganisationPreferences1;
      export type AccessToken =
        Components.Parameters.PutOrganisationPreferences2;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisationShare {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly shareId: Parameters.ShareId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getOrganisationShareResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetOrganisationShare2;
      export type AccessToken = Components.Parameters.GetOrganisationShare3;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type ShareId = Components.Parameters.ShareId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisationShares {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly limit: Parameters.Limit;
      readonly start: Parameters.Start;
      readonly withTypes?: Parameters.WithTypes;
      readonly isTransmittable?: Parameters.IsTransmittable;
      readonly withModes?: Parameters.WithModes;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getOrganisationSharesResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetOrganisationShares6;
      export type AccessToken = Components.Parameters.GetOrganisationShares7;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type Limit = Components.Parameters.Limit;
      export type Start = Components.Parameters.Start;
      export type WithTypes = Components.Parameters.ShareWithTypesFilter;
      export type IsTransmittable =
        Components.Parameters.ShareIsTransmittableFilter;
      export type WithModes = Components.Parameters.ShareWithModesFilter;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetOrganisationsTrialOperations {
    export type Output = Responses.$200;
    export type Input = {
      readonly active?: Parameters.Active;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getOrganisationsTrialOperationsResponse200<200>;
    }
    export namespace Parameters {
      export type Active =
        Components.Parameters.GetOrganisationsTrialOperations1;
      export type Authorization =
        Components.Parameters.GetOrganisationsTrialOperations2;
      export type AccessToken =
        Components.Parameters.GetOrganisationsTrialOperations3;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetPartnerDevices {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly partnerId: Parameters.PartnerId;
      readonly limit: Parameters.Limit;
      readonly start: Parameters.Start;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getPartnerDevicesResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetPartnerDevices3;
      export type AccessToken = Components.Parameters.GetPartnerDevices4;
      export type PartnerId = Components.Parameters.PartnerId;
      export type Limit = Components.Parameters.Limit;
      export type Start = Components.Parameters.Start;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetPing {
    export type Output = Responses.$200;
    export type Input = {
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getPingResponse200<200>;
    }
    export namespace Parameters {
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetPlans {
    export type Output = Responses.$200;
    export type Input = {
      readonly countryCode: Parameters.CountryCode;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getPlansResponse200<200>;
    }
    export namespace Parameters {
      export type CountryCode = Components.Parameters.GetPlans0;
      export type Authorization = Components.Parameters.GetPlans1;
      export type AccessToken = Components.Parameters.GetPlans2;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetPublicWeatherLive {
    export type Output = Responses.$200;
    export type Input = {
      readonly topLeftBoxX: Parameters.TopLeftBoxX;
      readonly topLeftBoxY: Parameters.TopLeftBoxY;
      readonly bottomRightBoxX: Parameters.BottomRightBoxX;
      readonly bottomRightBoxY: Parameters.BottomRightBoxY;
      readonly measureType: Parameters.MeasureType;
      readonly aggregationType: Parameters.AggregationType;
      readonly precision: Parameters.Precision;
      readonly timeFrame?: Parameters.TimeFrame;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getPublicWeatherLiveResponse200<200>;
    }
    export namespace Parameters {
      export type TopLeftBoxX = Components.Parameters.GetPublicWeatherLive0;
      export type TopLeftBoxY = Components.Parameters.GetPublicWeatherLive1;
      export type BottomRightBoxX = Components.Parameters.GetPublicWeatherLive2;
      export type BottomRightBoxY = Components.Parameters.GetPublicWeatherLive3;
      export type MeasureType = Components.Parameters.GetPublicWeatherLive4;
      export type AggregationType = Components.Parameters.GetPublicWeatherLive5;
      export type Precision = Components.Parameters.GetPublicWeatherLive6;
      export type TimeFrame = Components.Parameters.GetPublicWeatherLive7;
      export type Authorization = Components.Parameters.GetPublicWeatherLive8;
      export type AccessToken = Components.Parameters.GetPublicWeatherLive9;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetPublicWeatherLiveAggregations {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly geohash: Parameters.Geohash;
      readonly aggregations: Parameters.Aggregations;
      readonly liveInterval: Parameters.LiveInterval;
      readonly timeZone?: Parameters.TimeZone;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getPublicWeatherLiveAggregationsResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.GetPublicWeatherLiveAggregations4;
      export type AccessToken =
        Components.Parameters.GetPublicWeatherLiveAggregations5;
      export type Geohash = Components.Parameters.Geohash;
      export type Aggregations = Components.Parameters.Aggregations;
      export type LiveInterval = Components.Parameters.LiveInterval;
      export type TimeZone = Components.Parameters.TimeZone;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetQuote {
    export type Output = Responses.$200;
    export type Input = {
      readonly quoteId: Parameters.QuoteId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getQuoteResponse200<200>;
    }
    export namespace Parameters {
      export type QuoteId = Components.Parameters.QuoteId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetQuoteAsPdf {
    export type Output = Responses.$200;
    export type Input = {
      readonly quoteId: Parameters.QuoteId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getQuoteAsPdfResponse200<200>;
    }
    export namespace Parameters {
      export type QuoteId = Components.Parameters.QuoteId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetQuoteHostedPage {
    export type Output = Responses.$201;
    export type Input = {
      readonly tva?: Parameters.Tva;
      readonly paymentMethod?: Parameters.PaymentMethod;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly quoteId: Parameters.QuoteId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.getQuoteHostedPageResponse201<201>;
    }
    export namespace Parameters {
      export type Tva = Components.Parameters.GetQuoteHostedPage2;
      export type PaymentMethod = Components.Parameters.GetQuoteHostedPage3;
      export type Authorization = Components.Parameters.GetQuoteHostedPage4;
      export type AccessToken = Components.Parameters.GetQuoteHostedPage5;
      export type UserId = Components.Parameters.UserId;
      export type QuoteId = Components.Parameters.QuoteId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetQuoteInvoiceAsPdf {
    export type Output = Responses.$200;
    export type Input = {
      readonly quoteId: Parameters.QuoteId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getQuoteInvoiceAsPdfResponse200<200>;
    }
    export namespace Parameters {
      export type QuoteId = Components.Parameters.QuoteId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetRadarImage {
    export type Output = Responses.$200;
    export type Input = {
      readonly type: Parameters.Type;
      readonly country: Parameters.Country;
      readonly date: Parameters.Date;
      readonly accept?: Parameters.Accept;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getRadarImageResponse200<200>;
    }
    export namespace Parameters {
      export type Type = Components.Parameters.GetRadarImage0;
      export type Country = Components.Parameters.GetRadarImage1;
      export type Date = Components.Parameters.GetRadarImage2;
      export type Accept = Components.Parameters.GetRadarImage3;
      export type Authorization = Components.Parameters.GetRadarImage4;
      export type AccessToken = Components.Parameters.GetRadarImage5;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetSearchDevices {
    export type Output = Responses.$200;
    export type Input = {
      readonly latitude: Parameters.Latitude;
      readonly longitude: Parameters.Longitude;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getSearchDevicesResponse200<200>;
    }
    export namespace Parameters {
      export type Latitude = Components.Parameters.Latitude;
      export type Longitude = Components.Parameters.Longitude;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetSearchNetworkDevices {
    export type Output = Responses.$200;
    export type Input = {
      readonly latitude: Parameters.Latitude;
      readonly longitude: Parameters.Longitude;
      readonly networkId: Parameters.NetworkId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getSearchNetworkDevicesResponse200<200>;
    }
    export namespace Parameters {
      export type Latitude = Components.Parameters.Latitude;
      export type Longitude = Components.Parameters.Longitude;
      export type NetworkId = Components.Parameters.NetworkId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetSearchPublicOrganisation {
    export type Output = Responses.$200;
    export type Input = {
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getSearchPublicOrganisationResponse200<200>;
    }
    export namespace Parameters {
      export type OrganisationId =
        Components.Parameters.GetSearchPublicOrganisation0;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetSearchPublicUser {
    export type Output = Responses.$200;
    export type Input = {
      readonly userId: Parameters.UserId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getSearchPublicUserResponse200<200>;
    }
    export namespace Parameters {
      export type UserId = Components.Parameters.GetSearchPublicUser0;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetSearchUser {
    export type Output = Responses.$200;
    export type Input = {
      readonly email: Parameters.Email;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getSearchUserResponse200<200>;
    }
    export namespace Parameters {
      export type Email = Components.Parameters.GetSearchUser0;
      export type Authorization = Components.Parameters.GetSearchUser1;
      export type AccessToken = Components.Parameters.GetSearchUser2;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetSigfoxCoverage {
    export type Output = Responses.$200;
    export type Input = {
      readonly latitude: Parameters.Latitude;
      readonly longitude: Parameters.Longitude;
      readonly radius?: Parameters.Radius;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getSigfoxCoverageResponse200<200>;
    }
    export namespace Parameters {
      export type Latitude = Components.Parameters.Latitude;
      export type Longitude = Components.Parameters.Longitude;
      export type Radius = Components.Parameters.Radius;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetSigfoxMap {
    export type Output = Responses.$200;
    export type Input = {
      readonly operator?: Parameters.Operator;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getSigfoxMapResponse200<200>;
    }
    export namespace Parameters {
      export type Operator = Components.Parameters.GetSigfoxMap1;
      export type Authorization = Components.Parameters.GetSigfoxMap2;
      export type AccessToken = Components.Parameters.GetSigfoxMap3;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetStatusHead {
    export type Output = Responses.$200;
    export type Input = {
      readonly locale: Parameters.Locale;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getStatusHeadResponse200<200>;
    }
    export namespace Parameters {
      export type Locale = Components.Parameters.GetStatusHead0;
      export type Authorization = Components.Parameters.GetStatusHead1;
      export type AccessToken = Components.Parameters.GetStatusHead2;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetTrialOperation {
    export type Output = Responses.$200;
    export type Input = {
      readonly trialOperationId: Parameters.TrialOperationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getTrialOperationResponse200<200>;
    }
    export namespace Parameters {
      export type TrialOperationId = Components.Parameters.TrialOperationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUser {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getUserResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetUser1;
      export type AccessToken = Components.Parameters.GetUser2;
      export type UserId = Components.Parameters.UserId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutUser {
    export type Body = Components.RequestBodies.PutUserRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 = Components.Responses.putUserResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PutUser1;
      export type AccessToken = Components.Parameters.PutUser2;
      export type UserId = Components.Parameters.UserId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserAggregation {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly aggregationId: Parameters.AggregationId;
      readonly patched?: Parameters.Patched;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserAggregationResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetUserAggregation3;
      export type AccessToken = Components.Parameters.GetUserAggregation4;
      export type UserId = Components.Parameters.UserId;
      export type AggregationId = Components.Parameters.AggregationId;
      export type Patched = Components.Parameters.Patched;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserAggregations {
    export type Output = Responses.$200;
    export type Input = {
      readonly compute?: Parameters.Compute;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly patched?: Parameters.Patched;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserAggregationsResponse200<200>;
    }
    export namespace Parameters {
      export type Compute = Components.Parameters.GetUserAggregations1;
      export type Authorization = Components.Parameters.GetUserAggregations3;
      export type AccessToken = Components.Parameters.GetUserAggregations4;
      export type UserId = Components.Parameters.UserId;
      export type Patched = Components.Parameters.Patched;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserAlert {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly alertId: Parameters.AlertId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getUserAlertResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetUserAlert2;
      export type AccessToken = Components.Parameters.GetUserAlert3;
      export type UserId = Components.Parameters.UserId;
      export type AlertId = Components.Parameters.AlertId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserAlertVoiceMessage {
    export type Output = Responses.$200;
    export type Input = {
      readonly date: Parameters.Date;
      readonly userId: Parameters.UserId;
      readonly alertId: Parameters.AlertId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserAlertVoiceMessageResponse200<200>;
    }
    export namespace Parameters {
      export type Date = Components.Parameters.GetUserAlertVoiceMessage2;
      export type UserId = Components.Parameters.UserId;
      export type AlertId = Components.Parameters.AlertId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserAlerts {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getUserAlertsResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetUserAlerts1;
      export type AccessToken = Components.Parameters.GetUserAlerts2;
      export type UserId = Components.Parameters.UserId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserDailyData {
    export type Output = Responses.$200;
    export type Input = {
      readonly ranges?: Parameters.Ranges;
      readonly measures: Parameters.Measures;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly latitude: Parameters.Latitude;
      readonly longitude: Parameters.Longitude;
      readonly beforeDate: Parameters.BeforeDate;
      readonly days: Parameters.Days;
      readonly includeHistory?: Parameters.IncludeHistory;
      readonly withCalibration?: Parameters.WithCalibration;
      readonly timeZone?: Parameters.TimeZone;
      readonly patched?: Parameters.Patched;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getUserDailyDataResponse200<200>;
    }
    export namespace Parameters {
      export type Ranges = Components.Parameters.GetUserDailyData6;
      export type Measures = Components.Parameters.GetUserDailyData7;
      export type Authorization = Components.Parameters.GetUserDailyData11;
      export type AccessToken = Components.Parameters.GetUserDailyData12;
      export type UserId = Components.Parameters.UserId;
      export type Latitude = Components.Parameters.Latitude;
      export type Longitude = Components.Parameters.Longitude;
      export type BeforeDate = Components.Parameters.BeforeDate;
      export type Days = Components.Parameters.Days;
      export type IncludeHistory = Components.Parameters.IncludeHistory;
      export type WithCalibration = Components.Parameters.WithCalibration;
      export type TimeZone = Components.Parameters.TimeZone;
      export type Patched = Components.Parameters.Patched;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserDevice {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly deviceId: Parameters.DeviceId;
      readonly includeHistory?: Parameters.IncludeHistory;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getUserDeviceResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetUserDevice3;
      export type AccessToken = Components.Parameters.GetUserDevice4;
      export type UserId = Components.Parameters.UserId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type IncludeHistory = Components.Parameters.IncludeHistory;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserDeviceContinuousStatistics {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly deviceId: Parameters.DeviceId;
      readonly startDate: Parameters.StartDate;
      readonly endDate: Parameters.EndDate;
      readonly measures: Parameters.Measures;
      readonly patched?: Parameters.Patched;
      readonly includeHistory?: Parameters.IncludeHistory;
      readonly timeZone?: Parameters.TimeZone;
      readonly interval?: Parameters.Interval;
      readonly withCalibration?: Parameters.WithCalibration;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserDeviceContinuousStatisticsResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.GetUserDeviceContinuousStatistics10;
      export type AccessToken =
        Components.Parameters.GetUserDeviceContinuousStatistics11;
      export type UserId = Components.Parameters.UserId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type StartDate = Components.Parameters.StartDate;
      export type EndDate = Components.Parameters.EndDate;
      export type Measures = Components.Parameters.Measures;
      export type Patched = Components.Parameters.Patched;
      export type IncludeHistory = Components.Parameters.IncludeHistory;
      export type TimeZone = Components.Parameters.TimeZone;
      export type Interval = Components.Parameters.Interval;
      export type WithCalibration = Components.Parameters.WithCalibration;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserDeviceDailyData {
    export type Output = Responses.$200;
    export type Input = {
      readonly measures: Parameters.Measures;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly deviceId: Parameters.DeviceId;
      readonly beforeDate: Parameters.BeforeDate;
      readonly days: Parameters.Days;
      readonly includeHistory?: Parameters.IncludeHistory;
      readonly timeZone?: Parameters.TimeZone;
      readonly withCalibration?: Parameters.WithCalibration;
      readonly patched?: Parameters.Patched;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserDeviceDailyDataResponse200<200>;
    }
    export namespace Parameters {
      export type Measures = Components.Parameters.GetUserDeviceDailyData6;
      export type Authorization = Components.Parameters.GetUserDeviceDailyData9;
      export type AccessToken = Components.Parameters.GetUserDeviceDailyData10;
      export type UserId = Components.Parameters.UserId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type BeforeDate = Components.Parameters.BeforeDate;
      export type Days = Components.Parameters.Days;
      export type IncludeHistory = Components.Parameters.IncludeHistory;
      export type TimeZone = Components.Parameters.TimeZone;
      export type WithCalibration = Components.Parameters.WithCalibration;
      export type Patched = Components.Parameters.Patched;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserDeviceForecasts {
    export type Output = Responses.$200;
    export type Input = {
      readonly date: Parameters.Date;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserDeviceForecastsResponse200<200>;
    }
    export namespace Parameters {
      export type Date = Components.Parameters.GetUserDeviceForecasts2;
      export type Authorization = Components.Parameters.GetUserDeviceForecasts3;
      export type AccessToken = Components.Parameters.GetUserDeviceForecasts4;
      export type UserId = Components.Parameters.UserId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserDeviceHourlyData {
    export type Output = Responses.$200;
    export type Input = {
      readonly days: Parameters.Days;
      readonly measures: Parameters.Measures;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly deviceId: Parameters.DeviceId;
      readonly beforeDate: Parameters.BeforeDate;
      readonly includeHistory?: Parameters.IncludeHistory;
      readonly timeZone?: Parameters.TimeZone;
      readonly withCalibration?: Parameters.WithCalibration;
      readonly patched?: Parameters.Patched;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserDeviceHourlyDataResponse200<200>;
    }
    export namespace Parameters {
      export type Days = Components.Parameters.GetUserDeviceHourlyData5;
      export type Measures = Components.Parameters.GetUserDeviceHourlyData6;
      export type Authorization =
        Components.Parameters.GetUserDeviceHourlyData9;
      export type AccessToken = Components.Parameters.GetUserDeviceHourlyData10;
      export type UserId = Components.Parameters.UserId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type BeforeDate = Components.Parameters.BeforeDate;
      export type IncludeHistory = Components.Parameters.IncludeHistory;
      export type TimeZone = Components.Parameters.TimeZone;
      export type WithCalibration = Components.Parameters.WithCalibration;
      export type Patched = Components.Parameters.Patched;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserDeviceModules {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserDeviceModulesResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetUserDeviceModules2;
      export type AccessToken = Components.Parameters.GetUserDeviceModules3;
      export type UserId = Components.Parameters.UserId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserDevicePositions {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly deviceId: Parameters.DeviceId;
      readonly includeHistory?: Parameters.IncludeHistory;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserDevicePositionsResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetUserDevicePositions3;
      export type AccessToken = Components.Parameters.GetUserDevicePositions4;
      export type UserId = Components.Parameters.UserId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type IncludeHistory = Components.Parameters.IncludeHistory;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserDeviceRawData {
    export type Output = Responses.$200;
    export type Input = {
      readonly size: Parameters.Size;
      readonly measures: Parameters.Measures;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly deviceId: Parameters.DeviceId;
      readonly beforeDate: Parameters.BeforeDate;
      readonly includeHistory?: Parameters.IncludeHistory;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserDeviceRawDataResponse200<200>;
    }
    export namespace Parameters {
      export type Size = Components.Parameters.GetUserDeviceRawData4;
      export type Measures = Components.Parameters.GetUserDeviceRawData5;
      export type Authorization = Components.Parameters.GetUserDeviceRawData6;
      export type AccessToken = Components.Parameters.GetUserDeviceRawData7;
      export type UserId = Components.Parameters.UserId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type BeforeDate = Components.Parameters.BeforeDate;
      export type IncludeHistory = Components.Parameters.IncludeHistory;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserDeviceStatistics {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly deviceId: Parameters.DeviceId;
      readonly startDate: Parameters.StartDate;
      readonly endDate: Parameters.EndDate;
      readonly measures: Parameters.Measures;
      readonly patched?: Parameters.Patched;
      readonly includeHistory?: Parameters.IncludeHistory;
      readonly timeZone?: Parameters.TimeZone;
      readonly interval?: Parameters.Interval;
      readonly withCalibration?: Parameters.WithCalibration;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserDeviceStatisticsResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.GetUserDeviceStatistics10;
      export type AccessToken = Components.Parameters.GetUserDeviceStatistics11;
      export type UserId = Components.Parameters.UserId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type StartDate = Components.Parameters.StartDate;
      export type EndDate = Components.Parameters.EndDate;
      export type Measures = Components.Parameters.Measures;
      export type Patched = Components.Parameters.Patched;
      export type IncludeHistory = Components.Parameters.IncludeHistory;
      export type TimeZone = Components.Parameters.TimeZone;
      export type Interval = Components.Parameters.Interval;
      export type WithCalibration = Components.Parameters.WithCalibration;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserDeviceSummary {
    export type Output = Responses.$200;
    export type Input = {
      readonly date: Parameters.Date;
      readonly measures: Parameters.Measures;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly deviceId: Parameters.DeviceId;
      readonly includeHistory?: Parameters.IncludeHistory;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserDeviceSummaryResponse200<200>;
    }
    export namespace Parameters {
      export type Date = Components.Parameters.GetUserDeviceSummary3;
      export type Measures = Components.Parameters.GetUserDeviceSummary4;
      export type Authorization = Components.Parameters.GetUserDeviceSummary5;
      export type AccessToken = Components.Parameters.GetUserDeviceSummary6;
      export type UserId = Components.Parameters.UserId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type IncludeHistory = Components.Parameters.IncludeHistory;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserDevices {
    export type Output = Responses.$200;
    export type Input = {
      readonly date?: Parameters.Date;
      readonly measures?: Parameters.Measures;
      readonly limit?: Parameters.Limit;
      readonly start?: Parameters.Start;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly includeHistory?: Parameters.IncludeHistory;
      readonly patched?: Parameters.Patched;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getUserDevicesResponse200<200>;
    }
    export namespace Parameters {
      export type Date = Components.Parameters.GetUserDevices2;
      export type Measures = Components.Parameters.GetUserDevices3;
      export type Limit = Components.Parameters.GetUserDevices5;
      export type Start = Components.Parameters.GetUserDevices6;
      export type Authorization = Components.Parameters.GetUserDevices7;
      export type AccessToken = Components.Parameters.GetUserDevices8;
      export type UserId = Components.Parameters.UserId;
      export type IncludeHistory = Components.Parameters.IncludeHistory;
      export type Patched = Components.Parameters.Patched;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserDevicesLiveAggregations {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly devicesIds: Parameters.DevicesIds;
      readonly aggregations: Parameters.Aggregations;
      readonly parameters?: Parameters.Parameters;
      readonly intervals: Parameters.Intervals;
      readonly timeZone?: Parameters.TimeZone;
      readonly date?: Parameters.Date;
      readonly patched?: Parameters.Patched;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserDevicesLiveAggregationsResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.GetUserDevicesLiveAggregations8;
      export type AccessToken =
        Components.Parameters.GetUserDevicesLiveAggregations9;
      export type UserId = Components.Parameters.UserId;
      export type DevicesIds = Components.Parameters.DevicesIds;
      export type Aggregations = Components.Parameters.Aggregations;
      export type Parameters = Components.Parameters.Parameters;
      export type Intervals = Components.Parameters.Intervals;
      export type TimeZone = Components.Parameters.TimeZone;
      export type Date = Components.Parameters.StatusDate;
      export type Patched = Components.Parameters.Patched;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserForecasts {
    export type Output = Responses.$200;
    export type Input = {
      readonly date?: Parameters.Date;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly latitude: Parameters.Latitude;
      readonly longitude: Parameters.Longitude;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getUserForecastsResponse200<200>;
    }
    export namespace Parameters {
      export type Date = Components.Parameters.GetUserForecasts3;
      export type Authorization = Components.Parameters.GetUserForecasts4;
      export type AccessToken = Components.Parameters.GetUserForecasts5;
      export type UserId = Components.Parameters.UserId;
      export type Latitude = Components.Parameters.Latitude;
      export type Longitude = Components.Parameters.Longitude;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserHourlyData {
    export type Output = Responses.$200;
    export type Input = {
      readonly days: Parameters.Days;
      readonly ranges?: Parameters.Ranges;
      readonly measures: Parameters.Measures;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly latitude: Parameters.Latitude;
      readonly longitude: Parameters.Longitude;
      readonly beforeDate: Parameters.BeforeDate;
      readonly includeHistory?: Parameters.IncludeHistory;
      readonly withCalibration?: Parameters.WithCalibration;
      readonly timeZone?: Parameters.TimeZone;
      readonly patched?: Parameters.Patched;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getUserHourlyDataResponse200<200>;
    }
    export namespace Parameters {
      export type Days = Components.Parameters.GetUserHourlyData5;
      export type Ranges = Components.Parameters.GetUserHourlyData6;
      export type Measures = Components.Parameters.GetUserHourlyData7;
      export type Authorization = Components.Parameters.GetUserHourlyData11;
      export type AccessToken = Components.Parameters.GetUserHourlyData12;
      export type UserId = Components.Parameters.UserId;
      export type Latitude = Components.Parameters.Latitude;
      export type Longitude = Components.Parameters.Longitude;
      export type BeforeDate = Components.Parameters.BeforeDate;
      export type IncludeHistory = Components.Parameters.IncludeHistory;
      export type WithCalibration = Components.Parameters.WithCalibration;
      export type TimeZone = Components.Parameters.TimeZone;
      export type Patched = Components.Parameters.Patched;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserIntercomHash {
    export type Output = Responses.$200;
    export type Input = {
      readonly platform: Parameters.Platform;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserIntercomHashResponse200<200>;
    }
    export namespace Parameters {
      export type Platform = Components.Parameters.GetUserIntercomHash1;
      export type Authorization = Components.Parameters.GetUserIntercomHash2;
      export type AccessToken = Components.Parameters.GetUserIntercomHash3;
      export type UserId = Components.Parameters.UserId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserInvitation {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly invitationId: Parameters.InvitationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getUserInvitationResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetUserInvitation2;
      export type AccessToken = Components.Parameters.GetUserInvitation3;
      export type UserId = Components.Parameters.UserId;
      export type InvitationId = Components.Parameters.InvitationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserInvitations {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly limit: Parameters.Limit;
      readonly start: Parameters.Start;
      readonly withTypes?: Parameters.WithTypes;
      readonly withStatuses?: Parameters.WithStatuses;
      readonly withModes?: Parameters.WithModes;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserInvitationsResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetUserInvitations6;
      export type AccessToken = Components.Parameters.GetUserInvitations7;
      export type UserId = Components.Parameters.UserId;
      export type Limit = Components.Parameters.Limit;
      export type Start = Components.Parameters.Start;
      export type WithTypes = Components.Parameters.InvitationWithTypesFilter;
      export type WithStatuses =
        Components.Parameters.InvitationWithStatusesFilter;
      export type WithModes = Components.Parameters.InvitationWithModesFilter;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserOrganisations {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserOrganisationsResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetUserOrganisations1;
      export type AccessToken = Components.Parameters.GetUserOrganisations2;
      export type UserId = Components.Parameters.UserId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostUserOrganisation {
    export type Body = Components.RequestBodies.PostUserOrganisationRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.postUserOrganisationResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostUserOrganisation1;
      export type AccessToken = Components.Parameters.PostUserOrganisation2;
      export type UserId = Components.Parameters.UserId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserPendingInvitation {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly invitationId: Parameters.InvitationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserPendingInvitationResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.GetUserPendingInvitation2;
      export type AccessToken = Components.Parameters.GetUserPendingInvitation3;
      export type UserId = Components.Parameters.UserId;
      export type InvitationId = Components.Parameters.InvitationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserPendingInvitations {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly limit: Parameters.Limit;
      readonly start: Parameters.Start;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserPendingInvitationsResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.GetUserPendingInvitations3;
      export type AccessToken =
        Components.Parameters.GetUserPendingInvitations4;
      export type UserId = Components.Parameters.UserId;
      export type Limit = Components.Parameters.Limit;
      export type Start = Components.Parameters.Start;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserPreferences {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserPreferencesResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetUserPreferences1;
      export type AccessToken = Components.Parameters.GetUserPreferences2;
      export type UserId = Components.Parameters.UserId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutUserPreferences {
    export type Body = Components.RequestBodies.PutUserPreferencesRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.putUserPreferencesResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PutUserPreferences1;
      export type AccessToken = Components.Parameters.PutUserPreferences2;
      export type UserId = Components.Parameters.UserId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserStatistics {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly latitude: Parameters.Latitude;
      readonly longitude: Parameters.Longitude;
      readonly startDate: Parameters.StartDate;
      readonly endDate: Parameters.EndDate;
      readonly measures: Parameters.Measures;
      readonly ranges: Parameters.Ranges;
      readonly patched?: Parameters.Patched;
      readonly includeHistory?: Parameters.IncludeHistory;
      readonly interval?: Parameters.Interval;
      readonly withCalibration?: Parameters.WithCalibration;
      readonly timeZone?: Parameters.TimeZone;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getUserStatisticsResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetUserStatistics12;
      export type AccessToken = Components.Parameters.GetUserStatistics13;
      export type UserId = Components.Parameters.UserId;
      export type Latitude = Components.Parameters.Latitude;
      export type Longitude = Components.Parameters.Longitude;
      export type StartDate = Components.Parameters.StartDate;
      export type EndDate = Components.Parameters.EndDate;
      export type Measures = Components.Parameters.Measures;
      export type Ranges = Components.Parameters.Ranges;
      export type Patched = Components.Parameters.Patched;
      export type IncludeHistory = Components.Parameters.IncludeHistory;
      export type Interval = Components.Parameters.Interval;
      export type WithCalibration = Components.Parameters.WithCalibration;
      export type TimeZone = Components.Parameters.TimeZone;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetUserTestingSessions {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getUserTestingSessionsResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.GetUserTestingSessions1;
      export type AccessToken = Components.Parameters.GetUserTestingSessions2;
      export type UserId = Components.Parameters.UserId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutUserTestingSessions {
    export type Body =
      Components.RequestBodies.PutUserTestingSessionsRequestBody;
    export type Output = Responses.$200;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.putUserTestingSessionsResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PutUserTestingSessions1;
      export type AccessToken = Components.Parameters.PutUserTestingSessions2;
      export type UserId = Components.Parameters.UserId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetWeatherForecastModel {
    export type Output = Responses.$200;
    export type Input = {
      readonly marketingConsent?: Parameters.MarketingConsent;
      readonly email?: Parameters.Email;
      readonly latitude: Parameters.Latitude;
      readonly longitude: Parameters.Longitude;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.getWeatherForecastModelResponse200<200>;
    }
    export namespace Parameters {
      export type MarketingConsent =
        Components.Parameters.GetWeatherForecastModel2;
      export type Email = Components.Parameters.GetWeatherForecastModel3;
      export type Latitude = Components.Parameters.Latitude;
      export type Longitude = Components.Parameters.Longitude;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetWeatherLive {
    export type Output = Responses.$200;
    export type Input = {
      readonly timeFrame?: Parameters.TimeFrame;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly geohash: Parameters.Geohash;
      readonly measureType: Parameters.MeasureType;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getWeatherLiveResponse200<200>;
    }
    export namespace Parameters {
      export type TimeFrame = Components.Parameters.GetWeatherLive2;
      export type Authorization = Components.Parameters.GetWeatherLive3;
      export type AccessToken = Components.Parameters.GetWeatherLive4;
      export type Geohash = Components.Parameters.Geohash;
      export type MeasureType = Components.Parameters.MeasureType;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace HandleAggregationsCompute {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.HandleAggregationsCompute1;
      export type AccessToken =
        Components.Parameters.HandleAggregationsCompute2;
      export type Date = Components.Parameters.Date;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace HandleAlertCheck {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.handleAlertCheckResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.HandleAlertCheck1;
      export type AccessToken = Components.Parameters.HandleAlertCheck2;
      export type Date = Components.Parameters.Date;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace HandleAlertRepetition {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.HandleAlertRepetition1;
      export type AccessToken = Components.Parameters.HandleAlertRepetition2;
      export type Date = Components.Parameters.Date;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostAircallIncomingCalls {
    export type Body =
      Components.RequestBodies.PostAircallIncomingCallsRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostAlertsTrialDisable {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostAlertsTrialDisable1;
      export type AccessToken = Components.Parameters.PostAlertsTrialDisable2;
      export type Date = Components.Parameters.Date;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostApplicationEvent {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.postApplicationEventResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostApplicationEvent1;
      export type AccessToken = Components.Parameters.PostApplicationEvent2;
      export type Date = Components.Parameters.ConsumptionDate;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostBulkExportsSupport {
    export type Body =
      Components.RequestBodies.PostBulkExportsSupportRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly userId: Parameters.UserId;
      readonly emailRecipient: Parameters.EmailRecipient;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type UserId = Components.Parameters.PostBulkExportsSupport0;
      export type EmailRecipient =
        Components.Parameters.PostBulkExportsSupport1;
      export type Authorization = Components.Parameters.PostBulkExportsSupport2;
      export type AccessToken = Components.Parameters.PostBulkExportsSupport3;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostCSVArvalisMileosData {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostCSVArvalisMileosData1;
      export type AccessToken = Components.Parameters.PostCSVArvalisMileosData2;
      export type Date = Components.Parameters.Date;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostCSVArvalisMileosInitialData {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostCSVArvalisMileosInitialData1;
      export type AccessToken =
        Components.Parameters.PostCSVArvalisMileosInitialData2;
      export type Date = Components.Parameters.Date;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostCSVBatchProd {
    export type Body = Components.RequestBodies.PostCSVBatchProdRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostCSVBatchProd0;
      export type AccessToken = Components.Parameters.PostCSVBatchProd1;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostCSVCTIFLEmail {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostCSVCTIFLEmail1;
      export type AccessToken = Components.Parameters.PostCSVCTIFLEmail2;
      export type Date = Components.Parameters.Date;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostCSVMovidaEmail {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostCSVMovidaEmail1;
      export type AccessToken = Components.Parameters.PostCSVMovidaEmail2;
      export type Date = Components.Parameters.Date;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostCSVMovidaInitialEmail {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostCSVMovidaInitialEmail1;
      export type AccessToken =
        Components.Parameters.PostCSVMovidaInitialEmail2;
      export type Date = Components.Parameters.Date;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostCSVRimproData {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostCSVRimproData1;
      export type AccessToken = Components.Parameters.PostCSVRimproData2;
      export type Date = Components.Parameters.Date;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostCSVTTWData {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostCSVTTWData1;
      export type AccessToken = Components.Parameters.PostCSVTTWData2;
      export type Date = Components.Parameters.Date;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostChargebeeEvents {
    export type Body = Components.RequestBodies.PostChargebeeEventsRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostChargebeeEvents0;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostCloudwatchEvents {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.postCloudwatchEventsResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostCloudwatchEvents1;
      export type AccessToken = Components.Parameters.PostCloudwatchEvents2;
      export type Date = Components.Parameters.ConsumptionDate;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostCropsHits {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostCropsHits1;
      export type AccessToken = Components.Parameters.PostCropsHits2;
      export type Date = Components.Parameters.Date;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostDataDeviceSituation {
    export type Body =
      Components.RequestBodies.PostDataDeviceSituationRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostDataDeviceSituation1;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostDataWeatherForecastModel {
    export type Body =
      Components.RequestBodies.PostDataWeatherForecastModelRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostDataWeatherForecastModel0;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostDeviceCleanIngestionCacheSupport {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostDeviceCleanIngestionCacheSupport1;
      export type AccessToken =
        Components.Parameters.PostDeviceCleanIngestionCacheSupport2;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostDeviceReplacement {
    export type Body =
      Components.RequestBodies.PostDeviceReplacementRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly deviceId: Parameters.DeviceId;
      readonly newDeviceId: Parameters.NewDeviceId;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type DeviceId = Components.Parameters.PostDeviceReplacement0;
      export type NewDeviceId = Components.Parameters.PostDeviceReplacement1;
      export type Authorization = Components.Parameters.PostDeviceReplacement2;
      export type AccessToken = Components.Parameters.PostDeviceReplacement3;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostFirehoseRecordPerLine {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostFirehoseRecordPerLine0;
      export type AccessToken =
        Components.Parameters.PostFirehoseRecordPerLine1;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostFirehoseStream {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostFirehoseStream1;
      export type AccessToken = Components.Parameters.PostFirehoseStream2;
      export type Date = Components.Parameters.ConsumptionDate;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostForecastStorage {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostForecastStorage1;
      export type AccessToken = Components.Parameters.PostForecastStorage2;
      export type Date = Components.Parameters.ConsumptionDate;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostHubSpotContactSyncHook {
    export type Body =
      Components.RequestBodies.PostHubSpotContactSyncHookRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostHubSpotContactTrialReactivation {
    export type Body =
      Components.RequestBodies.PostHubSpotContactTrialReactivationRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostHubSpotDealSyncHook {
    export type Body =
      Components.RequestBodies.PostHubSpotDealSyncHookRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostHubSpotDeleteDealHook {
    export type Body =
      Components.RequestBodies.PostHubSpotDeleteDealHookRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostIngestionData {
    export type Output = Responses.$201;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 = Components.Responses.postIngestionDataResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostIngestionData1;
      export type AccessToken = Components.Parameters.PostIngestionData2;
      export type Date = Components.Parameters.ConsumptionDate;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostInvitationResend {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly invitationId: Parameters.InvitationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostInvitationResend1;
      export type AccessToken = Components.Parameters.PostInvitationResend2;
      export type InvitationId = Components.Parameters.InvitationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostIrrelisData {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostIrrelisData1;
      export type AccessToken = Components.Parameters.PostIrrelisData2;
      export type Date = Components.Parameters.Date;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostLambdaLogs {
    export type Output = Responses.$200;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.postLambdaLogsResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostLambdaLogs1;
      export type AccessToken = Components.Parameters.PostLambdaLogs2;
      export type Date = Components.Parameters.ConsumptionDate;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostLogin {
    export type Body = Components.RequestBodies.PostLoginRequestBody;
    export type Output = Responses.$200;
    export type Input = {
      readonly body: Body;
      readonly useJwt?: Parameters.UseJWT;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.postLoginResponse200<200>;
    }
    export namespace Parameters {
      export type UseJWT = Components.Parameters.PostLogin0;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostLostPassword {
    export type Body = Components.RequestBodies.PostLostPasswordRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostMailgunWebHook {
    export type Body = Components.RequestBodies.PostMailgunWebHookRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly mailgunWebHookType: Parameters.MailgunWebHookType;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type MailgunWebHookType =
        Components.Parameters.PostMailgunWebHook0;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostMailjetEvents {
    export type Body = Components.RequestBodies.PostMailjetEventsRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostMeteoGroupData {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostMeteoGroupData1;
      export type AccessToken = Components.Parameters.PostMeteoGroupData2;
      export type Date = Components.Parameters.Date;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostMovidaSetup {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostMovidaSetup1;
      export type AccessToken = Components.Parameters.PostMovidaSetup2;
      export type Date = Components.Parameters.Date;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostNetworkJoinRequest {
    export type Body =
      Components.RequestBodies.PostNetworkJoinRequestRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly networkId: Parameters.NetworkId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.postNetworkJoinRequestResponse201<201>;
    }
    export namespace Parameters {
      export type NetworkId = Components.Parameters.NetworkId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostNexmoEvents {
    export type Body = Components.RequestBodies.PostNexmoEventsRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostOAuth2Acknowledge {
    export type Body =
      Components.RequestBodies.PostOAuth2AcknowledgeRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly grantType: Parameters.GrantType;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.postOAuth2AcknowledgeResponse201<201>;
    }
    export namespace Parameters {
      export type GrantType = Components.Parameters.PostOAuth2Acknowledge0;
      export type Authorization = Components.Parameters.PostOAuth2Acknowledge1;
      export type AccessToken = Components.Parameters.PostOAuth2Acknowledge2;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostOAuth2Token {
    export type Body = Components.RequestBodies.PostOAuth2TokenRequestBody;
    export type Output = Responses.$200;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.postOAuth2TokenResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostOAuth2Token0;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostOrganisation {
    export type Body = Components.RequestBodies.PostOrganisationRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 = Components.Responses.postOrganisationResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostOrganisation0;
      export type AccessToken = Components.Parameters.PostOrganisation1;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostOrganisationDeviceTransfer {
    export type Output = unknown;
    export type Input = {
      readonly authorId: Parameters.AuthorId;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type AuthorId =
        Components.Parameters.PostOrganisationDeviceTransfer2;
      export type Authorization =
        Components.Parameters.PostOrganisationDeviceTransfer3;
      export type AccessToken =
        Components.Parameters.PostOrganisationDeviceTransfer4;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostOrganisationEndTrialSupport {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostOrganisationEndTrialSupport1;
      export type AccessToken =
        Components.Parameters.PostOrganisationEndTrialSupport2;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostOrganisationNetworkMemberPlan {
    export type Body =
      Components.RequestBodies.PostOrganisationNetworkMemberPlanRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly networkMemberId: Parameters.NetworkMemberId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostOrganisationNetworkMemberPlan2;
      export type AccessToken =
        Components.Parameters.PostOrganisationNetworkMemberPlan3;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type NetworkMemberId = Components.Parameters.NetworkMemberId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostOrganisationPaymentComplete {
    export type Body =
      Components.RequestBodies.PostOrganisationPaymentCompleteRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostOrganisationPaymentComplete1;
      export type AccessToken =
        Components.Parameters.PostOrganisationPaymentComplete2;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostOrganisationToNetworkSupport {
    export type Body =
      Components.RequestBodies.PostOrganisationToNetworkSupportRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostOrganisationToNetworkSupport1;
      export type AccessToken =
        Components.Parameters.PostOrganisationToNetworkSupport2;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostOrganisationTrialReactivation {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostOrganisationTrialReactivation1;
      export type AccessToken =
        Components.Parameters.PostOrganisationTrialReactivation2;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostOrganisationsMerge {
    export type Output = unknown;
    export type Input = {
      readonly organisationTargetId: Parameters.OrganisationTargetId;
      readonly organisationSourceId: Parameters.OrganisationSourceId;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type OrganisationTargetId =
        Components.Parameters.PostOrganisationsMerge0;
      export type OrganisationSourceId =
        Components.Parameters.PostOrganisationsMerge1;
      export type Authorization = Components.Parameters.PostOrganisationsMerge2;
      export type AccessToken = Components.Parameters.PostOrganisationsMerge3;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostPartnerApplication {
    export type Body =
      Components.RequestBodies.PostPartnerApplicationRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly partnerId: Parameters.PartnerId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.postPartnerApplicationResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostPartnerApplication1;
      export type AccessToken = Components.Parameters.PostPartnerApplication2;
      export type PartnerId = Components.Parameters.PartnerId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostPartnerModule {
    export type Body = Components.RequestBodies.PostPartnerModuleRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly partnerId: Parameters.PartnerId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 = Components.Responses.postPartnerModuleResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostPartnerModule1;
      export type AccessToken = Components.Parameters.PostPartnerModule2;
      export type PartnerId = Components.Parameters.PartnerId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostPartnerToken {
    export type Body = Components.RequestBodies.PostPartnerTokenRequestBody;
    export type Output = Responses.$200;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly partnerId: Parameters.PartnerId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.postPartnerTokenResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostPartnerToken1;
      export type PartnerId = Components.Parameters.PartnerId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostPartnerTokenRequest {
    export type Body =
      Components.RequestBodies.PostPartnerTokenRequestRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly partnerId: Parameters.PartnerId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostPartnerTokenRequest1;
      export type PartnerId = Components.Parameters.PartnerId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostPlace {
    export type Body = Components.RequestBodies.PostPlaceRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 = Components.Responses.postPlaceResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostPlace0;
      export type AccessToken = Components.Parameters.PostPlace1;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostPrevilisData {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostPrevilisData1;
      export type AccessToken = Components.Parameters.PostPrevilisData2;
      export type Date = Components.Parameters.Date;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostQuote {
    export type Body = Components.RequestBodies.PostQuoteRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 = Components.Responses.postQuoteResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostQuote0;
      export type AccessToken = Components.Parameters.PostQuote1;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostQuoteOffline {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly quoteId: Parameters.QuoteId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostQuoteOffline2;
      export type AccessToken = Components.Parameters.PostQuoteOffline3;
      export type UserId = Components.Parameters.UserId;
      export type QuoteId = Components.Parameters.QuoteId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostQuoteRequest {
    export type Body = Components.RequestBodies.PostQuoteRequestRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostRegister {
    export type Body = Components.RequestBodies.PostRegisterRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly trialOperationId?: Parameters.TrialOperationId;
      readonly referralId?: Parameters.ReferralId;
      readonly referralAuthorId?: Parameters.ReferralAuthorId;
      readonly marketingOptin?: Parameters.MarketingOptin;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 = Components.Responses.postRegisterResponse201<201>;
    }
    export namespace Parameters {
      export type TrialOperationId = Components.Parameters.PostRegister0;
      export type ReferralId = Components.Parameters.PostRegister1;
      export type ReferralAuthorId = Components.Parameters.PostRegister2;
      export type MarketingOptin = Components.Parameters.PostRegister3;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostRegisterAuth0 {
    export type Body = Components.RequestBodies.PostRegisterAuth0RequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 = Components.Responses.postRegisterAuth0Response201<201>;
    }
    export namespace Parameters {
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostSigfoxData {
    export type Body = Components.RequestBodies.PostSigfoxDataRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly key: Parameters.Key;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 = Components.Responses.postSigfoxDataResponse201<201>;
    }
    export namespace Parameters {
      export type Key = Components.Parameters.PostSigfoxData0;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostSigfoxReshape {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly date: Parameters.Date;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostSigfoxReshape1;
      export type AccessToken = Components.Parameters.PostSigfoxReshape2;
      export type Date = Components.Parameters.Date;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostSignatureCloudinary {
    export type Body =
      Components.RequestBodies.PostSignatureCloudinaryRequestBody;
    export type Output = Responses.$200;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.postSignatureCloudinaryResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostSignatureCloudinary0;
      export type AccessToken = Components.Parameters.PostSignatureCloudinary1;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostSignupCheck {
    export type Body = Components.RequestBodies.PostSignupCheckRequestBody;
    export type Output = Responses.$200;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.postSignupCheckResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostSignupCheck0;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostSlackBotCommand {
    export type Body = Components.RequestBodies.PostSlackBotCommandRequestBody;
    export type Output = Responses.$200;
    export type Input = {
      readonly body: Body;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.postSlackBotCommandResponse200<200>;
    }
    export namespace Parameters {
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostSocialImage {
    export type Body = Components.RequestBodies.PostSocialImageRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostSocialImage0;
      export type AccessToken = Components.Parameters.PostSocialImage1;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostTelitData {
    export type Body = Components.RequestBodies.PostTelitDataRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 = Components.Responses.postTelitDataResponse201<201>;
    }
    export namespace Parameters {
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostTwilioEvents {
    export type Body = Components.RequestBodies.PostTwilioEventsRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostUser {
    export type Body = Components.RequestBodies.PostUserRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 = Components.Responses.postUserResponse201<201>;
    }
    export namespace Parameters {
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostUserClearSessions {
    export type Body =
      Components.RequestBodies.PostUserClearSessionsRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostUserClearSessions1;
      export type AccessToken = Components.Parameters.PostUserClearSessions2;
      export type UserId = Components.Parameters.UserId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostUserDeviceExport {
    export type Body = Components.RequestBodies.PostUserDeviceExportRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly emailRecipient?: Parameters.EmailRecipient;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type EmailRecipient = Components.Parameters.PostUserDeviceExport2;
      export type Authorization = Components.Parameters.PostUserDeviceExport3;
      export type AccessToken = Components.Parameters.PostUserDeviceExport4;
      export type UserId = Components.Parameters.UserId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostUserNetworkInvitation {
    export type Body =
      Components.RequestBodies.PostUserNetworkInvitationRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly trialOperationId?: Parameters.TrialOperationId;
      readonly userId: Parameters.UserId;
      readonly networkId: Parameters.NetworkId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.postUserNetworkInvitationResponse201<201>;
    }
    export namespace Parameters {
      export type TrialOperationId =
        Components.Parameters.PostUserNetworkInvitation2;
      export type UserId = Components.Parameters.UserId;
      export type NetworkId = Components.Parameters.NetworkId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostUserOrganisationAggregation {
    export type Body =
      Components.RequestBodies.PostUserOrganisationAggregationRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.postUserOrganisationAggregationResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostUserOrganisationAggregation2;
      export type AccessToken =
        Components.Parameters.PostUserOrganisationAggregation3;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostUserOrganisationAlert {
    export type Body =
      Components.RequestBodies.PostUserOrganisationAlertRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.postUserOrganisationAlertResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostUserOrganisationAlert2;
      export type AccessToken =
        Components.Parameters.PostUserOrganisationAlert3;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostUserOrganisationDevice {
    export type Body =
      Components.RequestBodies.PostUserOrganisationDeviceRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly userId: Parameters.UserId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.postUserOrganisationDeviceResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostUserOrganisationDevice2;
      export type AccessToken =
        Components.Parameters.PostUserOrganisationDevice3;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type UserId = Components.Parameters.UserId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostUserOrganisationDeviceSupport {
    export type Output = Responses.$201;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.postUserOrganisationDeviceSupportResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostUserOrganisationDeviceSupport3;
      export type AccessToken =
        Components.Parameters.PostUserOrganisationDeviceSupport4;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostUserOrganisationDevicesSupport {
    export type Body =
      Components.RequestBodies.PostUserOrganisationDevicesSupportRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostUserOrganisationDevicesSupport2;
      export type AccessToken =
        Components.Parameters.PostUserOrganisationDevicesSupport3;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostUserOrganisationInvitation {
    export type Body =
      Components.RequestBodies.PostUserOrganisationInvitationRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.postUserOrganisationInvitationResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostUserOrganisationInvitation2;
      export type AccessToken =
        Components.Parameters.PostUserOrganisationInvitation3;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostUserOrganisationMember {
    export type Body =
      Components.RequestBodies.PostUserOrganisationMemberRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.postUserOrganisationMemberResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostUserOrganisationMember2;
      export type AccessToken =
        Components.Parameters.PostUserOrganisationMember3;
      export type UserId = Components.Parameters.UserId;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostUserPassword {
    export type Body = Components.RequestBodies.PostUserPasswordRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly userId: Parameters.UserId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type UserId = Components.Parameters.UserId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostUserTerminateTokensSupport {
    export type Output = unknown;
    export type Input = {
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostUserTerminateTokensSupport1;
      export type AccessToken =
        Components.Parameters.PostUserTerminateTokensSupport2;
      export type UserId = Components.Parameters.UserId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostUserTerminatedSessions {
    export type Body =
      Components.RequestBodies.PostUserTerminatedSessionsRequestBody;
    export type Output = Responses.$200;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 =
        Components.Responses.postUserTerminatedSessionsResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PostUserTerminatedSessions1;
      export type AccessToken =
        Components.Parameters.PostUserTerminatedSessions2;
      export type UserId = Components.Parameters.UserId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostVerify {
    export type Body = Components.RequestBodies.PostVerifyRequestBody;
    export type Output = Responses.$200;
    export type Input = {
      readonly body: Body;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.postVerifyResponse200<200>;
    }
    export namespace Parameters {
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutBatchDevices {
    export type Body = Components.RequestBodies.PutBatchDevicesRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PutBatchDevices0;
      export type AccessToken = Components.Parameters.PutBatchDevices1;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutDataDeviceCity {
    export type Body = Components.RequestBodies.PutDataDeviceCityRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PutDataDeviceCity1;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutDevicePosition {
    export type Body = Components.RequestBodies.PutDevicePositionRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization = Components.Parameters.PutDevicePosition1;
      export type AccessToken = Components.Parameters.PutDevicePosition2;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutIrradianceSensorCalibration {
    export type Body =
      Components.RequestBodies.PutIrradianceSensorCalibrationRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly key: Parameters.Key;
      readonly sensorId: Parameters.SensorId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Key = Components.Parameters.PutIrradianceSensorCalibration1;
      export type SensorId = Components.Parameters.SensorId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutOrganisationDeviceCalibration {
    export type Output = unknown;
    export type Input = {
      readonly calibrationName: Parameters.CalibrationName;
      readonly ratio: Parameters.Ratio;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type CalibrationName =
        Components.Parameters.PutOrganisationDeviceCalibration2;
      export type Ratio =
        Components.Parameters.PutOrganisationDeviceCalibration3;
      export type Authorization =
        Components.Parameters.PutOrganisationDeviceCalibration4;
      export type AccessToken =
        Components.Parameters.PutOrganisationDeviceCalibration5;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutOrganisationDeviceSettings {
    export type Body =
      Components.RequestBodies.PutOrganisationDeviceSettingsRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly deviceId: Parameters.DeviceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.putOrganisationDeviceSettingsResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PutOrganisationDeviceSettings2;
      export type AccessToken =
        Components.Parameters.PutOrganisationDeviceSettings3;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutOrganisationPlace {
    export type Body = Components.RequestBodies.PutOrganisationPlaceRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly placeId: Parameters.PlaceId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.putOrganisationPlaceResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PutOrganisationPlace2;
      export type AccessToken = Components.Parameters.PutOrganisationPlace3;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type PlaceId = Components.Parameters.PlaceId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutOrganisationTrial {
    export type Body = Components.RequestBodies.PutOrganisationTrialRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly sendEmail?: Parameters.SendEmail;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly organisationId: Parameters.OrganisationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type SendEmail = Components.Parameters.PutOrganisationTrial1;
      export type Authorization = Components.Parameters.PutOrganisationTrial2;
      export type AccessToken = Components.Parameters.PutOrganisationTrial3;
      export type OrganisationId = Components.Parameters.OrganisationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutPartnerApplication {
    export type Body =
      Components.RequestBodies.PutPartnerApplicationRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly partnerId: Parameters.PartnerId;
      readonly applicationId: Parameters.ApplicationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.putPartnerApplicationResponse201<201>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PutPartnerApplication2;
      export type AccessToken = Components.Parameters.PutPartnerApplication3;
      export type PartnerId = Components.Parameters.PartnerId;
      export type ApplicationId = Components.Parameters.ApplicationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutPartnerModuleParameters {
    export type Body =
      Components.RequestBodies.PutPartnerModuleParametersRequestBody;
    export type Output = Responses.$201;
    export type Input = {
      readonly body: Body;
      readonly partnerUserId: Parameters.PartnerUserId;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly partnerId: Parameters.PartnerId;
      readonly deviceId: Parameters.DeviceId;
      readonly moduleId: Parameters.ModuleId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $201 =
        Components.Responses.putPartnerModuleParametersResponse201<201>;
    }
    export namespace Parameters {
      export type PartnerUserId =
        Components.Parameters.PutPartnerModuleParameters1;
      export type Authorization =
        Components.Parameters.PutPartnerModuleParameters4;
      export type AccessToken =
        Components.Parameters.PutPartnerModuleParameters5;
      export type PartnerId = Components.Parameters.PartnerId;
      export type DeviceId = Components.Parameters.DeviceId;
      export type ModuleId = Components.Parameters.ModuleId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutPassword {
    export type Body = Components.RequestBodies.PutPasswordRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutQuoteStatus {
    export type Output = unknown;
    export type Input = {
      readonly status: Parameters.Status;
      readonly quoteId: Parameters.QuoteId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Status = Components.Parameters.PutQuoteStatus1;
      export type QuoteId = Components.Parameters.QuoteId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutUserFCMToken {
    export type Body = Components.RequestBodies.PutUserFCMTokenRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly isApnToken?: Parameters.IsAPNToken;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type IsAPNToken = Components.Parameters.PutUserFCMToken1;
      export type Authorization = Components.Parameters.PutUserFCMToken2;
      export type AccessToken = Components.Parameters.PutUserFCMToken3;
      export type UserId = Components.Parameters.UserId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PutUserInvitationAnswer {
    export type Body =
      Components.RequestBodies.PutUserInvitationAnswerRequestBody;
    export type Output = unknown;
    export type Input = {
      readonly body: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly userId: Parameters.UserId;
      readonly invitationId: Parameters.InvitationId;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Parameters {
      export type Authorization =
        Components.Parameters.PutUserInvitationAnswer2;
      export type AccessToken = Components.Parameters.PutUserInvitationAnswer3;
      export type UserId = Components.Parameters.UserId;
      export type InvitationId = Components.Parameters.InvitationId;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace GetGraphQL {
    export type Output = Responses.$200;
    export type Input = {
      readonly query: Parameters.Query;
      readonly variables?: Parameters.Variables;
      readonly operationName?: Parameters.OperationName;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.getGraphQLResponse200<200>;
    }
    export namespace Parameters {
      export type Query = Components.Parameters.GetGraphQL0;
      export type Variables = Components.Parameters.GetGraphQL1;
      export type OperationName = Components.Parameters.GetGraphQL2;
      export type Authorization = Components.Parameters.GetGraphQL3;
      export type AccessToken = Components.Parameters.GetGraphQL4;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
  export namespace PostGraphQL {
    export type Body = Components.RequestBodies.PostGraphQLRequestBody;
    export type Output = Responses.$200;
    export type Input = {
      readonly body?: Body;
      readonly authorization?: Parameters.Authorization;
      readonly accessToken?: Parameters.AccessToken;
      readonly xApiVersion?: Parameters.XAPIVersion;
      readonly xSdkVersion?: Parameters.XSDKVersion;
      readonly xAppVersion?: Parameters.XAPPVersion;
    };
    export namespace Responses {
      export type $200 = Components.Responses.postGraphQLResponse200<200>;
    }
    export namespace Parameters {
      export type Authorization = Components.Parameters.PostGraphQL0;
      export type AccessToken = Components.Parameters.PostGraphQL1;
      export type XAPIVersion = Components.Parameters.XApiVersion;
      export type XSDKVersion = Components.Parameters.XSdkVersion;
      export type XAPPVersion = Components.Parameters.XAppVersion;
    }
  }
}
declare namespace Components {
  export namespace RequestBodies {
    export type PutBucketDeviceSupportRequestBody =
      Components.Schemas.RequestBodiesputBucketDeviceSupportRequestBodyBody0;
    export type PutDeviceAccessSupportRequestBody =
      Components.Schemas.RequestBodiesputDeviceAccessSupportRequestBodyBody0;
    export type PutDeviceAccessesSupportRequestBody =
      Components.Schemas.RequestBodiesputDeviceAccessesSupportRequestBodyBody0;
    export type PostOrganisationChargebeeIdRequestBody =
      Components.Schemas.RequestBodiespostOrganisationChargebeeIdRequestBodyBody0;
    export type PutOrganisationDeviceRequestBody = Components.Schemas.Device;
    export type PutOrganisationMemberSupportRequestBody =
      Components.Schemas.RequestBodiesputOrganisationMemberSupportRequestBodyBody0;
    export type PutUserOrganisationAggregationRequestBody =
      Components.Schemas.Aggregation;
    export type PutUserOrganisationAlertRequestBody = Components.Schemas.Alert;
    export type PutUserOrganisationDeviceModuleRequestBody =
      Components.Schemas.RequestBodiesputUserOrganisationDeviceModuleRequestBodyBody0;
    export type PutUserOrganisationInvitationRequestBody =
      Components.Schemas.Invitation;
    export type PutUserOrganisationMemberRequestBody =
      Components.Schemas.RequestBodiesputUserOrganisationMemberRequestBodyBody0;
    export type PutUserOrganisationShareRequestBody = Components.Schemas.Shares;
    export type PostOrderRequestBody = Components.Schemas.OrderCreation;
    export type PutOrganisationRequestBody = Components.Schemas.Organisation;
    export type PostOrganisationPlaceRequestBody = Components.Schemas.Place;
    export type PutOrganisationPreferencesRequestBody =
      Components.Schemas.OrganisationPreferences;
    export type PutUserRequestBody = Components.Schemas.User;
    export type PostUserOrganisationRequestBody =
      Components.Schemas.Organisation;
    export type PutUserPreferencesRequestBody =
      Components.Schemas.UserPreferences;
    export type PutUserTestingSessionsRequestBody =
      Components.Schemas.RequestBodiesputUserTestingSessionsRequestBodyBody0;
    export type PostAircallIncomingCallsRequestBody =
      Components.Schemas.RequestBodiespostAircallIncomingCallsRequestBodyBody0;
    export type PostBulkExportsSupportRequestBody =
      Components.Schemas.RequestBodiespostBulkExportsSupportRequestBodyBody0;
    export type PostCSVBatchProdRequestBody =
      Components.Schemas.RequestBodiespostCSVBatchProdRequestBodyBody0;
    export type PostChargebeeEventsRequestBody =
      Components.Schemas.RequestBodiespostChargebeeEventsRequestBodyBody0;
    export type PostDataDeviceSituationRequestBody =
      Components.Schemas.RequestBodiespostDataDeviceSituationRequestBodyBody0;
    export type PostDataWeatherForecastModelRequestBody =
      Components.Schemas.RequestBodiespostDataWeatherForecastModelRequestBodyBody0;
    export type PostDeviceReplacementRequestBody =
      Components.Schemas.RequestBodiespostDeviceReplacementRequestBodyBody0;
    export type PostHubSpotContactSyncHookRequestBody =
      Components.Schemas.RequestBodiespostHubSpotContactSyncHookRequestBodyBody0;
    export type PostHubSpotContactTrialReactivationRequestBody =
      Components.Schemas.RequestBodiespostHubSpotContactTrialReactivationRequestBodyBody0;
    export type PostHubSpotDealSyncHookRequestBody =
      Components.Schemas.RequestBodiespostHubSpotDealSyncHookRequestBodyBody0;
    export type PostHubSpotDeleteDealHookRequestBody =
      Components.Schemas.RequestBodiespostHubSpotDeleteDealHookRequestBodyBody0;
    export type PostLoginRequestBody =
      | Components.Schemas.RequestBodiespostLoginRequestBodyBody0
      | Components.Schemas.RequestBodiespostLoginRequestBodyBody1;
    export type PostLostPasswordRequestBody =
      Components.Schemas.RequestBodiespostLostPasswordRequestBodyBody0;
    export type PostMailgunWebHookRequestBody =
      Components.Schemas.RequestBodiespostMailgunWebHookRequestBodyBody0;
    export type PostMailjetEventsRequestBody =
      Components.Schemas.RequestBodiespostMailjetEventsRequestBodyBody0;
    export type PostNetworkJoinRequestRequestBody =
      Components.Schemas.RequestBodiespostNetworkJoinRequestRequestBodyBody0;
    export type PostNexmoEventsRequestBody =
      Components.Schemas.RequestBodiespostNexmoEventsRequestBodyBody0;
    export type PostOAuth2AcknowledgeRequestBody =
      Components.Schemas.RequestBodiespostOAuth2AcknowledgeRequestBodyBody0;
    export type PostOAuth2TokenRequestBody =
      | Components.Schemas.RequestBodiespostOAuth2TokenRequestBodyBody0
      | Components.Schemas.RequestBodiespostOAuth2TokenRequestBodyBody1;
    export type PostOrganisationRequestBody = Components.Schemas.Organisation;
    export type PostOrganisationNetworkMemberPlanRequestBody =
      Components.Schemas.RequestBodiespostOrganisationNetworkMemberPlanRequestBodyBody0;
    export type PostOrganisationPaymentCompleteRequestBody =
      Components.Schemas.RequestBodiespostOrganisationPaymentCompleteRequestBodyBody0;
    export type PostOrganisationToNetworkSupportRequestBody =
      Components.Schemas.RequestBodiespostOrganisationToNetworkSupportRequestBodyBody0;
    export type PostPartnerApplicationRequestBody =
      Components.Schemas.Application;
    export type PostPartnerModuleRequestBody = Components.Schemas.Module;
    export type PostPartnerTokenRequestBody =
      | Components.Schemas.RequestBodiespostPartnerTokenRequestBodyBody0
      | Components.Schemas.RequestBodiespostPartnerTokenRequestBodyBody1;
    export type PostPartnerTokenRequestRequestBody =
      Components.Schemas.RequestBodiespostPartnerTokenRequestRequestBodyBody0;
    export type PostPlaceRequestBody = Components.Schemas.Place;
    export type PostQuoteRequestBody = Components.Schemas.NewQuote;
    export type PostQuoteRequestRequestBody =
      Components.Schemas.RequestBodiespostQuoteRequestRequestBodyBody0;
    export type PostRegisterRequestBody =
      Components.Schemas.RequestBodiespostRegisterRequestBodyBody0;
    export type PostRegisterAuth0RequestBody =
      Components.Schemas.RequestBodiespostRegisterAuth0RequestBodyBody0;
    export type PostSigfoxDataRequestBody =
      Components.Schemas.RequestBodiespostSigfoxDataRequestBodyBody0;
    export type PostSignatureCloudinaryRequestBody =
      Components.Schemas.RequestBodiespostSignatureCloudinaryRequestBodyBody0;
    export type PostSignupCheckRequestBody =
      Components.Schemas.RequestBodiespostSignupCheckRequestBodyBody0;
    export type PostSlackBotCommandRequestBody =
      Components.Schemas.RequestBodiespostSlackBotCommandRequestBodyBody0;
    export type PostSocialImageRequestBody =
      Components.Schemas.RequestBodiespostSocialImageRequestBodyBody0;
    export type PostTelitDataRequestBody =
      Components.Schemas.RequestBodiespostTelitDataRequestBodyBody0;
    export type PostTwilioEventsRequestBody =
      Components.Schemas.RequestBodiespostTwilioEventsRequestBodyBody0;
    export type PostUserRequestBody = Components.Schemas.NewUser;
    export type PostUserClearSessionsRequestBody =
      Components.Schemas.RequestBodiespostUserClearSessionsRequestBodyBody0;
    export type PostUserDeviceExportRequestBody =
      Components.Schemas.RequestBodiespostUserDeviceExportRequestBodyBody0;
    export type PostUserNetworkInvitationRequestBody =
      Components.Schemas.RequestBodiespostUserNetworkInvitationRequestBodyBody0;
    export type PostUserOrganisationAggregationRequestBody =
      Components.Schemas.Aggregation;
    export type PostUserOrganisationAlertRequestBody = Components.Schemas.Alert;
    export type PostUserOrganisationDeviceRequestBody =
      Components.Schemas.RequestBodiespostUserOrganisationDeviceRequestBodyBody0;
    export type PostUserOrganisationDevicesSupportRequestBody =
      Components.Schemas.RequestBodiespostUserOrganisationDevicesSupportRequestBodyBody0;
    export type PostUserOrganisationInvitationRequestBody =
      Components.Schemas.Invitation;
    export type PostUserOrganisationMemberRequestBody =
      Components.Schemas.RequestBodiespostUserOrganisationMemberRequestBodyBody0;
    export type PostUserPasswordRequestBody =
      Components.Schemas.RequestBodiespostUserPasswordRequestBodyBody0;
    export type PostUserTerminatedSessionsRequestBody =
      Components.Schemas.RequestBodiespostUserTerminatedSessionsRequestBodyBody0;
    export type PostVerifyRequestBody =
      Components.Schemas.RequestBodiespostVerifyRequestBodyBody0;
    export type PutBatchDevicesRequestBody =
      Components.Schemas.RequestBodiesputBatchDevicesRequestBodyBody0;
    export type PutDataDeviceCityRequestBody =
      Components.Schemas.RequestBodiesputDataDeviceCityRequestBodyBody0;
    export type PutDevicePositionRequestBody =
      Components.Schemas.RequestBodiesputDevicePositionRequestBodyBody0;
    export type PutIrradianceSensorCalibrationRequestBody =
      Components.Schemas.RequestBodiesputIrradianceSensorCalibrationRequestBodyBody0;
    export type PutOrganisationDeviceSettingsRequestBody =
      Components.Schemas.DeviceSettings;
    export type PutOrganisationPlaceRequestBody = Components.Schemas.Place;
    export type PutOrganisationTrialRequestBody =
      Components.Schemas.RequestBodiesputOrganisationTrialRequestBodyBody0;
    export type PutPartnerApplicationRequestBody =
      Components.Schemas.Application;
    export type PutPartnerModuleParametersRequestBody =
      Components.Schemas.RequestBodiesputPartnerModuleParametersRequestBodyBody0;
    export type PutPasswordRequestBody =
      Components.Schemas.RequestBodiesputPasswordRequestBodyBody0;
    export type PutUserFCMTokenRequestBody =
      Components.Schemas.RequestBodiesputUserFCMTokenRequestBodyBody0;
    export type PutUserInvitationAnswerRequestBody =
      Components.Schemas.RequestBodiesputUserInvitationAnswerRequestBodyBody0;
    export type PostGraphQLRequestBody =
      Components.Schemas.RequestBodiespostGraphQLRequestBodyBody0;
  }
  export namespace Parameters {
    export type Currency = "EUR" | "GBP";
    export type Limit = 10 | 50 | 100;
    export type LiveInterval =
      | "current"
      | "one_hour"
      | "three_hours"
      | "one_day"
      | "one_week"
      | "today"
      | "yesterday"
      | "this_week"
      | "last_seven_days";
    export type Days = 7 | 30 | 60 | 90;
    export type Interval = "15m" | "1h" | "6h" | "1d" | "1w" | "1M" | "1y";
    export type MeasureType =
      | "RELATIVE_HUMIDITY"
      | "TEMPERATURE"
      | "WIND_SPEED"
      | "WIND_GUST"
      | "WIND_DIRECTION"
      | "RAIN_FALL"
      | "IRRADIANCE";
    export type DeleteSencropMeasures3 =
      | "RELATIVE_HUMIDITY"
      | "TEMPERATURE"
      | "WIND_SPEED"
      | "WIND_GUST"
      | "WIND_DIRECTION"
      | "RAIN_FALL"
      | "IRRADIANCE";
    export type GetData4 = "1h" | "1d" | "1M";
    export type GetDeviceData5 = "1h" | "1d" | "1M";
    export type GetDeviceRawData3 = 10 | 50 | 100 | 500 | 1000;
    export type GetHubSpotProperties0 = "companies" | "contacts" | "deals";
    export type GetOAuth2Authorize0 = "code" | "token";
    export type GetOrders1 = "queued";
    export type GetOrdersAsXLSX1 = "queued";
    export type GetOrganisationHostedPage2 =
      | "plan-essential"
      | "plan-essential-gbp"
      | "plan-pro"
      | "plan-pro-gbp"
      | "plan-plus"
      | "plan-plus-gbp";
    export type GetOrganisationNetworkDevices2 = 10 | 50 | 100 | 1000;
    export type GetPublicWeatherLive4 =
      | "RAIN_FALL"
      | "TEMPERATURE"
      | "RELATIVE_HUMIDITY";
    export type GetPublicWeatherLive5 = "avg" | "min" | "max";
    export type GetPublicWeatherLive7 = "now" | "1h" | "1d" | "1w";
    export type GetQuoteHostedPage3 = "card" | "sepa" | "online";
    export type GetRadarImage0 =
      | "precipitation_type"
      | "precipitation_intensity";
    export type GetRadarImage1 = "be" | "fr" | "nl" | "uk" | "sp" | "de";
    export type GetUserDeviceHourlyData5 = 1 | 2 | 7 | 20 | 31;
    export type GetUserDeviceRawData4 = 10 | 50 | 100 | 500 | 1000;
    export type GetUserDevices5 = 10 | 50 | 100;
    export type GetUserHourlyData5 = 1 | 2 | 7 | 20 | 31;
    export type GetUserIntercomHash1 = "android" | "ios" | "web";
    export type GetWeatherLive2 = "now" | "1h" | "1d" | "1w";
    export type PostOAuth2Acknowledge0 = "code" | "token";
    export type PutOrganisationDeviceCalibration2 = "RAIN_FALL";
    export type PutQuoteStatus1 = "viewed" | "accepted" | "declined" | "closed";
    export type AccessId = NonNullable<number>;
    export type DeviceId = NonNullable<number>;
    export type Date = NonNullable<string>;
    export type OrganisationId = NonNullable<number>;
    export type MemberId = NonNullable<number>;
    export type NetworkId = NonNullable<string>;
    export type AlertId = NonNullable<string>;
    export type ModuleId = NonNullable<number>;
    export type InvitationId = NonNullable<string>;
    export type ShareId = NonNullable<string>;
    export type Zone = NonNullable<string>;
    export type WithCalibration = NonNullable<boolean>;
    export type Latitude = NonNullable<number>;
    export type Longitude = NonNullable<number>;
    export type MinimumFiability = NonNullable<number>;
    export type EndDate = NonNullable<string>;
    export type StartDate = NonNullable<string>;
    export type TimeZone = NonNullable<string>;
    export type IncludeHistory = NonNullable<boolean>;
    export type BeforeDate = NonNullable<string>;
    export type SensorId = NonNullable<string>;
    export type Start = NonNullable<number>;
    export type Patched = NonNullable<boolean>;
    export type StatusDate = NonNullable<string>;
    export type PlaceId = NonNullable<number>;
    export type ShareIsTransmittableFilter = NonNullable<boolean>;
    export type PartnerId = NonNullable<number>;
    export type QuoteId = NonNullable<string>;
    export type Radius = NonNullable<number>;
    export type TrialOperationId = NonNullable<string>;
    export type UserId = NonNullable<number>;
    export type AggregationId = NonNullable<string>;
    export type Geohash = NonNullable<string>;
    export type ConsumptionDate = NonNullable<string>;
    export type NetworkMemberId = NonNullable<number>;
    export type BucketDeviceId = NonNullable<number>;
    export type ApplicationId = NonNullable<string>;
    export type XApiVersion = NonNullable<string>;
    export type XSdkVersion = NonNullable<string>;
    export type XAppVersion = NonNullable<string>;
    export type DeleteBucketDeviceSupport1 = NonNullable<string>;
    export type DeleteBucketDeviceSupport2 = NonNullable<string>;
    export type PutBucketDeviceSupport1 = NonNullable<string>;
    export type PutBucketDeviceSupport2 = NonNullable<string>;
    export type DeleteDeviceAccessSupport1 = NonNullable<string>;
    export type DeleteDeviceAccessSupport2 = NonNullable<string>;
    export type PutDeviceAccessSupport1 = NonNullable<string>;
    export type PutDeviceAccessSupport2 = NonNullable<string>;
    export type DeleteDeviceAccessesSupport1 = NonNullable<string>;
    export type DeleteDeviceAccessesSupport2 = NonNullable<string>;
    export type PutDeviceAccessesSupport1 = NonNullable<string>;
    export type PutDeviceAccessesSupport2 = NonNullable<string>;
    export type DeleteExpiredTokens1 = NonNullable<string>;
    export type DeleteExpiredTokens2 = NonNullable<string>;
    export type DeleteOrganisationChargebeeId1 = NonNullable<string>;
    export type DeleteOrganisationChargebeeId2 = NonNullable<string>;
    export type PostOrganisationChargebeeId1 = NonNullable<string>;
    export type PostOrganisationChargebeeId2 = NonNullable<string>;
    export type DeleteOrganisationDevice2 = NonNullable<string>;
    export type DeleteOrganisationDevice3 = NonNullable<string>;
    export type GetOrganisationDevice2 = NonNullable<string>;
    export type GetOrganisationDevice3 = NonNullable<string>;
    export type PutOrganisationDevice2 = NonNullable<string>;
    export type PutOrganisationDevice3 = NonNullable<string>;
    export type DeleteOrganisationMemberSupport2 = NonNullable<string>;
    export type DeleteOrganisationMemberSupport3 = NonNullable<string>;
    export type PutOrganisationMemberSupport2 = NonNullable<string>;
    export type PutOrganisationMemberSupport3 = NonNullable<string>;
    export type DeleteOrganisationNetworkDeviceAccess3 = NonNullable<string>;
    export type DeleteOrganisationNetworkDeviceAccess4 = NonNullable<string>;
    export type PutOrganisationNetworkDeviceAccess3 = NonNullable<string>;
    export type PutOrganisationNetworkDeviceAccess4 = NonNullable<string>;
    export type DeleteSencropMeasures1 = NonNullable<string>;
    export type DeleteSencropMeasures2 = NonNullable<string>;
    export type DeleteSencropMeasures4 = NonNullable<string>;
    export type DeleteSencropMeasures5 = NonNullable<string>;
    export type DeleteUserOrganisationAggregation3 = NonNullable<string>;
    export type DeleteUserOrganisationAggregation4 = NonNullable<string>;
    export type PutUserOrganisationAggregation3 = NonNullable<string>;
    export type PutUserOrganisationAggregation4 = NonNullable<string>;
    export type DeleteUserOrganisationAlert3 = NonNullable<string>;
    export type DeleteUserOrganisationAlert4 = NonNullable<string>;
    export type PutUserOrganisationAlert3 = NonNullable<string>;
    export type PutUserOrganisationAlert4 = NonNullable<string>;
    export type DeleteUserOrganisationDeviceModule4 = NonNullable<string>;
    export type DeleteUserOrganisationDeviceModule5 = NonNullable<string>;
    export type PutUserOrganisationDeviceModule4 = NonNullable<string>;
    export type PutUserOrganisationDeviceModule5 = NonNullable<string>;
    export type DeleteUserOrganisationInvitation3 = NonNullable<string>;
    export type DeleteUserOrganisationInvitation4 = NonNullable<string>;
    export type PutUserOrganisationInvitation3 = NonNullable<string>;
    export type PutUserOrganisationInvitation4 = NonNullable<string>;
    export type DeleteUserOrganisationMember3 = NonNullable<string>;
    export type DeleteUserOrganisationMember4 = NonNullable<string>;
    export type PutUserOrganisationMember3 = NonNullable<string>;
    export type PutUserOrganisationMember4 = NonNullable<string>;
    export type DeleteUserOrganisationOpenNetworkMembership3 =
      NonNullable<string>;
    export type DeleteUserOrganisationOpenNetworkMembership4 =
      NonNullable<string>;
    export type PutUserOrganisationOpenNetworkMembership3 = NonNullable<string>;
    export type PutUserOrganisationOpenNetworkMembership4 = NonNullable<string>;
    export type DeleteUserOrganisationShare3 = NonNullable<string>;
    export type DeleteUserOrganisationShare4 = NonNullable<string>;
    export type PutUserOrganisationShare3 = NonNullable<string>;
    export type PutUserOrganisationShare4 = NonNullable<string>;
    export type GetOpenAPI3 = NonNullable<boolean>;
    export type GetOpenAPI4 = NonNullable<string>;
    export type GetOpenAPI5 = NonNullable<string>;
    export type GetAgritelPrices1 = NonNullable<string>;
    export type GetAgritelPrices2 = NonNullable<string>;
    export type GetChargebeeCustomer0 = NonNullable<string>;
    export type GetChargebeeCustomer1 = NonNullable<string>;
    export type GetChargebeeCustomer2 = NonNullable<string>;
    export type GetChargebeeCustomer3 = NonNullable<string>;
    export type GetChargebeeRessources2 = NonNullable<string>;
    export type GetChargebeeRessources3 = NonNullable<string>;
    export type GetCotations0 = NonNullable<string>;
    export type GetCotations1 = NonNullable<string>;
    export type GetCustomerByEmail0 = NonNullable<string>;
    export type GetCustomerByEmail1 = NonNullable<string>;
    export type GetCustomerByEmail2 = NonNullable<string>;
    export type GetData10 = NonNullable<string>;
    export type GetData11 = NonNullable<string>;
    export type GetData12 = NonNullable<string>;
    export type GetDeviceData6 = NonNullable<string>;
    export type GetDeviceRawData5 = NonNullable<string>;
    export type GetDeviceRawData6 = NonNullable<string>;
    export type GetDevices1 = NonNullable<string>;
    export type GetDevices2 = NonNullable<string>;
    export type GetFactoryMeasures1 = NonNullable<string>;
    export type GetFactoryMeasures2 = NonNullable<string>;
    export type GetFactorySigfoxStatus0 = NonNullable<string>;
    export type GetFactorySigfoxStatus1 = NonNullable<string>;
    export type GetFactorySigfoxStatus2 = NonNullable<string>;
    export type GetHubSpotContact0 = NonNullable<string>;
    export type GetHubSpotContact1 = NonNullable<string>;
    export type GetHubSpotContact2 = NonNullable<string>;
    export type GetHubSpotProperties1 = NonNullable<string>;
    export type GetHubSpotProperties2 = NonNullable<string>;
    export type GetHubSpotProperties3 = NonNullable<string>;
    export type GetHubSpotQuotatorCard0 = NonNullable<string>;
    export type GetHubSpotQuotatorCard1 = NonNullable<string>;
    export type GetHubSpotQuotatorCard2 = NonNullable<number>;
    export type GetHubSpotQuotatorCard3 = NonNullable<string>;
    export type GetHubSpotQuotatorCard4 = NonNullable<number>;
    export type GetHubSpotQuotatorCard5 = NonNullable<number>;
    export type GetHubSpotTableauCard0 = NonNullable<string>;
    export type GetHubSpotTableauCard1 = NonNullable<string>;
    export type GetHubSpotTableauCard2 = NonNullable<number>;
    export type GetHubSpotTableauCard3 = NonNullable<string>;
    export type GetHubSpotTableauCard4 = NonNullable<number>;
    export type GetHubSpotTableauCard5 = NonNullable<number>;
    export type GetHubSpotTableauCard6 = NonNullable<string>;
    export type GetHubSpotTableauCard7 = NonNullable<string>;
    export type GetInvitationDeviceLiveAggregations2 = NonNullable<string>;
    export type GetLatestIrrCalibration1 = NonNullable<string>;
    export type GetLatestIrrCalibration2 = NonNullable<string>;
    export type GetLatestIrrCalibrations1 = NonNullable<string>;
    export type GetLatestIrrCalibrations2 = NonNullable<string>;
    export type GetMySelf0 = NonNullable<string>;
    export type GetMySelf1 = NonNullable<string>;
    export type GetNetworkDeviceLiveAggregations2 = NonNullable<string>;
    export type GetOAuth2Authorize1 = NonNullable<string>;
    export type GetOAuth2Authorize2 = NonNullable<string>;
    export type GetOAuth2Authorize3 = NonNullable<string>;
    export type GetOAuth2Authorize4 = NonNullable<string>;
    export type GetOrders2 = NonNullable<boolean>;
    export type GetOrders3 = NonNullable<string>;
    export type GetOrders4 = NonNullable<string>;
    export type PostOrder0 = NonNullable<string>;
    export type PostOrder1 = NonNullable<string>;
    export type GetOrdersAsXLSX2 = NonNullable<boolean>;
    export type GetOrdersAsXLSX3 = NonNullable<string>;
    export type GetOrdersAsXLSX4 = NonNullable<string>;
    export type GetOrdersByUserEmail0 = NonNullable<string>;
    export type GetOrdersByUserEmail1 = NonNullable<string>;
    export type GetOrdersByUserEmail2 = NonNullable<string>;
    export type GetOrganisation1 = NonNullable<string>;
    export type GetOrganisation2 = NonNullable<string>;
    export type PutOrganisation1 = NonNullable<string>;
    export type PutOrganisation2 = NonNullable<string>;
    export type GetOrganisationChargebeePortalSession1 = NonNullable<string>;
    export type GetOrganisationChargebeePortalSession2 = NonNullable<string>;
    export type GetOrganisationDevices3 = NonNullable<string>;
    export type GetOrganisationDevices4 = NonNullable<string>;
    export type GetOrganisationDevicesLiveAggregations8 = NonNullable<string>;
    export type GetOrganisationDevicesLiveAggregations9 = NonNullable<string>;
    export type GetOrganisationHostedPage1 = NonNullable<string>;
    export type GetOrganisationHostedPage3 = NonNullable<string>;
    export type GetOrganisationHostedPage4 = NonNullable<string>;
    export type GetOrganisationHostedPageBattery1 = NonNullable<string>;
    export type GetOrganisationHostedPageBattery2 = NonNullable<number>;
    export type GetOrganisationHostedPageBattery3 = NonNullable<number>;
    export type GetOrganisationHostedPageBattery4 = NonNullable<number>;
    export type GetOrganisationHostedPageBattery5 = NonNullable<string>;
    export type GetOrganisationHostedPageBattery6 = NonNullable<string>;
    export type GetOrganisationHostedPageUpgradePlanPlus1 = NonNullable<string>;
    export type GetOrganisationHostedPageUpgradePlanPlus2 = NonNullable<string>;
    export type GetOrganisationHostedPageUpgradePlanPlus3 = NonNullable<string>;
    export type GetOrganisationInvitation2 = NonNullable<string>;
    export type GetOrganisationInvitation3 = NonNullable<string>;
    export type GetOrganisationInvitations5 = NonNullable<string>;
    export type GetOrganisationInvitations6 = NonNullable<string>;
    export type GetOrganisationMember2 = NonNullable<string>;
    export type GetOrganisationMember3 = NonNullable<string>;
    export type GetOrganisationMembers1 = NonNullable<string>;
    export type GetOrganisationMembers2 = NonNullable<string>;
    export type GetOrganisationNetworkDevices4 = NonNullable<string>;
    export type GetOrganisationNetworkDevices5 = NonNullable<string>;
    export type GetOrganisationNetworkDevices6 = NonNullable<string>;
    export type GetOrganisationNetworks3 = NonNullable<string>;
    export type GetOrganisationNetworks4 = NonNullable<string>;
    export type GetOrganisationPlace2 = NonNullable<string>;
    export type GetOrganisationPlace3 = NonNullable<string>;
    export type GetOrganisationPlaces1 = NonNullable<string>;
    export type GetOrganisationPlaces2 = NonNullable<string>;
    export type PostOrganisationPlace1 = NonNullable<string>;
    export type PostOrganisationPlace2 = NonNullable<string>;
    export type GetOrganisationPreferences1 = NonNullable<string>;
    export type GetOrganisationPreferences2 = NonNullable<string>;
    export type PutOrganisationPreferences1 = NonNullable<string>;
    export type PutOrganisationPreferences2 = NonNullable<string>;
    export type GetOrganisationShare2 = NonNullable<string>;
    export type GetOrganisationShare3 = NonNullable<string>;
    export type GetOrganisationShares6 = NonNullable<string>;
    export type GetOrganisationShares7 = NonNullable<string>;
    export type GetOrganisationsTrialOperations1 = NonNullable<boolean>;
    export type GetOrganisationsTrialOperations2 = NonNullable<string>;
    export type GetOrganisationsTrialOperations3 = NonNullable<string>;
    export type GetPartnerDevices3 = NonNullable<string>;
    export type GetPartnerDevices4 = NonNullable<string>;
    export type GetPlans0 = NonNullable<string>;
    export type GetPlans1 = NonNullable<string>;
    export type GetPlans2 = NonNullable<string>;
    export type GetPublicWeatherLive0 = NonNullable<number>;
    export type GetPublicWeatherLive1 = NonNullable<number>;
    export type GetPublicWeatherLive2 = NonNullable<number>;
    export type GetPublicWeatherLive3 = NonNullable<number>;
    export type GetPublicWeatherLive6 = NonNullable<number>;
    export type GetPublicWeatherLive8 = NonNullable<string>;
    export type GetPublicWeatherLive9 = NonNullable<string>;
    export type GetPublicWeatherLiveAggregations4 = NonNullable<string>;
    export type GetPublicWeatherLiveAggregations5 = NonNullable<string>;
    export type GetQuoteHostedPage2 = NonNullable<string>;
    export type GetQuoteHostedPage4 = NonNullable<string>;
    export type GetQuoteHostedPage5 = NonNullable<string>;
    export type GetRadarImage2 = NonNullable<string>;
    export type GetRadarImage3 = NonNullable<string>;
    export type GetRadarImage4 = NonNullable<string>;
    export type GetRadarImage5 = NonNullable<string>;
    export type GetSearchPublicOrganisation0 = NonNullable<string>;
    export type GetSearchPublicUser0 = NonNullable<string>;
    export type GetSearchUser0 = NonNullable<string>;
    export type GetSearchUser1 = NonNullable<string>;
    export type GetSearchUser2 = NonNullable<string>;
    export type GetSigfoxMap1 = NonNullable<string>;
    export type GetSigfoxMap2 = NonNullable<string>;
    export type GetSigfoxMap3 = NonNullable<string>;
    export type GetStatusHead0 = NonNullable<string>;
    export type GetStatusHead1 = NonNullable<string>;
    export type GetStatusHead2 = NonNullable<string>;
    export type GetUser1 = NonNullable<string>;
    export type GetUser2 = NonNullable<string>;
    export type PutUser1 = NonNullable<string>;
    export type PutUser2 = NonNullable<string>;
    export type GetUserAggregation3 = NonNullable<string>;
    export type GetUserAggregation4 = NonNullable<string>;
    export type GetUserAggregations1 = NonNullable<boolean>;
    export type GetUserAggregations3 = NonNullable<string>;
    export type GetUserAggregations4 = NonNullable<string>;
    export type GetUserAlert2 = NonNullable<string>;
    export type GetUserAlert3 = NonNullable<string>;
    export type GetUserAlertVoiceMessage2 = NonNullable<string>;
    export type GetUserAlerts1 = NonNullable<string>;
    export type GetUserAlerts2 = NonNullable<string>;
    export type GetUserDailyData11 = NonNullable<string>;
    export type GetUserDailyData12 = NonNullable<string>;
    export type GetUserDevice3 = NonNullable<string>;
    export type GetUserDevice4 = NonNullable<string>;
    export type GetUserDeviceContinuousStatistics10 = NonNullable<string>;
    export type GetUserDeviceContinuousStatistics11 = NonNullable<string>;
    export type GetUserDeviceDailyData9 = NonNullable<string>;
    export type GetUserDeviceDailyData10 = NonNullable<string>;
    export type GetUserDeviceForecasts2 = NonNullable<string>;
    export type GetUserDeviceForecasts3 = NonNullable<string>;
    export type GetUserDeviceForecasts4 = NonNullable<string>;
    export type GetUserDeviceHourlyData9 = NonNullable<string>;
    export type GetUserDeviceHourlyData10 = NonNullable<string>;
    export type GetUserDeviceModules2 = NonNullable<string>;
    export type GetUserDeviceModules3 = NonNullable<string>;
    export type GetUserDevicePositions3 = NonNullable<string>;
    export type GetUserDevicePositions4 = NonNullable<string>;
    export type GetUserDeviceRawData6 = NonNullable<string>;
    export type GetUserDeviceRawData7 = NonNullable<string>;
    export type GetUserDeviceStatistics10 = NonNullable<string>;
    export type GetUserDeviceStatistics11 = NonNullable<string>;
    export type GetUserDeviceSummary3 = NonNullable<string>;
    export type GetUserDeviceSummary5 = NonNullable<string>;
    export type GetUserDeviceSummary6 = NonNullable<string>;
    export type GetUserDevices2 = NonNullable<string>;
    export type GetUserDevices6 = NonNullable<number>;
    export type GetUserDevices7 = NonNullable<string>;
    export type GetUserDevices8 = NonNullable<string>;
    export type GetUserDevicesLiveAggregations8 = NonNullable<string>;
    export type GetUserDevicesLiveAggregations9 = NonNullable<string>;
    export type GetUserForecasts3 = NonNullable<string>;
    export type GetUserForecasts4 = NonNullable<string>;
    export type GetUserForecasts5 = NonNullable<string>;
    export type GetUserHourlyData11 = NonNullable<string>;
    export type GetUserHourlyData12 = NonNullable<string>;
    export type GetUserIntercomHash2 = NonNullable<string>;
    export type GetUserIntercomHash3 = NonNullable<string>;
    export type GetUserInvitation2 = NonNullable<string>;
    export type GetUserInvitation3 = NonNullable<string>;
    export type GetUserInvitations6 = NonNullable<string>;
    export type GetUserInvitations7 = NonNullable<string>;
    export type GetUserOrganisations1 = NonNullable<string>;
    export type GetUserOrganisations2 = NonNullable<string>;
    export type PostUserOrganisation1 = NonNullable<string>;
    export type PostUserOrganisation2 = NonNullable<string>;
    export type GetUserPendingInvitation2 = NonNullable<string>;
    export type GetUserPendingInvitation3 = NonNullable<string>;
    export type GetUserPendingInvitations3 = NonNullable<string>;
    export type GetUserPendingInvitations4 = NonNullable<string>;
    export type GetUserPreferences1 = NonNullable<string>;
    export type GetUserPreferences2 = NonNullable<string>;
    export type PutUserPreferences1 = NonNullable<string>;
    export type PutUserPreferences2 = NonNullable<string>;
    export type GetUserStatistics12 = NonNullable<string>;
    export type GetUserStatistics13 = NonNullable<string>;
    export type GetUserTestingSessions1 = NonNullable<string>;
    export type GetUserTestingSessions2 = NonNullable<string>;
    export type PutUserTestingSessions1 = NonNullable<string>;
    export type PutUserTestingSessions2 = NonNullable<string>;
    export type GetWeatherForecastModel2 = NonNullable<boolean>;
    export type GetWeatherForecastModel3 = NonNullable<string>;
    export type GetWeatherLive3 = NonNullable<string>;
    export type GetWeatherLive4 = NonNullable<string>;
    export type HandleAggregationsCompute1 = NonNullable<string>;
    export type HandleAggregationsCompute2 = NonNullable<string>;
    export type HandleAlertCheck1 = NonNullable<string>;
    export type HandleAlertCheck2 = NonNullable<string>;
    export type HandleAlertRepetition1 = NonNullable<string>;
    export type HandleAlertRepetition2 = NonNullable<string>;
    export type PostAlertsTrialDisable1 = NonNullable<string>;
    export type PostAlertsTrialDisable2 = NonNullable<string>;
    export type PostApplicationEvent1 = NonNullable<string>;
    export type PostApplicationEvent2 = NonNullable<string>;
    export type PostBulkExportsSupport0 = NonNullable<number>;
    export type PostBulkExportsSupport1 = NonNullable<string>;
    export type PostBulkExportsSupport2 = NonNullable<string>;
    export type PostBulkExportsSupport3 = NonNullable<string>;
    export type PostCSVArvalisMileosData1 = NonNullable<string>;
    export type PostCSVArvalisMileosData2 = NonNullable<string>;
    export type PostCSVArvalisMileosInitialData1 = NonNullable<string>;
    export type PostCSVArvalisMileosInitialData2 = NonNullable<string>;
    export type PostCSVBatchProd0 = NonNullable<string>;
    export type PostCSVBatchProd1 = NonNullable<string>;
    export type PostCSVCTIFLEmail1 = NonNullable<string>;
    export type PostCSVCTIFLEmail2 = NonNullable<string>;
    export type PostCSVMovidaEmail1 = NonNullable<string>;
    export type PostCSVMovidaEmail2 = NonNullable<string>;
    export type PostCSVMovidaInitialEmail1 = NonNullable<string>;
    export type PostCSVMovidaInitialEmail2 = NonNullable<string>;
    export type PostCSVRimproData1 = NonNullable<string>;
    export type PostCSVRimproData2 = NonNullable<string>;
    export type PostCSVTTWData1 = NonNullable<string>;
    export type PostCSVTTWData2 = NonNullable<string>;
    export type PostChargebeeEvents0 = NonNullable<string>;
    export type PostCloudwatchEvents1 = NonNullable<string>;
    export type PostCloudwatchEvents2 = NonNullable<string>;
    export type PostCropsHits1 = NonNullable<string>;
    export type PostCropsHits2 = NonNullable<string>;
    export type PostDataDeviceSituation1 = NonNullable<string>;
    export type PostDataWeatherForecastModel0 = NonNullable<string>;
    export type PostDeviceCleanIngestionCacheSupport1 = NonNullable<string>;
    export type PostDeviceCleanIngestionCacheSupport2 = NonNullable<string>;
    export type PostDeviceReplacement0 = NonNullable<number>;
    export type PostDeviceReplacement1 = NonNullable<number>;
    export type PostDeviceReplacement2 = NonNullable<string>;
    export type PostDeviceReplacement3 = NonNullable<string>;
    export type PostFirehoseRecordPerLine0 = NonNullable<string>;
    export type PostFirehoseRecordPerLine1 = NonNullable<string>;
    export type PostFirehoseStream1 = NonNullable<string>;
    export type PostFirehoseStream2 = NonNullable<string>;
    export type PostForecastStorage1 = NonNullable<string>;
    export type PostForecastStorage2 = NonNullable<string>;
    export type PostIngestionData1 = NonNullable<string>;
    export type PostIngestionData2 = NonNullable<string>;
    export type PostInvitationResend1 = NonNullable<string>;
    export type PostInvitationResend2 = NonNullable<string>;
    export type PostIrrelisData1 = NonNullable<string>;
    export type PostIrrelisData2 = NonNullable<string>;
    export type PostLambdaLogs1 = NonNullable<string>;
    export type PostLambdaLogs2 = NonNullable<string>;
    export type PostLogin0 = NonNullable<boolean>;
    export type PostMailgunWebHook0 = NonNullable<string>;
    export type PostMeteoGroupData1 = NonNullable<string>;
    export type PostMeteoGroupData2 = NonNullable<string>;
    export type PostMovidaSetup1 = NonNullable<string>;
    export type PostMovidaSetup2 = NonNullable<string>;
    export type PostOAuth2Acknowledge1 = NonNullable<string>;
    export type PostOAuth2Acknowledge2 = NonNullable<string>;
    export type PostOAuth2Token0 = NonNullable<string>;
    export type PostOrganisation0 = NonNullable<string>;
    export type PostOrganisation1 = NonNullable<string>;
    export type PostOrganisationDeviceTransfer2 = NonNullable<string>;
    export type PostOrganisationDeviceTransfer3 = NonNullable<string>;
    export type PostOrganisationDeviceTransfer4 = NonNullable<string>;
    export type PostOrganisationEndTrialSupport1 = NonNullable<string>;
    export type PostOrganisationEndTrialSupport2 = NonNullable<string>;
    export type PostOrganisationNetworkMemberPlan2 = NonNullable<string>;
    export type PostOrganisationNetworkMemberPlan3 = NonNullable<string>;
    export type PostOrganisationPaymentComplete1 = NonNullable<string>;
    export type PostOrganisationPaymentComplete2 = NonNullable<string>;
    export type PostOrganisationToNetworkSupport1 = NonNullable<string>;
    export type PostOrganisationToNetworkSupport2 = NonNullable<string>;
    export type PostOrganisationTrialReactivation1 = NonNullable<string>;
    export type PostOrganisationTrialReactivation2 = NonNullable<string>;
    export type PostOrganisationsMerge0 = NonNullable<number>;
    export type PostOrganisationsMerge1 = NonNullable<number>;
    export type PostOrganisationsMerge2 = NonNullable<string>;
    export type PostOrganisationsMerge3 = NonNullable<string>;
    export type PostPartnerApplication1 = NonNullable<string>;
    export type PostPartnerApplication2 = NonNullable<string>;
    export type PostPartnerModule1 = NonNullable<string>;
    export type PostPartnerModule2 = NonNullable<string>;
    export type PostPartnerToken1 = NonNullable<string>;
    export type PostPartnerTokenRequest1 = NonNullable<string>;
    export type PostPlace0 = NonNullable<string>;
    export type PostPlace1 = NonNullable<string>;
    export type PostPrevilisData1 = NonNullable<string>;
    export type PostPrevilisData2 = NonNullable<string>;
    export type PostQuote0 = NonNullable<string>;
    export type PostQuote1 = NonNullable<string>;
    export type PostQuoteOffline2 = NonNullable<string>;
    export type PostQuoteOffline3 = NonNullable<string>;
    export type PostRegister0 = NonNullable<string>;
    export type PostRegister1 = NonNullable<string>;
    export type PostRegister2 = NonNullable<string>;
    export type PostRegister3 = NonNullable<boolean>;
    export type PostSigfoxData0 = NonNullable<string>;
    export type PostSigfoxReshape1 = NonNullable<string>;
    export type PostSigfoxReshape2 = NonNullable<string>;
    export type PostSignatureCloudinary0 = NonNullable<string>;
    export type PostSignatureCloudinary1 = NonNullable<string>;
    export type PostSignupCheck0 = NonNullable<string>;
    export type PostSocialImage0 = NonNullable<string>;
    export type PostSocialImage1 = NonNullable<string>;
    export type PostUserClearSessions1 = NonNullable<string>;
    export type PostUserClearSessions2 = NonNullable<string>;
    export type PostUserDeviceExport2 = NonNullable<string>;
    export type PostUserDeviceExport3 = NonNullable<string>;
    export type PostUserDeviceExport4 = NonNullable<string>;
    export type PostUserNetworkInvitation2 = NonNullable<string>;
    export type PostUserOrganisationAggregation2 = NonNullable<string>;
    export type PostUserOrganisationAggregation3 = NonNullable<string>;
    export type PostUserOrganisationAlert2 = NonNullable<string>;
    export type PostUserOrganisationAlert3 = NonNullable<string>;
    export type PostUserOrganisationDevice2 = NonNullable<string>;
    export type PostUserOrganisationDevice3 = NonNullable<string>;
    export type PostUserOrganisationDeviceSupport3 = NonNullable<string>;
    export type PostUserOrganisationDeviceSupport4 = NonNullable<string>;
    export type PostUserOrganisationDevicesSupport2 = NonNullable<string>;
    export type PostUserOrganisationDevicesSupport3 = NonNullable<string>;
    export type PostUserOrganisationInvitation2 = NonNullable<string>;
    export type PostUserOrganisationInvitation3 = NonNullable<string>;
    export type PostUserOrganisationMember2 = NonNullable<string>;
    export type PostUserOrganisationMember3 = NonNullable<string>;
    export type PostUserTerminateTokensSupport1 = NonNullable<string>;
    export type PostUserTerminateTokensSupport2 = NonNullable<string>;
    export type PostUserTerminatedSessions1 = NonNullable<string>;
    export type PostUserTerminatedSessions2 = NonNullable<string>;
    export type PutBatchDevices0 = NonNullable<string>;
    export type PutBatchDevices1 = NonNullable<string>;
    export type PutDataDeviceCity1 = NonNullable<string>;
    export type PutDevicePosition1 = NonNullable<string>;
    export type PutDevicePosition2 = NonNullable<string>;
    export type PutIrradianceSensorCalibration1 = NonNullable<string>;
    export type PutOrganisationDeviceCalibration3 = NonNullable<number>;
    export type PutOrganisationDeviceCalibration4 = NonNullable<string>;
    export type PutOrganisationDeviceCalibration5 = NonNullable<string>;
    export type PutOrganisationDeviceSettings2 = NonNullable<string>;
    export type PutOrganisationDeviceSettings3 = NonNullable<string>;
    export type PutOrganisationPlace2 = NonNullable<string>;
    export type PutOrganisationPlace3 = NonNullable<string>;
    export type PutOrganisationTrial1 = NonNullable<boolean>;
    export type PutOrganisationTrial2 = NonNullable<string>;
    export type PutOrganisationTrial3 = NonNullable<string>;
    export type PutPartnerApplication2 = NonNullable<string>;
    export type PutPartnerApplication3 = NonNullable<string>;
    export type PutPartnerModuleParameters1 = NonNullable<number>;
    export type PutPartnerModuleParameters4 = NonNullable<string>;
    export type PutPartnerModuleParameters5 = NonNullable<string>;
    export type PutUserFCMToken1 = NonNullable<boolean>;
    export type PutUserFCMToken2 = NonNullable<string>;
    export type PutUserFCMToken3 = NonNullable<string>;
    export type PutUserInvitationAnswer2 = NonNullable<string>;
    export type PutUserInvitationAnswer3 = NonNullable<string>;
    export type GetGraphQL0 = NonNullable<string>;
    export type GetGraphQL1 = NonNullable<string>;
    export type GetGraphQL2 = NonNullable<string>;
    export type GetGraphQL3 = NonNullable<string>;
    export type GetGraphQL4 = NonNullable<string>;
    export type PostGraphQL0 = NonNullable<string>;
    export type PostGraphQL1 = NonNullable<string>;
    export type Ranges = NonNullable<(0.3 | 3 | 7 | 10 | 15 | 40 | 80)[]>;
    export type Measures = NonNullable<
      (
        | "RELATIVE_HUMIDITY"
        | "RELATIVE_HUMIDITY_MIN"
        | "RELATIVE_HUMIDITY_MAX"
        | "RELATIVE_HUMIDITY_INTERPOLATED"
        | "RELATIVE_HUMIDITY_LAST"
        | "TEMPERATURE"
        | "TEMPERATURE_MIN"
        | "TEMPERATURE_MAX"
        | "TEMPERATURE_INTERPOLATED"
        | "TEMPERATURE_LAST"
        | "WET_TEMPERATURE"
        | "WET_TEMPERATURE_MIN"
        | "WET_TEMPERATURE_MAX"
        | "WET_TEMPERATURE_INTERPOLATED"
        | "WET_TEMPERATURE_LAST"
        | "LEAF_SENSOR_CONDUCTIVITY"
        | "LEAF_SENSOR_CONDUCTIVITY_MIN"
        | "LEAF_SENSOR_CONDUCTIVITY_MAX"
        | "LEAF_SENSOR_CONDUCTIVITY_LAST"
        | "RAIN_FALL"
        | "RAIN_FALL_MEAN_SUM"
        | "RAIN_FALL_MEAN_SUM_ADJUSTED"
        | "WIND_SPEED"
        | "WIND_GUST"
        | "WIND_DIRECTION"
        | "MOVE"
        | "LEAF_WETNESS"
        | "LEAF_WETNESS_MEDIUM"
        | "LEAF_WETNESS_HIGH"
        | "IRRADIANCE"
        | "RH_AIR_H1"
        | "RH_AIR_H1_MIN"
        | "RH_AIR_H1_MAX"
        | "TEMP_AIR_H1"
        | "TEMP_AIR_H1_MIN"
        | "TEMP_AIR_H1_MAX"
        | "RAIN_TIC"
        | "WIND_MEAN"
        | "WIND_MAX"
      )[]
    >;
    export type Aggregations = NonNullable<
      (
        | "RELATIVE_HUMIDITY_MEAN"
        | "RELATIVE_HUMIDITY_MIN"
        | "RELATIVE_HUMIDITY_MAX"
        | "RELATIVE_HUMIDITY_INTERPOLATED"
        | "RELATIVE_HUMIDITY_LAST"
        | "TEMPERATURE_MEAN"
        | "TEMPERATURE_MIN"
        | "TEMPERATURE_MAX"
        | "TEMPERATURE_INTERPOLATED"
        | "TEMPERATURE_LAST"
        | "WET_TEMPERATURE_MEAN"
        | "WET_TEMPERATURE_MIN"
        | "WET_TEMPERATURE_MAX"
        | "WET_TEMPERATURE_INTERPOLATED"
        | "WET_TEMPERATURE_LAST"
        | "RAIN_FALL_MEAN_SUM"
        | "RAIN_FALL_MEAN_SUM_ADJUSTED"
        | "RAIN_FALL_SUM"
        | "RAIN_FALL_MIN"
        | "RAIN_FALL_MIN_SUM"
        | "RAIN_FALL_MAX"
        | "RAIN_FALL_MAX_SUM"
        | "MOVE_SUM"
        | "INIT_SUM"
        | "WIND_SPEED_MEAN"
        | "WIND_SPEED_MIN"
        | "WIND_SPEED_MAX"
        | "WIND_SPEED_INTERPOLATED"
        | "WIND_SPEED_LAST"
        | "WIND_GUST_MEAN"
        | "WIND_GUST_MIN"
        | "WIND_GUST_MAX"
        | "WIND_GUST_INTERPOLATED"
        | "WIND_GUST_LAST"
        | "WIND_DIRECTION_MEAN"
        | "DEGREE_DAY"
        | "COLD_HOURS"
        | "LEAF_SENSOR_CONDUCTIVITY_MIN"
        | "LEAF_SENSOR_CONDUCTIVITY_MAX"
        | "LEAF_SENSOR_CONDUCTIVITY_MEAN"
        | "LEAF_WETNESS"
        | "IRRADIANCE_MEAN"
        | "LEAF_WETNESS_MEDIUM"
        | "LEAF_WETNESS_HIGH"
      )[]
    >;
    export type Intervals = NonNullable<
      (
        | "current"
        | "one_hour"
        | "three_hours"
        | "one_day"
        | "one_week"
        | "today"
        | "yesterday"
        | "this_week"
        | "last_seven_days"
      )[]
    >;
    export type InvitationWithStatusesFilter = NonNullable<
      ("pending" | "accepted" | "declined")[]
    >;
    export type InvitationWithTypesFilter = NonNullable<("member" | "share")[]>;
    export type ShareWithModesFilter = NonNullable<("received" | "shared")[]>;
    export type ShareWithTypesFilter = NonNullable<("set" | "select")[]>;
    export type InvitationWithModesFilter = NonNullable<
      ("received" | "sent")[]
    >;
    export type GetDeviceRawData4 = NonNullable<
      (
        | "RELATIVE_HUMIDITY"
        | "TEMPERATURE"
        | "WET_TEMPERATURE"
        | "RAIN_FALL"
        | "WIND_SPEED"
        | "WIND_GUST"
        | "WIND_DIRECTION"
        | "MOVE"
        | "LEAF_SENSOR_CONDUCTIVITY"
        | "RH_AIR_H1"
        | "TEMP_AIR_H1"
        | "RAIN_TIC"
        | "WIND_MEAN"
        | "WIND_MAX"
        | "LAT"
        | "LNG"
        | "ALT"
      )[]
    >;
    export type GetUserDailyData6 = NonNullable<
      (0.3 | 3 | 7 | 10 | 15 | 40 | 80)[]
    >;
    export type GetUserDailyData7 = NonNullable<
      (
        | "RELATIVE_HUMIDITY"
        | "RELATIVE_HUMIDITY_MIN"
        | "RELATIVE_HUMIDITY_MAX"
        | "RELATIVE_HUMIDITY_INTERPOLATED"
        | "RELATIVE_HUMIDITY_LAST"
        | "TEMPERATURE"
        | "TEMPERATURE_MIN"
        | "TEMPERATURE_MAX"
        | "TEMPERATURE_INTERPOLATED"
        | "TEMPERATURE_LAST"
        | "WET_TEMPERATURE"
        | "WET_TEMPERATURE_MIN"
        | "WET_TEMPERATURE_MAX"
        | "WET_TEMPERATURE_INTERPOLATED"
        | "WET_TEMPERATURE_LAST"
        | "LEAF_SENSOR_CONDUCTIVITY"
        | "LEAF_SENSOR_CONDUCTIVITY_MIN"
        | "LEAF_SENSOR_CONDUCTIVITY_MAX"
        | "LEAF_SENSOR_CONDUCTIVITY_LAST"
        | "RAIN_FALL"
        | "RAIN_FALL_MEAN_SUM"
        | "RAIN_FALL_MEAN_SUM_ADJUSTED"
        | "WIND_SPEED"
        | "WIND_GUST"
        | "WIND_DIRECTION"
        | "MOVE"
        | "LEAF_WETNESS"
        | "LEAF_WETNESS_MEDIUM"
        | "LEAF_WETNESS_HIGH"
        | "IRRADIANCE"
        | "RH_AIR_H1"
        | "RH_AIR_H1_MIN"
        | "RH_AIR_H1_MAX"
        | "TEMP_AIR_H1"
        | "TEMP_AIR_H1_MIN"
        | "TEMP_AIR_H1_MAX"
        | "RAIN_TIC"
        | "WIND_MEAN"
        | "WIND_MAX"
      )[]
    >;
    export type GetUserDeviceDailyData6 = NonNullable<
      (
        | "RELATIVE_HUMIDITY"
        | "RELATIVE_HUMIDITY_MIN"
        | "RELATIVE_HUMIDITY_MAX"
        | "RELATIVE_HUMIDITY_INTERPOLATED"
        | "RELATIVE_HUMIDITY_LAST"
        | "TEMPERATURE"
        | "TEMPERATURE_MIN"
        | "TEMPERATURE_MAX"
        | "TEMPERATURE_INTERPOLATED"
        | "TEMPERATURE_LAST"
        | "WET_TEMPERATURE"
        | "WET_TEMPERATURE_MIN"
        | "WET_TEMPERATURE_MAX"
        | "WET_TEMPERATURE_INTERPOLATED"
        | "WET_TEMPERATURE_LAST"
        | "LEAF_SENSOR_CONDUCTIVITY"
        | "LEAF_SENSOR_CONDUCTIVITY_MIN"
        | "LEAF_SENSOR_CONDUCTIVITY_MAX"
        | "LEAF_SENSOR_CONDUCTIVITY_LAST"
        | "RAIN_FALL"
        | "RAIN_FALL_MEAN_SUM"
        | "RAIN_FALL_MEAN_SUM_ADJUSTED"
        | "WIND_SPEED"
        | "WIND_GUST"
        | "WIND_DIRECTION"
        | "MOVE"
        | "LEAF_WETNESS"
        | "LEAF_WETNESS_MEDIUM"
        | "LEAF_WETNESS_HIGH"
        | "IRRADIANCE"
        | "RH_AIR_H1"
        | "RH_AIR_H1_MIN"
        | "RH_AIR_H1_MAX"
        | "TEMP_AIR_H1"
        | "TEMP_AIR_H1_MIN"
        | "TEMP_AIR_H1_MAX"
        | "RAIN_TIC"
        | "WIND_MEAN"
        | "WIND_MAX"
      )[]
    >;
    export type GetUserDeviceHourlyData6 = NonNullable<
      (
        | "RELATIVE_HUMIDITY"
        | "RELATIVE_HUMIDITY_MIN"
        | "RELATIVE_HUMIDITY_MAX"
        | "RELATIVE_HUMIDITY_INTERPOLATED"
        | "RELATIVE_HUMIDITY_LAST"
        | "TEMPERATURE"
        | "TEMPERATURE_MIN"
        | "TEMPERATURE_MAX"
        | "TEMPERATURE_INTERPOLATED"
        | "TEMPERATURE_LAST"
        | "WET_TEMPERATURE"
        | "WET_TEMPERATURE_MIN"
        | "WET_TEMPERATURE_MAX"
        | "WET_TEMPERATURE_INTERPOLATED"
        | "WET_TEMPERATURE_LAST"
        | "LEAF_SENSOR_CONDUCTIVITY"
        | "LEAF_SENSOR_CONDUCTIVITY_MIN"
        | "LEAF_SENSOR_CONDUCTIVITY_MAX"
        | "LEAF_SENSOR_CONDUCTIVITY_LAST"
        | "RAIN_FALL"
        | "RAIN_FALL_MEAN_SUM"
        | "RAIN_FALL_MEAN_SUM_ADJUSTED"
        | "WIND_SPEED"
        | "WIND_GUST"
        | "WIND_DIRECTION"
        | "MOVE"
        | "LEAF_WETNESS"
        | "LEAF_WETNESS_MEDIUM"
        | "LEAF_WETNESS_HIGH"
        | "IRRADIANCE"
        | "RH_AIR_H1"
        | "RH_AIR_H1_MIN"
        | "RH_AIR_H1_MAX"
        | "TEMP_AIR_H1"
        | "TEMP_AIR_H1_MIN"
        | "TEMP_AIR_H1_MAX"
        | "RAIN_TIC"
        | "WIND_MEAN"
        | "WIND_MAX"
      )[]
    >;
    export type GetUserDeviceRawData5 = NonNullable<
      (
        | "RELATIVE_HUMIDITY"
        | "TEMPERATURE"
        | "WET_TEMPERATURE"
        | "RAIN_FALL"
        | "WIND_SPEED"
        | "WIND_GUST"
        | "WIND_DIRECTION"
        | "MOVE"
        | "LEAF_SENSOR_CONDUCTIVITY"
        | "RH_AIR_H1"
        | "TEMP_AIR_H1"
        | "RAIN_TIC"
        | "WIND_MEAN"
        | "WIND_MAX"
        | "LAT"
        | "LNG"
        | "ALT"
      )[]
    >;
    export type GetUserDeviceSummary4 = NonNullable<
      (
        | "RELATIVE_HUMIDITY"
        | "TEMPERATURE"
        | "WET_TEMPERATURE"
        | "RAIN_FALL"
        | "WIND_SPEED"
        | "WIND_GUST"
        | "WIND_DIRECTION"
        | "MOVE"
        | "LEAF_SENSOR_CONDUCTIVITY"
        | "RH_AIR_H1"
        | "TEMP_AIR_H1"
        | "RAIN_TIC"
        | "WIND_MEAN"
        | "WIND_MAX"
      )[]
    >;
    export type GetUserDevices3 = NonNullable<
      (
        | "RELATIVE_HUMIDITY"
        | "TEMPERATURE"
        | "WET_TEMPERATURE"
        | "RAIN_FALL"
        | "WIND_SPEED"
        | "WIND_GUST"
        | "WIND_DIRECTION"
        | "MOVE"
        | "LEAF_SENSOR_CONDUCTIVITY"
        | "RH_AIR_H1"
        | "TEMP_AIR_H1"
        | "RAIN_TIC"
        | "WIND_MEAN"
        | "WIND_MAX"
      )[]
    >;
    export type GetUserHourlyData6 = NonNullable<
      (0.3 | 3 | 7 | 10 | 15 | 40 | 80)[]
    >;
    export type GetUserHourlyData7 = NonNullable<
      (
        | "RELATIVE_HUMIDITY"
        | "RELATIVE_HUMIDITY_MIN"
        | "RELATIVE_HUMIDITY_MAX"
        | "RELATIVE_HUMIDITY_INTERPOLATED"
        | "RELATIVE_HUMIDITY_LAST"
        | "TEMPERATURE"
        | "TEMPERATURE_MIN"
        | "TEMPERATURE_MAX"
        | "TEMPERATURE_INTERPOLATED"
        | "TEMPERATURE_LAST"
        | "WET_TEMPERATURE"
        | "WET_TEMPERATURE_MIN"
        | "WET_TEMPERATURE_MAX"
        | "WET_TEMPERATURE_INTERPOLATED"
        | "WET_TEMPERATURE_LAST"
        | "LEAF_SENSOR_CONDUCTIVITY"
        | "LEAF_SENSOR_CONDUCTIVITY_MIN"
        | "LEAF_SENSOR_CONDUCTIVITY_MAX"
        | "LEAF_SENSOR_CONDUCTIVITY_LAST"
        | "RAIN_FALL"
        | "RAIN_FALL_MEAN_SUM"
        | "RAIN_FALL_MEAN_SUM_ADJUSTED"
        | "WIND_SPEED"
        | "WIND_GUST"
        | "WIND_DIRECTION"
        | "MOVE"
        | "LEAF_WETNESS"
        | "LEAF_WETNESS_MEDIUM"
        | "LEAF_WETNESS_HIGH"
        | "IRRADIANCE"
        | "RH_AIR_H1"
        | "RH_AIR_H1_MIN"
        | "RH_AIR_H1_MAX"
        | "TEMP_AIR_H1"
        | "TEMP_AIR_H1_MIN"
        | "TEMP_AIR_H1_MAX"
        | "RAIN_TIC"
        | "WIND_MEAN"
        | "WIND_MAX"
      )[]
    >;
    export type DevicesIds = NonNullable<NonNullable<number>[]>;
    export type Parameters = NonNullable<NonNullable<string>[]>;
    export type GetOpenAPI0 = NonNullable<NonNullable<string>[]>;
    export type GetOpenAPI1 = NonNullable<NonNullable<string>[]>;
    export type GetOpenAPI2 = NonNullable<NonNullable<string>[]>;
    export type GetFactoryMeasures0 = NonNullable<NonNullable<string>[]>;
    export type GetLatestIrrCalibrations0 = NonNullable<NonNullable<string>[]>;
    export type GetOrders0 = NonNullable<NonNullable<string>[]>;
    export type GetOrdersAsXLSX0 = NonNullable<NonNullable<string>[]>;
  }
  export namespace Responses {
    type putUserOrganisationDeviceModuleResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body?: NonNullable<unknown>;
    };
    type getOrganisationChargebeePortalSessionResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body?: NonNullable<unknown>;
    };
    type getUserAlertResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body?: NonNullable<unknown>;
    };
    type postApplicationEventResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body?: NonNullable<unknown>;
    };
    type postCloudwatchEventsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body?: NonNullable<unknown>;
    };
    type postIngestionDataResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body?: NonNullable<unknown>;
    };
    type postLambdaLogsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body?: NonNullable<unknown>;
    };
    type postSignupCheckResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body?: NonNullable<unknown>;
    };
    type getOrganisationDeviceResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceItem;
    };
    type putOrganisationDeviceResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceItem;
    };
    type putOrganisationMemberSupportResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesputOrganisationMemberSupportResponse201Body0;
    };
    type putUserOrganisationAggregationResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.AggregationItem;
    };
    type putUserOrganisationAlertResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.AlertItem;
    };
    type putUserOrganisationInvitationResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.InvitationItem;
    };
    type putUserOrganisationMemberResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesputUserOrganisationMemberResponse201Body0;
    };
    type putUserOrganisationShareResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.SharesItem;
    };
    type getOpenAPIResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetOpenAPIResponse200Body0;
    };
    type getChargebeeCustomerResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetChargebeeCustomerResponse200Body0;
    };
    type getChargebeeResourcesPublicResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetChargebeeResourcesPublicResponse200Body0;
    };
    type getChargebeeRessourcesResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetChargebeeRessourcesResponse200Body0;
    };
    type getCotationsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetCotationsResponse200Body0;
    };
    type getCropsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.CropsItems;
    };
    type getCustomerByEmailResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetCustomerByEmailResponse200Body0;
    };
    type getDataResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceData;
    };
    type getDeviceDataResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceData;
    };
    type getDeviceRawDataResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetDeviceRawDataResponse200Body0;
    };
    type getDevicesResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DevicesItems;
    };
    type getFactoryMeasuresResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetFactoryMeasuresResponse200Body0;
    };
    type getFactorySigfoxStatusResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetFactorySigfoxStatusResponse200Body0;
    };
    type getHubSpotContactResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetHubSpotContactResponse200Body0;
    };
    type getHubSpotPropertiesResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetHubSpotPropertiesResponse200Body0;
    };
    type getHubSpotQuotatorCardResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.HubSpotCard;
    };
    type getHubSpotTableauCardResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.HubSpotCard;
    };
    type getInvitationResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.InvitationItem;
    };
    type getInvitationDeviceLiveAggregationsResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceLiveAggregationItem;
    };
    type getLatestIrrCalibrationResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetLatestIrrCalibrationResponse200Body0;
    };
    type getLatestIrrCalibrationsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetLatestIrrCalibrationsResponse200Body0;
    };
    type getModulesResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ModuleItems;
    };
    type getMySelfResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.UserItem;
    };
    type getNetworkResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.NetworkItem;
    };
    type getNetworkDeviceLiveAggregationsResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceLiveAggregationItem;
    };
    type getNetworkDevicesResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetNetworkDevicesResponse200Body0;
    };
    type getOrdersResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.OrderItems;
    };
    type postOrderResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.OrderItem;
    };
    type getOrdersAsXLSXResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetOrdersAsXLSXResponse200Body0;
    };
    type getOrdersByUserEmailResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.OrderByUserEmailItems;
    };
    type getOrganisationResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.OrganisationItem;
    };
    type putOrganisationResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.OrganisationItem;
    };
    type getOrganisationDevicesResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DevicesItems;
    };
    type getOrganisationDevicesLiveAggregationsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceLiveAggregationItems;
    };
    type getOrganisationHostedPageResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetOrganisationHostedPageResponse201Body0;
    };
    type getOrganisationHostedPageBatteryResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetOrganisationHostedPageBatteryResponse201Body0;
    };
    type getOrganisationHostedPageUpgradePlanPlusResponse201<S extends number> =
      {
        readonly status: S;
        readonly headers?: {
          readonly [name: string]: unknown;
        };
        readonly body: Components.Schemas.ResponsesgetOrganisationHostedPageUpgradePlanPlusResponse201Body0;
      };
    type getOrganisationInvitationResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.InvitationItem;
    };
    type getOrganisationInvitationsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.InvitationItems;
    };
    type getOrganisationMemberResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.UserItem;
    };
    type getOrganisationMembersResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.UserItems;
    };
    type getOrganisationNetworkDevicesResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.NetworkDevicesItems;
    };
    type getOrganisationNetworksResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.NetworksItems;
    };
    type getOrganisationPlaceResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.PlaceItem;
    };
    type getOrganisationPlacesResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.PlacesItems;
    };
    type postOrganisationPlaceResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.PlaceItem;
    };
    type getOrganisationPreferencesResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.OrganisationPreferences;
    };
    type putOrganisationPreferencesResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.OrganisationPreferences;
    };
    type getOrganisationShareResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.SharesItem;
    };
    type getOrganisationSharesResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.SharesItems;
    };
    type getOrganisationsTrialOperationsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.TrialOperationItems;
    };
    type getPartnerDevicesResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.PartnerDevicesItems;
    };
    type getPingResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetPingResponse200Body0;
    };
    type getPlansResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.PlanItems;
    };
    type getPublicWeatherLiveResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetPublicWeatherLiveResponse200Body0;
    };
    type getPublicWeatherLiveAggregationsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.WeatherLiveAggregationItems;
    };
    type getQuoteResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.QuoteItem;
    };
    type getQuoteAsPdfResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetQuoteAsPdfResponse200Body0;
    };
    type getQuoteHostedPageResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetQuoteHostedPageResponse201Body0;
    };
    type getQuoteInvoiceAsPdfResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetQuoteInvoiceAsPdfResponse200Body0;
    };
    type getRadarImageResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetRadarImageResponse200Body0;
    };
    type getSearchDevicesResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetSearchDevicesResponse200Body0;
    };
    type getSearchNetworkDevicesResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetSearchNetworkDevicesResponse200Body0;
    };
    type getSearchPublicOrganisationResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.OrganisationItems;
    };
    type getSearchPublicUserResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.UserItems;
    };
    type getSearchUserResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.UserItems;
    };
    type getSigfoxCoverageResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetSigfoxCoverageResponse200Body0;
    };
    type getSigfoxMapResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetSigfoxMapResponse200Body0;
    };
    type getStatusHeadResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetStatusHeadResponse200Body0;
    };
    type getTrialOperationResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.TrialOperationItem;
    };
    type getUserResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.UserItem;
    };
    type putUserResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.UserItem;
    };
    type getUserAggregationResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.AggregationItem;
    };
    type getUserAggregationsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.AggregationItems;
    };
    type getUserAlertVoiceMessageResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetUserAlertVoiceMessageResponse200Body0;
    };
    type getUserAlertsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.AlertsItems;
    };
    type getUserDailyDataResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceStatistics;
    };
    type getUserDeviceResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceItem;
    };
    type getUserDeviceContinuousStatisticsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceStatistics;
    };
    type getUserDeviceDailyDataResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceStatistics;
    };
    type getUserDeviceForecastsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceForecasts;
    };
    type getUserDeviceHourlyDataResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceStatistics;
    };
    type getUserDeviceModulesResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ModuleItems;
    };
    type getUserDevicePositionsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetUserDevicePositionsResponse200Body0;
    };
    type getUserDeviceRawDataResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetUserDeviceRawDataResponse200Body0;
    };
    type getUserDeviceStatisticsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceStatistics;
    };
    type getUserDeviceSummaryResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceSummaryItem;
    };
    type getUserDevicesResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DevicesItems;
    };
    type getUserDevicesLiveAggregationsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceLiveAggregationItems;
    };
    type getUserForecastsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceForecasts;
    };
    type getUserHourlyDataResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceStatistics;
    };
    type getUserIntercomHashResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetUserIntercomHashResponse200Body0;
    };
    type getUserInvitationResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.InvitationItem;
    };
    type getUserInvitationsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.InvitationItems;
    };
    type getUserOrganisationsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.OrganisationItems;
    };
    type postUserOrganisationResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.OrganisationItem;
    };
    type getUserPendingInvitationResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.InvitationItem;
    };
    type getUserPendingInvitationsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.InvitationItems;
    };
    type getUserPreferencesResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.UserPreferences;
    };
    type putUserPreferencesResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.UserPreferences;
    };
    type getUserStatisticsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceStatistics;
    };
    type getUserTestingSessionsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.TestingSessionsItems;
    };
    type putUserTestingSessionsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.TestingSessionsItems;
    };
    type getWeatherForecastModelResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetWeatherForecastModelResponse200Body0;
    };
    type getWeatherLiveResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetWeatherLiveResponse200Body0;
    };
    type handleAlertCheckResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponseshandleAlertCheckResponse200Body0;
    };
    type postLoginResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.Session;
    };
    type postNetworkJoinRequestResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsespostNetworkJoinRequestResponse201Body0;
    };
    type postOAuth2AcknowledgeResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsespostOAuth2AcknowledgeResponse201Body0;
    };
    type postOAuth2TokenResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsespostOAuth2TokenResponse200Body0;
    };
    type postOrganisationResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.OrganisationItem;
    };
    type postPartnerApplicationResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ApplicationItem;
    };
    type postPartnerModuleResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ModuleItem;
    };
    type postPartnerTokenResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.Session;
    };
    type postPlaceResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.PlaceItem;
    };
    type postQuoteResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.QuoteItem;
    };
    type postRegisterResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsespostRegisterResponse201Body0;
    };
    type postRegisterAuth0Response201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsespostRegisterAuth0Response201Body0;
    };
    type postSigfoxDataResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsespostSigfoxDataResponse201Body0;
    };
    type postSignatureCloudinaryResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsespostSignatureCloudinaryResponse200Body0;
    };
    type postSlackBotCommandResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsespostSlackBotCommandResponse200Body0;
    };
    type postTelitDataResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsespostTelitDataResponse201Body0;
    };
    type postUserResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.UserItem;
    };
    type postUserNetworkInvitationResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.InvitationItem;
    };
    type postUserOrganisationAggregationResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.AggregationItem;
    };
    type postUserOrganisationAlertResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.AlertItem;
    };
    type postUserOrganisationDeviceResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceItem;
    };
    type postUserOrganisationDeviceSupportResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceItem;
    };
    type postUserOrganisationInvitationResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.InvitationItem;
    };
    type postUserOrganisationMemberResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsespostUserOrganisationMemberResponse201Body0;
    };
    type postUserTerminatedSessionsResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsespostUserTerminatedSessionsResponse200Body0;
    };
    type postVerifyResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.Session;
    };
    type putOrganisationDeviceSettingsResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.DeviceSettings;
    };
    type putOrganisationPlaceResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.PlaceItem;
    };
    type putPartnerApplicationResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ApplicationItem;
    };
    type putPartnerModuleParametersResponse201<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesputPartnerModuleParametersResponse201Body0;
    };
    type getGraphQLResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsesgetGraphQLResponse200Body0;
    };
    type postGraphQLResponse200<S extends number> = {
      readonly status: S;
      readonly headers?: {
        readonly [name: string]: unknown;
      };
      readonly body: Components.Schemas.ResponsespostGraphQLResponse200Body0;
    };
  }
  export namespace Schemas {
    export type RequestBodiesputBucketDeviceSupportRequestBodyBody0 =
      NonNullable<{
        startDate: NonNullable<string>;
        endDate?: NonNullable<string>;
      }>;
    export type RequestBodiesputDeviceAccessSupportRequestBodyBody0 =
      NonNullable<{
        startDate: NonNullable<string>;
        endDate?: NonNullable<string>;
      }>;
    export type RequestBodiesputDeviceAccessesSupportRequestBodyBody0 =
      NonNullable<{
        startDate?: NonNullable<string>;
        endDate?: NonNullable<string>;
        allAccesses?: NonNullable<boolean>;
      }>;
    export type RequestBodiespostOrganisationChargebeeIdRequestBodyBody0 =
      NonNullable<{
        chargebeeId: NonNullable<string>;
      }>;
    export type Device = NonNullable<{
      id?: NonNullable<number>;
      identification?: NonNullable<string>;
      modelId?: NonNullable<number>;
      organisationId?: NonNullable<number>;
      city?: NonNullable<string>;
      location?: NonNullable<{
        latitude: NonNullable<number>;
        longitude: NonNullable<number>;
        altitude?: NonNullable<number>;
        satellites?: NonNullable<number>;
        precision?: NonNullable<number>;
        source?: "gps" | "blur";
      }>;
      status?: NonNullable<{
        firmware: NonNullable<string>;
        battery: NonNullable<number>;
        signal: NonNullable<number>;
        lastLocationDate: NonNullable<string>;
        lastLocationFailureDate: NonNullable<string>;
        lastRebootDate: NonNullable<string>;
        lastMoveDate: NonNullable<string>;
        lastStatusUpdateDate: NonNullable<string>;
        activationDate?: NonNullable<string>;
        manufacturedDate?: NonNullable<string>;
      }>;
      contents: Components.Schemas.DeviceContents;
      settings?: Components.Schemas.DeviceSettings;
      accessPeriods?: NonNullable<
        NonNullable<{
          id?: NonNullable<number>;
          deviceId: NonNullable<number>;
          moduleId?: NonNullable<number>;
          authorId: NonNullable<number>;
          sharingOrganisationId: NonNullable<number>;
          granteeId: NonNullable<number>;
          delegatorId?: NonNullable<number>;
          organisationId?: NonNullable<number>;
          userId?: NonNullable<number>;
          parameters?: NonNullable<{
            [pattern: string]: unknown;
          }>;
          name?: NonNullable<string>;
          type: "owner" | "user" | "guest" | "collaborator" | "partner";
          situation?:
            | "UNKNOWN"
            | "GREENHOUSE"
            | "OUTDOOR_COVERED"
            | "OUTDOOR_INFLUENCED"
            | "OUTDOOR_ISOLATED";
          startDate: NonNullable<string>;
          endDate: NonNullable<string>;
          deletionDate?: NonNullable<string>;
        }>[]
      >;
      calibrations?: NonNullable<{
        RELATIVE_HUMIDITY?: NonNullable<
          NonNullable<{
            startDate?: NonNullable<string>;
            ratio?: NonNullable<number>;
          }>[]
        >;
        TEMPERATURE?: NonNullable<
          NonNullable<{
            startDate?: NonNullable<string>;
            ratio?: NonNullable<number>;
          }>[]
        >;
        WET_TEMPERATURE?: NonNullable<
          NonNullable<{
            startDate?: NonNullable<string>;
            ratio?: NonNullable<number>;
          }>[]
        >;
        RAIN_FALL?: NonNullable<
          NonNullable<{
            startDate?: NonNullable<string>;
            ratio?: NonNullable<number>;
          }>[]
        >;
        WIND_SPEED?: NonNullable<
          NonNullable<{
            startDate?: NonNullable<string>;
            ratio?: NonNullable<number>;
          }>[]
        >;
        WIND_GUST?: NonNullable<
          NonNullable<{
            startDate?: NonNullable<string>;
            ratio?: NonNullable<number>;
          }>[]
        >;
        WIND_DIRECTION?: NonNullable<
          NonNullable<{
            startDate?: NonNullable<string>;
            ratio?: NonNullable<number>;
          }>[]
        >;
        MOVE?: NonNullable<
          NonNullable<{
            startDate?: NonNullable<string>;
            ratio?: NonNullable<number>;
          }>[]
        >;
        LEAF_SENSOR_CONDUCTIVITY?: NonNullable<
          NonNullable<{
            startDate?: NonNullable<string>;
            ratio?: NonNullable<number>;
          }>[]
        >;
        RH_AIR_H1?: NonNullable<
          NonNullable<{
            startDate?: NonNullable<string>;
            ratio?: NonNullable<number>;
          }>[]
        >;
        TEMP_AIR_H1?: NonNullable<
          NonNullable<{
            startDate?: NonNullable<string>;
            ratio?: NonNullable<number>;
          }>[]
        >;
        RAIN_TIC?: NonNullable<
          NonNullable<{
            startDate?: NonNullable<string>;
            ratio?: NonNullable<number>;
          }>[]
        >;
        WIND_MEAN?: NonNullable<
          NonNullable<{
            startDate?: NonNullable<string>;
            ratio?: NonNullable<number>;
          }>[]
        >;
        WIND_MAX?: NonNullable<
          NonNullable<{
            startDate?: NonNullable<string>;
            ratio?: NonNullable<number>;
          }>[]
        >;
      }>;
      serial?: NonNullable<string>;
      userId?: NonNullable<number>;
    }>;
    export type RequestBodiesputOrganisationMemberSupportRequestBodyBody0 =
      NonNullable<{
        type: "owner" | "admin" | "collaborator";
      }>;
    export type Aggregation = NonNullable<{
      id?: NonNullable<string>;
      userId?: NonNullable<number>;
      organisationId?: NonNullable<number>;
      creationDate?: NonNullable<string>;
      lastModificationDate?: NonNullable<string>;
      lastComputationDate?: NonNullable<string>;
      cachedComputedDate?: NonNullable<string>;
      timeZone?: NonNullable<string>;
      contents: Components.Schemas.AggregationContents;
    }>;
    export type Alert = NonNullable<{
      id?: NonNullable<string>;
      userId?: NonNullable<number>;
      activationDate?: NonNullable<string>;
      creationDate?: NonNullable<string>;
      deletionDate?: NonNullable<string>;
      lastModificationDate?: NonNullable<string>;
      lastCheckDate?: NonNullable<string>;
      lastTriggerDate?: NonNullable<string>;
      lastNotificationTriggerDate?: NonNullable<string>;
      contents: Components.Schemas.AlertContents;
    }>;
    export type RequestBodiesputUserOrganisationDeviceModuleRequestBodyBody0 =
      | NonNullable<{
          code: "mileos";
          id: NonNullable<number>;
          [pattern: string]: unknown;
        }>
      | NonNullable<{
          code: "avisio";
          organisationName: NonNullable<string>;
          [pattern: string]: unknown;
        }>
      | NonNullable<{
          code: "movida";
          administrator: NonNullable<string>;
          organisationName: NonNullable<string>;
          id: NonNullable<number>;
          [pattern: string]: unknown;
        }>
      | NonNullable<{
          code: "atlas";
          siret: NonNullable<string>;
          [pattern: string]: unknown;
        }>
      | NonNullable<{
          code: "scanbean";
          siret: NonNullable<string>;
          organisationName: NonNullable<string>;
          [pattern: string]: unknown;
        }>
      | NonNullable<{
          code: "vintel";
          [pattern: string]: unknown;
        }>
      | NonNullable<{
          code: "vitimeteo";
          [pattern: string]: unknown;
        }>
      | NonNullable<{
          code: "agrovision";
          [pattern: string]: unknown;
        }>
      | NonNullable<{
          code: "corhize";
          [pattern: string]: unknown;
        }>
      | NonNullable<{
          code: "fruition";
          [pattern: string]: unknown;
        }>
      | NonNullable<{
          code: "pca";
          [pattern: string]: unknown;
        }>
      | NonNullable<{
          code: "niab";
          [pattern: string]: unknown;
        }>
      | NonNullable<{
          code: "decitrait";
          [pattern: string]: unknown;
        }>
      | NonNullable<{
          code: "vigimap";
          [pattern: string]: unknown;
        }>
      | NonNullable<{
          code: "myeasyfarm";
          [pattern: string]: unknown;
        }>
      | NonNullable<{
          code: "fruitweb";
          [pattern: string]: unknown;
        }>
      | NonNullable<{
          code: NonNullable<string>;
          externalIdentifier: NonNullable<string>;
          organisationName?: NonNullable<string>;
          userEmail?: NonNullable<string>;
          [pattern: string]: unknown;
        }>;
    export type Invitation = NonNullable<{
      id?: NonNullable<string>;
      organisationId?: NonNullable<number>;
      authorId?: NonNullable<number>;
      status?: "pending" | "accepted" | "declined";
      completionDate?: NonNullable<string>;
      creationDate?: NonNullable<string>;
      lastModificationDate?: NonNullable<string>;
      lastSentDate?: NonNullable<string>;
      countInvitationSent?: NonNullable<number>;
      contents: Components.Schemas.InvitationContents;
    }>;
    export type RequestBodiesputUserOrganisationMemberRequestBodyBody0 =
      NonNullable<{
        type: "owner" | "admin" | "collaborator";
      }>;
    export type Shares = NonNullable<{
      id?: NonNullable<string>;
      authorId?: NonNullable<number>;
      sharingOrganisationId?: NonNullable<number>;
      granteeId?: NonNullable<number>;
      creationDate?: NonNullable<string>;
      lastModificationDate?: NonNullable<string>;
      contents: Components.Schemas.ShareContents;
    }>;
    export type OrderCreation = NonNullable<{
      id?: NonNullable<string>;
      source?: NonNullable<string>;
      contents:
        | Components.Schemas.ManualOrderCreationContent
        | Components.Schemas.ChargebeeOrderWithCustomerContent;
    }>;
    export type Organisation = NonNullable<{
      id?: NonNullable<number>;
      uuid?: NonNullable<string>;
      ownersIds?: NonNullable<NonNullable<number>[]>;
      isNetwork?: NonNullable<boolean>;
      features?: NonNullable<NonNullable<string>[]>;
      numberOfAccesses?: NonNullable<number>;
      preferences?: NonNullable<{
        [pattern: string]: unknown;
      }>;
      chargebeeId?: NonNullable<string>;
      networkPaymentMode?: NonNullable<string>;
      networkCouponId?: NonNullable<string>;
      networkSponsoringDetails?: NonNullable<{
        hasAccessToBillingApp?: NonNullable<boolean>;
        sponsoredSubscriptions?: NonNullable<{
          [pattern: string]: unknown;
        }>;
      }>;
      overridePlanEnterprise?: NonNullable<boolean>;
      referralStatus?: "PENDING" | "VALIDATED";
      plan?: Components.Schemas.OrganisationPlan;
      limits?: Components.Schemas.OrganisationLimits;
      placeId?: NonNullable<number>;
      places?: Components.Schemas.OrganisationPlaceHash;
      members?: Components.Schemas.OrganisationMemberHash;
      creationDate?: NonNullable<string>;
      lastModificationDate?: NonNullable<string>;
      contents: Components.Schemas.OrganisationContents;
    }>;
    export type Place = NonNullable<{
      id?: NonNullable<number>;
      creationDate?: NonNullable<string>;
      lastModificationDate?: NonNullable<string>;
      addressLocalized?: NonNullable<string>;
      contents: Components.Schemas.PlaceContents;
    }>;
    export type OrganisationPreferences = NonNullable<{
      accessLevelNetwork?: "demand" | "invitation" | "freely";
      transmittableNetwork?: NonNullable<boolean>;
      [pattern: string]: unknown;
    }>;
    export type User = NonNullable<{
      id?: NonNullable<number>;
      signupType?: "form" | "invitation" | "unknown";
      emailVerified?: NonNullable<boolean>;
      locale?: NonNullable<string>;
      timeZone?: NonNullable<string>;
      organisationsIds?: NonNullable<NonNullable<number>[]>;
      roles?: NonNullable<
        ("ROLE_USER" | "ROLE_ADMIN" | "ROLE_TEAM" | "ROLE_CANDIDATE")[]
      >;
      hubSpotContactId?: NonNullable<string>;
      creationDate?: NonNullable<string>;
      lastModificationDate?: NonNullable<string>;
      contents: Components.Schemas.UserContents;
    }>;
    export type UserPreferences = NonNullable<{
      [pattern: string]: unknown;
    }>;
    export type RequestBodiesputUserTestingSessionsRequestBodyBody0 =
      NonNullable<
        NonNullable<{
          serial: NonNullable<string>;
          identification: NonNullable<string>;
          modelId: NonNullable<number>;
          testingState:
            | "AWAIT_TESTING"
            | "UNDER_TESTING"
            | "PASSED_TESTING"
            | "DONE_TESTING";
          testingValues: Components.Schemas.TestingValues;
          testingErrors: Components.Schemas.TestingErrors;
          activationCode?: NonNullable<string>;
          irradianceSensorId?: NonNullable<string>;
        }>[]
      >;
    export type RequestBodiespostAircallIncomingCallsRequestBodyBody0 =
      NonNullable<{
        [pattern: string]: unknown;
      }>;
    export type RequestBodiespostBulkExportsSupportRequestBodyBody0 =
      NonNullable<{
        startDate: NonNullable<string>;
        endDate: NonNullable<string>;
        measures: NonNullable<
          (
            | "RELATIVE_HUMIDITY"
            | "RELATIVE_HUMIDITY_MIN"
            | "RELATIVE_HUMIDITY_MAX"
            | "RELATIVE_HUMIDITY_INTERPOLATED"
            | "RELATIVE_HUMIDITY_LAST"
            | "TEMPERATURE"
            | "TEMPERATURE_MIN"
            | "TEMPERATURE_MAX"
            | "TEMPERATURE_INTERPOLATED"
            | "TEMPERATURE_LAST"
            | "WET_TEMPERATURE"
            | "WET_TEMPERATURE_MIN"
            | "WET_TEMPERATURE_MAX"
            | "WET_TEMPERATURE_INTERPOLATED"
            | "WET_TEMPERATURE_LAST"
            | "LEAF_SENSOR_CONDUCTIVITY"
            | "LEAF_SENSOR_CONDUCTIVITY_MIN"
            | "LEAF_SENSOR_CONDUCTIVITY_MAX"
            | "LEAF_SENSOR_CONDUCTIVITY_LAST"
            | "RAIN_FALL"
            | "RAIN_FALL_MEAN_SUM"
            | "RAIN_FALL_MEAN_SUM_ADJUSTED"
            | "WIND_SPEED"
            | "WIND_GUST"
            | "WIND_DIRECTION"
            | "MOVE"
            | "LEAF_WETNESS"
            | "LEAF_WETNESS_MEDIUM"
            | "LEAF_WETNESS_HIGH"
            | "IRRADIANCE"
            | "RH_AIR_H1"
            | "RH_AIR_H1_MIN"
            | "RH_AIR_H1_MAX"
            | "TEMP_AIR_H1"
            | "TEMP_AIR_H1_MIN"
            | "TEMP_AIR_H1_MAX"
            | "RAIN_TIC"
            | "WIND_MEAN"
            | "WIND_MAX"
          )[]
        >;
        interval: "15m" | "1h" | "6h" | "1d" | "1w" | "1M" | "1y";
        timeZone: NonNullable<string>;
        fileFormat: "xlsx";
        patched: NonNullable<boolean>;
        bypassFilterGeolocation: NonNullable<boolean>;
      }>;
    export type RequestBodiespostCSVBatchProdRequestBodyBody0 = NonNullable<{
      devices: NonNullable<
        NonNullable<{
          serial: NonNullable<string>;
          identification: NonNullable<string>;
          modelId: NonNullable<number>;
          activationCode: NonNullable<string>;
          buildDate: NonNullable<string>;
          batch: NonNullable<string>;
          [pattern: string]: unknown;
        }>[]
      >;
    }>;
    export type RequestBodiespostChargebeeEventsRequestBodyBody0 = NonNullable<{
      [pattern: string]: unknown;
    }>;
    export type RequestBodiespostDataDeviceSituationRequestBodyBody0 =
      NonNullable<{
        situation:
          | "INDOOR"
          | "OUTDOOR"
          | "EXCLUDED"
          | "IN_VIGILANCE_AREA"
          | "SATURATED_RELATIVE_HUMIDITY";
        startDate: NonNullable<string>;
      }>;
    export type RequestBodiespostDataWeatherForecastModelRequestBodyBody0 =
      NonNullable<{
        geoHash: NonNullable<string>;
        model: NonNullable<string>;
      }>;
    export type RequestBodiespostDeviceReplacementRequestBodyBody0 =
      NonNullable<{
        replacementDate: NonNullable<string>;
      }>;
    export type RequestBodiespostHubSpotContactSyncHookRequestBodyBody0 =
      NonNullable<{
        [pattern: string]: unknown;
      }>;
    export type RequestBodiespostHubSpotContactTrialReactivationRequestBodyBody0 =
      NonNullable<{
        [pattern: string]: unknown;
      }>;
    export type RequestBodiespostHubSpotDealSyncHookRequestBodyBody0 =
      NonNullable<{
        [pattern: string]: unknown;
      }>;
    export type RequestBodiespostHubSpotDeleteDealHookRequestBodyBody0 =
      NonNullable<
        NonNullable<{
          [pattern: string]: unknown;
        }>[]
      >;
    export type RequestBodiespostLoginRequestBodyBody0 = NonNullable<{
      email: NonNullable<string>;
      password: NonNullable<string>;
    }>;
    export type RequestBodiespostLoginRequestBodyBody1 = NonNullable<{
      email: NonNullable<string>;
      password: NonNullable<string>;
    }>;
    export type RequestBodiespostLostPasswordRequestBodyBody0 = NonNullable<{
      email: NonNullable<string>;
      callbackURL?: NonNullable<string>;
      callbackPath?: NonNullable<string>;
    }>;
    export type RequestBodiespostMailgunWebHookRequestBodyBody0 = NonNullable<{
      [pattern: string]: unknown;
    }>;
    export type RequestBodiespostMailjetEventsRequestBodyBody0 = NonNullable<
      NonNullable<{
        [pattern: string]: unknown;
      }>[]
    >;
    export type RequestBodiespostNetworkJoinRequestRequestBodyBody0 =
      NonNullable<{
        authorId?: NonNullable<number>;
        devicesIds?: NonNullable<NonNullable<number>[]>;
      }> &
        (
          | NonNullable<{
              recipientEmail: NonNullable<string>;
            }>
          | NonNullable<{
              recipientId: NonNullable<number>;
            }>
        );
    export type RequestBodiespostNexmoEventsRequestBodyBody0 = NonNullable<{
      [pattern: string]: unknown;
    }>;
    export type RequestBodiespostOAuth2AcknowledgeRequestBodyBody0 =
      NonNullable<{
        clientId: NonNullable<string>;
        redirectURI: NonNullable<string>;
        scope: NonNullable<string>;
        state: NonNullable<string>;
        acknowledged: NonNullable<boolean>;
      }>;
    export type RequestBodiespostOAuth2TokenRequestBodyBody0 =
      | Components.Schemas.AuthorizationCodeTokenRequestBody
      | Components.Schemas.PasswordTokenRequestBody
      | Components.Schemas.ClientCredentialsTokenRequestBody
      | Components.Schemas.RefreshTokenRequestBody
      | Components.Schemas.ModuleTokenRequestBody
      | Components.Schemas.CodeRequestTokenRequestBody;
    export type RequestBodiespostOAuth2TokenRequestBodyBody1 =
      | Components.Schemas.AuthorizationCodeTokenRequestBody
      | Components.Schemas.PasswordTokenRequestBody
      | Components.Schemas.ClientCredentialsTokenRequestBody
      | Components.Schemas.RefreshTokenRequestBody
      | Components.Schemas.ModuleTokenRequestBody
      | Components.Schemas.CodeRequestTokenRequestBody;
    export type RequestBodiespostOrganisationNetworkMemberPlanRequestBodyBody0 =
      NonNullable<{
        planId: NonNullable<string>;
      }>;
    export type RequestBodiespostOrganisationPaymentCompleteRequestBodyBody0 =
      NonNullable<{
        planId:
          | "plan-essential"
          | "plan-pro"
          | "plan-plus"
          | "plan-essential-gbp"
          | "plan-pro-gbp"
          | "plan-plus-gbp";
      }>;
    export type RequestBodiespostOrganisationToNetworkSupportRequestBodyBody0 =
      NonNullable<{
        conversionType?: "network" | "sponsoring" | "promoting";
        convertToBottom?: NonNullable<boolean>;
        optOut?: NonNullable<boolean>;
        sponsoringCoupon?: NonNullable<string>;
        promotingTrialEndDate?: NonNullable<string>;
      }>;
    export type Application = NonNullable<{
      id?: NonNullable<string>;
      userId?: NonNullable<number>;
      organisationId?: NonNullable<number>;
      truncatedSecret?: NonNullable<string>;
      secret?: NonNullable<string>;
      creationDate?: NonNullable<string>;
      modificationDate?: NonNullable<string>;
      contents: Components.Schemas.ApplicationContents;
    }>;
    export type Module = NonNullable<{
      id?: NonNullable<number>;
      organisationId?: NonNullable<number>;
      contents: Components.Schemas.ModuleContents;
    }>;
    export type RequestBodiespostPartnerTokenRequestBodyBody0 = NonNullable<{
      email: NonNullable<string>;
      code: NonNullable<string>;
    }>;
    export type RequestBodiespostPartnerTokenRequestBodyBody1 = NonNullable<{
      email: NonNullable<string>;
      code: NonNullable<string>;
    }>;
    export type RequestBodiespostPartnerTokenRequestRequestBodyBody0 =
      NonNullable<{
        email: NonNullable<string>;
      }>;
    export type NewQuote = NonNullable<{
      customer: NonNullable<{
        userId?: NonNullable<number>;
        user?: Components.Schemas.UserContents;
        organisationId?: NonNullable<number>;
        organisation?: Components.Schemas.OrganisationContents;
        subscriptionId?: NonNullable<string>;
        organisationPlaceId?: NonNullable<number>;
        organisationPlace?: Components.Schemas.PlaceContents;
        country?: NonNullable<string>;
      }>;
      authorId: NonNullable<number>;
      quote: NonNullable<{
        subscription: NonNullable<{
          planId: NonNullable<string>;
        }>;
        addons?: NonNullable<
          NonNullable<{
            id: NonNullable<string>;
            quantity: NonNullable<number>;
          }>[]
        >;
        couponId?: NonNullable<string>;
        notes?: NonNullable<string>;
        currency: "EUR" | "GBP";
      }>;
      hubSpotDealId?: NonNullable<string>;
      hubSpotSubSegmentation?: NonNullable<string>;
      operationId?: NonNullable<string>;
      isPromotingConversion?: NonNullable<boolean>;
      referral?: Components.Schemas.Referral;
    }>;
    export type RequestBodiespostQuoteRequestRequestBodyBody0 = NonNullable<{
      customer: NonNullable<{
        user: Components.Schemas.UserContents;
        organisation: NonNullable<{
          name: NonNullable<string>;
        }>;
        place: NonNullable<{
          zip: NonNullable<string>;
          country: NonNullable<string>;
        }>;
      }>;
      quote: NonNullable<{
        subscription?: NonNullable<{
          planId: NonNullable<string>;
        }>;
        addons?: NonNullable<
          NonNullable<{
            id: NonNullable<string>;
            quantity: NonNullable<number>;
          }>[]
        >;
        notes?: NonNullable<string>;
        coupon?: NonNullable<string>;
      }>;
      hubSpotSubSegmentation?: NonNullable<string>;
      referral?: Components.Schemas.Referral;
    }>;
    export type RequestBodiespostRegisterRequestBodyBody0 = NonNullable<{
      user: NonNullable<{
        email: NonNullable<string>;
        phone: NonNullable<string>;
        locale: NonNullable<string>;
        timeZone: NonNullable<string>;
        referral?: Components.Schemas.Referral;
      }> &
        (
          | NonNullable<{
              password: NonNullable<string>;
            }>
          | NonNullable<{
              auth0Id: NonNullable<string>;
            }>
        );
      organisation?: NonNullable<{
        name: NonNullable<string>;
      }>;
      organisationPlace?: NonNullable<{
        line1: NonNullable<string>;
        line2?: NonNullable<string>;
        city: NonNullable<string>;
        state?: NonNullable<string>;
        zip: NonNullable<string>;
        country: NonNullable<string>;
        latitude?: NonNullable<number>;
        longitude?: NonNullable<number>;
      }>;
    }>;
    export type RequestBodiespostRegisterAuth0RequestBodyBody0 = NonNullable<{
      email: NonNullable<string>;
      password: NonNullable<string>;
      locale: NonNullable<string>;
    }>;
    export type RequestBodiespostSigfoxDataRequestBodyBody0 = NonNullable<{
      device: NonNullable<string>;
      time: NonNullable<number>;
      data: NonNullable<string>;
      lqi?: NonNullable<string>;
      seqNumber?: NonNullable<number>;
      operatorName?: NonNullable<string>;
      countryCode?: NonNullable<number>;
      computedLocation?: NonNullable<{
        lat?: NonNullable<number>;
        lng?: NonNullable<number>;
        radius?: NonNullable<number>;
        source?: NonNullable<number>;
        status?: NonNullable<number>;
      }>;
    }>;
    export type RequestBodiespostSignatureCloudinaryRequestBodyBody0 =
      NonNullable<{
        [pattern: string]: unknown;
      }>;
    export type RequestBodiespostSignupCheckRequestBodyBody0 = NonNullable<{
      email: NonNullable<string>;
    }>;
    export type RequestBodiespostSlackBotCommandRequestBodyBody0 = NonNullable<{
      token?: NonNullable<string>;
      team_id?: NonNullable<string>;
      team_domain?: NonNullable<string>;
      channel_id?: NonNullable<string>;
      channel_name?: NonNullable<string>;
      user_id?: NonNullable<string>;
      user_name?: NonNullable<string>;
      command: NonNullable<string>;
      text: NonNullable<string>;
      response_url?: NonNullable<string>;
      [pattern: string]: unknown;
    }>;
    export type RequestBodiespostSocialImageRequestBodyBody0 = NonNullable<{
      width?: NonNullable<number>;
      height?: NonNullable<number>;
      template?: NonNullable<string>;
      format?: NonNullable<string>;
      params?: NonNullable<{
        [pattern: string]: unknown;
      }>;
    }>;
    export type RequestBodiespostTelitDataRequestBodyBody0 = NonNullable<{
      [pattern: string]: unknown;
    }>;
    export type RequestBodiespostTwilioEventsRequestBodyBody0 = NonNullable<{
      [pattern: string]: unknown;
    }>;
    export type NewUser = NonNullable<{
      id?: NonNullable<number>;
      signupType?: "form" | "invitation" | "unknown";
      emailVerified?: NonNullable<boolean>;
      locale?: NonNullable<string>;
      timeZone?: NonNullable<string>;
      organisationsIds?: NonNullable<NonNullable<number>[]>;
      roles?: NonNullable<
        ("ROLE_USER" | "ROLE_ADMIN" | "ROLE_TEAM" | "ROLE_CANDIDATE")[]
      >;
      hubSpotContactId?: NonNullable<string>;
      creationDate?: NonNullable<string>;
      lastModificationDate?: NonNullable<string>;
      contents: Components.Schemas.UserContents;
      referral?: Components.Schemas.Referral;
      mustBeInTrial?: NonNullable<boolean>;
      callbackURL?: NonNullable<string>;
    }> &
      (
        | NonNullable<{
            password: NonNullable<string>;
          }>
        | NonNullable<{
            auth0Id: NonNullable<string>;
          }>
      );
    export type RequestBodiespostUserClearSessionsRequestBodyBody0 =
      NonNullable<{
        devices: NonNullable<
          NonNullable<{
            serial: NonNullable<string>;
            identification: NonNullable<string>;
          }>[]
        >;
      }>;
    export type RequestBodiespostUserDeviceExportRequestBodyBody0 =
      NonNullable<{
        startDate: NonNullable<string>;
        endDate: NonNullable<string>;
        measures: NonNullable<
          (
            | "RELATIVE_HUMIDITY"
            | "RELATIVE_HUMIDITY_MIN"
            | "RELATIVE_HUMIDITY_MAX"
            | "RELATIVE_HUMIDITY_INTERPOLATED"
            | "RELATIVE_HUMIDITY_LAST"
            | "TEMPERATURE"
            | "TEMPERATURE_MIN"
            | "TEMPERATURE_MAX"
            | "TEMPERATURE_INTERPOLATED"
            | "TEMPERATURE_LAST"
            | "WET_TEMPERATURE"
            | "WET_TEMPERATURE_MIN"
            | "WET_TEMPERATURE_MAX"
            | "WET_TEMPERATURE_INTERPOLATED"
            | "WET_TEMPERATURE_LAST"
            | "LEAF_SENSOR_CONDUCTIVITY"
            | "LEAF_SENSOR_CONDUCTIVITY_MIN"
            | "LEAF_SENSOR_CONDUCTIVITY_MAX"
            | "LEAF_SENSOR_CONDUCTIVITY_LAST"
            | "RAIN_FALL"
            | "RAIN_FALL_MEAN_SUM"
            | "RAIN_FALL_MEAN_SUM_ADJUSTED"
            | "WIND_SPEED"
            | "WIND_GUST"
            | "WIND_DIRECTION"
            | "MOVE"
            | "LEAF_WETNESS"
            | "LEAF_WETNESS_MEDIUM"
            | "LEAF_WETNESS_HIGH"
            | "IRRADIANCE"
            | "RH_AIR_H1"
            | "RH_AIR_H1_MIN"
            | "RH_AIR_H1_MAX"
            | "TEMP_AIR_H1"
            | "TEMP_AIR_H1_MIN"
            | "TEMP_AIR_H1_MAX"
            | "RAIN_TIC"
            | "WIND_MEAN"
            | "WIND_MAX"
          )[]
        >;
        interval: "15m" | "1h" | "6h" | "1d" | "1w" | "1M" | "1y";
        timeZone: NonNullable<string>;
        fileFormat: "csv" | "xlsx" | "ods";
        patched: NonNullable<boolean>;
      }>;
    export type RequestBodiespostUserNetworkInvitationRequestBodyBody0 =
      NonNullable<{
        email: NonNullable<string>;
        planId?: NonNullable<string>;
        callbackURL?: NonNullable<string>;
      }>;
    export type RequestBodiespostUserOrganisationDeviceRequestBodyBody0 =
      NonNullable<{
        identification: NonNullable<string>;
        activationCode: NonNullable<string>;
        name?: NonNullable<string>;
        device?: NonNullable<number>;
      }>;
    export type RequestBodiespostUserOrganisationDevicesSupportRequestBodyBody0 =
      NonNullable<{
        devicesIdentifications: NonNullable<NonNullable<string>[]>;
      }>;
    export type RequestBodiespostUserOrganisationMemberRequestBodyBody0 =
      NonNullable<{
        type: "owner" | "admin" | "collaborator";
        email: NonNullable<string>;
      }>;
    export type RequestBodiespostUserPasswordRequestBodyBody0 = NonNullable<{
      currentPassword: NonNullable<string>;
      newPassword: NonNullable<string>;
    }>;
    export type RequestBodiespostUserTerminatedSessionsRequestBodyBody0 =
      NonNullable<
        NonNullable<{
          serial: NonNullable<string>;
          identification: NonNullable<string>;
          modelId: NonNullable<number>;
          testingState:
            | "AWAIT_TESTING"
            | "UNDER_TESTING"
            | "PASSED_TESTING"
            | "DONE_TESTING";
          testingValues: Components.Schemas.TestingValues;
          testingErrors: Components.Schemas.TestingErrors;
          activationCode?: NonNullable<string>;
          irradianceSensorId?: NonNullable<string>;
        }>[]
      >;
    export type RequestBodiespostVerifyRequestBodyBody0 = NonNullable<{
      email: NonNullable<string>;
      verify: NonNullable<string>;
    }>;
    export type RequestBodiesputBatchDevicesRequestBodyBody0 = NonNullable<{
      devices: NonNullable<
        NonNullable<{
          identification: NonNullable<string>;
          batch: NonNullable<string>;
          [pattern: string]: unknown;
        }>[]
      >;
    }>;
    export type RequestBodiesputDataDeviceCityRequestBodyBody0 = NonNullable<{
      city: NonNullable<string>;
    }>;
    export type RequestBodiesputDevicePositionRequestBodyBody0 = NonNullable<{
      latitude: NonNullable<number>;
      longitude: NonNullable<number>;
      altitude: NonNullable<number>;
      lastLocationDate: NonNullable<string>;
    }>;
    export type RequestBodiesputIrradianceSensorCalibrationRequestBodyBody0 =
      NonNullable<{
        irradianceFactor: NonNullable<number>;
        irradianceOffset: NonNullable<number>;
        metadata?: NonNullable<{
          [pattern: string]: unknown;
        }>;
      }>;
    export type DeviceSettings = NonNullable<{
      patchSmallRainFall?: NonNullable<boolean>;
    }>;
    export type RequestBodiesputOrganisationTrialRequestBodyBody0 =
      NonNullable<{
        reason?: NonNullable<string>;
        endDate: NonNullable<string>;
      }>;
    export type RequestBodiesputPartnerModuleParametersRequestBodyBody0 =
      NonNullable<{
        enabled?: NonNullable<boolean>;
      }>;
    export type RequestBodiesputPasswordRequestBodyBody0 = NonNullable<{
      lostToken: NonNullable<string>;
      newPassword: NonNullable<string>;
    }>;
    export type RequestBodiesputUserFCMTokenRequestBodyBody0 = NonNullable<{
      FCMToken: NonNullable<string>;
      device: NonNullable<string>;
    }>;
    export type RequestBodiesputUserInvitationAnswerRequestBodyBody0 =
      NonNullable<{
        organisationId?: NonNullable<number>;
        answer: "accept" | "decline";
      }>;
    export type RequestBodiespostGraphQLRequestBodyBody0 = NonNullable<{
      query?: NonNullable<string>;
      [pattern: string]: unknown;
    }>;
    export type DeviceItem = NonNullable<{
      item: NonNullable<number>;
      devices: Components.Schemas.GenericDeviceHash;
      models: Components.Schemas.ModelHash;
      devicesStatuses: Components.Schemas.DeviceStatusHash;
      organisations?: Components.Schemas.OrganisationHash;
    }>;
    export type ResponsesputOrganisationMemberSupportResponse201Body0 =
      NonNullable<{}>;
    export type AggregationItem = NonNullable<{
      item: NonNullable<string>;
      aggregations: Components.Schemas.AggregationHash;
      devices?: Components.Schemas.GenericDeviceHash;
      measures?: Components.Schemas.Measure;
    }>;
    export type AlertItem = NonNullable<{
      item: NonNullable<string>;
      alerts: Components.Schemas.AlertHash;
    }>;
    export type InvitationItem = NonNullable<{
      item: NonNullable<string>;
      invitations: Components.Schemas.InvitationHash;
      users?: Components.Schemas.UserHash;
      organisations?: Components.Schemas.OrganisationHash;
    }>;
    export type ResponsesputUserOrganisationMemberResponse201Body0 =
      NonNullable<{}>;
    export type SharesItem = NonNullable<{
      item: NonNullable<string>;
      shares: Components.Schemas.SharesHash;
      organisations?: Components.Schemas.OrganisationHash;
      users?: Components.Schemas.UserHash;
      places?: Components.Schemas.PlaceHash;
    }>;
    export type ResponsesgetOpenAPIResponse200Body0 = NonNullable<{}>;
    export type ResponsesgetChargebeeCustomerResponse200Body0 = NonNullable<{
      customer?: NonNullable<{
        [pattern: string]: unknown;
      }>;
      subscriptions?: NonNullable<
        NonNullable<{
          [pattern: string]: unknown;
        }>[]
      >;
    }>;
    export type ResponsesgetChargebeeResourcesPublicResponse200Body0 =
      NonNullable<{
        plans?: NonNullable<{
          [pattern: string]: unknown;
        }>;
        products?: NonNullable<{
          [pattern: string]: unknown;
        }>;
      }>;
    export type ResponsesgetChargebeeRessourcesResponse200Body0 = NonNullable<{
      plans?: NonNullable<{
        [pattern: string]: unknown;
      }>;
      products?: NonNullable<{
        [pattern: string]: unknown;
      }>;
      coupons?: NonNullable<{
        [pattern: string]: unknown;
      }>;
      recurringAddons?: NonNullable<{
        [pattern: string]: unknown;
      }>;
      currencies?: NonNullable<NonNullable<string>[]>;
      deliveryZones?: NonNullable<NonNullable<string>[]>;
    }>;
    export type ResponsesgetCotationsResponse200Body0 = NonNullable<{}>;
    export type CropsItems = NonNullable<{
      crops: Components.Schemas.CropHash;
      items: NonNullable<NonNullable<number>[]>;
    }>;
    export type ResponsesgetCustomerByEmailResponse200Body0 = NonNullable<{
      user?: Components.Schemas.User;
      organisations?: Components.Schemas.OrganisationHash;
      places?: Components.Schemas.PlaceHash;
      hubSpotContact?: NonNullable<{
        [pattern: string]: unknown;
      }>;
    }>;
    export type DeviceData = NonNullable<{
      measures: Components.Schemas.Measure;
    }>;
    export type ResponsesgetDeviceRawDataResponse200Body0 = NonNullable<
      NonNullable<{
        date?: NonNullable<string>;
        type?:
          | "RELATIVE_HUMIDITY"
          | "TEMPERATURE"
          | "WET_TEMPERATURE"
          | "RAIN_FALL"
          | "WIND_SPEED"
          | "WIND_GUST"
          | "WIND_DIRECTION"
          | "MOVE"
          | "LEAF_SENSOR_CONDUCTIVITY"
          | "RH_AIR_H1"
          | "TEMP_AIR_H1"
          | "RAIN_TIC"
          | "WIND_MEAN"
          | "WIND_MAX";
        value?: NonNullable<number>;
      }>[]
    >;
    export type DevicesItems = NonNullable<{
      items: NonNullable<NonNullable<number>[]>;
      total?: NonNullable<number>;
      models: Components.Schemas.ModelHash;
      devices: Components.Schemas.GenericDeviceHash;
      devicesStatuses?: Components.Schemas.DeviceStatusHash;
      devicesSummaries?: Components.Schemas.DeviceSummaryHash;
      users?: Components.Schemas.UserHash;
      organisations?: Components.Schemas.OrganisationHash;
    }>;
    export type ResponsesgetFactoryMeasuresResponse200Body0 = NonNullable<{}>;
    export type ResponsesgetFactorySigfoxStatusResponse200Body0 = NonNullable<{
      [pattern: string]: unknown;
    }>;
    export type ResponsesgetHubSpotContactResponse200Body0 = NonNullable<{
      [pattern: string]: unknown;
    }>;
    export type ResponsesgetHubSpotPropertiesResponse200Body0 = NonNullable<{}>;
    export type HubSpotCard = NonNullable<{
      results?: NonNullable<
        NonNullable<{
          objectId?: NonNullable<number>;
          title?: NonNullable<string>;
          link?: NonNullable<string>;
        }>[]
      >;
    }>;
    export type DeviceLiveAggregationItem = NonNullable<{
      item: NonNullable<number>;
      devicesLiveAggregations: Components.Schemas.DeviceLiveAggregationHash;
    }>;
    export type ResponsesgetLatestIrrCalibrationResponse200Body0 = NonNullable<{
      sensorId?: NonNullable<string>;
      irradianceFactor?: NonNullable<number>;
      irradianceOffset?: NonNullable<number>;
      metadata?: NonNullable<{
        [pattern: string]: unknown;
      }>;
      creationDate?: NonNullable<string>;
      lastModificationDate?: NonNullable<string>;
    }>;
    export type ResponsesgetLatestIrrCalibrationsResponse200Body0 = NonNullable<
      NonNullable<{
        sensorId?: NonNullable<string>;
        irradianceFactor?: NonNullable<number>;
        irradianceOffset?: NonNullable<number>;
        metadata?: NonNullable<{
          [pattern: string]: unknown;
        }>;
        creationDate?: NonNullable<string>;
        lastModificationDate?: NonNullable<string>;
      }>[]
    >;
    export type ModuleItems = NonNullable<{
      items: NonNullable<NonNullable<number>[]>;
      modules: Components.Schemas.ModuleHash;
      organisations?: Components.Schemas.OrganisationHash;
      places?: Components.Schemas.PlaceHash;
    }>;
    export type UserItem = NonNullable<{
      item: NonNullable<number>;
      users: Components.Schemas.UserHash;
      organisations?: Components.Schemas.OrganisationHash;
      places?: Components.Schemas.PlaceHash;
    }>;
    export type NetworkItem = NonNullable<{
      item: NonNullable<string>;
      networks: Components.Schemas.NetworkHash;
    }>;
    export type ResponsesgetNetworkDevicesResponse200Body0 = NonNullable<{
      total?: NonNullable<number>;
      items: NonNullable<NonNullable<number>[]>;
      devices: Components.Schemas.GenericDeviceHash;
    }>;
    export type OrderItems = NonNullable<{
      items: NonNullable<NonNullable<string>[]>;
      itemsManuals: NonNullable<NonNullable<string>[]>;
      itemsChargebee: NonNullable<NonNullable<string>[]>;
      orders: Components.Schemas.OrderHash;
    }>;
    export type OrderItem = NonNullable<{
      item: NonNullable<string>;
      orders: Components.Schemas.OrderCreationHash;
    }>;
    export type ResponsesgetOrdersAsXLSXResponse200Body0 = NonNullable<{
      link?: NonNullable<string>;
      linkLogs?: NonNullable<string>;
    }>;
    export type OrderByUserEmailItems = NonNullable<{
      items: NonNullable<NonNullable<string>[]>;
      orders: Components.Schemas.OrderByUserEmailHash;
    }>;
    export type OrganisationItem = NonNullable<{
      item: NonNullable<number>;
      organisations: Components.Schemas.OrganisationHash;
      places?: Components.Schemas.PlaceHash;
    }>;
    export type DeviceLiveAggregationItems = NonNullable<{
      items: NonNullable<NonNullable<number>[]>;
      devicesLiveAggregations: Components.Schemas.DeviceLiveAggregationHash;
    }>;
    export type ResponsesgetOrganisationHostedPageResponse201Body0 =
      NonNullable<{
        embed?: NonNullable<boolean>;
        created_at?: NonNullable<number>;
        expires_at?: NonNullable<number>;
        id?: NonNullable<string>;
        object?: NonNullable<string>;
        resource_version?: NonNullable<number>;
        state?: NonNullable<string>;
        type?: NonNullable<string>;
        updated_at?: NonNullable<number>;
        url?: NonNullable<string>;
      }>;
    export type ResponsesgetOrganisationHostedPageBatteryResponse201Body0 =
      NonNullable<{
        embed?: NonNullable<boolean>;
        created_at?: NonNullable<number>;
        expires_at?: NonNullable<number>;
        id?: NonNullable<string>;
        object?: NonNullable<string>;
        resource_version?: NonNullable<number>;
        state?: NonNullable<string>;
        type?: NonNullable<string>;
        updated_at?: NonNullable<number>;
        url?: NonNullable<string>;
      }>;
    export type ResponsesgetOrganisationHostedPageUpgradePlanPlusResponse201Body0 =
      NonNullable<{
        embed?: NonNullable<boolean>;
        created_at?: NonNullable<number>;
        expires_at?: NonNullable<number>;
        id?: NonNullable<string>;
        object?: NonNullable<string>;
        resource_version?: NonNullable<number>;
        state?: NonNullable<string>;
        type?: NonNullable<string>;
        updated_at?: NonNullable<number>;
        url?: NonNullable<string>;
      }>;
    export type InvitationItems = NonNullable<{
      total?: NonNullable<number>;
      invitations: Components.Schemas.InvitationHash;
      items: NonNullable<NonNullable<string>[]>;
      users?: Components.Schemas.UserHash;
      organisations?: Components.Schemas.OrganisationHash;
    }>;
    export type UserItems = NonNullable<{
      items: NonNullable<NonNullable<number>[]>;
      users: Components.Schemas.UserHash;
      organisations?: Components.Schemas.OrganisationHash;
    }>;
    export type NetworkDevicesItems = NonNullable<{
      items: NonNullable<NonNullable<number>[]>;
      total?: NonNullable<number>;
      devices: Components.Schemas.GenericDeviceHash;
      organisations: Components.Schemas.OrganisationHash;
    }>;
    export type NetworksItems = NonNullable<{
      items: NonNullable<NonNullable<string>[]>;
      networks: Components.Schemas.NetworkHash;
    }>;
    export type PlaceItem = NonNullable<{
      item: NonNullable<number>;
      places: Components.Schemas.PlaceHash;
    }>;
    export type PlacesItems = NonNullable<{
      items: NonNullable<NonNullable<number>[]>;
      places: Components.Schemas.PlaceHash;
    }>;
    export type SharesItems = NonNullable<{
      total: NonNullable<number>;
      items: NonNullable<NonNullable<string>[]>;
      shares: Components.Schemas.SharesHash;
      organisations?: Components.Schemas.OrganisationHash;
      users?: Components.Schemas.UserHash;
      places?: Components.Schemas.PlaceHash;
    }>;
    export type TrialOperationItems = NonNullable<{
      total?: NonNullable<number>;
      items: NonNullable<NonNullable<string>[]>;
      trialOperations: Components.Schemas.TrialOperationHash;
    }>;
    export type PartnerDevicesItems = NonNullable<{
      items: NonNullable<NonNullable<number>[]>;
      total?: NonNullable<number>;
      models: Components.Schemas.ModelHash;
      devicesStatuses: Components.Schemas.DeviceStatusHash;
      devices: Components.Schemas.GenericDeviceHash;
      users: Components.Schemas.UserHash;
      organisations: Components.Schemas.OrganisationHash;
      places?: Components.Schemas.PlaceHash;
    }>;
    export type ResponsesgetPingResponse200Body0 = NonNullable<{
      pong: "pong";
    }>;
    export type PlanItems = NonNullable<{
      items: NonNullable<NonNullable<string>[]>;
      plans: Components.Schemas.PlanHash;
    }>;
    export type ResponsesgetPublicWeatherLiveResponse200Body0 = NonNullable<{
      items?: NonNullable<
        NonNullable<{
          lat?: NonNullable<number>;
          lon?: NonNullable<number>;
          value?: NonNullable<number>;
          count?: NonNullable<number>;
        }>[]
      >;
    }>;
    export type WeatherLiveAggregationItems = NonNullable<{
      items: NonNullable<NonNullable<number>[]>;
      devices: Components.Schemas.GenericDeviceHash;
      devicesLiveAggregations: Components.Schemas.WeatherLiveAggregationHash;
    }>;
    export type QuoteItem = NonNullable<{
      item: NonNullable<string>;
      quotes: Components.Schemas.QuoteHash;
      users?: Components.Schemas.UserHash;
      organisations?: Components.Schemas.OrganisationHash;
    }>;
    export type ResponsesgetQuoteAsPdfResponse200Body0 = NonNullable<{
      downloadURL?: NonNullable<string>;
    }>;
    export type ResponsesgetQuoteHostedPageResponse201Body0 = NonNullable<{
      [pattern: string]: unknown;
    }>;
    export type ResponsesgetQuoteInvoiceAsPdfResponse200Body0 = NonNullable<{
      downloadURL?: NonNullable<string>;
    }>;
    export type ResponsesgetRadarImageResponse200Body0 = NonNullable<string>;
    export type ResponsesgetSearchDevicesResponse200Body0 = NonNullable<{
      total: NonNullable<number>;
      itemsOutsideRadius: NonNullable<boolean>;
      items: NonNullable<NonNullable<number>[]>;
      devices: Components.Schemas.GenericDeviceHash;
    }>;
    export type ResponsesgetSearchNetworkDevicesResponse200Body0 = NonNullable<{
      total?: NonNullable<number>;
      items: NonNullable<NonNullable<number>[]>;
      devices: Components.Schemas.GenericDeviceHash;
    }>;
    export type OrganisationItems = NonNullable<{
      items: NonNullable<NonNullable<number>[]>;
      organisations: Components.Schemas.OrganisationHash;
      places?: Components.Schemas.PlaceHash;
    }>;
    export type ResponsesgetSigfoxCoverageResponse200Body0 = NonNullable<{
      sigfoxBody: NonNullable<{
        locationCovered: NonNullable<boolean>;
        margins: NonNullable<NonNullable<number>[]>;
      }>;
      sigfoxCoverage: "COVERAGE_LIMIT" | "AVERAGE" | "GOOD" | "VERY_GOOD";
    }>;
    export type ResponsesgetSigfoxMapResponse200Body0 = NonNullable<{}>;
    export type ResponsesgetStatusHeadResponse200Body0 = NonNullable<{
      pong?: NonNullable<string>;
    }>;
    export type TrialOperationItem = NonNullable<{
      item: NonNullable<string>;
      trialOperations: Components.Schemas.TrialOperationHash;
    }>;
    export type AggregationItems = NonNullable<{
      aggregations: Components.Schemas.AggregationHash;
      aggregationsResults?: Components.Schemas.AggregationsResultsHash;
      items: NonNullable<NonNullable<string>[]>;
      devices?: Components.Schemas.GenericDeviceHash;
    }>;
    export type ResponsesgetUserAlertVoiceMessageResponse200Body0 =
      NonNullable<string>;
    export type AlertsItems = NonNullable<{
      alerts: Components.Schemas.AlertHash;
      items: NonNullable<NonNullable<string>[]>;
    }>;
    export type DeviceStatistics = NonNullable<{
      models: Components.Schemas.ModelHash;
      devices: Components.Schemas.GenericDeviceHash;
      item: NonNullable<number>;
      measures: Components.Schemas.Measure;
    }>;
    export type DeviceForecasts = NonNullable<{
      models: Components.Schemas.ModelHash;
      devices: Components.Schemas.GenericDeviceHash;
      item: NonNullable<number>;
      forecasts: NonNullable<{
        [pattern: string]: unknown;
      }>;
    }>;
    export type ResponsesgetUserDevicePositionsResponse200Body0 = NonNullable<
      NonNullable<{
        locationDate?: NonNullable<string>;
        latitude?: NonNullable<number>;
        longitude?: NonNullable<number>;
        altitude?: NonNullable<number>;
      }>[]
    >;
    export type ResponsesgetUserDeviceRawDataResponse200Body0 = NonNullable<
      NonNullable<{
        date?: NonNullable<string>;
        type?:
          | "RELATIVE_HUMIDITY"
          | "TEMPERATURE"
          | "WET_TEMPERATURE"
          | "RAIN_FALL"
          | "WIND_SPEED"
          | "WIND_GUST"
          | "WIND_DIRECTION"
          | "MOVE"
          | "LEAF_SENSOR_CONDUCTIVITY"
          | "RH_AIR_H1"
          | "TEMP_AIR_H1"
          | "RAIN_TIC"
          | "WIND_MEAN"
          | "WIND_MAX";
        value?: NonNullable<number>;
      }>[]
    >;
    export type DeviceSummaryItem = NonNullable<{
      models: Components.Schemas.ModelHash;
      devices: Components.Schemas.GenericDeviceHash;
      devicesSummaries: Components.Schemas.DeviceSummaryHash;
      item: NonNullable<number>;
    }>;
    export type ResponsesgetUserIntercomHashResponse200Body0 = NonNullable<{
      hmac?: NonNullable<string>;
    }>;
    export type TestingSessionsItems = NonNullable<{
      items: NonNullable<NonNullable<string>[]>;
      testingSessions: Components.Schemas.TestingSessionHash;
    }>;
    export type ResponsesgetWeatherForecastModelResponse200Body0 = NonNullable<{
      model?: NonNullable<string>;
    }>;
    export type ResponsesgetWeatherLiveResponse200Body0 = NonNullable<{
      items?: NonNullable<
        (
          | NonNullable<{
              geohash: NonNullable<string>;
              RELATIVE_HUMIDITY: NonNullable<number>;
            }>
          | NonNullable<{
              geohash: NonNullable<string>;
              TEMPERATURE: NonNullable<number>;
            }>
          | NonNullable<{
              geohash: NonNullable<string>;
              WIND_SPEED: NonNullable<number>;
            }>
          | NonNullable<{
              geohash: NonNullable<string>;
              WIND_GUST: NonNullable<number>;
            }>
          | NonNullable<{
              geohash: NonNullable<string>;
              WIND_DIRECTION: NonNullable<number>;
            }>
          | NonNullable<{
              geohash: NonNullable<string>;
              RAIN_FALL: NonNullable<number>;
            }>
          | NonNullable<{
              geohash: NonNullable<string>;
              IRRADIANCE: NonNullable<number>;
            }>
        )[]
      >;
    }>;
    export type ResponseshandleAlertCheckResponse200Body0 = NonNullable<{}>;
    export type Session = NonNullable<{
      token: NonNullable<string>;
      refreshToken?: NonNullable<string>;
      expirationDate: NonNullable<string>;
      userId: NonNullable<number>;
      organisationsIds?: NonNullable<NonNullable<number>[]>;
      roles?: NonNullable<("ROLE_USER" | "ROLE_ADMIN" | "ROLE_TEAM")[]>;
    }>;
    export type ResponsespostNetworkJoinRequestResponse201Body0 = NonNullable<{
      candidate: NonNullable<boolean>;
      invitationId: NonNullable<string>;
    }>;
    export type ResponsespostOAuth2AcknowledgeResponse201Body0 = NonNullable<{
      redirectURI: NonNullable<string>;
    }>;
    export type ResponsespostOAuth2TokenResponse200Body0 = NonNullable<{
      access_token: NonNullable<string>;
      token_type: "bearer" | "mac";
      expires_in?: NonNullable<number>;
      refresh_token?: NonNullable<string>;
    }>;
    export type ApplicationItem = NonNullable<{
      item: NonNullable<string>;
      applications: Components.Schemas.ApplicationHash;
    }>;
    export type ModuleItem = NonNullable<{
      item: NonNullable<number>;
      modules: Components.Schemas.ModuleHash;
      organisations?: Components.Schemas.OrganisationHash;
    }>;
    export type ResponsespostRegisterResponse201Body0 = NonNullable<{
      userId?: NonNullable<number>;
      organisationId?: NonNullable<number>;
      organisationWasCreated?: NonNullable<boolean>;
    }>;
    export type ResponsespostRegisterAuth0Response201Body0 = NonNullable<{
      userId?: NonNullable<string>;
      email?: NonNullable<string>;
    }>;
    export type ResponsespostSigfoxDataResponse201Body0 = NonNullable<{
      queued: true;
    }>;
    export type ResponsespostSignatureCloudinaryResponse200Body0 = NonNullable<{
      signature?: NonNullable<string>;
    }>;
    export type ResponsespostSlackBotCommandResponse200Body0 =
      NonNullable<string>;
    export type ResponsespostTelitDataResponse201Body0 = NonNullable<{}>;
    export type ResponsespostUserOrganisationMemberResponse201Body0 =
      NonNullable<{
        id?: NonNullable<number>;
        contents?: NonNullable<{
          organisationId?: NonNullable<number>;
          memberId?: NonNullable<number>;
          type?: "owner" | "admin" | "collaborator";
        }>;
      }>;
    export type ResponsespostUserTerminatedSessionsResponse200Body0 =
      NonNullable<
        NonNullable<{
          serial: NonNullable<string>;
          identification: NonNullable<string>;
          modelId: NonNullable<number>;
          testingState:
            | "AWAIT_TESTING"
            | "UNDER_TESTING"
            | "PASSED_TESTING"
            | "DONE_TESTING";
          testingValues: Components.Schemas.TestingValues;
          testingErrors: Components.Schemas.TestingErrors;
          activationCode?: NonNullable<string>;
        }>[]
      >;
    export type ResponsesputPartnerModuleParametersResponse201Body0 =
      NonNullable<{}>;
    export type ResponsesgetGraphQLResponse200Body0 = NonNullable<{
      [pattern: string]: unknown;
    }>;
    export type ResponsespostGraphQLResponse200Body0 = NonNullable<{
      [pattern: string]: unknown;
    }>;
    export type DeviceContents = NonNullable<{
      name: NonNullable<string>;
    }>;
    export type AggregationContents = NonNullable<{
      name: NonNullable<string>;
      aggregation:
        | "DEGREE_DAY"
        | "COLD_HOURS"
        | "RAIN_FALL_SUM"
        | "LEAF_WETNESS"
        | "LEAF_WETNESS_MEDIUM"
        | "LEAF_WETNESS_HIGH";
      parameters?: NonNullable<{
        base?: NonNullable<number>;
        maxDegreeDayValue?: NonNullable<number>;
      }>;
      devicesIds: NonNullable<NonNullable<number>[]>;
    }> &
      (
        | NonNullable<{
            type: "date";
            ceil: NonNullable<number>;
          }>
        | NonNullable<{
            type: "value";
            timeSpan: NonNullable<number>;
          }>
        | NonNullable<{
            type: "value";
            startDate: NonNullable<string>;
            endDate?: NonNullable<string>;
          }>
      );
    export type AlertContents = NonNullable<{
      name: NonNullable<string>;
      status: "enabled" | "disabled";
      notificationTypes: NonNullable<
        ("sms" | "email" | "phone" | "webhook" | "push" | "notification")[]
      >;
      conditions: Components.Schemas.AlertGroupConditions;
      enableStartTime?: NonNullable<string>;
      enableEndTime?: NonNullable<string>;
      numberOfNotificationPerTrigger: NonNullable<number>;
      renewInterval?: NonNullable<number>;
      devicesIds: NonNullable<NonNullable<number>[]>;
    }>;
    export type InvitationContents = NonNullable<{}> &
      (
        | (NonNullable<{
            type: "share";
            data?: NonNullable<{
              recipientId?: NonNullable<number>;
              transmittable: NonNullable<boolean>;
              message?: NonNullable<string>;
              planId?: NonNullable<string>;
              type: unknown;
              devicesIds: unknown;
            }> &
              (
                | NonNullable<{
                    type?: "set";
                    devicesIds?: NonNullable<NonNullable<number>[]>;
                  }>
                | NonNullable<{
                    type?: "select";
                    devicesIds?: NonNullable<NonNullable<number>[]>;
                    allowedDevicesIds?: NonNullable<NonNullable<number>[]>;
                    maximumSharedAccesses?: NonNullable<number>;
                  }>
              );
          }> &
            (
              | NonNullable<{
                  recipient: Components.Schemas.User;
                }>
              | NonNullable<{
                  recipientId: NonNullable<number>;
                }>
            ))
        | NonNullable<{
            type: "member";
            recipient: Components.Schemas.User;
            data: NonNullable<{
              type: "owner" | "admin" | "collaborator";
            }>;
          }>
      );
    export type ShareContents = NonNullable<{
      recipientId: NonNullable<number>;
      transmittable: NonNullable<boolean>;
      message?: NonNullable<string>;
      planId?: NonNullable<string>;
      type: unknown;
      devicesIds: unknown;
    }> &
      (
        | NonNullable<{
            type?: "set";
            devicesIds?: NonNullable<NonNullable<number>[]>;
          }>
        | NonNullable<{
            type?: "select";
            devicesIds?: NonNullable<NonNullable<number>[]>;
            allowedDevicesIds?: NonNullable<NonNullable<number>[]>;
            maximumSharedAccesses?: NonNullable<number>;
          }>
      );
    export type ManualOrderCreationContent = NonNullable<{
      status?:
        | "queued"
        | "awaiting_shipment"
        | "on_hold"
        | "delivered"
        | "shipped"
        | "partially_delivered"
        | "returned";
      type:
        | "unknown"
        | "promoting_farmer"
        | "replacement"
        | "spare_parts"
        | "mono_point"
        | "functional_demo_stations"
        | "non_functional_demo_stations"
        | "remote_stock"
        | "leafcrop_recall"
        | "prototype";
      hubspotTicketId?: NonNullable<string>;
      hubspotDealId?: NonNullable<string>;
      operationId?: NonNullable<string>;
      customer: NonNullable<{
        email: NonNullable<string>;
        firstname: NonNullable<string>;
        lastname: NonNullable<string>;
        phone: NonNullable<string>;
        locale: NonNullable<string>;
      }>;
      company?: NonNullable<{
        name?: NonNullable<string>;
      }>;
      shippingAddress: Components.Schemas.ManualOrderAddress;
      billingAddress?: Components.Schemas.ManualOrderAddress;
      products: NonNullable<
        NonNullable<{
          sku: NonNullable<string>;
          quantity: NonNullable<number>;
        }>[]
      >;
      device?: NonNullable<number>;
    }>;
    export type ChargebeeOrderWithCustomerContent = NonNullable<{
      status?:
        | "queued"
        | "awaiting_shipment"
        | "on_hold"
        | "delivered"
        | "shipped"
        | "partially_delivered"
        | "returned";
      type: "unknown";
      customer: NonNullable<{
        chargebeeCustomerId: NonNullable<string>;
        email?: NonNullable<string>;
        firstname: NonNullable<string>;
        lastname: NonNullable<string>;
        phone?: NonNullable<string>;
        locale: NonNullable<string>;
      }>;
      company?: NonNullable<{
        name?: NonNullable<string>;
      }>;
      shippingAddress?: Components.Schemas.ChargebeeOrderShippingAddress;
      billingAddress: Components.Schemas.ChargebeeOrderBillingAddress;
      products: NonNullable<Components.Schemas.ChargebeeOrderProduct[]>;
    }>;
    export type OrganisationPlan = NonNullable<{
      id?: NonNullable<string>;
      subscriptionId?: NonNullable<string>;
      couponId?: NonNullable<string>;
      promotionalCredit?: NonNullable<number>;
      chargebeeId?: NonNullable<string>;
      status?:
        | "in_trial"
        | "active"
        | "non_renewing"
        | "active_waiting_chargebee"
        | "paused"
        | "cancelled"
        | "deleted";
      trialEndDate?: NonNullable<string>;
      renewalDate?: NonNullable<string>;
      currency?: NonNullable<string>;
      recurringAddons?: Components.Schemas.RecurringAddonHash;
    }>;
    export type OrganisationLimits = NonNullable<{
      maxDevices?: NonNullable<number>;
      maxDevicesNetworkOverride?: NonNullable<number>;
      maxHistoryAccessPeriod?: NonNullable<string>;
      maxExportPeriod?: NonNullable<string>;
      maxLiveWeatherPeriod?: NonNullable<string>;
      dst?: NonNullable<boolean>;
      maxMembersOrganisation?: NonNullable<number>;
    }>;
    export type OrganisationPlaceHash = NonNullable<{
      [pattern: string]: Components.Schemas.OrganisationPlace;
    }>;
    export type OrganisationMemberHash = NonNullable<{
      [pattern: string]: Components.Schemas.OrganisationMember;
    }>;
    export type OrganisationContents = NonNullable<{
      name: NonNullable<string>;
      locale: NonNullable<string>;
      timeZone: NonNullable<string>;
      identification?: NonNullable<string>;
      type:
        | "farm"
        | "cooperative"
        | "interest_group"
        | "chamber_of_agriculture"
        | "company"
        | "personal"
        | "public_agency";
      typeFarm?: NonNullable<
        ("agricultural" | "wine" | "arboreal" | "vegetable" | "apiarian")[]
      >;
      area?: NonNullable<number>;
      crops?: NonNullable<
        (
          | "apple"
          | "banana"
          | "barley"
          | "bean"
          | "beet"
          | "cabbage"
          | "carrot"
          | "colza"
          | "corn"
          | "garden"
          | "garlic"
          | "greenhouse"
          | "lettuce"
          | "linen"
          | "oat"
          | "onion"
          | "other_fruits"
          | "other_vegetables"
          | "other-agri"
          | "other-tree"
          | "pea"
          | "peach"
          | "pear"
          | "potato"
          | "potatoes"
          | "rapeseed"
          | "rye"
          | "sunflower"
          | "tomato"
          | "vine"
          | "wheat"
          | "wine"
        )[]
      >;
      irrigation?: NonNullable<boolean>;
      logoUrl?: NonNullable<string>;
    }>;
    export type PlaceContents = NonNullable<{
      line1: NonNullable<string>;
      line2?: NonNullable<string>;
      city: NonNullable<string>;
      state?: NonNullable<string>;
      zip: NonNullable<string>;
      country: NonNullable<string>;
      latitude?: NonNullable<number>;
      longitude?: NonNullable<number>;
    }>;
    export type UserContents = NonNullable<{
      email: NonNullable<string>;
      phone?: NonNullable<string>;
      firstname?: NonNullable<string>;
      lastname?: NonNullable<string>;
      country?: NonNullable<string>;
      crops?: NonNullable<
        (
          | "apple"
          | "banana"
          | "barley"
          | "bean"
          | "beet"
          | "cabbage"
          | "carrot"
          | "colza"
          | "corn"
          | "garden"
          | "garlic"
          | "greenhouse"
          | "lettuce"
          | "linen"
          | "oat"
          | "onion"
          | "other_fruits"
          | "other_vegetables"
          | "other-agri"
          | "other-tree"
          | "pea"
          | "peach"
          | "pear"
          | "potato"
          | "potatoes"
          | "rapeseed"
          | "rye"
          | "sunflower"
          | "tomato"
          | "vine"
          | "wheat"
          | "wine"
        )[]
      >;
      locale?: NonNullable<string>;
      timeZone?: NonNullable<string>;
    }>;
    export type TestingValues = NonNullable<{
      WIND_SPEED?: Components.Schemas.TestingValue;
      WIND_DIRECTION?: Components.Schemas.TestingValue;
      WIND_GUST?: Components.Schemas.TestingValue;
      RELATIVE_HUMIDITY?: Components.Schemas.TestingValue;
      TEMPERATURE?: Components.Schemas.TestingValue;
      WET_TEMPERATURE?: Components.Schemas.TestingValue;
      LEAF_SENSOR_CONDUCTIVITY?: Components.Schemas.TestingValue;
      TEMPERATURE_INTERNAL?: Components.Schemas.TestingValue;
      IRRADIANCE?: Components.Schemas.TestingValue;
      IRRADIANCE_GAUGE?: Components.Schemas.TestingValue;
      RAIN_FALL?: Components.Schemas.TestingValue;
      RAIN_GAUGE?: Components.Schemas.TestingValue;
      RAIN_DAY?: Components.Schemas.TestingValue;
      PRESSURE?: Components.Schemas.TestingValue;
      MOVE?: Components.Schemas.TestingValue;
      INIT?: Components.Schemas.TestingValue;
      ERROR?: Components.Schemas.TestingValue;
      RESET?: Components.Schemas.TestingValue;
      TEST?: Components.Schemas.TestingValue;
      BATTERY?: Components.Schemas.TestingValue;
      FIRMWARE?: Components.Schemas.TestingValue;
      SAT?: Components.Schemas.TestingValue;
      LAT?: Components.Schemas.TestingValue;
      LNG?: Components.Schemas.TestingValue;
      ALT?: Components.Schemas.TestingValue;
      PRECISION?: Components.Schemas.TestingValue;
      WIND_MEAN?: Components.Schemas.TestingValue;
      WIND_MAX?: Components.Schemas.TestingValue;
      RH_AIR_H1?: Components.Schemas.TestingValue;
      TEMP_AIR_H1?: Components.Schemas.TestingValue;
      RAIN_TIC?: Components.Schemas.TestingValue;
      RAIN_SUM?: Components.Schemas.TestingValue;
      SIGNAL?: Components.Schemas.TestingValue;
      GPS?: Components.Schemas.TestingValue;
    }>;
    export type TestingErrors = NonNullable<Components.Schemas.TestingError[]>;
    export type AuthorizationCodeTokenRequestBody = NonNullable<{
      grant_type: "authorization_code";
      code?: NonNullable<string>;
      client_id?: NonNullable<string>;
      redirect_uri?: NonNullable<string>;
    }>;
    export type PasswordTokenRequestBody = NonNullable<{
      grant_type: "password";
      username: NonNullable<string>;
      password: NonNullable<string>;
      scope?: NonNullable<string>;
    }>;
    export type ClientCredentialsTokenRequestBody = NonNullable<{
      grant_type: "client_credentials";
      scope?: NonNullable<string>;
    }>;
    export type RefreshTokenRequestBody = NonNullable<{
      grant_type: "refresh_token";
      refresh_token: NonNullable<string>;
      scope?: NonNullable<string>;
    }>;
    export type ModuleTokenRequestBody = NonNullable<{
      grant_type: "module";
      email: NonNullable<string>;
      scope?: NonNullable<string>;
    }>;
    export type CodeRequestTokenRequestBody = NonNullable<{
      grant_type: "code_request";
      email: NonNullable<string>;
      code: NonNullable<string>;
      scope?: NonNullable<string>;
    }>;
    export type ApplicationContents = NonNullable<{
      name: NonNullable<string>;
      description: NonNullable<string>;
      isActive: NonNullable<boolean>;
      scopes: NonNullable<NonNullable<string>[]>;
      grantTypes: NonNullable<NonNullable<string>[]>;
      callbackURL: NonNullable<string>;
    }>;
    export type ModuleContents = NonNullable<{
      name: NonNullable<string>;
      code: NonNullable<string>;
      description?: NonNullable<string>;
      type: "email" | "ftp" | "partner" | "s3";
    }>;
    export type Referral = NonNullable<{
      utm_source?: NonNullable<string>;
      utm_medium?: NonNullable<string>;
      utm_campaign?: NonNullable<string>;
      [pattern: string]: unknown;
    }>;
    export type GenericDeviceHash = NonNullable<{
      [pattern: string]: Components.Schemas.GenericDevice;
    }>;
    export type ModelHash = NonNullable<{
      [pattern: string]: Components.Schemas.Model;
    }>;
    export type DeviceStatusHash = NonNullable<{
      [pattern: string]: Components.Schemas.DeviceStatus;
    }>;
    export type OrganisationHash = NonNullable<{
      [pattern: string]: Components.Schemas.Organisation;
    }>;
    export type AggregationHash = NonNullable<{
      [pattern: string]: Components.Schemas.Aggregation;
    }>;
    export type Measure = NonNullable<{
      data?: NonNullable<
        (NonNullable<{
          key: NonNullable<number>;
          docCount: NonNullable<number>;
        }> &
          (
            | NonNullable<{
                RELATIVE_HUMIDITY: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                RELATIVE_HUMIDITY_MIN: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                RELATIVE_HUMIDITY_MAX: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                RELATIVE_HUMIDITY_INTERPOLATED: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                RELATIVE_HUMIDITY_LAST: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                TEMPERATURE: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                TEMPERATURE_MIN: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                TEMPERATURE_MAX: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                TEMPERATURE_INTERPOLATED: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                TEMPERATURE_LAST: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                WET_TEMPERATURE: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                WET_TEMPERATURE_MIN: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                WET_TEMPERATURE_MAX: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                WET_TEMPERATURE_INTERPOLATED: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                WET_TEMPERATURE_LAST: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                LEAF_SENSOR_CONDUCTIVITY: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                LEAF_SENSOR_CONDUCTIVITY_MIN: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                LEAF_SENSOR_CONDUCTIVITY_MAX: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                LEAF_SENSOR_CONDUCTIVITY_LAST: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                RAIN_FALL: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                RAIN_FALL_MEAN_SUM: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                RAIN_FALL_MEAN_SUM_ADJUSTED: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                WIND_SPEED: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                WIND_GUST: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                WIND_DIRECTION: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                MOVE: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                LEAF_WETNESS: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                LEAF_WETNESS_MEDIUM: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                LEAF_WETNESS_HIGH: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                IRRADIANCE: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                RH_AIR_H1: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                RH_AIR_H1_MIN: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                RH_AIR_H1_MAX: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                TEMP_AIR_H1: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                TEMP_AIR_H1_MIN: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                TEMP_AIR_H1_MAX: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                RAIN_TIC: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                WIND_MEAN: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
            | NonNullable<{
                WIND_MAX: NonNullable<{
                  value: NonNullable<number>;
                  precision?: NonNullable<number>;
                  source?: NonNullable<string>;
                }>;
              }>
          ))[]
      >;
      interval?: "15m" | "1h" | "6h" | "1d" | "1w" | "1M" | "1y";
    }>;
    export type AlertHash = NonNullable<{
      [pattern: string]: Components.Schemas.Alert;
    }>;
    export type InvitationHash = NonNullable<{
      [pattern: string]: Components.Schemas.Invitation;
    }>;
    export type UserHash = NonNullable<{
      [pattern: string]: Components.Schemas.User;
    }>;
    export type SharesHash = NonNullable<{
      [pattern: string]: Components.Schemas.Shares;
    }>;
    export type PlaceHash = NonNullable<{
      [pattern: string]: Components.Schemas.Place;
    }>;
    export type CropHash = NonNullable<{
      [pattern: string]: Components.Schemas.Crop;
    }>;
    export type DeviceSummaryHash = NonNullable<{
      [pattern: string]: Components.Schemas.DeviceSummary;
    }>;
    export type DeviceLiveAggregationHash = NonNullable<{
      [pattern: string]: Components.Schemas.DeviceLiveAggregation;
    }>;
    export type ModuleHash = NonNullable<{
      [pattern: string]: Components.Schemas.Module;
    }>;
    export type NetworkHash = NonNullable<{
      [pattern: string]: Components.Schemas.Network;
    }>;
    export type OrderHash = NonNullable<{
      [pattern: string]: Components.Schemas.Order;
    }>;
    export type OrderCreationHash = NonNullable<{
      [pattern: string]: Components.Schemas.OrderCreation;
    }>;
    export type OrderByUserEmailHash = NonNullable<{
      [pattern: string]: Components.Schemas.OrderByUserEmail;
    }>;
    export type TrialOperationHash = NonNullable<{
      [pattern: string]: Components.Schemas.TrialOperation;
    }>;
    export type PlanHash = NonNullable<{
      [pattern: string]: Components.Schemas.Plan;
    }>;
    export type WeatherLiveAggregationHash = NonNullable<{
      [pattern: string]: Components.Schemas.WeatherLiveAggregation;
    }>;
    export type QuoteHash = NonNullable<{
      [pattern: string]: Components.Schemas.Quote;
    }>;
    export type AggregationsResultsHash = NonNullable<{
      [pattern: string]: Components.Schemas.AggregationResult;
    }>;
    export type TestingSessionHash = NonNullable<{
      [pattern: string]: Components.Schemas.TestingSession;
    }>;
    export type ApplicationHash = NonNullable<{
      [pattern: string]: Components.Schemas.Application;
    }>;
    export type AlertGroupConditions = NonNullable<{
      type: "and" | "or";
      tests: NonNullable<Components.Schemas.AlertConditions[]>;
    }>;
    export type ManualOrderAddress = NonNullable<{
      line1: NonNullable<string>;
      line2?: NonNullable<string>;
      city: NonNullable<string>;
      zip: NonNullable<string>;
      country: NonNullable<string>;
    }>;
    export type ChargebeeOrderShippingAddress = NonNullable<{
      line1: NonNullable<string>;
      line2?: NonNullable<string>;
      city: NonNullable<string>;
      zip: NonNullable<string>;
      country: NonNullable<string>;
      firstname: NonNullable<string>;
      lastname: NonNullable<string>;
      email: NonNullable<string>;
      phone: NonNullable<string>;
      company?: NonNullable<string>;
    }>;
    export type ChargebeeOrderBillingAddress = NonNullable<{
      line1: NonNullable<string>;
      line2?: NonNullable<string>;
      city: NonNullable<string>;
      zip: NonNullable<string>;
      country: NonNullable<string>;
      firstname: NonNullable<string>;
      lastname: NonNullable<string>;
      email?: NonNullable<string>;
      phone?: NonNullable<string>;
      company?: NonNullable<string>;
    }>;
    export type ChargebeeOrderProduct = NonNullable<{
      sku: NonNullable<string>;
      quantity: NonNullable<number>;
      description?: NonNullable<string>;
    }>;
    export type RecurringAddonHash = NonNullable<{
      [pattern: string]: Components.Schemas.RecurringAddon;
    }>;
    export type OrganisationPlace = NonNullable<{
      id?: NonNullable<number>;
      placeId?: NonNullable<number>;
      organisationId?: NonNullable<number>;
      type?: NonNullable<string>;
    }>;
    export type OrganisationMember = NonNullable<{
      id?: NonNullable<number>;
      userId?: NonNullable<number>;
      organisationId?: NonNullable<number>;
      type?: NonNullable<string>;
    }>;
    export type TestingValue = NonNullable<{
      time: NonNullable<number>;
      values: NonNullable<(NonNullable<number> | NonNullable<string>)[]>;
    }>;
    export type TestingError = NonNullable<{
      type: NonNullable<string>;
      level: NonNullable<number>;
      measure: NonNullable<string>;
      message: NonNullable<string>;
    }>;
    export type GenericDevice =
      | Components.Schemas.Device
      | Components.Schemas.LimitedDevice;
    export type Model = NonNullable<{
      id?: NonNullable<number>;
      contents: Components.Schemas.ModelContents;
    }>;
    export type DeviceStatus = NonNullable<{
      id?: NonNullable<number>;
      contents: Components.Schemas.DeviceStatusContents;
    }>;
    export type Crop = NonNullable<{
      id?: NonNullable<number>;
      hits?: NonNullable<number>;
      contents: Components.Schemas.CropContents;
    }>;
    export type DeviceSummary = NonNullable<{
      RELATIVE_HUMIDITY?: NonNullable<{
        date?: NonNullable<string>;
        lastMeasure: NonNullable<number>;
        lastHour?: NonNullable<number>;
        last3Hours?: NonNullable<number>;
        lastDay?: NonNullable<number>;
        lastWeek?: NonNullable<number>;
      }>;
      TEMPERATURE?: NonNullable<{
        date?: NonNullable<string>;
        lastMeasure: NonNullable<number>;
        lastHour?: NonNullable<number>;
        last3Hours?: NonNullable<number>;
        lastDay?: NonNullable<number>;
        lastWeek?: NonNullable<number>;
      }>;
      WET_TEMPERATURE?: NonNullable<{
        date?: NonNullable<string>;
        lastMeasure: NonNullable<number>;
        lastHour?: NonNullable<number>;
        last3Hours?: NonNullable<number>;
        lastDay?: NonNullable<number>;
        lastWeek?: NonNullable<number>;
      }>;
      RAIN_FALL?: NonNullable<{
        date?: NonNullable<string>;
        lastMeasure: NonNullable<number>;
        lastHour?: NonNullable<number>;
        last3Hours?: NonNullable<number>;
        lastDay?: NonNullable<number>;
        lastWeek?: NonNullable<number>;
      }>;
      WIND_SPEED?: NonNullable<{
        date?: NonNullable<string>;
        lastMeasure: NonNullable<number>;
        lastHour?: NonNullable<number>;
        last3Hours?: NonNullable<number>;
        lastDay?: NonNullable<number>;
        lastWeek?: NonNullable<number>;
      }>;
      WIND_GUST?: NonNullable<{
        date?: NonNullable<string>;
        lastMeasure: NonNullable<number>;
        lastHour?: NonNullable<number>;
        last3Hours?: NonNullable<number>;
        lastDay?: NonNullable<number>;
        lastWeek?: NonNullable<number>;
      }>;
      WIND_DIRECTION?: NonNullable<{
        date?: NonNullable<string>;
        lastMeasure: NonNullable<number>;
        lastHour?: NonNullable<number>;
        last3Hours?: NonNullable<number>;
        lastDay?: NonNullable<number>;
        lastWeek?: NonNullable<number>;
      }>;
      MOVE?: NonNullable<{
        date?: NonNullable<string>;
        lastMeasure: NonNullable<number>;
        lastHour?: NonNullable<number>;
        last3Hours?: NonNullable<number>;
        lastDay?: NonNullable<number>;
        lastWeek?: NonNullable<number>;
      }>;
      LEAF_SENSOR_CONDUCTIVITY?: NonNullable<{
        date?: NonNullable<string>;
        lastMeasure: NonNullable<number>;
        lastHour?: NonNullable<number>;
        last3Hours?: NonNullable<number>;
        lastDay?: NonNullable<number>;
        lastWeek?: NonNullable<number>;
      }>;
      RH_AIR_H1?: NonNullable<{
        date?: NonNullable<string>;
        lastMeasure: NonNullable<number>;
        lastHour?: NonNullable<number>;
        last3Hours?: NonNullable<number>;
        lastDay?: NonNullable<number>;
        lastWeek?: NonNullable<number>;
      }>;
      TEMP_AIR_H1?: NonNullable<{
        date?: NonNullable<string>;
        lastMeasure: NonNullable<number>;
        lastHour?: NonNullable<number>;
        last3Hours?: NonNullable<number>;
        lastDay?: NonNullable<number>;
        lastWeek?: NonNullable<number>;
      }>;
      RAIN_TIC?: NonNullable<{
        date?: NonNullable<string>;
        lastMeasure: NonNullable<number>;
        lastHour?: NonNullable<number>;
        last3Hours?: NonNullable<number>;
        lastDay?: NonNullable<number>;
        lastWeek?: NonNullable<number>;
      }>;
      WIND_MEAN?: NonNullable<{
        date?: NonNullable<string>;
        lastMeasure: NonNullable<number>;
        lastHour?: NonNullable<number>;
        last3Hours?: NonNullable<number>;
        lastDay?: NonNullable<number>;
        lastWeek?: NonNullable<number>;
      }>;
      WIND_MAX?: NonNullable<{
        date?: NonNullable<string>;
        lastMeasure: NonNullable<number>;
        lastHour?: NonNullable<number>;
        last3Hours?: NonNullable<number>;
        lastDay?: NonNullable<number>;
        lastWeek?: NonNullable<number>;
      }>;
    }>;
    export type DeviceLiveAggregation = NonNullable<{
      RELATIVE_HUMIDITY_MEAN?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      RELATIVE_HUMIDITY_MIN?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      RELATIVE_HUMIDITY_MAX?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      RELATIVE_HUMIDITY_INTERPOLATED?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      RELATIVE_HUMIDITY_LAST?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      TEMPERATURE_MEAN?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      TEMPERATURE_MIN?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      TEMPERATURE_MAX?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      TEMPERATURE_INTERPOLATED?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      TEMPERATURE_LAST?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      WET_TEMPERATURE_MEAN?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      WET_TEMPERATURE_MIN?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      WET_TEMPERATURE_MAX?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      WET_TEMPERATURE_INTERPOLATED?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      WET_TEMPERATURE_LAST?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      RAIN_FALL_MEAN_SUM?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      RAIN_FALL_MEAN_SUM_ADJUSTED?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      RAIN_FALL_SUM?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      RAIN_FALL_MIN?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      RAIN_FALL_MIN_SUM?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      RAIN_FALL_MAX?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      RAIN_FALL_MAX_SUM?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      MOVE_SUM?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      INIT_SUM?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      WIND_SPEED_MEAN?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      WIND_SPEED_MIN?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      WIND_SPEED_MAX?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      WIND_SPEED_INTERPOLATED?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      WIND_SPEED_LAST?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      WIND_GUST_MEAN?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      WIND_GUST_MIN?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      WIND_GUST_MAX?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      WIND_GUST_INTERPOLATED?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      WIND_GUST_LAST?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      WIND_DIRECTION_MEAN?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      DEGREE_DAY?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      COLD_HOURS?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      LEAF_SENSOR_CONDUCTIVITY_MIN?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      LEAF_SENSOR_CONDUCTIVITY_MAX?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      LEAF_SENSOR_CONDUCTIVITY_MEAN?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      LEAF_WETNESS?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      IRRADIANCE_MEAN?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      LEAF_WETNESS_MEDIUM?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
      LEAF_WETNESS_HIGH?: NonNullable<{
        current?: NonNullable<number>;
        one_hour?: NonNullable<number>;
        three_hours?: NonNullable<number>;
        one_day?: NonNullable<number>;
        one_week?: NonNullable<number>;
        today?: NonNullable<number>;
        yesterday?: NonNullable<number>;
        this_week?: NonNullable<number>;
        last_seven_days?: NonNullable<number>;
      }>;
    }>;
    export type Network = NonNullable<{
      id: NonNullable<string>;
      organisationId: NonNullable<number>;
      name: NonNullable<string>;
      description?: NonNullable<string>;
      logoUrl?: NonNullable<string>;
      type: "open" | "private";
      preferences: Components.Schemas.OrganisationPreferences;
      devicesCount?: NonNullable<number>;
      selectSharesCount?: NonNullable<number>;
      creationDate?: NonNullable<string>;
      lastModificationDate?: NonNullable<string>;
    }>;
    export type Order = NonNullable<{
      id: NonNullable<string>;
    }> &
      (
        | Components.Schemas.ManualOrder
        | Components.Schemas.ChargebeeOrderWithCustomer
      );
    export type OrderByUserEmail = NonNullable<{
      id: NonNullable<string>;
    }> &
      (Components.Schemas.ManualOrder | Components.Schemas.ChargebeeOrder);
    export type TrialOperation = NonNullable<{
      id: NonNullable<string>;
      creationDate: NonNullable<string>;
      lastModificationDate: NonNullable<string>;
      deletionDate?: NonNullable<string>;
      organisation?: NonNullable<number>;
      contents: Components.Schemas.TrialOperationContents;
    }>;
    export type Plan = NonNullable<{
      id?: NonNullable<string>;
      price?: NonNullable<number>;
      currency?: NonNullable<string>;
      chargebeePlanId?: NonNullable<string>;
    }>;
    export type WeatherLiveAggregation = NonNullable<{
      RELATIVE_HUMIDITY_MEAN?: NonNullable<number>;
      RELATIVE_HUMIDITY_MIN?: NonNullable<number>;
      RELATIVE_HUMIDITY_MAX?: NonNullable<number>;
      RELATIVE_HUMIDITY_INTERPOLATED?: NonNullable<number>;
      RELATIVE_HUMIDITY_LAST?: NonNullable<number>;
      TEMPERATURE_MEAN?: NonNullable<number>;
      TEMPERATURE_MIN?: NonNullable<number>;
      TEMPERATURE_MAX?: NonNullable<number>;
      TEMPERATURE_INTERPOLATED?: NonNullable<number>;
      TEMPERATURE_LAST?: NonNullable<number>;
      WET_TEMPERATURE_MEAN?: NonNullable<number>;
      WET_TEMPERATURE_MIN?: NonNullable<number>;
      WET_TEMPERATURE_MAX?: NonNullable<number>;
      WET_TEMPERATURE_INTERPOLATED?: NonNullable<number>;
      WET_TEMPERATURE_LAST?: NonNullable<number>;
      RAIN_FALL_MEAN_SUM?: NonNullable<number>;
      RAIN_FALL_MEAN_SUM_ADJUSTED?: NonNullable<number>;
      RAIN_FALL_SUM?: NonNullable<number>;
      RAIN_FALL_MIN?: NonNullable<number>;
      RAIN_FALL_MIN_SUM?: NonNullable<number>;
      RAIN_FALL_MAX?: NonNullable<number>;
      RAIN_FALL_MAX_SUM?: NonNullable<number>;
      MOVE_SUM?: NonNullable<number>;
      INIT_SUM?: NonNullable<number>;
      WIND_SPEED_MEAN?: NonNullable<number>;
      WIND_SPEED_MIN?: NonNullable<number>;
      WIND_SPEED_MAX?: NonNullable<number>;
      WIND_SPEED_INTERPOLATED?: NonNullable<number>;
      WIND_SPEED_LAST?: NonNullable<number>;
      WIND_GUST_MEAN?: NonNullable<number>;
      WIND_GUST_MIN?: NonNullable<number>;
      WIND_GUST_MAX?: NonNullable<number>;
      WIND_GUST_INTERPOLATED?: NonNullable<number>;
      WIND_GUST_LAST?: NonNullable<number>;
      WIND_DIRECTION_MEAN?: NonNullable<number>;
      DEGREE_DAY?: NonNullable<number>;
      COLD_HOURS?: NonNullable<number>;
      LEAF_SENSOR_CONDUCTIVITY_MIN?: NonNullable<number>;
      LEAF_SENSOR_CONDUCTIVITY_MAX?: NonNullable<number>;
      LEAF_SENSOR_CONDUCTIVITY_MEAN?: NonNullable<number>;
      LEAF_WETNESS?: NonNullable<number>;
      IRRADIANCE_MEAN?: NonNullable<number>;
      LEAF_WETNESS_MEDIUM?: NonNullable<number>;
      LEAF_WETNESS_HIGH?: NonNullable<number>;
    }>;
    export type Quote = NonNullable<{
      id: NonNullable<string>;
      author: NonNullable<number>;
      userRecipient: NonNullable<number>;
      organisationRecipient: NonNullable<number>;
      chargebeeQuoteId: NonNullable<string>;
      chargebee?: NonNullable<{
        quote?: NonNullable<{
          [pattern: string]: unknown;
        }>;
      }>;
      hubSpotDealId: NonNullable<string>;
      operationId?: NonNullable<string>;
      isPromotingConversion: NonNullable<boolean>;
      country?: NonNullable<string>;
      creationDate?: NonNullable<string>;
      lastModificationDate?: NonNullable<string>;
    }>;
    export type AggregationResult = NonNullable<{
      type:
        | "DEGREE_DAY"
        | "COLD_HOURS"
        | "RAIN_FALL_SUM"
        | "LEAF_WETNESS"
        | "LEAF_WETNESS_MEDIUM"
        | "LEAF_WETNESS_HIGH";
      DEGREE_DAY?: NonNullable<{
        value?: NonNullable<number>;
      }>;
      COLD_HOURS?: NonNullable<{
        value?: NonNullable<number>;
      }>;
      RAIN_FALL_SUM?: NonNullable<{
        value?: NonNullable<number>;
      }>;
      LEAF_WETNESS?: NonNullable<{
        value?: NonNullable<number>;
      }>;
      LEAF_WETNESS_MEDIUM?: NonNullable<{
        value?: NonNullable<number>;
      }>;
      LEAF_WETNESS_HIGH?: NonNullable<{
        value?: NonNullable<number>;
      }>;
    }>;
    export type TestingSession = NonNullable<{
      id: NonNullable<string>;
      userId: NonNullable<number>;
      modelId: NonNullable<number>;
      serial: NonNullable<string>;
      identification: NonNullable<string>;
      testingState:
        | "AWAIT_TESTING"
        | "UNDER_TESTING"
        | "PASSED_TESTING"
        | "DONE_TESTING";
      activationCode?: NonNullable<string>;
      sensorId?: NonNullable<string>;
      testingValues: Components.Schemas.TestingValues;
      testingErrors?: Components.Schemas.TestingErrors;
      batchId?: NonNullable<number>;
      creationDate: NonNullable<string>;
      lastModificationDate: NonNullable<string>;
      passingDate?: NonNullable<string>;
    }>;
    export type AlertConditions = NonNullable<{
      ceil: NonNullable<number>;
      type: "aggregation";
      ceilComparisonType: "lte" | "gte";
      startDate?: NonNullable<string>;
      aggregation:
        | "RELATIVE_HUMIDITY_MEAN"
        | "RELATIVE_HUMIDITY_MIN"
        | "RELATIVE_HUMIDITY_MAX"
        | "RELATIVE_HUMIDITY_INTERPOLATED"
        | "RELATIVE_HUMIDITY_LAST"
        | "TEMPERATURE_MEAN"
        | "TEMPERATURE_MIN"
        | "TEMPERATURE_MAX"
        | "TEMPERATURE_INTERPOLATED"
        | "TEMPERATURE_LAST"
        | "WET_TEMPERATURE_MEAN"
        | "WET_TEMPERATURE_MIN"
        | "WET_TEMPERATURE_MAX"
        | "WET_TEMPERATURE_INTERPOLATED"
        | "WET_TEMPERATURE_LAST"
        | "RAIN_FALL_MEAN_SUM"
        | "RAIN_FALL_MEAN_SUM_ADJUSTED"
        | "RAIN_FALL_SUM"
        | "RAIN_FALL_MIN"
        | "RAIN_FALL_MIN_SUM"
        | "RAIN_FALL_MAX"
        | "RAIN_FALL_MAX_SUM"
        | "MOVE_SUM"
        | "INIT_SUM"
        | "WIND_SPEED_MEAN"
        | "WIND_SPEED_MIN"
        | "WIND_SPEED_MAX"
        | "WIND_SPEED_INTERPOLATED"
        | "WIND_SPEED_LAST"
        | "WIND_GUST_MEAN"
        | "WIND_GUST_MIN"
        | "WIND_GUST_MAX"
        | "WIND_GUST_INTERPOLATED"
        | "WIND_GUST_LAST"
        | "WIND_DIRECTION_MEAN"
        | "DEGREE_DAY"
        | "COLD_HOURS"
        | "LEAF_SENSOR_CONDUCTIVITY_MIN"
        | "LEAF_SENSOR_CONDUCTIVITY_MAX"
        | "LEAF_SENSOR_CONDUCTIVITY_MEAN"
        | "LEAF_WETNESS"
        | "IRRADIANCE_MEAN"
        | "LEAF_WETNESS_MEDIUM"
        | "LEAF_WETNESS_HIGH";
      aggregationParams?: NonNullable<{
        [pattern: string]: unknown;
      }>;
    }> &
      (
        | NonNullable<{
            timespan: NonNullable<number>;
          }>
        | NonNullable<{
            startDate: NonNullable<string>;
          }>
      );
    export type RecurringAddon = NonNullable<{
      id?: NonNullable<string>;
      quantity?: NonNullable<number>;
    }>;
    export type LimitedDevice = NonNullable<{
      id?: NonNullable<number>;
      identification?: NonNullable<string>;
      modelId?: NonNullable<number>;
      organisationId?: NonNullable<number>;
      city?: NonNullable<string>;
      location?: NonNullable<{
        latitude: NonNullable<number>;
        longitude: NonNullable<number>;
        altitude?: NonNullable<number>;
        satellites?: NonNullable<number>;
        precision?: NonNullable<number>;
        source?: "gps" | "blur";
      }>;
      status?: NonNullable<{
        firmware: NonNullable<string>;
        battery: NonNullable<number>;
        signal: NonNullable<number>;
        lastLocationDate: NonNullable<string>;
        lastLocationFailureDate: NonNullable<string>;
        lastRebootDate: NonNullable<string>;
        lastMoveDate: NonNullable<string>;
        lastStatusUpdateDate: NonNullable<string>;
        activationDate?: NonNullable<string>;
        manufacturedDate?: NonNullable<string>;
      }>;
    }>;
    export type ModelContents = NonNullable<{
      name: NonNullable<string>;
      externalDiameter?: NonNullable<number>;
      conception: NonNullable<string>;
      manufacturing: NonNullable<string>;
      calibration: NonNullable<string>;
      height?: NonNullable<number>;
      weight: NonNullable<number>;
    }>;
    export type DeviceStatusContents = NonNullable<{
      firmware: NonNullable<string>;
      battery: NonNullable<number>;
      lqi: NonNullable<string>;
      latitude: NonNullable<number>;
      longitude: NonNullable<number>;
      altitude: NonNullable<number>;
      locationSatellites: NonNullable<number>;
      locationPrecision: NonNullable<number>;
      lastLocationDate: NonNullable<string>;
      lastLocationFailureDate: NonNullable<string>;
      lastRebootDate: NonNullable<string>;
      lastMoveDate: NonNullable<string>;
      lastStatusUpdateDate: NonNullable<string>;
      activationDate?: NonNullable<string>;
      manufacturedDate?: NonNullable<string>;
    }>;
    export type CropContents = NonNullable<{
      name: NonNullable<string>;
    }>;
    export type TrialOperationContents = NonNullable<{
      title: NonNullable<string>;
      duration?: NonNullable<number>;
      endDate?: NonNullable<string>;
      expirationDate?: NonNullable<string>;
    }>;
    export type ManualOrder = NonNullable<{
      id: NonNullable<string>;
      creationDate: NonNullable<string>;
      lastModificationDate: NonNullable<string>;
      source: "manual";
      contents: Components.Schemas.ManualOrderContent;
    }>;
    export type ChargebeeOrderWithCustomer = NonNullable<{
      id: NonNullable<string>;
      creationDate: NonNullable<string>;
      lastModificationDate: NonNullable<string>;
      source: "chargebee";
      invoiceId: NonNullable<string>;
      contents: Components.Schemas.ChargebeeOrderWithCustomerContent;
    }>;
    export type ChargebeeOrder = NonNullable<{
      id: NonNullable<string>;
      creationDate: NonNullable<string>;
      lastModificationDate: NonNullable<string>;
      source: "chargebee";
      invoiceId: NonNullable<string>;
      contents: Components.Schemas.ChargebeeOrderContent;
    }>;
    export type ManualOrderContent = NonNullable<{
      status:
        | "queued"
        | "awaiting_shipment"
        | "on_hold"
        | "delivered"
        | "shipped"
        | "partially_delivered"
        | "returned";
      type:
        | "unknown"
        | "promoting_farmer"
        | "replacement"
        | "spare_parts"
        | "mono_point"
        | "functional_demo_stations"
        | "non_functional_demo_stations"
        | "remote_stock"
        | "leafcrop_recall"
        | "prototype";
      hubspotTicketId?: NonNullable<string>;
      hubspotDealId?: NonNullable<string>;
      operationId?: NonNullable<string>;
      customer: NonNullable<{
        email: NonNullable<string>;
        firstname: NonNullable<string>;
        lastname: NonNullable<string>;
        phone: NonNullable<string>;
        locale: NonNullable<string>;
      }>;
      company?: NonNullable<{
        name?: NonNullable<string>;
      }>;
      shippingAddress: Components.Schemas.ManualOrderAddress;
      billingAddress?: Components.Schemas.ManualOrderAddress;
      products: NonNullable<
        NonNullable<{
          sku: NonNullable<string>;
          quantity: NonNullable<number>;
        }>[]
      >;
    }>;
    export type ChargebeeOrderContent = NonNullable<{
      status?:
        | "queued"
        | "awaiting_shipment"
        | "on_hold"
        | "delivered"
        | "shipped"
        | "partially_delivered"
        | "returned";
      type: "unknown";
      customer: NonNullable<{}>;
      company: NonNullable<{}>;
      shippingAddress?: Components.Schemas.ChargebeeOrderShippingAddress;
      billingAddress?: Components.Schemas.ChargebeeOrderBillingAddress;
      products: NonNullable<Components.Schemas.ChargebeeOrderProduct[]>;
    }>;
  }
}

const DEFAULT_BASE_URL = "http://localhost:1337/master";
const URI_BUILDER_DEFAULTS = {
  baseURL: DEFAULT_BASE_URL,
};
const INPUT_BUILDER_DEFAULTS = {
  baseURL: DEFAULT_BASE_URL,
  headers: {},
};

/**
 * API to consume Sencrop data
 * @module API
 * @version 1.72.19
 */
const API = {
  deleteBucketDeviceSupport,
  putBucketDeviceSupport,
  deleteDeviceAccessSupport,
  putDeviceAccessSupport,
  deleteDeviceAccessesSupport,
  putDeviceAccessesSupport,
  deleteExpiredTokens,
  deleteOrganisationChargebeeId,
  postOrganisationChargebeeId,
  deleteOrganisationDevice,
  getOrganisationDevice,
  putOrganisationDevice,
  deleteOrganisationMemberSupport,
  putOrganisationMemberSupport,
  deleteOrganisationNetworkDeviceAccess,
  putOrganisationNetworkDeviceAccess,
  deleteSencropMeasures,
  deleteUserOrganisationAggregation,
  putUserOrganisationAggregation,
  deleteUserOrganisationAlert,
  putUserOrganisationAlert,
  deleteUserOrganisationDeviceModule,
  putUserOrganisationDeviceModule,
  deleteUserOrganisationInvitation,
  putUserOrganisationInvitation,
  deleteUserOrganisationMember,
  putUserOrganisationMember,
  deleteUserOrganisationOpenNetworkMembership,
  putUserOrganisationOpenNetworkMembership,
  deleteUserOrganisationShare,
  putUserOrganisationShare,
  getOpenAPI,
  getAgritelPrices,
  getChargebeeCustomer,
  getChargebeeResourcesPublic,
  getChargebeeRessources,
  getCotations,
  getCrops,
  getCustomerByEmail,
  getData,
  getDeviceData,
  getDeviceRawData,
  getDevices,
  getFactoryMeasures,
  getFactorySigfoxStatus,
  getHubSpotContact,
  getHubSpotProperties,
  getHubSpotQuotatorCard,
  getHubSpotTableauCard,
  getInvitation,
  getInvitationDeviceLiveAggregations,
  getLatestIrrCalibration,
  getLatestIrrCalibrations,
  getModules,
  getMySelf,
  getNetwork,
  getNetworkDeviceLiveAggregations,
  getNetworkDevices,
  getOAuth2Authorize,
  getOrders,
  postOrder,
  getOrdersAsXLSX,
  getOrdersByUserEmail,
  getOrganisation,
  putOrganisation,
  getOrganisationChargebeePortalSession,
  getOrganisationDevices,
  getOrganisationDevicesLiveAggregations,
  getOrganisationHostedPage,
  getOrganisationHostedPageBattery,
  getOrganisationHostedPageUpgradePlanPlus,
  getOrganisationInvitation,
  getOrganisationInvitations,
  getOrganisationMember,
  getOrganisationMembers,
  getOrganisationNetworkDevices,
  getOrganisationNetworks,
  getOrganisationPlace,
  getOrganisationPlaces,
  postOrganisationPlace,
  getOrganisationPreferences,
  putOrganisationPreferences,
  getOrganisationShare,
  getOrganisationShares,
  getOrganisationsTrialOperations,
  getPartnerDevices,
  getPing,
  getPlans,
  getPublicWeatherLive,
  getPublicWeatherLiveAggregations,
  getQuote,
  getQuoteAsPdf,
  getQuoteHostedPage,
  getQuoteInvoiceAsPdf,
  getRadarImage,
  getSearchDevices,
  getSearchNetworkDevices,
  getSearchPublicOrganisation,
  getSearchPublicUser,
  getSearchUser,
  getSigfoxCoverage,
  getSigfoxMap,
  getStatusHead,
  getTrialOperation,
  getUser,
  putUser,
  getUserAggregation,
  getUserAggregations,
  getUserAlert,
  getUserAlertVoiceMessage,
  getUserAlerts,
  getUserDailyData,
  getUserDevice,
  getUserDeviceContinuousStatistics,
  getUserDeviceDailyData,
  getUserDeviceForecasts,
  getUserDeviceHourlyData,
  getUserDeviceModules,
  getUserDevicePositions,
  getUserDeviceRawData,
  getUserDeviceStatistics,
  getUserDeviceSummary,
  getUserDevices,
  getUserDevicesLiveAggregations,
  getUserForecasts,
  getUserHourlyData,
  getUserIntercomHash,
  getUserInvitation,
  getUserInvitations,
  getUserOrganisations,
  postUserOrganisation,
  getUserPendingInvitation,
  getUserPendingInvitations,
  getUserPreferences,
  putUserPreferences,
  getUserStatistics,
  getUserTestingSessions,
  putUserTestingSessions,
  getWeatherForecastModel,
  getWeatherLive,
  handleAggregationsCompute,
  handleAlertCheck,
  handleAlertRepetition,
  postAircallIncomingCalls,
  postAlertsTrialDisable,
  postApplicationEvent,
  postBulkExportsSupport,
  postCSVArvalisMileosData,
  postCSVArvalisMileosInitialData,
  postCSVBatchProd,
  postCSVCTIFLEmail,
  postCSVMovidaEmail,
  postCSVMovidaInitialEmail,
  postCSVRimproData,
  postCSVTTWData,
  postChargebeeEvents,
  postCloudwatchEvents,
  postCropsHits,
  postDataDeviceSituation,
  postDataWeatherForecastModel,
  postDeviceCleanIngestionCacheSupport,
  postDeviceReplacement,
  postFirehoseRecordPerLine,
  postFirehoseStream,
  postForecastStorage,
  postHubSpotContactSyncHook,
  postHubSpotContactTrialReactivation,
  postHubSpotDealSyncHook,
  postHubSpotDeleteDealHook,
  postIngestionData,
  postInvitationResend,
  postIrrelisData,
  postLambdaLogs,
  postLogin,
  postLostPassword,
  postMailgunWebHook,
  postMailjetEvents,
  postMeteoGroupData,
  postMovidaSetup,
  postNetworkJoinRequest,
  postNexmoEvents,
  postOAuth2Acknowledge,
  postOAuth2Token,
  postOrganisation,
  postOrganisationDeviceTransfer,
  postOrganisationEndTrialSupport,
  postOrganisationNetworkMemberPlan,
  postOrganisationPaymentComplete,
  postOrganisationToNetworkSupport,
  postOrganisationTrialReactivation,
  postOrganisationsMerge,
  postPartnerApplication,
  postPartnerModule,
  postPartnerToken,
  postPartnerTokenRequest,
  postPlace,
  postPrevilisData,
  postQuote,
  postQuoteOffline,
  postQuoteRequest,
  postRegister,
  postRegisterAuth0,
  postSigfoxData,
  postSigfoxReshape,
  postSignatureCloudinary,
  postSignupCheck,
  postSlackBotCommand,
  postSocialImage,
  postTelitData,
  postTwilioEvents,
  postUser,
  postUserClearSessions,
  postUserDeviceExport,
  postUserNetworkInvitation,
  postUserOrganisationAggregation,
  postUserOrganisationAlert,
  postUserOrganisationDevice,
  postUserOrganisationDeviceSupport,
  postUserOrganisationDevicesSupport,
  postUserOrganisationInvitation,
  postUserOrganisationMember,
  postUserPassword,
  postUserTerminateTokensSupport,
  postUserTerminatedSessions,
  postVerify,
  putBatchDevices,
  putDataDeviceCity,
  putDevicePosition,
  putIrradianceSensorCalibration,
  putOrganisationDeviceCalibration,
  putOrganisationDeviceSettings,
  putOrganisationPlace,
  putOrganisationTrial,
  putPartnerApplication,
  putPartnerModuleParameters,
  putPassword,
  putQuoteStatus,
  putUserFCMToken,
  putUserInvitationAnswer,
  getGraphQL,
  postGraphQL,
};

export const APIURIBuilders = {
  deleteBucketDeviceSupport: buildDeleteBucketDeviceSupportURI,
  putBucketDeviceSupport: buildPutBucketDeviceSupportURI,
  deleteDeviceAccessSupport: buildDeleteDeviceAccessSupportURI,
  putDeviceAccessSupport: buildPutDeviceAccessSupportURI,
  deleteDeviceAccessesSupport: buildDeleteDeviceAccessesSupportURI,
  putDeviceAccessesSupport: buildPutDeviceAccessesSupportURI,
  deleteExpiredTokens: buildDeleteExpiredTokensURI,
  deleteOrganisationChargebeeId: buildDeleteOrganisationChargebeeIdURI,
  postOrganisationChargebeeId: buildPostOrganisationChargebeeIdURI,
  deleteOrganisationDevice: buildDeleteOrganisationDeviceURI,
  getOrganisationDevice: buildGetOrganisationDeviceURI,
  putOrganisationDevice: buildPutOrganisationDeviceURI,
  deleteOrganisationMemberSupport: buildDeleteOrganisationMemberSupportURI,
  putOrganisationMemberSupport: buildPutOrganisationMemberSupportURI,
  deleteOrganisationNetworkDeviceAccess:
    buildDeleteOrganisationNetworkDeviceAccessURI,
  putOrganisationNetworkDeviceAccess:
    buildPutOrganisationNetworkDeviceAccessURI,
  deleteSencropMeasures: buildDeleteSencropMeasuresURI,
  deleteUserOrganisationAggregation: buildDeleteUserOrganisationAggregationURI,
  putUserOrganisationAggregation: buildPutUserOrganisationAggregationURI,
  deleteUserOrganisationAlert: buildDeleteUserOrganisationAlertURI,
  putUserOrganisationAlert: buildPutUserOrganisationAlertURI,
  deleteUserOrganisationDeviceModule:
    buildDeleteUserOrganisationDeviceModuleURI,
  putUserOrganisationDeviceModule: buildPutUserOrganisationDeviceModuleURI,
  deleteUserOrganisationInvitation: buildDeleteUserOrganisationInvitationURI,
  putUserOrganisationInvitation: buildPutUserOrganisationInvitationURI,
  deleteUserOrganisationMember: buildDeleteUserOrganisationMemberURI,
  putUserOrganisationMember: buildPutUserOrganisationMemberURI,
  deleteUserOrganisationOpenNetworkMembership:
    buildDeleteUserOrganisationOpenNetworkMembershipURI,
  putUserOrganisationOpenNetworkMembership:
    buildPutUserOrganisationOpenNetworkMembershipURI,
  deleteUserOrganisationShare: buildDeleteUserOrganisationShareURI,
  putUserOrganisationShare: buildPutUserOrganisationShareURI,
  getOpenAPI: buildGetOpenAPIURI,
  getAgritelPrices: buildGetAgritelPricesURI,
  getChargebeeCustomer: buildGetChargebeeCustomerURI,
  getChargebeeResourcesPublic: buildGetChargebeeResourcesPublicURI,
  getChargebeeRessources: buildGetChargebeeRessourcesURI,
  getCotations: buildGetCotationsURI,
  getCrops: buildGetCropsURI,
  getCustomerByEmail: buildGetCustomerByEmailURI,
  getData: buildGetDataURI,
  getDeviceData: buildGetDeviceDataURI,
  getDeviceRawData: buildGetDeviceRawDataURI,
  getDevices: buildGetDevicesURI,
  getFactoryMeasures: buildGetFactoryMeasuresURI,
  getFactorySigfoxStatus: buildGetFactorySigfoxStatusURI,
  getHubSpotContact: buildGetHubSpotContactURI,
  getHubSpotProperties: buildGetHubSpotPropertiesURI,
  getHubSpotQuotatorCard: buildGetHubSpotQuotatorCardURI,
  getHubSpotTableauCard: buildGetHubSpotTableauCardURI,
  getInvitation: buildGetInvitationURI,
  getInvitationDeviceLiveAggregations:
    buildGetInvitationDeviceLiveAggregationsURI,
  getLatestIrrCalibration: buildGetLatestIrrCalibrationURI,
  getLatestIrrCalibrations: buildGetLatestIrrCalibrationsURI,
  getModules: buildGetModulesURI,
  getMySelf: buildGetMySelfURI,
  getNetwork: buildGetNetworkURI,
  getNetworkDeviceLiveAggregations: buildGetNetworkDeviceLiveAggregationsURI,
  getNetworkDevices: buildGetNetworkDevicesURI,
  getOAuth2Authorize: buildGetOAuth2AuthorizeURI,
  getOrders: buildGetOrdersURI,
  postOrder: buildPostOrderURI,
  getOrdersAsXLSX: buildGetOrdersAsXLSXURI,
  getOrdersByUserEmail: buildGetOrdersByUserEmailURI,
  getOrganisation: buildGetOrganisationURI,
  putOrganisation: buildPutOrganisationURI,
  getOrganisationChargebeePortalSession:
    buildGetOrganisationChargebeePortalSessionURI,
  getOrganisationDevices: buildGetOrganisationDevicesURI,
  getOrganisationDevicesLiveAggregations:
    buildGetOrganisationDevicesLiveAggregationsURI,
  getOrganisationHostedPage: buildGetOrganisationHostedPageURI,
  getOrganisationHostedPageBattery: buildGetOrganisationHostedPageBatteryURI,
  getOrganisationHostedPageUpgradePlanPlus:
    buildGetOrganisationHostedPageUpgradePlanPlusURI,
  getOrganisationInvitation: buildGetOrganisationInvitationURI,
  getOrganisationInvitations: buildGetOrganisationInvitationsURI,
  getOrganisationMember: buildGetOrganisationMemberURI,
  getOrganisationMembers: buildGetOrganisationMembersURI,
  getOrganisationNetworkDevices: buildGetOrganisationNetworkDevicesURI,
  getOrganisationNetworks: buildGetOrganisationNetworksURI,
  getOrganisationPlace: buildGetOrganisationPlaceURI,
  getOrganisationPlaces: buildGetOrganisationPlacesURI,
  postOrganisationPlace: buildPostOrganisationPlaceURI,
  getOrganisationPreferences: buildGetOrganisationPreferencesURI,
  putOrganisationPreferences: buildPutOrganisationPreferencesURI,
  getOrganisationShare: buildGetOrganisationShareURI,
  getOrganisationShares: buildGetOrganisationSharesURI,
  getOrganisationsTrialOperations: buildGetOrganisationsTrialOperationsURI,
  getPartnerDevices: buildGetPartnerDevicesURI,
  getPing: buildGetPingURI,
  getPlans: buildGetPlansURI,
  getPublicWeatherLive: buildGetPublicWeatherLiveURI,
  getPublicWeatherLiveAggregations: buildGetPublicWeatherLiveAggregationsURI,
  getQuote: buildGetQuoteURI,
  getQuoteAsPdf: buildGetQuoteAsPdfURI,
  getQuoteHostedPage: buildGetQuoteHostedPageURI,
  getQuoteInvoiceAsPdf: buildGetQuoteInvoiceAsPdfURI,
  getRadarImage: buildGetRadarImageURI,
  getSearchDevices: buildGetSearchDevicesURI,
  getSearchNetworkDevices: buildGetSearchNetworkDevicesURI,
  getSearchPublicOrganisation: buildGetSearchPublicOrganisationURI,
  getSearchPublicUser: buildGetSearchPublicUserURI,
  getSearchUser: buildGetSearchUserURI,
  getSigfoxCoverage: buildGetSigfoxCoverageURI,
  getSigfoxMap: buildGetSigfoxMapURI,
  getStatusHead: buildGetStatusHeadURI,
  getTrialOperation: buildGetTrialOperationURI,
  getUser: buildGetUserURI,
  putUser: buildPutUserURI,
  getUserAggregation: buildGetUserAggregationURI,
  getUserAggregations: buildGetUserAggregationsURI,
  getUserAlert: buildGetUserAlertURI,
  getUserAlertVoiceMessage: buildGetUserAlertVoiceMessageURI,
  getUserAlerts: buildGetUserAlertsURI,
  getUserDailyData: buildGetUserDailyDataURI,
  getUserDevice: buildGetUserDeviceURI,
  getUserDeviceContinuousStatistics: buildGetUserDeviceContinuousStatisticsURI,
  getUserDeviceDailyData: buildGetUserDeviceDailyDataURI,
  getUserDeviceForecasts: buildGetUserDeviceForecastsURI,
  getUserDeviceHourlyData: buildGetUserDeviceHourlyDataURI,
  getUserDeviceModules: buildGetUserDeviceModulesURI,
  getUserDevicePositions: buildGetUserDevicePositionsURI,
  getUserDeviceRawData: buildGetUserDeviceRawDataURI,
  getUserDeviceStatistics: buildGetUserDeviceStatisticsURI,
  getUserDeviceSummary: buildGetUserDeviceSummaryURI,
  getUserDevices: buildGetUserDevicesURI,
  getUserDevicesLiveAggregations: buildGetUserDevicesLiveAggregationsURI,
  getUserForecasts: buildGetUserForecastsURI,
  getUserHourlyData: buildGetUserHourlyDataURI,
  getUserIntercomHash: buildGetUserIntercomHashURI,
  getUserInvitation: buildGetUserInvitationURI,
  getUserInvitations: buildGetUserInvitationsURI,
  getUserOrganisations: buildGetUserOrganisationsURI,
  postUserOrganisation: buildPostUserOrganisationURI,
  getUserPendingInvitation: buildGetUserPendingInvitationURI,
  getUserPendingInvitations: buildGetUserPendingInvitationsURI,
  getUserPreferences: buildGetUserPreferencesURI,
  putUserPreferences: buildPutUserPreferencesURI,
  getUserStatistics: buildGetUserStatisticsURI,
  getUserTestingSessions: buildGetUserTestingSessionsURI,
  putUserTestingSessions: buildPutUserTestingSessionsURI,
  getWeatherForecastModel: buildGetWeatherForecastModelURI,
  getWeatherLive: buildGetWeatherLiveURI,
  handleAggregationsCompute: buildHandleAggregationsComputeURI,
  handleAlertCheck: buildHandleAlertCheckURI,
  handleAlertRepetition: buildHandleAlertRepetitionURI,
  postAircallIncomingCalls: buildPostAircallIncomingCallsURI,
  postAlertsTrialDisable: buildPostAlertsTrialDisableURI,
  postApplicationEvent: buildPostApplicationEventURI,
  postBulkExportsSupport: buildPostBulkExportsSupportURI,
  postCSVArvalisMileosData: buildPostCSVArvalisMileosDataURI,
  postCSVArvalisMileosInitialData: buildPostCSVArvalisMileosInitialDataURI,
  postCSVBatchProd: buildPostCSVBatchProdURI,
  postCSVCTIFLEmail: buildPostCSVCTIFLEmailURI,
  postCSVMovidaEmail: buildPostCSVMovidaEmailURI,
  postCSVMovidaInitialEmail: buildPostCSVMovidaInitialEmailURI,
  postCSVRimproData: buildPostCSVRimproDataURI,
  postCSVTTWData: buildPostCSVTTWDataURI,
  postChargebeeEvents: buildPostChargebeeEventsURI,
  postCloudwatchEvents: buildPostCloudwatchEventsURI,
  postCropsHits: buildPostCropsHitsURI,
  postDataDeviceSituation: buildPostDataDeviceSituationURI,
  postDataWeatherForecastModel: buildPostDataWeatherForecastModelURI,
  postDeviceCleanIngestionCacheSupport:
    buildPostDeviceCleanIngestionCacheSupportURI,
  postDeviceReplacement: buildPostDeviceReplacementURI,
  postFirehoseRecordPerLine: buildPostFirehoseRecordPerLineURI,
  postFirehoseStream: buildPostFirehoseStreamURI,
  postForecastStorage: buildPostForecastStorageURI,
  postHubSpotContactSyncHook: buildPostHubSpotContactSyncHookURI,
  postHubSpotContactTrialReactivation:
    buildPostHubSpotContactTrialReactivationURI,
  postHubSpotDealSyncHook: buildPostHubSpotDealSyncHookURI,
  postHubSpotDeleteDealHook: buildPostHubSpotDeleteDealHookURI,
  postIngestionData: buildPostIngestionDataURI,
  postInvitationResend: buildPostInvitationResendURI,
  postIrrelisData: buildPostIrrelisDataURI,
  postLambdaLogs: buildPostLambdaLogsURI,
  postLogin: buildPostLoginURI,
  postLostPassword: buildPostLostPasswordURI,
  postMailgunWebHook: buildPostMailgunWebHookURI,
  postMailjetEvents: buildPostMailjetEventsURI,
  postMeteoGroupData: buildPostMeteoGroupDataURI,
  postMovidaSetup: buildPostMovidaSetupURI,
  postNetworkJoinRequest: buildPostNetworkJoinRequestURI,
  postNexmoEvents: buildPostNexmoEventsURI,
  postOAuth2Acknowledge: buildPostOAuth2AcknowledgeURI,
  postOAuth2Token: buildPostOAuth2TokenURI,
  postOrganisation: buildPostOrganisationURI,
  postOrganisationDeviceTransfer: buildPostOrganisationDeviceTransferURI,
  postOrganisationEndTrialSupport: buildPostOrganisationEndTrialSupportURI,
  postOrganisationNetworkMemberPlan: buildPostOrganisationNetworkMemberPlanURI,
  postOrganisationPaymentComplete: buildPostOrganisationPaymentCompleteURI,
  postOrganisationToNetworkSupport: buildPostOrganisationToNetworkSupportURI,
  postOrganisationTrialReactivation: buildPostOrganisationTrialReactivationURI,
  postOrganisationsMerge: buildPostOrganisationsMergeURI,
  postPartnerApplication: buildPostPartnerApplicationURI,
  postPartnerModule: buildPostPartnerModuleURI,
  postPartnerToken: buildPostPartnerTokenURI,
  postPartnerTokenRequest: buildPostPartnerTokenRequestURI,
  postPlace: buildPostPlaceURI,
  postPrevilisData: buildPostPrevilisDataURI,
  postQuote: buildPostQuoteURI,
  postQuoteOffline: buildPostQuoteOfflineURI,
  postQuoteRequest: buildPostQuoteRequestURI,
  postRegister: buildPostRegisterURI,
  postRegisterAuth0: buildPostRegisterAuth0URI,
  postSigfoxData: buildPostSigfoxDataURI,
  postSigfoxReshape: buildPostSigfoxReshapeURI,
  postSignatureCloudinary: buildPostSignatureCloudinaryURI,
  postSignupCheck: buildPostSignupCheckURI,
  postSlackBotCommand: buildPostSlackBotCommandURI,
  postSocialImage: buildPostSocialImageURI,
  postTelitData: buildPostTelitDataURI,
  postTwilioEvents: buildPostTwilioEventsURI,
  postUser: buildPostUserURI,
  postUserClearSessions: buildPostUserClearSessionsURI,
  postUserDeviceExport: buildPostUserDeviceExportURI,
  postUserNetworkInvitation: buildPostUserNetworkInvitationURI,
  postUserOrganisationAggregation: buildPostUserOrganisationAggregationURI,
  postUserOrganisationAlert: buildPostUserOrganisationAlertURI,
  postUserOrganisationDevice: buildPostUserOrganisationDeviceURI,
  postUserOrganisationDeviceSupport: buildPostUserOrganisationDeviceSupportURI,
  postUserOrganisationDevicesSupport:
    buildPostUserOrganisationDevicesSupportURI,
  postUserOrganisationInvitation: buildPostUserOrganisationInvitationURI,
  postUserOrganisationMember: buildPostUserOrganisationMemberURI,
  postUserPassword: buildPostUserPasswordURI,
  postUserTerminateTokensSupport: buildPostUserTerminateTokensSupportURI,
  postUserTerminatedSessions: buildPostUserTerminatedSessionsURI,
  postVerify: buildPostVerifyURI,
  putBatchDevices: buildPutBatchDevicesURI,
  putDataDeviceCity: buildPutDataDeviceCityURI,
  putDevicePosition: buildPutDevicePositionURI,
  putIrradianceSensorCalibration: buildPutIrradianceSensorCalibrationURI,
  putOrganisationDeviceCalibration: buildPutOrganisationDeviceCalibrationURI,
  putOrganisationDeviceSettings: buildPutOrganisationDeviceSettingsURI,
  putOrganisationPlace: buildPutOrganisationPlaceURI,
  putOrganisationTrial: buildPutOrganisationTrialURI,
  putPartnerApplication: buildPutPartnerApplicationURI,
  putPartnerModuleParameters: buildPutPartnerModuleParametersURI,
  putPassword: buildPutPasswordURI,
  putQuoteStatus: buildPutQuoteStatusURI,
  putUserFCMToken: buildPutUserFCMTokenURI,
  putUserInvitationAnswer: buildPutUserInvitationAnswerURI,
  getGraphQL: buildGetGraphQLURI,
  postGraphQL: buildPostGraphQLURI,
};

export const APIMethods = {
  deleteBucketDeviceSupport: "delete",
  putBucketDeviceSupport: "put",
  deleteDeviceAccessSupport: "delete",
  putDeviceAccessSupport: "put",
  deleteDeviceAccessesSupport: "delete",
  putDeviceAccessesSupport: "put",
  deleteExpiredTokens: "post",
  deleteOrganisationChargebeeId: "delete",
  postOrganisationChargebeeId: "post",
  deleteOrganisationDevice: "delete",
  getOrganisationDevice: "get",
  putOrganisationDevice: "put",
  deleteOrganisationMemberSupport: "delete",
  putOrganisationMemberSupport: "put",
  deleteOrganisationNetworkDeviceAccess: "delete",
  putOrganisationNetworkDeviceAccess: "put",
  deleteSencropMeasures: "delete",
  deleteUserOrganisationAggregation: "delete",
  putUserOrganisationAggregation: "put",
  deleteUserOrganisationAlert: "delete",
  putUserOrganisationAlert: "put",
  deleteUserOrganisationDeviceModule: "delete",
  putUserOrganisationDeviceModule: "put",
  deleteUserOrganisationInvitation: "delete",
  putUserOrganisationInvitation: "put",
  deleteUserOrganisationMember: "delete",
  putUserOrganisationMember: "put",
  deleteUserOrganisationOpenNetworkMembership: "delete",
  putUserOrganisationOpenNetworkMembership: "put",
  deleteUserOrganisationShare: "delete",
  putUserOrganisationShare: "put",
  getOpenAPI: "get",
  getAgritelPrices: "post",
  getChargebeeCustomer: "get",
  getChargebeeResourcesPublic: "get",
  getChargebeeRessources: "get",
  getCotations: "get",
  getCrops: "get",
  getCustomerByEmail: "get",
  getData: "get",
  getDeviceData: "get",
  getDeviceRawData: "get",
  getDevices: "get",
  getFactoryMeasures: "get",
  getFactorySigfoxStatus: "get",
  getHubSpotContact: "get",
  getHubSpotProperties: "get",
  getHubSpotQuotatorCard: "get",
  getHubSpotTableauCard: "get",
  getInvitation: "get",
  getInvitationDeviceLiveAggregations: "get",
  getLatestIrrCalibration: "get",
  getLatestIrrCalibrations: "get",
  getModules: "get",
  getMySelf: "get",
  getNetwork: "get",
  getNetworkDeviceLiveAggregations: "get",
  getNetworkDevices: "get",
  getOAuth2Authorize: "get",
  getOrders: "get",
  postOrder: "post",
  getOrdersAsXLSX: "get",
  getOrdersByUserEmail: "get",
  getOrganisation: "get",
  putOrganisation: "put",
  getOrganisationChargebeePortalSession: "get",
  getOrganisationDevices: "get",
  getOrganisationDevicesLiveAggregations: "get",
  getOrganisationHostedPage: "get",
  getOrganisationHostedPageBattery: "get",
  getOrganisationHostedPageUpgradePlanPlus: "get",
  getOrganisationInvitation: "get",
  getOrganisationInvitations: "get",
  getOrganisationMember: "get",
  getOrganisationMembers: "get",
  getOrganisationNetworkDevices: "get",
  getOrganisationNetworks: "get",
  getOrganisationPlace: "get",
  getOrganisationPlaces: "get",
  postOrganisationPlace: "post",
  getOrganisationPreferences: "get",
  putOrganisationPreferences: "put",
  getOrganisationShare: "get",
  getOrganisationShares: "get",
  getOrganisationsTrialOperations: "get",
  getPartnerDevices: "get",
  getPing: "get",
  getPlans: "get",
  getPublicWeatherLive: "get",
  getPublicWeatherLiveAggregations: "get",
  getQuote: "get",
  getQuoteAsPdf: "get",
  getQuoteHostedPage: "get",
  getQuoteInvoiceAsPdf: "get",
  getRadarImage: "get",
  getSearchDevices: "get",
  getSearchNetworkDevices: "get",
  getSearchPublicOrganisation: "get",
  getSearchPublicUser: "get",
  getSearchUser: "get",
  getSigfoxCoverage: "get",
  getSigfoxMap: "get",
  getStatusHead: "get",
  getTrialOperation: "get",
  getUser: "get",
  putUser: "put",
  getUserAggregation: "get",
  getUserAggregations: "get",
  getUserAlert: "get",
  getUserAlertVoiceMessage: "get",
  getUserAlerts: "get",
  getUserDailyData: "get",
  getUserDevice: "get",
  getUserDeviceContinuousStatistics: "get",
  getUserDeviceDailyData: "get",
  getUserDeviceForecasts: "get",
  getUserDeviceHourlyData: "get",
  getUserDeviceModules: "get",
  getUserDevicePositions: "get",
  getUserDeviceRawData: "get",
  getUserDeviceStatistics: "get",
  getUserDeviceSummary: "get",
  getUserDevices: "get",
  getUserDevicesLiveAggregations: "get",
  getUserForecasts: "get",
  getUserHourlyData: "get",
  getUserIntercomHash: "get",
  getUserInvitation: "get",
  getUserInvitations: "get",
  getUserOrganisations: "get",
  postUserOrganisation: "post",
  getUserPendingInvitation: "get",
  getUserPendingInvitations: "get",
  getUserPreferences: "get",
  putUserPreferences: "put",
  getUserStatistics: "get",
  getUserTestingSessions: "get",
  putUserTestingSessions: "put",
  getWeatherForecastModel: "get",
  getWeatherLive: "get",
  handleAggregationsCompute: "post",
  handleAlertCheck: "post",
  handleAlertRepetition: "post",
  postAircallIncomingCalls: "post",
  postAlertsTrialDisable: "post",
  postApplicationEvent: "post",
  postBulkExportsSupport: "post",
  postCSVArvalisMileosData: "post",
  postCSVArvalisMileosInitialData: "post",
  postCSVBatchProd: "post",
  postCSVCTIFLEmail: "post",
  postCSVMovidaEmail: "post",
  postCSVMovidaInitialEmail: "post",
  postCSVRimproData: "post",
  postCSVTTWData: "post",
  postChargebeeEvents: "post",
  postCloudwatchEvents: "post",
  postCropsHits: "post",
  postDataDeviceSituation: "post",
  postDataWeatherForecastModel: "post",
  postDeviceCleanIngestionCacheSupport: "post",
  postDeviceReplacement: "post",
  postFirehoseRecordPerLine: "post",
  postFirehoseStream: "post",
  postForecastStorage: "post",
  postHubSpotContactSyncHook: "post",
  postHubSpotContactTrialReactivation: "post",
  postHubSpotDealSyncHook: "post",
  postHubSpotDeleteDealHook: "post",
  postIngestionData: "post",
  postInvitationResend: "post",
  postIrrelisData: "post",
  postLambdaLogs: "post",
  postLogin: "post",
  postLostPassword: "post",
  postMailgunWebHook: "post",
  postMailjetEvents: "post",
  postMeteoGroupData: "post",
  postMovidaSetup: "post",
  postNetworkJoinRequest: "post",
  postNexmoEvents: "post",
  postOAuth2Acknowledge: "post",
  postOAuth2Token: "post",
  postOrganisation: "post",
  postOrganisationDeviceTransfer: "post",
  postOrganisationEndTrialSupport: "post",
  postOrganisationNetworkMemberPlan: "post",
  postOrganisationPaymentComplete: "post",
  postOrganisationToNetworkSupport: "post",
  postOrganisationTrialReactivation: "post",
  postOrganisationsMerge: "post",
  postPartnerApplication: "post",
  postPartnerModule: "post",
  postPartnerToken: "post",
  postPartnerTokenRequest: "post",
  postPlace: "post",
  postPrevilisData: "post",
  postQuote: "post",
  postQuoteOffline: "post",
  postQuoteRequest: "post",
  postRegister: "post",
  postRegisterAuth0: "post",
  postSigfoxData: "post",
  postSigfoxReshape: "post",
  postSignatureCloudinary: "post",
  postSignupCheck: "post",
  postSlackBotCommand: "post",
  postSocialImage: "post",
  postTelitData: "post",
  postTwilioEvents: "post",
  postUser: "post",
  postUserClearSessions: "post",
  postUserDeviceExport: "post",
  postUserNetworkInvitation: "post",
  postUserOrganisationAggregation: "post",
  postUserOrganisationAlert: "post",
  postUserOrganisationDevice: "post",
  postUserOrganisationDeviceSupport: "post",
  postUserOrganisationDevicesSupport: "post",
  postUserOrganisationInvitation: "post",
  postUserOrganisationMember: "post",
  postUserPassword: "post",
  postUserTerminateTokensSupport: "post",
  postUserTerminatedSessions: "post",
  postVerify: "post",
  putBatchDevices: "put",
  putDataDeviceCity: "put",
  putDevicePosition: "put",
  putIrradianceSensorCalibration: "put",
  putOrganisationDeviceCalibration: "put",
  putOrganisationDeviceSettings: "put",
  putOrganisationPlace: "put",
  putOrganisationTrial: "put",
  putPartnerApplication: "put",
  putPartnerModuleParameters: "put",
  putPassword: "put",
  putQuoteStatus: "put",
  putUserFCMToken: "put",
  putUserInvitationAnswer: "put",
  getGraphQL: "get",
  postGraphQL: "post",
} as const;

export const APIInputBuilders = {
  deleteBucketDeviceSupport: buildDeleteBucketDeviceSupportInput,
  putBucketDeviceSupport: buildPutBucketDeviceSupportInput,
  deleteDeviceAccessSupport: buildDeleteDeviceAccessSupportInput,
  putDeviceAccessSupport: buildPutDeviceAccessSupportInput,
  deleteDeviceAccessesSupport: buildDeleteDeviceAccessesSupportInput,
  putDeviceAccessesSupport: buildPutDeviceAccessesSupportInput,
  deleteExpiredTokens: buildDeleteExpiredTokensInput,
  deleteOrganisationChargebeeId: buildDeleteOrganisationChargebeeIdInput,
  postOrganisationChargebeeId: buildPostOrganisationChargebeeIdInput,
  deleteOrganisationDevice: buildDeleteOrganisationDeviceInput,
  getOrganisationDevice: buildGetOrganisationDeviceInput,
  putOrganisationDevice: buildPutOrganisationDeviceInput,
  deleteOrganisationMemberSupport: buildDeleteOrganisationMemberSupportInput,
  putOrganisationMemberSupport: buildPutOrganisationMemberSupportInput,
  deleteOrganisationNetworkDeviceAccess:
    buildDeleteOrganisationNetworkDeviceAccessInput,
  putOrganisationNetworkDeviceAccess:
    buildPutOrganisationNetworkDeviceAccessInput,
  deleteSencropMeasures: buildDeleteSencropMeasuresInput,
  deleteUserOrganisationAggregation:
    buildDeleteUserOrganisationAggregationInput,
  putUserOrganisationAggregation: buildPutUserOrganisationAggregationInput,
  deleteUserOrganisationAlert: buildDeleteUserOrganisationAlertInput,
  putUserOrganisationAlert: buildPutUserOrganisationAlertInput,
  deleteUserOrganisationDeviceModule:
    buildDeleteUserOrganisationDeviceModuleInput,
  putUserOrganisationDeviceModule: buildPutUserOrganisationDeviceModuleInput,
  deleteUserOrganisationInvitation: buildDeleteUserOrganisationInvitationInput,
  putUserOrganisationInvitation: buildPutUserOrganisationInvitationInput,
  deleteUserOrganisationMember: buildDeleteUserOrganisationMemberInput,
  putUserOrganisationMember: buildPutUserOrganisationMemberInput,
  deleteUserOrganisationOpenNetworkMembership:
    buildDeleteUserOrganisationOpenNetworkMembershipInput,
  putUserOrganisationOpenNetworkMembership:
    buildPutUserOrganisationOpenNetworkMembershipInput,
  deleteUserOrganisationShare: buildDeleteUserOrganisationShareInput,
  putUserOrganisationShare: buildPutUserOrganisationShareInput,
  getOpenAPI: buildGetOpenAPIInput,
  getAgritelPrices: buildGetAgritelPricesInput,
  getChargebeeCustomer: buildGetChargebeeCustomerInput,
  getChargebeeResourcesPublic: buildGetChargebeeResourcesPublicInput,
  getChargebeeRessources: buildGetChargebeeRessourcesInput,
  getCotations: buildGetCotationsInput,
  getCrops: buildGetCropsInput,
  getCustomerByEmail: buildGetCustomerByEmailInput,
  getData: buildGetDataInput,
  getDeviceData: buildGetDeviceDataInput,
  getDeviceRawData: buildGetDeviceRawDataInput,
  getDevices: buildGetDevicesInput,
  getFactoryMeasures: buildGetFactoryMeasuresInput,
  getFactorySigfoxStatus: buildGetFactorySigfoxStatusInput,
  getHubSpotContact: buildGetHubSpotContactInput,
  getHubSpotProperties: buildGetHubSpotPropertiesInput,
  getHubSpotQuotatorCard: buildGetHubSpotQuotatorCardInput,
  getHubSpotTableauCard: buildGetHubSpotTableauCardInput,
  getInvitation: buildGetInvitationInput,
  getInvitationDeviceLiveAggregations:
    buildGetInvitationDeviceLiveAggregationsInput,
  getLatestIrrCalibration: buildGetLatestIrrCalibrationInput,
  getLatestIrrCalibrations: buildGetLatestIrrCalibrationsInput,
  getModules: buildGetModulesInput,
  getMySelf: buildGetMySelfInput,
  getNetwork: buildGetNetworkInput,
  getNetworkDeviceLiveAggregations: buildGetNetworkDeviceLiveAggregationsInput,
  getNetworkDevices: buildGetNetworkDevicesInput,
  getOAuth2Authorize: buildGetOAuth2AuthorizeInput,
  getOrders: buildGetOrdersInput,
  postOrder: buildPostOrderInput,
  getOrdersAsXLSX: buildGetOrdersAsXLSXInput,
  getOrdersByUserEmail: buildGetOrdersByUserEmailInput,
  getOrganisation: buildGetOrganisationInput,
  putOrganisation: buildPutOrganisationInput,
  getOrganisationChargebeePortalSession:
    buildGetOrganisationChargebeePortalSessionInput,
  getOrganisationDevices: buildGetOrganisationDevicesInput,
  getOrganisationDevicesLiveAggregations:
    buildGetOrganisationDevicesLiveAggregationsInput,
  getOrganisationHostedPage: buildGetOrganisationHostedPageInput,
  getOrganisationHostedPageBattery: buildGetOrganisationHostedPageBatteryInput,
  getOrganisationHostedPageUpgradePlanPlus:
    buildGetOrganisationHostedPageUpgradePlanPlusInput,
  getOrganisationInvitation: buildGetOrganisationInvitationInput,
  getOrganisationInvitations: buildGetOrganisationInvitationsInput,
  getOrganisationMember: buildGetOrganisationMemberInput,
  getOrganisationMembers: buildGetOrganisationMembersInput,
  getOrganisationNetworkDevices: buildGetOrganisationNetworkDevicesInput,
  getOrganisationNetworks: buildGetOrganisationNetworksInput,
  getOrganisationPlace: buildGetOrganisationPlaceInput,
  getOrganisationPlaces: buildGetOrganisationPlacesInput,
  postOrganisationPlace: buildPostOrganisationPlaceInput,
  getOrganisationPreferences: buildGetOrganisationPreferencesInput,
  putOrganisationPreferences: buildPutOrganisationPreferencesInput,
  getOrganisationShare: buildGetOrganisationShareInput,
  getOrganisationShares: buildGetOrganisationSharesInput,
  getOrganisationsTrialOperations: buildGetOrganisationsTrialOperationsInput,
  getPartnerDevices: buildGetPartnerDevicesInput,
  getPing: buildGetPingInput,
  getPlans: buildGetPlansInput,
  getPublicWeatherLive: buildGetPublicWeatherLiveInput,
  getPublicWeatherLiveAggregations: buildGetPublicWeatherLiveAggregationsInput,
  getQuote: buildGetQuoteInput,
  getQuoteAsPdf: buildGetQuoteAsPdfInput,
  getQuoteHostedPage: buildGetQuoteHostedPageInput,
  getQuoteInvoiceAsPdf: buildGetQuoteInvoiceAsPdfInput,
  getRadarImage: buildGetRadarImageInput,
  getSearchDevices: buildGetSearchDevicesInput,
  getSearchNetworkDevices: buildGetSearchNetworkDevicesInput,
  getSearchPublicOrganisation: buildGetSearchPublicOrganisationInput,
  getSearchPublicUser: buildGetSearchPublicUserInput,
  getSearchUser: buildGetSearchUserInput,
  getSigfoxCoverage: buildGetSigfoxCoverageInput,
  getSigfoxMap: buildGetSigfoxMapInput,
  getStatusHead: buildGetStatusHeadInput,
  getTrialOperation: buildGetTrialOperationInput,
  getUser: buildGetUserInput,
  putUser: buildPutUserInput,
  getUserAggregation: buildGetUserAggregationInput,
  getUserAggregations: buildGetUserAggregationsInput,
  getUserAlert: buildGetUserAlertInput,
  getUserAlertVoiceMessage: buildGetUserAlertVoiceMessageInput,
  getUserAlerts: buildGetUserAlertsInput,
  getUserDailyData: buildGetUserDailyDataInput,
  getUserDevice: buildGetUserDeviceInput,
  getUserDeviceContinuousStatistics:
    buildGetUserDeviceContinuousStatisticsInput,
  getUserDeviceDailyData: buildGetUserDeviceDailyDataInput,
  getUserDeviceForecasts: buildGetUserDeviceForecastsInput,
  getUserDeviceHourlyData: buildGetUserDeviceHourlyDataInput,
  getUserDeviceModules: buildGetUserDeviceModulesInput,
  getUserDevicePositions: buildGetUserDevicePositionsInput,
  getUserDeviceRawData: buildGetUserDeviceRawDataInput,
  getUserDeviceStatistics: buildGetUserDeviceStatisticsInput,
  getUserDeviceSummary: buildGetUserDeviceSummaryInput,
  getUserDevices: buildGetUserDevicesInput,
  getUserDevicesLiveAggregations: buildGetUserDevicesLiveAggregationsInput,
  getUserForecasts: buildGetUserForecastsInput,
  getUserHourlyData: buildGetUserHourlyDataInput,
  getUserIntercomHash: buildGetUserIntercomHashInput,
  getUserInvitation: buildGetUserInvitationInput,
  getUserInvitations: buildGetUserInvitationsInput,
  getUserOrganisations: buildGetUserOrganisationsInput,
  postUserOrganisation: buildPostUserOrganisationInput,
  getUserPendingInvitation: buildGetUserPendingInvitationInput,
  getUserPendingInvitations: buildGetUserPendingInvitationsInput,
  getUserPreferences: buildGetUserPreferencesInput,
  putUserPreferences: buildPutUserPreferencesInput,
  getUserStatistics: buildGetUserStatisticsInput,
  getUserTestingSessions: buildGetUserTestingSessionsInput,
  putUserTestingSessions: buildPutUserTestingSessionsInput,
  getWeatherForecastModel: buildGetWeatherForecastModelInput,
  getWeatherLive: buildGetWeatherLiveInput,
  handleAggregationsCompute: buildHandleAggregationsComputeInput,
  handleAlertCheck: buildHandleAlertCheckInput,
  handleAlertRepetition: buildHandleAlertRepetitionInput,
  postAircallIncomingCalls: buildPostAircallIncomingCallsInput,
  postAlertsTrialDisable: buildPostAlertsTrialDisableInput,
  postApplicationEvent: buildPostApplicationEventInput,
  postBulkExportsSupport: buildPostBulkExportsSupportInput,
  postCSVArvalisMileosData: buildPostCSVArvalisMileosDataInput,
  postCSVArvalisMileosInitialData: buildPostCSVArvalisMileosInitialDataInput,
  postCSVBatchProd: buildPostCSVBatchProdInput,
  postCSVCTIFLEmail: buildPostCSVCTIFLEmailInput,
  postCSVMovidaEmail: buildPostCSVMovidaEmailInput,
  postCSVMovidaInitialEmail: buildPostCSVMovidaInitialEmailInput,
  postCSVRimproData: buildPostCSVRimproDataInput,
  postCSVTTWData: buildPostCSVTTWDataInput,
  postChargebeeEvents: buildPostChargebeeEventsInput,
  postCloudwatchEvents: buildPostCloudwatchEventsInput,
  postCropsHits: buildPostCropsHitsInput,
  postDataDeviceSituation: buildPostDataDeviceSituationInput,
  postDataWeatherForecastModel: buildPostDataWeatherForecastModelInput,
  postDeviceCleanIngestionCacheSupport:
    buildPostDeviceCleanIngestionCacheSupportInput,
  postDeviceReplacement: buildPostDeviceReplacementInput,
  postFirehoseRecordPerLine: buildPostFirehoseRecordPerLineInput,
  postFirehoseStream: buildPostFirehoseStreamInput,
  postForecastStorage: buildPostForecastStorageInput,
  postHubSpotContactSyncHook: buildPostHubSpotContactSyncHookInput,
  postHubSpotContactTrialReactivation:
    buildPostHubSpotContactTrialReactivationInput,
  postHubSpotDealSyncHook: buildPostHubSpotDealSyncHookInput,
  postHubSpotDeleteDealHook: buildPostHubSpotDeleteDealHookInput,
  postIngestionData: buildPostIngestionDataInput,
  postInvitationResend: buildPostInvitationResendInput,
  postIrrelisData: buildPostIrrelisDataInput,
  postLambdaLogs: buildPostLambdaLogsInput,
  postLogin: buildPostLoginInput,
  postLostPassword: buildPostLostPasswordInput,
  postMailgunWebHook: buildPostMailgunWebHookInput,
  postMailjetEvents: buildPostMailjetEventsInput,
  postMeteoGroupData: buildPostMeteoGroupDataInput,
  postMovidaSetup: buildPostMovidaSetupInput,
  postNetworkJoinRequest: buildPostNetworkJoinRequestInput,
  postNexmoEvents: buildPostNexmoEventsInput,
  postOAuth2Acknowledge: buildPostOAuth2AcknowledgeInput,
  postOAuth2Token: buildPostOAuth2TokenInput,
  postOrganisation: buildPostOrganisationInput,
  postOrganisationDeviceTransfer: buildPostOrganisationDeviceTransferInput,
  postOrganisationEndTrialSupport: buildPostOrganisationEndTrialSupportInput,
  postOrganisationNetworkMemberPlan:
    buildPostOrganisationNetworkMemberPlanInput,
  postOrganisationPaymentComplete: buildPostOrganisationPaymentCompleteInput,
  postOrganisationToNetworkSupport: buildPostOrganisationToNetworkSupportInput,
  postOrganisationTrialReactivation:
    buildPostOrganisationTrialReactivationInput,
  postOrganisationsMerge: buildPostOrganisationsMergeInput,
  postPartnerApplication: buildPostPartnerApplicationInput,
  postPartnerModule: buildPostPartnerModuleInput,
  postPartnerToken: buildPostPartnerTokenInput,
  postPartnerTokenRequest: buildPostPartnerTokenRequestInput,
  postPlace: buildPostPlaceInput,
  postPrevilisData: buildPostPrevilisDataInput,
  postQuote: buildPostQuoteInput,
  postQuoteOffline: buildPostQuoteOfflineInput,
  postQuoteRequest: buildPostQuoteRequestInput,
  postRegister: buildPostRegisterInput,
  postRegisterAuth0: buildPostRegisterAuth0Input,
  postSigfoxData: buildPostSigfoxDataInput,
  postSigfoxReshape: buildPostSigfoxReshapeInput,
  postSignatureCloudinary: buildPostSignatureCloudinaryInput,
  postSignupCheck: buildPostSignupCheckInput,
  postSlackBotCommand: buildPostSlackBotCommandInput,
  postSocialImage: buildPostSocialImageInput,
  postTelitData: buildPostTelitDataInput,
  postTwilioEvents: buildPostTwilioEventsInput,
  postUser: buildPostUserInput,
  postUserClearSessions: buildPostUserClearSessionsInput,
  postUserDeviceExport: buildPostUserDeviceExportInput,
  postUserNetworkInvitation: buildPostUserNetworkInvitationInput,
  postUserOrganisationAggregation: buildPostUserOrganisationAggregationInput,
  postUserOrganisationAlert: buildPostUserOrganisationAlertInput,
  postUserOrganisationDevice: buildPostUserOrganisationDeviceInput,
  postUserOrganisationDeviceSupport:
    buildPostUserOrganisationDeviceSupportInput,
  postUserOrganisationDevicesSupport:
    buildPostUserOrganisationDevicesSupportInput,
  postUserOrganisationInvitation: buildPostUserOrganisationInvitationInput,
  postUserOrganisationMember: buildPostUserOrganisationMemberInput,
  postUserPassword: buildPostUserPasswordInput,
  postUserTerminateTokensSupport: buildPostUserTerminateTokensSupportInput,
  postUserTerminatedSessions: buildPostUserTerminatedSessionsInput,
  postVerify: buildPostVerifyInput,
  putBatchDevices: buildPutBatchDevicesInput,
  putDataDeviceCity: buildPutDataDeviceCityInput,
  putDevicePosition: buildPutDevicePositionInput,
  putIrradianceSensorCalibration: buildPutIrradianceSensorCalibrationInput,
  putOrganisationDeviceCalibration: buildPutOrganisationDeviceCalibrationInput,
  putOrganisationDeviceSettings: buildPutOrganisationDeviceSettingsInput,
  putOrganisationPlace: buildPutOrganisationPlaceInput,
  putOrganisationTrial: buildPutOrganisationTrialInput,
  putPartnerApplication: buildPutPartnerApplicationInput,
  putPartnerModuleParameters: buildPutPartnerModuleParametersInput,
  putPassword: buildPutPasswordInput,
  putQuoteStatus: buildPutQuoteStatusInput,
  putUserFCMToken: buildPutUserFCMTokenInput,
  putUserInvitationAnswer: buildPutUserInvitationAnswerInput,
  getGraphQL: buildGetGraphQLInput,
  postGraphQL: buildPostGraphQLInput,
};

/**
 * Build the "deleteBucketDeviceSupport" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.bucketDeviceId
 * The bucket device id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildDeleteBucketDeviceSupportURI(
  {
    bucketDeviceId,
    accessToken,
  }: Pick<
    SencropTypes.DeleteBucketDeviceSupport.Input,
    "bucketDeviceId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (bucketDeviceId == null) {
    throw new Error(
      "Missing required parameter : bucketDeviceId. Value : " + bucketDeviceId
    );
  }

  const __pathParts = ["support", "bucketDevices", bucketDeviceId];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "deleteBucketDeviceSupport" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.bucketDeviceId
 * The bucket device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildDeleteBucketDeviceSupportInput(
  {
    bucketDeviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteBucketDeviceSupport.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.deleteBucketDeviceSupport;
  const __uriData = buildDeleteBucketDeviceSupportURI(
    {
      bucketDeviceId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Delete bucket device link
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.bucketDeviceId
 * The bucket device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function deleteBucketDeviceSupport(
  {
    bucketDeviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteBucketDeviceSupport.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.DeleteBucketDeviceSupport.Output> {
  const httpRequest = buildDeleteBucketDeviceSupportInput(
    {
      bucketDeviceId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.DeleteBucketDeviceSupport.Output;
}

/**
 * Build the "putBucketDeviceSupport" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.bucketDeviceId
 * The bucket device id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutBucketDeviceSupportURI(
  {
    bucketDeviceId,
    accessToken,
  }: Pick<
    SencropTypes.PutBucketDeviceSupport.Input,
    "bucketDeviceId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (bucketDeviceId == null) {
    throw new Error(
      "Missing required parameter : bucketDeviceId. Value : " + bucketDeviceId
    );
  }

  const __pathParts = ["support", "bucketDevices", bucketDeviceId];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putBucketDeviceSupport" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.bucketDeviceId
 * The bucket device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutBucketDeviceSupportInput(
  {
    body,
    bucketDeviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutBucketDeviceSupport.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutBucketDeviceSupport.Input["body"]> {
  const __method = APIMethods.putBucketDeviceSupport;
  const __uriData = buildPutBucketDeviceSupportURI(
    {
      bucketDeviceId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Change bucket device dates
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.bucketDeviceId
 * The bucket device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putBucketDeviceSupport(
  {
    body,
    bucketDeviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutBucketDeviceSupport.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutBucketDeviceSupport.Output> {
  const httpRequest = buildPutBucketDeviceSupportInput(
    {
      body,
      bucketDeviceId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutBucketDeviceSupport.Output;
}

/**
 * Build the "deleteDeviceAccessSupport" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.accessId
 * The access id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildDeleteDeviceAccessSupportURI(
  {
    accessId,
    accessToken,
  }: Pick<
    SencropTypes.DeleteDeviceAccessSupport.Input,
    "accessId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (accessId == null) {
    throw new Error(
      "Missing required parameter : accessId. Value : " + accessId
    );
  }

  const __pathParts = ["support", "access", accessId];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "deleteDeviceAccessSupport" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.accessId
 * The access id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildDeleteDeviceAccessSupportInput(
  {
    accessId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteDeviceAccessSupport.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.deleteDeviceAccessSupport;
  const __uriData = buildDeleteDeviceAccessSupportURI(
    {
      accessId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Delete device access
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.accessId
 * The access id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function deleteDeviceAccessSupport(
  {
    accessId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteDeviceAccessSupport.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.DeleteDeviceAccessSupport.Output> {
  const httpRequest = buildDeleteDeviceAccessSupportInput(
    {
      accessId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.DeleteDeviceAccessSupport.Output;
}

/**
 * Build the "putDeviceAccessSupport" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.accessId
 * The access id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutDeviceAccessSupportURI(
  {
    accessId,
    accessToken,
  }: Pick<
    SencropTypes.PutDeviceAccessSupport.Input,
    "accessId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (accessId == null) {
    throw new Error(
      "Missing required parameter : accessId. Value : " + accessId
    );
  }

  const __pathParts = ["support", "access", accessId];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putDeviceAccessSupport" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.accessId
 * The access id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutDeviceAccessSupportInput(
  {
    body,
    accessId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutDeviceAccessSupport.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutDeviceAccessSupport.Input["body"]> {
  const __method = APIMethods.putDeviceAccessSupport;
  const __uriData = buildPutDeviceAccessSupportURI(
    {
      accessId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Change device access dates
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.accessId
 * The access id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putDeviceAccessSupport(
  {
    body,
    accessId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutDeviceAccessSupport.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutDeviceAccessSupport.Output> {
  const httpRequest = buildPutDeviceAccessSupportInput(
    {
      body,
      accessId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutDeviceAccessSupport.Output;
}

/**
 * Build the "deleteDeviceAccessesSupport" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildDeleteDeviceAccessesSupportURI(
  {
    deviceId,
    accessToken,
  }: Pick<
    SencropTypes.DeleteDeviceAccessesSupport.Input,
    "deviceId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }

  const __pathParts = ["support", "devices", deviceId, "accesses"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "deleteDeviceAccessesSupport" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildDeleteDeviceAccessesSupportInput(
  {
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteDeviceAccessesSupport.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.deleteDeviceAccessesSupport;
  const __uriData = buildDeleteDeviceAccessesSupportURI(
    {
      deviceId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Terminate a device's accesses
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function deleteDeviceAccessesSupport(
  {
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteDeviceAccessesSupport.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.DeleteDeviceAccessesSupport.Output> {
  const httpRequest = buildDeleteDeviceAccessesSupportInput(
    {
      deviceId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.DeleteDeviceAccessesSupport.Output;
}

/**
 * Build the "putDeviceAccessesSupport" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutDeviceAccessesSupportURI(
  {
    deviceId,
    accessToken,
  }: Pick<
    SencropTypes.PutDeviceAccessesSupport.Input,
    "deviceId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }

  const __pathParts = ["support", "devices", deviceId, "accesses"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putDeviceAccessesSupport" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutDeviceAccessesSupportInput(
  {
    body,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutDeviceAccessesSupport.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutDeviceAccessesSupport.Input["body"]> {
  const __method = APIMethods.putDeviceAccessesSupport;
  const __uriData = buildPutDeviceAccessesSupportURI(
    {
      deviceId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Change device accesses dates
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putDeviceAccessesSupport(
  {
    body,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutDeviceAccessesSupport.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutDeviceAccessesSupport.Output> {
  const httpRequest = buildPutDeviceAccessesSupportInput(
    {
      body,
      deviceId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutDeviceAccessesSupport.Output;
}

/**
 * Build the "deleteExpiredTokens" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildDeleteExpiredTokensURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.DeleteExpiredTokens.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["crons", "expiredTokens"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "deleteExpiredTokens" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildDeleteExpiredTokensInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteExpiredTokens.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.deleteExpiredTokens;
  const __uriData = buildDeleteExpiredTokensURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Delete old tokens to prevent our DB from growing too much.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function deleteExpiredTokens(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteExpiredTokens.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.DeleteExpiredTokens.Output> {
  const httpRequest = buildDeleteExpiredTokensInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.DeleteExpiredTokens.Output;
}

/**
 * Build the "deleteOrganisationChargebeeId" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildDeleteOrganisationChargebeeIdURI(
  {
    organisationId,
    accessToken,
  }: Pick<
    SencropTypes.DeleteOrganisationChargebeeId.Input,
    "organisationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = ["organisations", organisationId, "chargebeeId"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "deleteOrganisationChargebeeId" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildDeleteOrganisationChargebeeIdInput(
  {
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteOrganisationChargebeeId.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.deleteOrganisationChargebeeId;
  const __uriData = buildDeleteOrganisationChargebeeIdURI(
    {
      organisationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Remove chargebeeId from the organisation.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function deleteOrganisationChargebeeId(
  {
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteOrganisationChargebeeId.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.DeleteOrganisationChargebeeId.Output> {
  const httpRequest = buildDeleteOrganisationChargebeeIdInput(
    {
      organisationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.DeleteOrganisationChargebeeId.Output;
}

/**
 * Build the "postOrganisationChargebeeId" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostOrganisationChargebeeIdURI(
  {
    organisationId,
    accessToken,
  }: Pick<
    SencropTypes.PostOrganisationChargebeeId.Input,
    "organisationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = ["organisations", organisationId, "chargebeeId"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postOrganisationChargebeeId" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostOrganisationChargebeeIdInput(
  {
    body,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisationChargebeeId.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostOrganisationChargebeeId.Input["body"]> {
  const __method = APIMethods.postOrganisationChargebeeId;
  const __uriData = buildPostOrganisationChargebeeIdURI(
    {
      organisationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Add chargebeeId for an organisation.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postOrganisationChargebeeId(
  {
    body,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisationChargebeeId.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostOrganisationChargebeeId.Output> {
  const httpRequest = buildPostOrganisationChargebeeIdInput(
    {
      body,
      organisationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostOrganisationChargebeeId.Output;
}

/**
 * Build the "deleteOrganisationDevice" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildDeleteOrganisationDeviceURI(
  {
    organisationId,
    deviceId,
    accessToken,
  }: Pick<
    SencropTypes.DeleteOrganisationDevice.Input,
    "organisationId" | "deviceId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }

  const __pathParts = ["organisations", organisationId, "devices", deviceId];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "deleteOrganisationDevice" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildDeleteOrganisationDeviceInput(
  {
    organisationId,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteOrganisationDevice.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.deleteOrganisationDevice;
  const __uriData = buildDeleteOrganisationDeviceURI(
    {
      organisationId,
      deviceId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Delete an organisation's device access.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function deleteOrganisationDevice(
  {
    organisationId,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteOrganisationDevice.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.DeleteOrganisationDevice.Output> {
  const httpRequest = buildDeleteOrganisationDeviceInput(
    {
      organisationId,
      deviceId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.DeleteOrganisationDevice.Output;
}

/**
 * Build the "getOrganisationDevice" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationDeviceURI(
  {
    organisationId,
    deviceId,
    accessToken,
  }: Pick<
    SencropTypes.GetOrganisationDevice.Input,
    "organisationId" | "deviceId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }

  const __pathParts = ["organisations", organisationId, "devices", deviceId];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisationDevice" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationDeviceInput(
  {
    organisationId,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationDevice.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisationDevice;
  const __uriData = buildGetOrganisationDeviceURI(
    {
      organisationId,
      deviceId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a current organisation's device.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisationDevice(
  {
    organisationId,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationDevice.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisationDevice.Output> {
  const httpRequest = buildGetOrganisationDeviceInput(
    {
      organisationId,
      deviceId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisationDevice.Output;
}

/**
 * Build the "putOrganisationDevice" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutOrganisationDeviceURI(
  {
    organisationId,
    deviceId,
    accessToken,
  }: Pick<
    SencropTypes.PutOrganisationDevice.Input,
    "organisationId" | "deviceId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }

  const __pathParts = ["organisations", organisationId, "devices", deviceId];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putOrganisationDevice" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutOrganisationDeviceInput(
  {
    body,
    organisationId,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutOrganisationDevice.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutOrganisationDevice.Input["body"]> {
  const __method = APIMethods.putOrganisationDevice;
  const __uriData = buildPutOrganisationDeviceURI(
    {
      organisationId,
      deviceId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Update an organisation's device.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putOrganisationDevice(
  {
    body,
    organisationId,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutOrganisationDevice.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutOrganisationDevice.Output> {
  const httpRequest = buildPutOrganisationDeviceInput(
    {
      body,
      organisationId,
      deviceId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutOrganisationDevice.Output;
}

/**
 * Build the "deleteOrganisationMemberSupport" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.memberId
 * The member id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildDeleteOrganisationMemberSupportURI(
  {
    organisationId,
    memberId,
    accessToken,
  }: Pick<
    SencropTypes.DeleteOrganisationMemberSupport.Input,
    "organisationId" | "memberId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (memberId == null) {
    throw new Error(
      "Missing required parameter : memberId. Value : " + memberId
    );
  }

  const __pathParts = [
    "support",
    "organisations",
    organisationId,
    "members",
    memberId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "deleteOrganisationMemberSupport" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.memberId
 * The member id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildDeleteOrganisationMemberSupportInput(
  {
    organisationId,
    memberId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteOrganisationMemberSupport.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.deleteOrganisationMemberSupport;
  const __uriData = buildDeleteOrganisationMemberSupportURI(
    {
      organisationId,
      memberId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Delete a member from an user's organisation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.memberId
 * The member id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function deleteOrganisationMemberSupport(
  {
    organisationId,
    memberId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteOrganisationMemberSupport.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.DeleteOrganisationMemberSupport.Output> {
  const httpRequest = buildDeleteOrganisationMemberSupportInput(
    {
      organisationId,
      memberId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.DeleteOrganisationMemberSupport.Output;
}

/**
 * Build the "putOrganisationMemberSupport" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.memberId
 * The member id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutOrganisationMemberSupportURI(
  {
    organisationId,
    memberId,
    accessToken,
  }: Pick<
    SencropTypes.PutOrganisationMemberSupport.Input,
    "organisationId" | "memberId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (memberId == null) {
    throw new Error(
      "Missing required parameter : memberId. Value : " + memberId
    );
  }

  const __pathParts = [
    "support",
    "organisations",
    organisationId,
    "members",
    memberId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putOrganisationMemberSupport" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.memberId
 * The member id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutOrganisationMemberSupportInput(
  {
    body,
    organisationId,
    memberId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutOrganisationMemberSupport.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutOrganisationMemberSupport.Input["body"]> {
  const __method = APIMethods.putOrganisationMemberSupport;
  const __uriData = buildPutOrganisationMemberSupportURI(
    {
      organisationId,
      memberId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Add or edit an member to an organisation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.memberId
 * The member id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putOrganisationMemberSupport(
  {
    body,
    organisationId,
    memberId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutOrganisationMemberSupport.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutOrganisationMemberSupport.Output> {
  const httpRequest = buildPutOrganisationMemberSupportInput(
    {
      body,
      organisationId,
      memberId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutOrganisationMemberSupport.Output;
}

/**
 * Build the "deleteOrganisationNetworkDeviceAccess" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.networkId
 * The network id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildDeleteOrganisationNetworkDeviceAccessURI(
  {
    organisationId,
    networkId,
    deviceId,
    accessToken,
  }: Pick<
    SencropTypes.DeleteOrganisationNetworkDeviceAccess.Input,
    "organisationId" | "networkId" | "deviceId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (networkId == null) {
    throw new Error(
      "Missing required parameter : networkId. Value : " + networkId
    );
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }

  const __pathParts = [
    "organisations",
    organisationId,
    "networks",
    networkId,
    "devices",
    deviceId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "deleteOrganisationNetworkDeviceAccess" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.networkId
 * The network id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildDeleteOrganisationNetworkDeviceAccessInput(
  {
    organisationId,
    networkId,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteOrganisationNetworkDeviceAccess.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.deleteOrganisationNetworkDeviceAccess;
  const __uriData = buildDeleteOrganisationNetworkDeviceAccessURI(
    {
      organisationId,
      networkId,
      deviceId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Delete an organisation's network device access.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.networkId
 * The network id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function deleteOrganisationNetworkDeviceAccess(
  {
    organisationId,
    networkId,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteOrganisationNetworkDeviceAccess.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.DeleteOrganisationNetworkDeviceAccess.Output> {
  const httpRequest = buildDeleteOrganisationNetworkDeviceAccessInput(
    {
      organisationId,
      networkId,
      deviceId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.DeleteOrganisationNetworkDeviceAccess.Output;
}

/**
 * Build the "putOrganisationNetworkDeviceAccess" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.networkId
 * The network id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutOrganisationNetworkDeviceAccessURI(
  {
    organisationId,
    networkId,
    deviceId,
    accessToken,
  }: Pick<
    SencropTypes.PutOrganisationNetworkDeviceAccess.Input,
    "organisationId" | "networkId" | "deviceId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (networkId == null) {
    throw new Error(
      "Missing required parameter : networkId. Value : " + networkId
    );
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }

  const __pathParts = [
    "organisations",
    organisationId,
    "networks",
    networkId,
    "devices",
    deviceId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putOrganisationNetworkDeviceAccess" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.networkId
 * The network id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutOrganisationNetworkDeviceAccessInput(
  {
    organisationId,
    networkId,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutOrganisationNetworkDeviceAccess.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.putOrganisationNetworkDeviceAccess;
  const __uriData = buildPutOrganisationNetworkDeviceAccessURI(
    {
      organisationId,
      networkId,
      deviceId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Update an organisation's device.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.networkId
 * The network id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putOrganisationNetworkDeviceAccess(
  {
    organisationId,
    networkId,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutOrganisationNetworkDeviceAccess.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutOrganisationNetworkDeviceAccess.Output> {
  const httpRequest = buildPutOrganisationNetworkDeviceAccessInput(
    {
      organisationId,
      networkId,
      deviceId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutOrganisationNetworkDeviceAccess.Output;
}

/**
 * Build the "deleteSencropMeasures" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.startDate
 * Start date of the discard of measures,
 * @param {string} parameters.endDate
 * End date of the discard of measures,
 * @param {string} parameters.measureType
 * Type of measure to discard,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildDeleteSencropMeasuresURI(
  {
    deviceId,
    startDate,
    endDate,
    measureType,
    accessToken,
  }: Pick<
    SencropTypes.DeleteSencropMeasures.Input,
    "deviceId" | "startDate" | "endDate" | "measureType" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }
  if (startDate == null) {
    throw new Error(
      "Missing required parameter : startDate. Value : " + startDate
    );
  }
  if (endDate == null) {
    throw new Error("Missing required parameter : endDate. Value : " + endDate);
  }
  if (measureType == null) {
    throw new Error(
      "Missing required parameter : measureType. Value : " + measureType
    );
  }

  const __pathParts = ["support", "devices", deviceId, "measures"];
  const __qs = cleanQuery({
    startDate: startDate,
    endDate: endDate,
    measureType: measureType,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "deleteSencropMeasures" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.startDate
 * Start date of the discard of measures,
 * @param {string} parameters.endDate
 * End date of the discard of measures,
 * @param {string} parameters.measureType
 * Type of measure to discard,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildDeleteSencropMeasuresInput(
  {
    deviceId,
    startDate,
    endDate,
    measureType,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteSencropMeasures.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.deleteSencropMeasures;
  const __uriData = buildDeleteSencropMeasuresURI(
    {
      deviceId,
      startDate,
      endDate,
      measureType,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Discard measures
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.startDate
 * Start date of the discard of measures,
 * @param {string} parameters.endDate
 * End date of the discard of measures,
 * @param {string} parameters.measureType
 * Type of measure to discard,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function deleteSencropMeasures(
  {
    deviceId,
    startDate,
    endDate,
    measureType,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteSencropMeasures.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.DeleteSencropMeasures.Output> {
  const httpRequest = buildDeleteSencropMeasuresInput(
    {
      deviceId,
      startDate,
      endDate,
      measureType,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.DeleteSencropMeasures.Output;
}

/**
 * Build the "deleteUserOrganisationAggregation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.aggregationId
 * The aggregation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildDeleteUserOrganisationAggregationURI(
  {
    userId,
    organisationId,
    aggregationId,
    accessToken,
  }: Pick<
    SencropTypes.DeleteUserOrganisationAggregation.Input,
    "userId" | "organisationId" | "aggregationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (aggregationId == null) {
    throw new Error(
      "Missing required parameter : aggregationId. Value : " + aggregationId
    );
  }

  const __pathParts = [
    "users",
    userId,
    "organisations",
    organisationId,
    "aggregations",
    aggregationId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "deleteUserOrganisationAggregation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.aggregationId
 * The aggregation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildDeleteUserOrganisationAggregationInput(
  {
    userId,
    organisationId,
    aggregationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteUserOrganisationAggregation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.deleteUserOrganisationAggregation;
  const __uriData = buildDeleteUserOrganisationAggregationURI(
    {
      userId,
      organisationId,
      aggregationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Delete a user's aggregation.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.aggregationId
 * The aggregation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function deleteUserOrganisationAggregation(
  {
    userId,
    organisationId,
    aggregationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteUserOrganisationAggregation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.DeleteUserOrganisationAggregation.Output> {
  const httpRequest = buildDeleteUserOrganisationAggregationInput(
    {
      userId,
      organisationId,
      aggregationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.DeleteUserOrganisationAggregation.Output;
}

/**
 * Build the "putUserOrganisationAggregation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.aggregationId
 * The aggregation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutUserOrganisationAggregationURI(
  {
    userId,
    organisationId,
    aggregationId,
    accessToken,
  }: Pick<
    SencropTypes.PutUserOrganisationAggregation.Input,
    "userId" | "organisationId" | "aggregationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (aggregationId == null) {
    throw new Error(
      "Missing required parameter : aggregationId. Value : " + aggregationId
    );
  }

  const __pathParts = [
    "users",
    userId,
    "organisations",
    organisationId,
    "aggregations",
    aggregationId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putUserOrganisationAggregation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.aggregationId
 * The aggregation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutUserOrganisationAggregationInput(
  {
    body,
    userId,
    organisationId,
    aggregationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserOrganisationAggregation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutUserOrganisationAggregation.Input["body"]> {
  const __method = APIMethods.putUserOrganisationAggregation;
  const __uriData = buildPutUserOrganisationAggregationURI(
    {
      userId,
      organisationId,
      aggregationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Upsert an organisation's aggregation.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.aggregationId
 * The aggregation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putUserOrganisationAggregation(
  {
    body,
    userId,
    organisationId,
    aggregationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserOrganisationAggregation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutUserOrganisationAggregation.Output> {
  const httpRequest = buildPutUserOrganisationAggregationInput(
    {
      body,
      userId,
      organisationId,
      aggregationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutUserOrganisationAggregation.Output;
}

/**
 * Build the "deleteUserOrganisationAlert" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.alertId
 * The alert id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildDeleteUserOrganisationAlertURI(
  {
    userId,
    organisationId,
    alertId,
    accessToken,
  }: Pick<
    SencropTypes.DeleteUserOrganisationAlert.Input,
    "userId" | "organisationId" | "alertId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (alertId == null) {
    throw new Error("Missing required parameter : alertId. Value : " + alertId);
  }

  const __pathParts = [
    "users",
    userId,
    "organisations",
    organisationId,
    "alerts",
    alertId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "deleteUserOrganisationAlert" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.alertId
 * The alert id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildDeleteUserOrganisationAlertInput(
  {
    userId,
    organisationId,
    alertId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteUserOrganisationAlert.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.deleteUserOrganisationAlert;
  const __uriData = buildDeleteUserOrganisationAlertURI(
    {
      userId,
      organisationId,
      alertId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Delete a user's alert.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.alertId
 * The alert id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function deleteUserOrganisationAlert(
  {
    userId,
    organisationId,
    alertId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteUserOrganisationAlert.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.DeleteUserOrganisationAlert.Output> {
  const httpRequest = buildDeleteUserOrganisationAlertInput(
    {
      userId,
      organisationId,
      alertId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.DeleteUserOrganisationAlert.Output;
}

/**
 * Build the "putUserOrganisationAlert" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.alertId
 * The alert id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutUserOrganisationAlertURI(
  {
    userId,
    organisationId,
    alertId,
    accessToken,
  }: Pick<
    SencropTypes.PutUserOrganisationAlert.Input,
    "userId" | "organisationId" | "alertId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (alertId == null) {
    throw new Error("Missing required parameter : alertId. Value : " + alertId);
  }

  const __pathParts = [
    "users",
    userId,
    "organisations",
    organisationId,
    "alerts",
    alertId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putUserOrganisationAlert" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.alertId
 * The alert id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutUserOrganisationAlertInput(
  {
    body,
    userId,
    organisationId,
    alertId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserOrganisationAlert.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutUserOrganisationAlert.Input["body"]> {
  const __method = APIMethods.putUserOrganisationAlert;
  const __uriData = buildPutUserOrganisationAlertURI(
    {
      userId,
      organisationId,
      alertId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Update an organisation's alert.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.alertId
 * The alert id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putUserOrganisationAlert(
  {
    body,
    userId,
    organisationId,
    alertId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserOrganisationAlert.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutUserOrganisationAlert.Output> {
  const httpRequest = buildPutUserOrganisationAlertInput(
    {
      body,
      userId,
      organisationId,
      alertId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutUserOrganisationAlert.Output;
}

/**
 * Build the "deleteUserOrganisationDeviceModule" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {number} parameters.moduleId
 * The module id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildDeleteUserOrganisationDeviceModuleURI(
  {
    userId,
    organisationId,
    deviceId,
    moduleId,
    accessToken,
  }: Pick<
    SencropTypes.DeleteUserOrganisationDeviceModule.Input,
    "userId" | "organisationId" | "deviceId" | "moduleId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }
  if (moduleId == null) {
    throw new Error(
      "Missing required parameter : moduleId. Value : " + moduleId
    );
  }

  const __pathParts = [
    "users",
    userId,
    "organisations",
    organisationId,
    "devices",
    deviceId,
    "modules",
    moduleId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "deleteUserOrganisationDeviceModule" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {number} parameters.moduleId
 * The module id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildDeleteUserOrganisationDeviceModuleInput(
  {
    userId,
    organisationId,
    deviceId,
    moduleId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteUserOrganisationDeviceModule.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.deleteUserOrganisationDeviceModule;
  const __uriData = buildDeleteUserOrganisationDeviceModuleURI(
    {
      userId,
      organisationId,
      deviceId,
      moduleId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Disable a module for a user's organisations's device
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {number} parameters.moduleId
 * The module id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function deleteUserOrganisationDeviceModule(
  {
    userId,
    organisationId,
    deviceId,
    moduleId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteUserOrganisationDeviceModule.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.DeleteUserOrganisationDeviceModule.Output> {
  const httpRequest = buildDeleteUserOrganisationDeviceModuleInput(
    {
      userId,
      organisationId,
      deviceId,
      moduleId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.DeleteUserOrganisationDeviceModule.Output;
}

/**
 * Build the "putUserOrganisationDeviceModule" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {number} parameters.moduleId
 * The module id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutUserOrganisationDeviceModuleURI(
  {
    userId,
    organisationId,
    deviceId,
    moduleId,
    accessToken,
  }: Pick<
    SencropTypes.PutUserOrganisationDeviceModule.Input,
    "userId" | "organisationId" | "deviceId" | "moduleId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }
  if (moduleId == null) {
    throw new Error(
      "Missing required parameter : moduleId. Value : " + moduleId
    );
  }

  const __pathParts = [
    "users",
    userId,
    "organisations",
    organisationId,
    "devices",
    deviceId,
    "modules",
    moduleId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putUserOrganisationDeviceModule" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {number} parameters.moduleId
 * The module id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutUserOrganisationDeviceModuleInput(
  {
    body,
    userId,
    organisationId,
    deviceId,
    moduleId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserOrganisationDeviceModule.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutUserOrganisationDeviceModule.Input["body"]> {
  const __method = APIMethods.putUserOrganisationDeviceModule;
  const __uriData = buildPutUserOrganisationDeviceModuleURI(
    {
      userId,
      organisationId,
      deviceId,
      moduleId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Add a module to a user's organisations's device
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {number} parameters.moduleId
 * The module id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putUserOrganisationDeviceModule(
  {
    body,
    userId,
    organisationId,
    deviceId,
    moduleId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserOrganisationDeviceModule.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutUserOrganisationDeviceModule.Output> {
  const httpRequest = buildPutUserOrganisationDeviceModuleInput(
    {
      body,
      userId,
      organisationId,
      deviceId,
      moduleId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutUserOrganisationDeviceModule.Output;
}

/**
 * Build the "deleteUserOrganisationInvitation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildDeleteUserOrganisationInvitationURI(
  {
    userId,
    organisationId,
    invitationId,
    accessToken,
  }: Pick<
    SencropTypes.DeleteUserOrganisationInvitation.Input,
    "userId" | "organisationId" | "invitationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (invitationId == null) {
    throw new Error(
      "Missing required parameter : invitationId. Value : " + invitationId
    );
  }

  const __pathParts = [
    "users",
    userId,
    "organisations",
    organisationId,
    "invitations",
    invitationId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "deleteUserOrganisationInvitation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildDeleteUserOrganisationInvitationInput(
  {
    userId,
    organisationId,
    invitationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteUserOrganisationInvitation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.deleteUserOrganisationInvitation;
  const __uriData = buildDeleteUserOrganisationInvitationURI(
    {
      userId,
      organisationId,
      invitationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Delete an invitation in an organization.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function deleteUserOrganisationInvitation(
  {
    userId,
    organisationId,
    invitationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteUserOrganisationInvitation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.DeleteUserOrganisationInvitation.Output> {
  const httpRequest = buildDeleteUserOrganisationInvitationInput(
    {
      userId,
      organisationId,
      invitationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.DeleteUserOrganisationInvitation.Output;
}

/**
 * Build the "putUserOrganisationInvitation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutUserOrganisationInvitationURI(
  {
    userId,
    organisationId,
    invitationId,
    accessToken,
  }: Pick<
    SencropTypes.PutUserOrganisationInvitation.Input,
    "userId" | "organisationId" | "invitationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (invitationId == null) {
    throw new Error(
      "Missing required parameter : invitationId. Value : " + invitationId
    );
  }

  const __pathParts = [
    "users",
    userId,
    "organisations",
    organisationId,
    "invitations",
    invitationId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putUserOrganisationInvitation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutUserOrganisationInvitationInput(
  {
    body,
    userId,
    organisationId,
    invitationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserOrganisationInvitation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutUserOrganisationInvitation.Input["body"]> {
  const __method = APIMethods.putUserOrganisationInvitation;
  const __uriData = buildPutUserOrganisationInvitationURI(
    {
      userId,
      organisationId,
      invitationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Update an organisation's invitation.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putUserOrganisationInvitation(
  {
    body,
    userId,
    organisationId,
    invitationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserOrganisationInvitation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutUserOrganisationInvitation.Output> {
  const httpRequest = buildPutUserOrganisationInvitationInput(
    {
      body,
      userId,
      organisationId,
      invitationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutUserOrganisationInvitation.Output;
}

/**
 * Build the "deleteUserOrganisationMember" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.memberId
 * The member id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildDeleteUserOrganisationMemberURI(
  {
    userId,
    organisationId,
    memberId,
    accessToken,
  }: Pick<
    SencropTypes.DeleteUserOrganisationMember.Input,
    "userId" | "organisationId" | "memberId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (memberId == null) {
    throw new Error(
      "Missing required parameter : memberId. Value : " + memberId
    );
  }

  const __pathParts = [
    "users",
    userId,
    "organisations",
    organisationId,
    "members",
    memberId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "deleteUserOrganisationMember" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.memberId
 * The member id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildDeleteUserOrganisationMemberInput(
  {
    userId,
    organisationId,
    memberId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteUserOrganisationMember.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.deleteUserOrganisationMember;
  const __uriData = buildDeleteUserOrganisationMemberURI(
    {
      userId,
      organisationId,
      memberId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Delete a member from an user's organisation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.memberId
 * The member id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function deleteUserOrganisationMember(
  {
    userId,
    organisationId,
    memberId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteUserOrganisationMember.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.DeleteUserOrganisationMember.Output> {
  const httpRequest = buildDeleteUserOrganisationMemberInput(
    {
      userId,
      organisationId,
      memberId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.DeleteUserOrganisationMember.Output;
}

/**
 * Build the "putUserOrganisationMember" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.memberId
 * The member id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutUserOrganisationMemberURI(
  {
    userId,
    organisationId,
    memberId,
    accessToken,
  }: Pick<
    SencropTypes.PutUserOrganisationMember.Input,
    "userId" | "organisationId" | "memberId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (memberId == null) {
    throw new Error(
      "Missing required parameter : memberId. Value : " + memberId
    );
  }

  const __pathParts = [
    "users",
    userId,
    "organisations",
    organisationId,
    "members",
    memberId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putUserOrganisationMember" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.memberId
 * The member id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutUserOrganisationMemberInput(
  {
    body,
    userId,
    organisationId,
    memberId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserOrganisationMember.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutUserOrganisationMember.Input["body"]> {
  const __method = APIMethods.putUserOrganisationMember;
  const __uriData = buildPutUserOrganisationMemberURI(
    {
      userId,
      organisationId,
      memberId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Add or edit an member to an organisation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.memberId
 * The member id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putUserOrganisationMember(
  {
    body,
    userId,
    organisationId,
    memberId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserOrganisationMember.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutUserOrganisationMember.Output> {
  const httpRequest = buildPutUserOrganisationMemberInput(
    {
      body,
      userId,
      organisationId,
      memberId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutUserOrganisationMember.Output;
}

/**
 * Build the "deleteUserOrganisationOpenNetworkMembership" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.networkId
 * The network id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildDeleteUserOrganisationOpenNetworkMembershipURI(
  {
    userId,
    organisationId,
    networkId,
    accessToken,
  }: Pick<
    SencropTypes.DeleteUserOrganisationOpenNetworkMembership.Input,
    "userId" | "organisationId" | "networkId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (networkId == null) {
    throw new Error(
      "Missing required parameter : networkId. Value : " + networkId
    );
  }

  const __pathParts = [
    "users",
    userId,
    "organisations",
    organisationId,
    "openNetworks",
    networkId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "deleteUserOrganisationOpenNetworkMembership" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.networkId
 * The network id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildDeleteUserOrganisationOpenNetworkMembershipInput(
  {
    userId,
    organisationId,
    networkId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteUserOrganisationOpenNetworkMembership.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.deleteUserOrganisationOpenNetworkMembership;
  const __uriData = buildDeleteUserOrganisationOpenNetworkMembershipURI(
    {
      userId,
      organisationId,
      networkId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Allow an organisation to quit an open network
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.networkId
 * The network id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function deleteUserOrganisationOpenNetworkMembership(
  {
    userId,
    organisationId,
    networkId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteUserOrganisationOpenNetworkMembership.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.DeleteUserOrganisationOpenNetworkMembership.Output> {
  const httpRequest = buildDeleteUserOrganisationOpenNetworkMembershipInput(
    {
      userId,
      organisationId,
      networkId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.DeleteUserOrganisationOpenNetworkMembership.Output;
}

/**
 * Build the "putUserOrganisationOpenNetworkMembership" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.networkId
 * The network id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutUserOrganisationOpenNetworkMembershipURI(
  {
    userId,
    organisationId,
    networkId,
    accessToken,
  }: Pick<
    SencropTypes.PutUserOrganisationOpenNetworkMembership.Input,
    "userId" | "organisationId" | "networkId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (networkId == null) {
    throw new Error(
      "Missing required parameter : networkId. Value : " + networkId
    );
  }

  const __pathParts = [
    "users",
    userId,
    "organisations",
    organisationId,
    "openNetworks",
    networkId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putUserOrganisationOpenNetworkMembership" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.networkId
 * The network id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutUserOrganisationOpenNetworkMembershipInput(
  {
    userId,
    organisationId,
    networkId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserOrganisationOpenNetworkMembership.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.putUserOrganisationOpenNetworkMembership;
  const __uriData = buildPutUserOrganisationOpenNetworkMembershipURI(
    {
      userId,
      organisationId,
      networkId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Allow an organisation to join an open network
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.networkId
 * The network id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putUserOrganisationOpenNetworkMembership(
  {
    userId,
    organisationId,
    networkId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserOrganisationOpenNetworkMembership.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutUserOrganisationOpenNetworkMembership.Output> {
  const httpRequest = buildPutUserOrganisationOpenNetworkMembershipInput(
    {
      userId,
      organisationId,
      networkId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutUserOrganisationOpenNetworkMembership.Output;
}

/**
 * Build the "deleteUserOrganisationShare" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.shareId
 * The share id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildDeleteUserOrganisationShareURI(
  {
    userId,
    organisationId,
    shareId,
    accessToken,
  }: Pick<
    SencropTypes.DeleteUserOrganisationShare.Input,
    "userId" | "organisationId" | "shareId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (shareId == null) {
    throw new Error("Missing required parameter : shareId. Value : " + shareId);
  }

  const __pathParts = [
    "users",
    userId,
    "organisations",
    organisationId,
    "shares",
    shareId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "deleteUserOrganisationShare" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.shareId
 * The share id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildDeleteUserOrganisationShareInput(
  {
    userId,
    organisationId,
    shareId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteUserOrganisationShare.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.deleteUserOrganisationShare;
  const __uriData = buildDeleteUserOrganisationShareURI(
    {
      userId,
      organisationId,
      shareId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * undefined
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.shareId
 * The share id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function deleteUserOrganisationShare(
  {
    userId,
    organisationId,
    shareId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.DeleteUserOrganisationShare.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.DeleteUserOrganisationShare.Output> {
  const httpRequest = buildDeleteUserOrganisationShareInput(
    {
      userId,
      organisationId,
      shareId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.DeleteUserOrganisationShare.Output;
}

/**
 * Build the "putUserOrganisationShare" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.shareId
 * The share id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutUserOrganisationShareURI(
  {
    userId,
    organisationId,
    shareId,
    accessToken,
  }: Pick<
    SencropTypes.PutUserOrganisationShare.Input,
    "userId" | "organisationId" | "shareId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (shareId == null) {
    throw new Error("Missing required parameter : shareId. Value : " + shareId);
  }

  const __pathParts = [
    "users",
    userId,
    "organisations",
    organisationId,
    "shares",
    shareId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putUserOrganisationShare" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.shareId
 * The share id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutUserOrganisationShareInput(
  {
    body,
    userId,
    organisationId,
    shareId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserOrganisationShare.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutUserOrganisationShare.Input["body"]> {
  const __method = APIMethods.putUserOrganisationShare;
  const __uriData = buildPutUserOrganisationShareURI(
    {
      userId,
      organisationId,
      shareId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * undefined
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.shareId
 * The share id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putUserOrganisationShare(
  {
    body,
    userId,
    organisationId,
    shareId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserOrganisationShare.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutUserOrganisationShare.Output> {
  const httpRequest = buildPutUserOrganisationShareInput(
    {
      body,
      userId,
      organisationId,
      shareId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutUserOrganisationShare.Output;
}

/**
 * Build the "getOpenAPI" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {array} [parameters.mutedParameters],
 * @param {array} [parameters.mutedMethods],
 * @param {array} [parameters.tags],
 * @param {boolean} [parameters.authenticatedHandlers],
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOpenAPIURI(
  {
    mutedParameters,
    mutedMethods,
    tags,
    authenticatedHandlers,
    accessToken,
  }: Pick<
    SencropTypes.GetOpenAPI.Input,
    | "mutedParameters"
    | "mutedMethods"
    | "tags"
    | "authenticatedHandlers"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["openAPI"];
  const __qs = cleanQuery({
    mutedParameters: mutedParameters,
    mutedMethods: mutedMethods,
    tags: tags,
    authenticatedHandlers: authenticatedHandlers,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOpenAPI" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {array} [parameters.mutedParameters],
 * @param {array} [parameters.mutedMethods],
 * @param {array} [parameters.tags],
 * @param {boolean} [parameters.authenticatedHandlers],
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOpenAPIInput(
  {
    mutedParameters,
    mutedMethods,
    tags,
    authenticatedHandlers,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOpenAPI.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOpenAPI;
  const __uriData = buildGetOpenAPIURI(
    {
      mutedParameters,
      mutedMethods,
      tags,
      authenticatedHandlers,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get API documentation.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {array} [parameters.mutedParameters],
 * @param {array} [parameters.mutedMethods],
 * @param {array} [parameters.tags],
 * @param {boolean} [parameters.authenticatedHandlers],
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOpenAPI(
  {
    mutedParameters,
    mutedMethods,
    tags,
    authenticatedHandlers,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOpenAPI.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOpenAPI.Output> {
  const httpRequest = buildGetOpenAPIInput(
    {
      mutedParameters,
      mutedMethods,
      tags,
      authenticatedHandlers,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOpenAPI.Output;
}

/**
 * Build the "getAgritelPrices" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetAgritelPricesURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.GetAgritelPrices.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["crons", "agritelPrices"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getAgritelPrices" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetAgritelPricesInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetAgritelPrices.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getAgritelPrices;
  const __uriData = buildGetAgritelPricesURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Retrieve and store agritel prices
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getAgritelPrices(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetAgritelPrices.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetAgritelPrices.Output> {
  const httpRequest = buildGetAgritelPricesInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetAgritelPrices.Output;
}

/**
 * Build the "getChargebeeCustomer" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} [parameters.customerEmail]
 * Email of the chargebee customer to retrieve,
 * @param {string} [parameters.customerId]
 * Id of the chargebee customer to retrieve,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetChargebeeCustomerURI(
  {
    customerEmail,
    customerId,
    accessToken,
  }: Pick<
    SencropTypes.GetChargebeeCustomer.Input,
    "customerEmail" | "customerId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["chargebee", "customers"];
  const __qs = cleanQuery({
    customerEmail: customerEmail,
    customerId: customerId,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getChargebeeCustomer" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.customerEmail]
 * Email of the chargebee customer to retrieve,
 * @param {string} [parameters.customerId]
 * Id of the chargebee customer to retrieve,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetChargebeeCustomerInput(
  {
    customerEmail,
    customerId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetChargebeeCustomer.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getChargebeeCustomer;
  const __uriData = buildGetChargebeeCustomerURI(
    {
      customerEmail,
      customerId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a chargebee customer with current subscription
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.customerEmail]
 * Email of the chargebee customer to retrieve,
 * @param {string} [parameters.customerId]
 * Id of the chargebee customer to retrieve,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getChargebeeCustomer(
  {
    customerEmail,
    customerId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetChargebeeCustomer.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetChargebeeCustomer.Output> {
  const httpRequest = buildGetChargebeeCustomerInput(
    {
      customerEmail,
      customerId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetChargebeeCustomer.Output;
}

/**
 * Build the "getChargebeeResourcesPublic" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.currency,
 * @param {string} parameters.zone
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetChargebeeResourcesPublicURI(
  {
    currency,
    zone,
  }: Pick<SencropTypes.GetChargebeeResourcesPublic.Input, "currency" | "zone">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (currency == null) {
    throw new Error(
      "Missing required parameter : currency. Value : " + currency
    );
  }
  if (zone == null) {
    throw new Error("Missing required parameter : zone. Value : " + zone);
  }

  const __pathParts = ["chargebee", "ressources", "public"];
  const __qs = cleanQuery({
    currency: currency,
    zone: zone,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getChargebeeResourcesPublic" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.currency,
 * @param {string} parameters.zone,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetChargebeeResourcesPublicInput(
  {
    currency,
    zone,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetChargebeeResourcesPublic.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getChargebeeResourcesPublic;
  const __uriData = buildGetChargebeeResourcesPublicURI(
    {
      currency,
      zone,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Retrieve public ressources from chargebee (plans and products)
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.currency,
 * @param {string} parameters.zone,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getChargebeeResourcesPublic(
  {
    currency,
    zone,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetChargebeeResourcesPublic.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetChargebeeResourcesPublic.Output> {
  const httpRequest = buildGetChargebeeResourcesPublicInput(
    {
      currency,
      zone,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetChargebeeResourcesPublic.Output;
}

/**
 * Build the "getChargebeeRessources" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.currency,
 * @param {string} parameters.zone,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetChargebeeRessourcesURI(
  {
    currency,
    zone,
    accessToken,
  }: Pick<
    SencropTypes.GetChargebeeRessources.Input,
    "currency" | "zone" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (currency == null) {
    throw new Error(
      "Missing required parameter : currency. Value : " + currency
    );
  }
  if (zone == null) {
    throw new Error("Missing required parameter : zone. Value : " + zone);
  }

  const __pathParts = ["chargebee", "ressources"];
  const __qs = cleanQuery({
    currency: currency,
    zone: zone,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getChargebeeRessources" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.currency,
 * @param {string} parameters.zone,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetChargebeeRessourcesInput(
  {
    currency,
    zone,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetChargebeeRessources.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getChargebeeRessources;
  const __uriData = buildGetChargebeeRessourcesURI(
    {
      currency,
      zone,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Retrieve ressources from chargebee (plans/addons/coupons)
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.currency,
 * @param {string} parameters.zone,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getChargebeeRessources(
  {
    currency,
    zone,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetChargebeeRessources.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetChargebeeRessources.Output> {
  const httpRequest = buildGetChargebeeRessourcesInput(
    {
      currency,
      zone,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetChargebeeRessources.Output;
}

/**
 * Build the "getCotations" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetCotationsURI(
  { accessToken }: Pick<SencropTypes.GetCotations.Input, "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["cotations"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getCotations" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetCotationsInput(
  {
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetCotations.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getCotations;
  const __uriData = buildGetCotationsURI(
    {
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get lastest cotations.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getCotations(
  {
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetCotations.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetCotations.Output> {
  const httpRequest = buildGetCotationsInput(
    {
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetCotations.Output;
}

/**
 * Build the "getCrops" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetCropsURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["crops"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getCrops" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetCropsInput(
  { xApiVersion, xSdkVersion, xAppVersion }: SencropTypes.GetCrops.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getCrops;
  const __uriData = buildGetCropsURI({}, { baseURL: __baseURL });

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get the crops
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getCrops(
  { xApiVersion, xSdkVersion, xAppVersion }: SencropTypes.GetCrops.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetCrops.Output> {
  const httpRequest = buildGetCropsInput(
    {
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetCrops.Output;
}

/**
 * Build the "getCustomerByEmail" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} [parameters.customerEmail]
 * Email of the customer to retrieve,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetCustomerByEmailURI(
  {
    customerEmail,
    accessToken,
  }: Pick<
    SencropTypes.GetCustomerByEmail.Input,
    "customerEmail" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["customers"];
  const __qs = cleanQuery({
    customerEmail: customerEmail,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getCustomerByEmail" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.customerEmail]
 * Email of the customer to retrieve,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetCustomerByEmailInput(
  {
    customerEmail,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetCustomerByEmail.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getCustomerByEmail;
  const __uriData = buildGetCustomerByEmailURI(
    {
      customerEmail,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a customer (database / hubspot) by email
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.customerEmail]
 * Email of the customer to retrieve,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getCustomerByEmail(
  {
    customerEmail,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetCustomerByEmail.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetCustomerByEmail.Output> {
  const httpRequest = buildGetCustomerByEmailInput(
    {
      customerEmail,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetCustomerByEmail.Output;
}

/**
 * Build the "getData" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.startDate
 * The statistics start date,
 * @param {string} parameters.endDate
 * The statistics end date,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {array} parameters.ranges
 * The ranges to get the data from,
 * @param {string} parameters.interval
 * The interval of data,
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {number} [parameters.minimumFiability]
 * The minimum fiability of measures to be used,
 * @param {string} [parameters.baseDeviceSerial],
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetDataURI(
  {
    startDate,
    endDate,
    measures,
    ranges,
    interval,
    latitude,
    longitude,
    withCalibration,
    timeZone,
    minimumFiability,
    baseDeviceSerial,
    accessToken,
  }: Pick<
    SencropTypes.GetData.Input,
    | "startDate"
    | "endDate"
    | "measures"
    | "ranges"
    | "interval"
    | "latitude"
    | "longitude"
    | "withCalibration"
    | "timeZone"
    | "minimumFiability"
    | "baseDeviceSerial"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (startDate == null) {
    throw new Error(
      "Missing required parameter : startDate. Value : " + startDate
    );
  }
  if (endDate == null) {
    throw new Error("Missing required parameter : endDate. Value : " + endDate);
  }
  if (measures == null) {
    throw new Error(
      "Missing required parameter : measures. Value : " + measures
    );
  }
  if (ranges == null) {
    throw new Error("Missing required parameter : ranges. Value : " + ranges);
  }
  if (interval == null) {
    throw new Error(
      "Missing required parameter : interval. Value : " + interval
    );
  }
  if (latitude == null) {
    throw new Error(
      "Missing required parameter : latitude. Value : " + latitude
    );
  }
  if (longitude == null) {
    throw new Error(
      "Missing required parameter : longitude. Value : " + longitude
    );
  }

  const __pathParts = ["data"];
  const __qs = cleanQuery({
    startDate: startDate,
    endDate: endDate,
    measures: measures,
    ranges: ranges,
    interval: interval,
    latitude: latitude,
    longitude: longitude,
    withCalibration: withCalibration,
    timeZone: timeZone,
    minimumFiability: minimumFiability,
    baseDeviceSerial: baseDeviceSerial,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getData" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.startDate
 * The statistics start date,
 * @param {string} parameters.endDate
 * The statistics end date,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {array} parameters.ranges
 * The ranges to get the data from,
 * @param {string} parameters.interval
 * The interval of data,
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {number} [parameters.minimumFiability]
 * The minimum fiability of measures to be used,
 * @param {string} [parameters.baseDeviceSerial],
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetDataInput(
  {
    startDate,
    endDate,
    measures,
    ranges,
    interval,
    latitude,
    longitude,
    withCalibration,
    timeZone,
    minimumFiability,
    baseDeviceSerial,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetData.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getData;
  const __uriData = buildGetDataURI(
    {
      startDate,
      endDate,
      measures,
      ranges,
      interval,
      latitude,
      longitude,
      withCalibration,
      timeZone,
      minimumFiability,
      baseDeviceSerial,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get data for a given geolocation.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.startDate
 * The statistics start date,
 * @param {string} parameters.endDate
 * The statistics end date,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {array} parameters.ranges
 * The ranges to get the data from,
 * @param {string} parameters.interval
 * The interval of data,
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {number} [parameters.minimumFiability]
 * The minimum fiability of measures to be used,
 * @param {string} [parameters.baseDeviceSerial],
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getData(
  {
    startDate,
    endDate,
    measures,
    ranges,
    interval,
    latitude,
    longitude,
    withCalibration,
    timeZone,
    minimumFiability,
    baseDeviceSerial,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetData.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetData.Output> {
  const httpRequest = buildGetDataInput(
    {
      startDate,
      endDate,
      measures,
      ranges,
      interval,
      latitude,
      longitude,
      withCalibration,
      timeZone,
      minimumFiability,
      baseDeviceSerial,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetData.Output;
}

/**
 * Build the "getDeviceData" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.startDate
 * The statistics start date,
 * @param {string} parameters.endDate
 * The statistics end date,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} parameters.interval
 * The interval of data,
 * @param {string} parameters.key
 * Access to this endpoint is enforced
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetDeviceDataURI(
  {
    deviceId,
    startDate,
    endDate,
    measures,
    timeZone,
    interval,
    key,
  }: Pick<
    SencropTypes.GetDeviceData.Input,
    | "deviceId"
    | "startDate"
    | "endDate"
    | "measures"
    | "timeZone"
    | "interval"
    | "key"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }
  if (startDate == null) {
    throw new Error(
      "Missing required parameter : startDate. Value : " + startDate
    );
  }
  if (endDate == null) {
    throw new Error("Missing required parameter : endDate. Value : " + endDate);
  }
  if (measures == null) {
    throw new Error(
      "Missing required parameter : measures. Value : " + measures
    );
  }
  if (interval == null) {
    throw new Error(
      "Missing required parameter : interval. Value : " + interval
    );
  }
  if (key == null) {
    throw new Error("Missing required parameter : key. Value : " + key);
  }

  const __pathParts = ["devices", deviceId, "data"];
  const __qs = cleanQuery({
    startDate: startDate,
    endDate: endDate,
    measures: measures,
    timeZone: timeZone,
    interval: interval,
    key: key,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getDeviceData" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.startDate
 * The statistics start date,
 * @param {string} parameters.endDate
 * The statistics end date,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} parameters.interval
 * The interval of data,
 * @param {string} parameters.key
 * Access to this endpoint is enforced,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetDeviceDataInput(
  {
    deviceId,
    startDate,
    endDate,
    measures,
    timeZone,
    interval,
    key,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetDeviceData.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getDeviceData;
  const __uriData = buildGetDeviceDataURI(
    {
      deviceId,
      startDate,
      endDate,
      measures,
      timeZone,
      interval,
      key,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get data for a given device, filled by geoqueries for holes (used by agro).
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.startDate
 * The statistics start date,
 * @param {string} parameters.endDate
 * The statistics end date,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} parameters.interval
 * The interval of data,
 * @param {string} parameters.key
 * Access to this endpoint is enforced,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getDeviceData(
  {
    deviceId,
    startDate,
    endDate,
    measures,
    timeZone,
    interval,
    key,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetDeviceData.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetDeviceData.Output> {
  const httpRequest = buildGetDeviceDataInput(
    {
      deviceId,
      startDate,
      endDate,
      measures,
      timeZone,
      interval,
      key,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetDeviceData.Output;
}

/**
 * Build the "getDeviceRawData" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.beforeDate
 * The date before which the data starts being retrieved,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {number} parameters.size
 * The number of measures to retrieve,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetDeviceRawDataURI(
  {
    deviceId,
    beforeDate,
    includeHistory,
    size,
    measures,
    accessToken,
  }: Pick<
    SencropTypes.GetDeviceRawData.Input,
    | "deviceId"
    | "beforeDate"
    | "includeHistory"
    | "size"
    | "measures"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }
  if (beforeDate == null) {
    throw new Error(
      "Missing required parameter : beforeDate. Value : " + beforeDate
    );
  }
  if (size == null) {
    throw new Error("Missing required parameter : size. Value : " + size);
  }
  if (measures == null) {
    throw new Error(
      "Missing required parameter : measures. Value : " + measures
    );
  }

  const __pathParts = ["devices", deviceId, "data", "raw"];
  const __qs = cleanQuery({
    beforeDate: beforeDate,
    includeHistory: includeHistory,
    size: size,
    measures: measures,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getDeviceRawData" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.beforeDate
 * The date before which the data starts being retrieved,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {number} parameters.size
 * The number of measures to retrieve,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetDeviceRawDataInput(
  {
    deviceId,
    beforeDate,
    includeHistory,
    size,
    measures,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetDeviceRawData.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getDeviceRawData;
  const __uriData = buildGetDeviceRawDataURI(
    {
      deviceId,
      beforeDate,
      includeHistory,
      size,
      measures,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a device's raw data.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.beforeDate
 * The date before which the data starts being retrieved,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {number} parameters.size
 * The number of measures to retrieve,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getDeviceRawData(
  {
    deviceId,
    beforeDate,
    includeHistory,
    size,
    measures,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetDeviceRawData.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetDeviceRawData.Output> {
  const httpRequest = buildGetDeviceRawDataInput(
    {
      deviceId,
      beforeDate,
      includeHistory,
      size,
      measures,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetDeviceRawData.Output;
}

/**
 * Build the "getDevices" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {array} parameters.devicesIds
 * List of id's of devices,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetDevicesURI(
  {
    devicesIds,
    accessToken,
  }: Pick<SencropTypes.GetDevices.Input, "devicesIds" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (devicesIds == null) {
    throw new Error(
      "Missing required parameter : devicesIds. Value : " + devicesIds
    );
  }

  const __pathParts = ["devices"];
  const __qs = cleanQuery({
    devicesIds: devicesIds,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getDevices" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {array} parameters.devicesIds
 * List of id's of devices,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetDevicesInput(
  {
    devicesIds,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetDevices.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getDevices;
  const __uriData = buildGetDevicesURI(
    {
      devicesIds,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get devices from list of id's.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {array} parameters.devicesIds
 * List of id's of devices,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getDevices(
  {
    devicesIds,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetDevices.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetDevices.Output> {
  const httpRequest = buildGetDevicesInput(
    {
      devicesIds,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetDevices.Output;
}

/**
 * Build the "getFactoryMeasures" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {array} parameters.serials
 * Sigfox serial from a device,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetFactoryMeasuresURI(
  {
    serials,
    accessToken,
  }: Pick<SencropTypes.GetFactoryMeasures.Input, "serials" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (serials == null) {
    throw new Error("Missing required parameter : serials. Value : " + serials);
  }

  const __pathParts = ["factory", "measures"];
  const __qs = cleanQuery({
    serials: serials,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getFactoryMeasures" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {array} parameters.serials
 * Sigfox serial from a device,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetFactoryMeasuresInput(
  {
    serials,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetFactoryMeasures.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getFactoryMeasures;
  const __uriData = buildGetFactoryMeasuresURI(
    {
      serials,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get last measures based on last 3 days production line tests
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {array} parameters.serials
 * Sigfox serial from a device,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getFactoryMeasures(
  {
    serials,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetFactoryMeasures.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetFactoryMeasures.Output> {
  const httpRequest = buildGetFactoryMeasuresInput(
    {
      serials,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetFactoryMeasures.Output;
}

/**
 * Build the "getFactorySigfoxStatus" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.serial
 * Sigfox serial from a device,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetFactorySigfoxStatusURI(
  {
    serial,
    accessToken,
  }: Pick<SencropTypes.GetFactorySigfoxStatus.Input, "serial" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (serial == null) {
    throw new Error("Missing required parameter : serial. Value : " + serial);
  }

  const __pathParts = ["factory", "sigfox", "status", serial];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getFactorySigfoxStatus" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.serial
 * Sigfox serial from a device,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetFactorySigfoxStatusInput(
  {
    serial,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetFactorySigfoxStatus.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getFactorySigfoxStatus;
  const __uriData = buildGetFactorySigfoxStatusURI(
    {
      serial,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get sigfox statuses for devices in production line tests
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.serial
 * Sigfox serial from a device,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getFactorySigfoxStatus(
  {
    serial,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetFactorySigfoxStatus.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetFactorySigfoxStatus.Output> {
  const httpRequest = buildGetFactorySigfoxStatusInput(
    {
      serial,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetFactorySigfoxStatus.Output;
}

/**
 * Build the "getHubSpotContact" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.email,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetHubSpotContactURI(
  {
    email,
    accessToken,
  }: Pick<SencropTypes.GetHubSpotContact.Input, "email" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (email == null) {
    throw new Error("Missing required parameter : email. Value : " + email);
  }

  const __pathParts = ["hubspot", "contact"];
  const __qs = cleanQuery({
    email: email,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getHubSpotContact" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.email,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetHubSpotContactInput(
  {
    email,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetHubSpotContact.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getHubSpotContact;
  const __uriData = buildGetHubSpotContactURI(
    {
      email,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Retrieve a contact from HubSpot
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.email,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getHubSpotContact(
  {
    email,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetHubSpotContact.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetHubSpotContact.Output> {
  const httpRequest = buildGetHubSpotContactInput(
    {
      email,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetHubSpotContact.Output;
}

/**
 * Build the "getHubSpotProperties" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} [parameters.type],
 * @param {string} [parameters.name],
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetHubSpotPropertiesURI(
  {
    type,
    name,
    accessToken,
  }: Pick<
    SencropTypes.GetHubSpotProperties.Input,
    "type" | "name" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["hubspot", "properties"];
  const __qs = cleanQuery({
    type: type,
    name: name,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getHubSpotProperties" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.type],
 * @param {string} [parameters.name],
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetHubSpotPropertiesInput(
  {
    type,
    name,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetHubSpotProperties.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getHubSpotProperties;
  const __uriData = buildGetHubSpotPropertiesURI(
    {
      type,
      name,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Retrieve all tradeshow from HubSpot
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.type],
 * @param {string} [parameters.name],
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getHubSpotProperties(
  {
    type,
    name,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetHubSpotProperties.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetHubSpotProperties.Output> {
  const httpRequest = buildGetHubSpotPropertiesInput(
    {
      type,
      name,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetHubSpotProperties.Output;
}

/**
 * Build the "getHubSpotQuotatorCard" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} [parameters.userId],
 * @param {string} [parameters.userEmail],
 * @param {number} [parameters.associatedObjectId],
 * @param {string} [parameters.associatedObjectType],
 * @param {number} [parameters.portalId],
 * @param {number} [parameters.hsObjectId]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetHubSpotQuotatorCardURI(
  {
    userId,
    userEmail,
    associatedObjectId,
    associatedObjectType,
    portalId,
    hsObjectId,
  }: Pick<
    SencropTypes.GetHubSpotQuotatorCard.Input,
    | "userId"
    | "userEmail"
    | "associatedObjectId"
    | "associatedObjectType"
    | "portalId"
    | "hsObjectId"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["hubspot", "quotator"];
  const __qs = cleanQuery({
    userId: userId,
    userEmail: userEmail,
    associatedObjectId: associatedObjectId,
    associatedObjectType: associatedObjectType,
    portalId: portalId,
    hs_object_id: hsObjectId,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getHubSpotQuotatorCard" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.userId],
 * @param {string} [parameters.userEmail],
 * @param {number} [parameters.associatedObjectId],
 * @param {string} [parameters.associatedObjectType],
 * @param {number} [parameters.portalId],
 * @param {number} [parameters.hsObjectId],
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetHubSpotQuotatorCardInput(
  {
    userId,
    userEmail,
    associatedObjectId,
    associatedObjectType,
    portalId,
    hsObjectId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetHubSpotQuotatorCard.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getHubSpotQuotatorCard;
  const __uriData = buildGetHubSpotQuotatorCardURI(
    {
      userId,
      userEmail,
      associatedObjectId,
      associatedObjectType,
      portalId,
      hsObjectId,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get quotator crm card for hubspot
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.userId],
 * @param {string} [parameters.userEmail],
 * @param {number} [parameters.associatedObjectId],
 * @param {string} [parameters.associatedObjectType],
 * @param {number} [parameters.portalId],
 * @param {number} [parameters.hsObjectId],
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getHubSpotQuotatorCard(
  {
    userId,
    userEmail,
    associatedObjectId,
    associatedObjectType,
    portalId,
    hsObjectId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetHubSpotQuotatorCard.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetHubSpotQuotatorCard.Output> {
  const httpRequest = buildGetHubSpotQuotatorCardInput(
    {
      userId,
      userEmail,
      associatedObjectId,
      associatedObjectType,
      portalId,
      hsObjectId,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetHubSpotQuotatorCard.Output;
}

/**
 * Build the "getHubSpotTableauCard" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} [parameters.userId],
 * @param {string} [parameters.userEmail],
 * @param {number} [parameters.associatedObjectId],
 * @param {string} [parameters.associatedObjectType],
 * @param {number} [parameters.portalId],
 * @param {number} [parameters.hsObjectId],
 * @param {string} [parameters.email],
 * @param {string} [parameters.appId]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetHubSpotTableauCardURI(
  {
    userId,
    userEmail,
    associatedObjectId,
    associatedObjectType,
    portalId,
    hsObjectId,
    email,
    appId,
  }: Pick<
    SencropTypes.GetHubSpotTableauCard.Input,
    | "userId"
    | "userEmail"
    | "associatedObjectId"
    | "associatedObjectType"
    | "portalId"
    | "hsObjectId"
    | "email"
    | "appId"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["hubspot", "tableau"];
  const __qs = cleanQuery({
    userId: userId,
    userEmail: userEmail,
    associatedObjectId: associatedObjectId,
    associatedObjectType: associatedObjectType,
    portalId: portalId,
    hs_object_id: hsObjectId,
    email: email,
    app_id: appId,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getHubSpotTableauCard" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.userId],
 * @param {string} [parameters.userEmail],
 * @param {number} [parameters.associatedObjectId],
 * @param {string} [parameters.associatedObjectType],
 * @param {number} [parameters.portalId],
 * @param {number} [parameters.hsObjectId],
 * @param {string} [parameters.email],
 * @param {string} [parameters.appId],
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetHubSpotTableauCardInput(
  {
    userId,
    userEmail,
    associatedObjectId,
    associatedObjectType,
    portalId,
    hsObjectId,
    email,
    appId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetHubSpotTableauCard.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getHubSpotTableauCard;
  const __uriData = buildGetHubSpotTableauCardURI(
    {
      userId,
      userEmail,
      associatedObjectId,
      associatedObjectType,
      portalId,
      hsObjectId,
      email,
      appId,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get tableau crm card for hubspot
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.userId],
 * @param {string} [parameters.userEmail],
 * @param {number} [parameters.associatedObjectId],
 * @param {string} [parameters.associatedObjectType],
 * @param {number} [parameters.portalId],
 * @param {number} [parameters.hsObjectId],
 * @param {string} [parameters.email],
 * @param {string} [parameters.appId],
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getHubSpotTableauCard(
  {
    userId,
    userEmail,
    associatedObjectId,
    associatedObjectType,
    portalId,
    hsObjectId,
    email,
    appId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetHubSpotTableauCard.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetHubSpotTableauCard.Output> {
  const httpRequest = buildGetHubSpotTableauCardInput(
    {
      userId,
      userEmail,
      associatedObjectId,
      associatedObjectType,
      portalId,
      hsObjectId,
      email,
      appId,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetHubSpotTableauCard.Output;
}

/**
 * Build the "getInvitation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.invitationId
 * The invitation id
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetInvitationURI(
  { invitationId }: Pick<SencropTypes.GetInvitation.Input, "invitationId">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (invitationId == null) {
    throw new Error(
      "Missing required parameter : invitationId. Value : " + invitationId
    );
  }

  const __pathParts = ["invitations", invitationId];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getInvitation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetInvitationInput(
  {
    invitationId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetInvitation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getInvitation;
  const __uriData = buildGetInvitationURI(
    {
      invitationId,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get an invitation.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getInvitation(
  {
    invitationId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetInvitation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetInvitation.Output> {
  const httpRequest = buildGetInvitationInput(
    {
      invitationId,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetInvitation.Output;
}

/**
 * Build the "getInvitationDeviceLiveAggregations" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.timeZone
 * The timezone of the data
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetInvitationDeviceLiveAggregationsURI(
  {
    invitationId,
    deviceId,
    timeZone,
  }: Pick<
    SencropTypes.GetInvitationDeviceLiveAggregations.Input,
    "invitationId" | "deviceId" | "timeZone"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (invitationId == null) {
    throw new Error(
      "Missing required parameter : invitationId. Value : " + invitationId
    );
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }
  if (timeZone == null) {
    throw new Error(
      "Missing required parameter : timeZone. Value : " + timeZone
    );
  }

  const __pathParts = [
    "invitations",
    invitationId,
    "devices",
    deviceId,
    "liveAggregations",
  ];
  const __qs = cleanQuery({
    timeZone: timeZone,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getInvitationDeviceLiveAggregations" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.timeZone
 * The timezone of the data,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetInvitationDeviceLiveAggregationsInput(
  {
    invitationId,
    deviceId,
    timeZone,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetInvitationDeviceLiveAggregations.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getInvitationDeviceLiveAggregations;
  const __uriData = buildGetInvitationDeviceLiveAggregationsURI(
    {
      invitationId,
      deviceId,
      timeZone,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Retrieve last measures for a given device from an invitation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.timeZone
 * The timezone of the data,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getInvitationDeviceLiveAggregations(
  {
    invitationId,
    deviceId,
    timeZone,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetInvitationDeviceLiveAggregations.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetInvitationDeviceLiveAggregations.Output> {
  const httpRequest = buildGetInvitationDeviceLiveAggregationsInput(
    {
      invitationId,
      deviceId,
      timeZone,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetInvitationDeviceLiveAggregations.Output;
}

/**
 * Build the "getLatestIrrCalibration" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.sensorId
 * The sensor id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetLatestIrrCalibrationURI(
  {
    sensorId,
    accessToken,
  }: Pick<
    SencropTypes.GetLatestIrrCalibration.Input,
    "sensorId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (sensorId == null) {
    throw new Error(
      "Missing required parameter : sensorId. Value : " + sensorId
    );
  }

  const __pathParts = ["irradiance-sensors", sensorId, "calibration-latest"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getLatestIrrCalibration" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.sensorId
 * The sensor id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetLatestIrrCalibrationInput(
  {
    sensorId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetLatestIrrCalibration.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getLatestIrrCalibration;
  const __uriData = buildGetLatestIrrCalibrationURI(
    {
      sensorId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get the more recent calibration for an irradiance sensor
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.sensorId
 * The sensor id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getLatestIrrCalibration(
  {
    sensorId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetLatestIrrCalibration.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetLatestIrrCalibration.Output> {
  const httpRequest = buildGetLatestIrrCalibrationInput(
    {
      sensorId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetLatestIrrCalibration.Output;
}

/**
 * Build the "getLatestIrrCalibrations" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {array} parameters.sensorIds
 * Sensor ids,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetLatestIrrCalibrationsURI(
  {
    sensorIds,
    accessToken,
  }: Pick<
    SencropTypes.GetLatestIrrCalibrations.Input,
    "sensorIds" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (sensorIds == null) {
    throw new Error(
      "Missing required parameter : sensorIds. Value : " + sensorIds
    );
  }

  const __pathParts = ["irradiance-sensor-calibrations"];
  const __qs = cleanQuery({
    sensorIds: sensorIds,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getLatestIrrCalibrations" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {array} parameters.sensorIds
 * Sensor ids,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetLatestIrrCalibrationsInput(
  {
    sensorIds,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetLatestIrrCalibrations.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getLatestIrrCalibrations;
  const __uriData = buildGetLatestIrrCalibrationsURI(
    {
      sensorIds,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get the more recent calibration of a bulk of irradiance sensors
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {array} parameters.sensorIds
 * Sensor ids,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getLatestIrrCalibrations(
  {
    sensorIds,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetLatestIrrCalibrations.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetLatestIrrCalibrations.Output> {
  const httpRequest = buildGetLatestIrrCalibrationsInput(
    {
      sensorIds,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetLatestIrrCalibrations.Output;
}

/**
 * Build the "getModules" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetModulesURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["modules"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getModules" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetModulesInput(
  { xApiVersion, xSdkVersion, xAppVersion }: SencropTypes.GetModules.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getModules;
  const __uriData = buildGetModulesURI({}, { baseURL: __baseURL });

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get the modules
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getModules(
  { xApiVersion, xSdkVersion, xAppVersion }: SencropTypes.GetModules.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetModules.Output> {
  const httpRequest = buildGetModulesInput(
    {
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetModules.Output;
}

/**
 * Build the "getMySelf" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetMySelfURI(
  { accessToken }: Pick<SencropTypes.GetMySelf.Input, "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["me"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getMySelf" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetMySelfInput(
  {
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetMySelf.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getMySelf;
  const __uriData = buildGetMySelfURI(
    {
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get the current user
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getMySelf(
  {
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetMySelf.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetMySelf.Output> {
  const httpRequest = buildGetMySelfInput(
    {
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetMySelf.Output;
}

/**
 * Build the "getNetwork" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.networkId
 * The network id
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetNetworkURI(
  { networkId }: Pick<SencropTypes.GetNetwork.Input, "networkId">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (networkId == null) {
    throw new Error(
      "Missing required parameter : networkId. Value : " + networkId
    );
  }

  const __pathParts = ["networks", networkId];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getNetwork" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.networkId
 * The network id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetNetworkInput(
  {
    networkId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetNetwork.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getNetwork;
  const __uriData = buildGetNetworkURI(
    {
      networkId,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get the network.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.networkId
 * The network id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getNetwork(
  {
    networkId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetNetwork.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetNetwork.Output> {
  const httpRequest = buildGetNetworkInput(
    {
      networkId,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetNetwork.Output;
}

/**
 * Build the "getNetworkDeviceLiveAggregations" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.networkId
 * The network id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.timeZone
 * The timezone of the data
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetNetworkDeviceLiveAggregationsURI(
  {
    networkId,
    deviceId,
    timeZone,
  }: Pick<
    SencropTypes.GetNetworkDeviceLiveAggregations.Input,
    "networkId" | "deviceId" | "timeZone"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (networkId == null) {
    throw new Error(
      "Missing required parameter : networkId. Value : " + networkId
    );
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }
  if (timeZone == null) {
    throw new Error(
      "Missing required parameter : timeZone. Value : " + timeZone
    );
  }

  const __pathParts = [
    "networks",
    networkId,
    "devices",
    deviceId,
    "liveAggregations",
  ];
  const __qs = cleanQuery({
    timeZone: timeZone,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getNetworkDeviceLiveAggregations" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.networkId
 * The network id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.timeZone
 * The timezone of the data,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetNetworkDeviceLiveAggregationsInput(
  {
    networkId,
    deviceId,
    timeZone,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetNetworkDeviceLiveAggregations.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getNetworkDeviceLiveAggregations;
  const __uriData = buildGetNetworkDeviceLiveAggregationsURI(
    {
      networkId,
      deviceId,
      timeZone,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Retrieve last measures for a given device from a network
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.networkId
 * The network id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.timeZone
 * The timezone of the data,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getNetworkDeviceLiveAggregations(
  {
    networkId,
    deviceId,
    timeZone,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetNetworkDeviceLiveAggregations.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetNetworkDeviceLiveAggregations.Output> {
  const httpRequest = buildGetNetworkDeviceLiveAggregationsInput(
    {
      networkId,
      deviceId,
      timeZone,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetNetworkDeviceLiveAggregations.Output;
}

/**
 * Build the "getNetworkDevices" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.networkId
 * The network id
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetNetworkDevicesURI(
  { networkId }: Pick<SencropTypes.GetNetworkDevices.Input, "networkId">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (networkId == null) {
    throw new Error(
      "Missing required parameter : networkId. Value : " + networkId
    );
  }

  const __pathParts = ["networks", networkId, "devices"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getNetworkDevices" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.networkId
 * The network id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetNetworkDevicesInput(
  {
    networkId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetNetworkDevices.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getNetworkDevices;
  const __uriData = buildGetNetworkDevicesURI(
    {
      networkId,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get devices for a network
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.networkId
 * The network id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getNetworkDevices(
  {
    networkId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetNetworkDevices.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetNetworkDevices.Output> {
  const httpRequest = buildGetNetworkDevicesInput(
    {
      networkId,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetNetworkDevices.Output;
}

/**
 * Build the "getOAuth2Authorize" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.responseType,
 * @param {string} parameters.clientId,
 * @param {string} [parameters.redirectUri],
 * @param {string} [parameters.scope]
 * See https://tools.ietf.org/html/rfc6749#section-3.3,
 * @param {string} parameters.state
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOAuth2AuthorizeURI(
  {
    responseType,
    clientId,
    redirectUri,
    scope,
    state,
  }: Pick<
    SencropTypes.GetOAuth2Authorize.Input,
    "responseType" | "clientId" | "redirectUri" | "scope" | "state"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (responseType == null) {
    throw new Error(
      "Missing required parameter : responseType. Value : " + responseType
    );
  }
  if (clientId == null) {
    throw new Error(
      "Missing required parameter : clientId. Value : " + clientId
    );
  }
  if (state == null) {
    throw new Error("Missing required parameter : state. Value : " + state);
  }

  const __pathParts = ["oauth2", "authorize"];
  const __qs = cleanQuery({
    response_type: responseType,
    client_id: clientId,
    redirect_uri: redirectUri,
    scope: scope,
    state: state,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOAuth2Authorize" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.responseType,
 * @param {string} parameters.clientId,
 * @param {string} [parameters.redirectUri],
 * @param {string} [parameters.scope]
 * See https://tools.ietf.org/html/rfc6749#section-3.3,
 * @param {string} parameters.state,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOAuth2AuthorizeInput(
  {
    responseType,
    clientId,
    redirectUri,
    scope,
    state,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOAuth2Authorize.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOAuth2Authorize;
  const __uriData = buildGetOAuth2AuthorizeURI(
    {
      responseType,
      clientId,
      redirectUri,
      scope,
      state,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
  * Implements the [Authorization Endpoint](https://tools.ietf.org/html/rfc6749#section-3.1)
 as defined per the OAuth2 RFC.
  * @return {Object}
  * The object describing the built parameters
  * @param {Object} parameters
  * The parameters provided to build them (destructured)
 * @param {string} parameters.responseType,
 * @param {string} parameters.clientId,
 * @param {string} [parameters.redirectUri],
 * @param {string} [parameters.scope]
  * See https://tools.ietf.org/html/rfc6749#section-3.3,
 * @param {string} parameters.state,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
  * @param {Object} options
  * Options to override Ky request configuration
  * @return {Object}
  * The HTTP response
  */
async function getOAuth2Authorize(
  {
    responseType,
    clientId,
    redirectUri,
    scope,
    state,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOAuth2Authorize.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOAuth2Authorize.Output> {
  const httpRequest = buildGetOAuth2AuthorizeInput(
    {
      responseType,
      clientId,
      redirectUri,
      scope,
      state,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOAuth2Authorize.Output;
}

/**
 * Build the "getOrders" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {array} [parameters.orderIdsToBypass]
 * Order identifiers that bypass the checks,
 * @param {string} parameters.status
 * Status of orders to retrieve,
 * @param {boolean} parameters.updateOrders
 * If orders need to be updated or not,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrdersURI(
  {
    orderIdsToBypass,
    status,
    updateOrders,
    accessToken,
  }: Pick<
    SencropTypes.GetOrders.Input,
    "orderIdsToBypass" | "status" | "updateOrders" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (status == null) {
    throw new Error("Missing required parameter : status. Value : " + status);
  }
  if (updateOrders == null) {
    throw new Error(
      "Missing required parameter : updateOrders. Value : " + updateOrders
    );
  }

  const __pathParts = ["orders"];
  const __qs = cleanQuery({
    orderIdsToBypass: orderIdsToBypass,
    status: status,
    updateOrders: updateOrders,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrders" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {array} [parameters.orderIdsToBypass]
 * Order identifiers that bypass the checks,
 * @param {string} parameters.status
 * Status of orders to retrieve,
 * @param {boolean} parameters.updateOrders
 * If orders need to be updated or not,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrdersInput(
  {
    orderIdsToBypass,
    status,
    updateOrders,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrders.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrders;
  const __uriData = buildGetOrdersURI(
    {
      orderIdsToBypass,
      status,
      updateOrders,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get orders (from Chargebee and manual) and update them (status from queued to awaiting_shipment)
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {array} [parameters.orderIdsToBypass]
 * Order identifiers that bypass the checks,
 * @param {string} parameters.status
 * Status of orders to retrieve,
 * @param {boolean} parameters.updateOrders
 * If orders need to be updated or not,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrders(
  {
    orderIdsToBypass,
    status,
    updateOrders,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrders.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrders.Output> {
  const httpRequest = buildGetOrdersInput(
    {
      orderIdsToBypass,
      status,
      updateOrders,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrders.Output;
}

/**
 * Build the "postOrder" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostOrderURI(
  { accessToken }: Pick<SencropTypes.PostOrder.Input, "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["orders"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postOrder" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostOrderInput(
  {
    body,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrder.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostOrder.Input["body"]> {
  const __method = APIMethods.postOrder;
  const __uriData = buildPostOrderURI(
    {
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Creates a new manual order.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postOrder(
  {
    body,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrder.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostOrder.Output> {
  const httpRequest = buildPostOrderInput(
    {
      body,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostOrder.Output;
}

/**
 * Build the "getOrdersAsXLSX" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {array} [parameters.orderIdsToBypass]
 * Order identifiers that bypass the checks,
 * @param {string} parameters.status
 * Status of orders to retrieve,
 * @param {boolean} parameters.updateOrders
 * If orders need to be updated or not,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrdersAsXLSXURI(
  {
    orderIdsToBypass,
    status,
    updateOrders,
    accessToken,
  }: Pick<
    SencropTypes.GetOrdersAsXLSX.Input,
    "orderIdsToBypass" | "status" | "updateOrders" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (status == null) {
    throw new Error("Missing required parameter : status. Value : " + status);
  }
  if (updateOrders == null) {
    throw new Error(
      "Missing required parameter : updateOrders. Value : " + updateOrders
    );
  }

  const __pathParts = ["ordersAsXLSX"];
  const __qs = cleanQuery({
    orderIdsToBypass: orderIdsToBypass,
    status: status,
    updateOrders: updateOrders,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrdersAsXLSX" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {array} [parameters.orderIdsToBypass]
 * Order identifiers that bypass the checks,
 * @param {string} parameters.status
 * Status of orders to retrieve,
 * @param {boolean} parameters.updateOrders
 * If orders need to be updated or not,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrdersAsXLSXInput(
  {
    orderIdsToBypass,
    status,
    updateOrders,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrdersAsXLSX.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrdersAsXLSX;
  const __uriData = buildGetOrdersAsXLSXURI(
    {
      orderIdsToBypass,
      status,
      updateOrders,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get orders (from Chargebee and manual), send file to S3 and update them (status from queued to awaiting_shipment)
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {array} [parameters.orderIdsToBypass]
 * Order identifiers that bypass the checks,
 * @param {string} parameters.status
 * Status of orders to retrieve,
 * @param {boolean} parameters.updateOrders
 * If orders need to be updated or not,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrdersAsXLSX(
  {
    orderIdsToBypass,
    status,
    updateOrders,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrdersAsXLSX.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrdersAsXLSX.Output> {
  const httpRequest = buildGetOrdersAsXLSXInput(
    {
      orderIdsToBypass,
      status,
      updateOrders,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrdersAsXLSX.Output;
}

/**
 * Build the "getOrdersByUserEmail" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.email
 * Email of the customer,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrdersByUserEmailURI(
  {
    email,
    accessToken,
  }: Pick<SencropTypes.GetOrdersByUserEmail.Input, "email" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (email == null) {
    throw new Error("Missing required parameter : email. Value : " + email);
  }

  const __pathParts = ["users", "orders"];
  const __qs = cleanQuery({
    email: email,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrdersByUserEmail" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.email
 * Email of the customer,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrdersByUserEmailInput(
  {
    email,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrdersByUserEmail.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrdersByUserEmail;
  const __uriData = buildGetOrdersByUserEmailURI(
    {
      email,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get orders (chargebee + manual) for a specific user email
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.email
 * Email of the customer,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrdersByUserEmail(
  {
    email,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrdersByUserEmail.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrdersByUserEmail.Output> {
  const httpRequest = buildGetOrdersByUserEmailInput(
    {
      email,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrdersByUserEmail.Output;
}

/**
 * Build the "getOrganisation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationURI(
  {
    organisationId,
    accessToken,
  }: Pick<SencropTypes.GetOrganisation.Input, "organisationId" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = ["organisations", organisationId];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationInput(
  {
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisation;
  const __uriData = buildGetOrganisationURI(
    {
      organisationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get the organisation.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisation(
  {
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisation.Output> {
  const httpRequest = buildGetOrganisationInput(
    {
      organisationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisation.Output;
}

/**
 * Build the "putOrganisation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutOrganisationURI(
  {
    organisationId,
    accessToken,
  }: Pick<SencropTypes.PutOrganisation.Input, "organisationId" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = ["organisations", organisationId];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putOrganisation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutOrganisationInput(
  {
    body,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutOrganisation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutOrganisation.Input["body"]> {
  const __method = APIMethods.putOrganisation;
  const __uriData = buildPutOrganisationURI(
    {
      organisationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Update an organisation.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putOrganisation(
  {
    body,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutOrganisation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutOrganisation.Output> {
  const httpRequest = buildPutOrganisationInput(
    {
      body,
      organisationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutOrganisation.Output;
}

/**
 * Build the "getOrganisationChargebeePortalSession" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationChargebeePortalSessionURI(
  {
    organisationId,
    accessToken,
  }: Pick<
    SencropTypes.GetOrganisationChargebeePortalSession.Input,
    "organisationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = [
    "organisations",
    organisationId,
    "chargebee",
    "portal_sessions",
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisationChargebeePortalSession" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationChargebeePortalSessionInput(
  {
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationChargebeePortalSession.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisationChargebeePortalSession;
  const __uriData = buildGetOrganisationChargebeePortalSessionURI(
    {
      organisationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Create a chargebee portal session
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisationChargebeePortalSession(
  {
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationChargebeePortalSession.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisationChargebeePortalSession.Output> {
  const httpRequest = buildGetOrganisationChargebeePortalSessionInput(
    {
      organisationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisationChargebeePortalSession.Output;
}

/**
 * Build the "getOrganisationDevices" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationDevicesURI(
  {
    organisationId,
    limit,
    start,
    accessToken,
  }: Pick<
    SencropTypes.GetOrganisationDevices.Input,
    "organisationId" | "limit" | "start" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (limit == null) {
    throw new Error("Missing required parameter : limit. Value : " + limit);
  }
  if (start == null) {
    throw new Error("Missing required parameter : start. Value : " + start);
  }

  const __pathParts = ["organisations", organisationId, "devices"];
  const __qs = cleanQuery({
    limit: limit,
    start: start,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisationDevices" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationDevicesInput(
  {
    organisationId,
    limit,
    start,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationDevices.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisationDevices;
  const __uriData = buildGetOrganisationDevicesURI(
    {
      organisationId,
      limit,
      start,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a current organisation's devices.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisationDevices(
  {
    organisationId,
    limit,
    start,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationDevices.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisationDevices.Output> {
  const httpRequest = buildGetOrganisationDevicesInput(
    {
      organisationId,
      limit,
      start,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisationDevices.Output;
}

/**
 * Build the "getOrganisationDevicesLiveAggregations" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {array} parameters.devicesIds
 * List of id's of devices,
 * @param {array} parameters.aggregations
 * The aggregations names,
 * @param {array} [parameters.parameters]
 * The params to set,
 * @param {array} parameters.intervals
 * The intervals to retrieve,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} [parameters.date]
 * The status date,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationDevicesLiveAggregationsURI(
  {
    organisationId,
    devicesIds,
    aggregations,
    parameters,
    intervals,
    timeZone,
    date,
    patched,
    accessToken,
  }: Pick<
    SencropTypes.GetOrganisationDevicesLiveAggregations.Input,
    | "organisationId"
    | "devicesIds"
    | "aggregations"
    | "parameters"
    | "intervals"
    | "timeZone"
    | "date"
    | "patched"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (devicesIds == null) {
    throw new Error(
      "Missing required parameter : devicesIds. Value : " + devicesIds
    );
  }
  if (aggregations == null) {
    throw new Error(
      "Missing required parameter : aggregations. Value : " + aggregations
    );
  }
  if (intervals == null) {
    throw new Error(
      "Missing required parameter : intervals. Value : " + intervals
    );
  }

  const __pathParts = [
    "organisations",
    organisationId,
    "devices",
    "liveAggregations",
  ];
  const __qs = cleanQuery({
    devicesIds: devicesIds,
    aggregations: aggregations,
    parameters: parameters,
    intervals: intervals,
    timeZone: timeZone,
    date: date,
    patched: patched,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisationDevicesLiveAggregations" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {array} parameters.devicesIds
 * List of id's of devices,
 * @param {array} parameters.aggregations
 * The aggregations names,
 * @param {array} [parameters.parameters]
 * The params to set,
 * @param {array} parameters.intervals
 * The intervals to retrieve,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} [parameters.date]
 * The status date,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationDevicesLiveAggregationsInput(
  {
    organisationId,
    devicesIds,
    aggregations,
    parameters,
    intervals,
    timeZone,
    date,
    patched,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationDevicesLiveAggregations.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisationDevicesLiveAggregations;
  const __uriData = buildGetOrganisationDevicesLiveAggregationsURI(
    {
      organisationId,
      devicesIds,
      aggregations,
      parameters,
      intervals,
      timeZone,
      date,
      patched,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Allow to run live aggregation queries against an organisation's devices.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {array} parameters.devicesIds
 * List of id's of devices,
 * @param {array} parameters.aggregations
 * The aggregations names,
 * @param {array} [parameters.parameters]
 * The params to set,
 * @param {array} parameters.intervals
 * The intervals to retrieve,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} [parameters.date]
 * The status date,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisationDevicesLiveAggregations(
  {
    organisationId,
    devicesIds,
    aggregations,
    parameters,
    intervals,
    timeZone,
    date,
    patched,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationDevicesLiveAggregations.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisationDevicesLiveAggregations.Output> {
  const httpRequest = buildGetOrganisationDevicesLiveAggregationsInput(
    {
      organisationId,
      devicesIds,
      aggregations,
      parameters,
      intervals,
      timeZone,
      date,
      patched,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisationDevicesLiveAggregations.Output;
}

/**
 * Build the "getOrganisationHostedPage" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.redirectUrl]
 * Specifies the callback url for chargebee hosted page,
 * @param {string} parameters.planId
 * Plan id related to chargebee plans,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationHostedPageURI(
  {
    organisationId,
    redirectUrl,
    planId,
    accessToken,
  }: Pick<
    SencropTypes.GetOrganisationHostedPage.Input,
    "organisationId" | "redirectUrl" | "planId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (planId == null) {
    throw new Error("Missing required parameter : planId. Value : " + planId);
  }

  const __pathParts = ["organisations", organisationId, "hostedPage"];
  const __qs = cleanQuery({
    redirectUrl: redirectUrl,
    planId: planId,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisationHostedPage" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.redirectUrl]
 * Specifies the callback url for chargebee hosted page,
 * @param {string} parameters.planId
 * Plan id related to chargebee plans,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationHostedPageInput(
  {
    organisationId,
    redirectUrl,
    planId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationHostedPage.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisationHostedPage;
  const __uriData = buildGetOrganisationHostedPageURI(
    {
      organisationId,
      redirectUrl,
      planId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Create a chargebee hosted page for an organisation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.redirectUrl]
 * Specifies the callback url for chargebee hosted page,
 * @param {string} parameters.planId
 * Plan id related to chargebee plans,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisationHostedPage(
  {
    organisationId,
    redirectUrl,
    planId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationHostedPage.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisationHostedPage.Output> {
  const httpRequest = buildGetOrganisationHostedPageInput(
    {
      organisationId,
      redirectUrl,
      planId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisationHostedPage.Output;
}

/**
 * Build the "getOrganisationHostedPageBattery" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.redirectUrl]
 * Specifies the callback url for chargebee hosted page,
 * @param {number} [parameters.raincrop]
 * Number of raincrop battery to order,
 * @param {number} [parameters.windcrop]
 * Number of windcrop battery to order,
 * @param {number} [parameters.leafcrop]
 * Number of leafcrop battery to order,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationHostedPageBatteryURI(
  {
    organisationId,
    redirectUrl,
    raincrop,
    windcrop,
    leafcrop,
    accessToken,
  }: Pick<
    SencropTypes.GetOrganisationHostedPageBattery.Input,
    | "organisationId"
    | "redirectUrl"
    | "raincrop"
    | "windcrop"
    | "leafcrop"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = ["organisations", organisationId, "hostedPageBattery"];
  const __qs = cleanQuery({
    redirectUrl: redirectUrl,
    raincrop: raincrop,
    windcrop: windcrop,
    leafcrop: leafcrop,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisationHostedPageBattery" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.redirectUrl]
 * Specifies the callback url for chargebee hosted page,
 * @param {number} [parameters.raincrop]
 * Number of raincrop battery to order,
 * @param {number} [parameters.windcrop]
 * Number of windcrop battery to order,
 * @param {number} [parameters.leafcrop]
 * Number of leafcrop battery to order,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationHostedPageBatteryInput(
  {
    organisationId,
    redirectUrl,
    raincrop,
    windcrop,
    leafcrop,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationHostedPageBattery.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisationHostedPageBattery;
  const __uriData = buildGetOrganisationHostedPageBatteryURI(
    {
      organisationId,
      redirectUrl,
      raincrop,
      windcrop,
      leafcrop,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Create a chargebee hosted page for an organisation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.redirectUrl]
 * Specifies the callback url for chargebee hosted page,
 * @param {number} [parameters.raincrop]
 * Number of raincrop battery to order,
 * @param {number} [parameters.windcrop]
 * Number of windcrop battery to order,
 * @param {number} [parameters.leafcrop]
 * Number of leafcrop battery to order,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisationHostedPageBattery(
  {
    organisationId,
    redirectUrl,
    raincrop,
    windcrop,
    leafcrop,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationHostedPageBattery.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisationHostedPageBattery.Output> {
  const httpRequest = buildGetOrganisationHostedPageBatteryInput(
    {
      organisationId,
      redirectUrl,
      raincrop,
      windcrop,
      leafcrop,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisationHostedPageBattery.Output;
}

/**
 * Build the "getOrganisationHostedPageUpgradePlanPlus" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.redirectUrl]
 * Specifies the callback url for chargebee hosted page,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationHostedPageUpgradePlanPlusURI(
  {
    organisationId,
    redirectUrl,
    accessToken,
  }: Pick<
    SencropTypes.GetOrganisationHostedPageUpgradePlanPlus.Input,
    "organisationId" | "redirectUrl" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = [
    "organisations",
    organisationId,
    "upgradeHostedPagePlanPlus",
  ];
  const __qs = cleanQuery({
    redirectUrl: redirectUrl,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisationHostedPageUpgradePlanPlus" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.redirectUrl]
 * Specifies the callback url for chargebee hosted page,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationHostedPageUpgradePlanPlusInput(
  {
    organisationId,
    redirectUrl,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationHostedPageUpgradePlanPlus.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisationHostedPageUpgradePlanPlus;
  const __uriData = buildGetOrganisationHostedPageUpgradePlanPlusURI(
    {
      organisationId,
      redirectUrl,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Create a chargebee hosted page for an organisation to upgrade to plan plus
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.redirectUrl]
 * Specifies the callback url for chargebee hosted page,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisationHostedPageUpgradePlanPlus(
  {
    organisationId,
    redirectUrl,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationHostedPageUpgradePlanPlus.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisationHostedPageUpgradePlanPlus.Output> {
  const httpRequest = buildGetOrganisationHostedPageUpgradePlanPlusInput(
    {
      organisationId,
      redirectUrl,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisationHostedPageUpgradePlanPlus.Output;
}

/**
 * Build the "getOrganisationInvitation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationInvitationURI(
  {
    organisationId,
    invitationId,
    accessToken,
  }: Pick<
    SencropTypes.GetOrganisationInvitation.Input,
    "organisationId" | "invitationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (invitationId == null) {
    throw new Error(
      "Missing required parameter : invitationId. Value : " + invitationId
    );
  }

  const __pathParts = [
    "organisations",
    organisationId,
    "invitations",
    invitationId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisationInvitation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationInvitationInput(
  {
    organisationId,
    invitationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationInvitation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisationInvitation;
  const __uriData = buildGetOrganisationInvitationURI(
    {
      organisationId,
      invitationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's invitation.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisationInvitation(
  {
    organisationId,
    invitationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationInvitation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisationInvitation.Output> {
  const httpRequest = buildGetOrganisationInvitationInput(
    {
      organisationId,
      invitationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisationInvitation.Output;
}

/**
 * Build the "getOrganisationInvitations" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {array} [parameters.withTypes],
 * @param {array} [parameters.withStatuses],
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationInvitationsURI(
  {
    organisationId,
    limit,
    start,
    withTypes,
    withStatuses,
    accessToken,
  }: Pick<
    SencropTypes.GetOrganisationInvitations.Input,
    | "organisationId"
    | "limit"
    | "start"
    | "withTypes"
    | "withStatuses"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (limit == null) {
    throw new Error("Missing required parameter : limit. Value : " + limit);
  }
  if (start == null) {
    throw new Error("Missing required parameter : start. Value : " + start);
  }

  const __pathParts = ["organisations", organisationId, "invitations"];
  const __qs = cleanQuery({
    limit: limit,
    start: start,
    withTypes: withTypes,
    withStatuses: withStatuses,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisationInvitations" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {array} [parameters.withTypes],
 * @param {array} [parameters.withStatuses],
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationInvitationsInput(
  {
    organisationId,
    limit,
    start,
    withTypes,
    withStatuses,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationInvitations.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisationInvitations;
  const __uriData = buildGetOrganisationInvitationsURI(
    {
      organisationId,
      limit,
      start,
      withTypes,
      withStatuses,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get an organisation's invitations.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {array} [parameters.withTypes],
 * @param {array} [parameters.withStatuses],
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisationInvitations(
  {
    organisationId,
    limit,
    start,
    withTypes,
    withStatuses,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationInvitations.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisationInvitations.Output> {
  const httpRequest = buildGetOrganisationInvitationsInput(
    {
      organisationId,
      limit,
      start,
      withTypes,
      withStatuses,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisationInvitations.Output;
}

/**
 * Build the "getOrganisationMember" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.memberId
 * The member id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationMemberURI(
  {
    organisationId,
    memberId,
    accessToken,
  }: Pick<
    SencropTypes.GetOrganisationMember.Input,
    "organisationId" | "memberId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (memberId == null) {
    throw new Error(
      "Missing required parameter : memberId. Value : " + memberId
    );
  }

  const __pathParts = ["organisations", organisationId, "members", memberId];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisationMember" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.memberId
 * The member id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationMemberInput(
  {
    organisationId,
    memberId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationMember.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisationMember;
  const __uriData = buildGetOrganisationMemberURI(
    {
      organisationId,
      memberId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Retrieve all members from an organisation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.memberId
 * The member id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisationMember(
  {
    organisationId,
    memberId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationMember.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisationMember.Output> {
  const httpRequest = buildGetOrganisationMemberInput(
    {
      organisationId,
      memberId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisationMember.Output;
}

/**
 * Build the "getOrganisationMembers" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationMembersURI(
  {
    organisationId,
    accessToken,
  }: Pick<
    SencropTypes.GetOrganisationMembers.Input,
    "organisationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = ["organisations", organisationId, "members"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisationMembers" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationMembersInput(
  {
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationMembers.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisationMembers;
  const __uriData = buildGetOrganisationMembersURI(
    {
      organisationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Retrieve all members from an organisation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisationMembers(
  {
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationMembers.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisationMembers.Output> {
  const httpRequest = buildGetOrganisationMembersInput(
    {
      organisationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisationMembers.Output;
}

/**
 * Build the "getOrganisationNetworkDevices" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.networkId
 * The network id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {string} [parameters.geohash]
 * The geohash to lookup,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationNetworkDevicesURI(
  {
    organisationId,
    networkId,
    limit,
    start,
    geohash,
    accessToken,
  }: Pick<
    SencropTypes.GetOrganisationNetworkDevices.Input,
    | "organisationId"
    | "networkId"
    | "limit"
    | "start"
    | "geohash"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (networkId == null) {
    throw new Error(
      "Missing required parameter : networkId. Value : " + networkId
    );
  }
  if (limit == null) {
    throw new Error("Missing required parameter : limit. Value : " + limit);
  }
  if (start == null) {
    throw new Error("Missing required parameter : start. Value : " + start);
  }

  const __pathParts = [
    "organisations",
    organisationId,
    "networks",
    networkId,
    "devices",
  ];
  const __qs = cleanQuery({
    limit: limit,
    start: start,
    geohash: geohash,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisationNetworkDevices" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.networkId
 * The network id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {string} [parameters.geohash]
 * The geohash to lookup,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationNetworkDevicesInput(
  {
    organisationId,
    networkId,
    limit,
    start,
    geohash,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationNetworkDevices.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisationNetworkDevices;
  const __uriData = buildGetOrganisationNetworkDevicesURI(
    {
      organisationId,
      networkId,
      limit,
      start,
      geohash,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get an organisation network.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.networkId
 * The network id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {string} [parameters.geohash]
 * The geohash to lookup,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisationNetworkDevices(
  {
    organisationId,
    networkId,
    limit,
    start,
    geohash,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationNetworkDevices.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisationNetworkDevices.Output> {
  const httpRequest = buildGetOrganisationNetworkDevicesInput(
    {
      organisationId,
      networkId,
      limit,
      start,
      geohash,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisationNetworkDevices.Output;
}

/**
 * Build the "getOrganisationNetworks" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationNetworksURI(
  {
    organisationId,
    limit,
    start,
    accessToken,
  }: Pick<
    SencropTypes.GetOrganisationNetworks.Input,
    "organisationId" | "limit" | "start" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (limit == null) {
    throw new Error("Missing required parameter : limit. Value : " + limit);
  }
  if (start == null) {
    throw new Error("Missing required parameter : start. Value : " + start);
  }

  const __pathParts = ["organisations", organisationId, "networks"];
  const __qs = cleanQuery({
    limit: limit,
    start: start,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisationNetworks" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationNetworksInput(
  {
    organisationId,
    limit,
    start,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationNetworks.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisationNetworks;
  const __uriData = buildGetOrganisationNetworksURI(
    {
      organisationId,
      limit,
      start,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get the networks an organisation has access to.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisationNetworks(
  {
    organisationId,
    limit,
    start,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationNetworks.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisationNetworks.Output> {
  const httpRequest = buildGetOrganisationNetworksInput(
    {
      organisationId,
      limit,
      start,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisationNetworks.Output;
}

/**
 * Build the "getOrganisationPlace" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.placeId
 * The place id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationPlaceURI(
  {
    organisationId,
    placeId,
    accessToken,
  }: Pick<
    SencropTypes.GetOrganisationPlace.Input,
    "organisationId" | "placeId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (placeId == null) {
    throw new Error("Missing required parameter : placeId. Value : " + placeId);
  }

  const __pathParts = ["organisations", organisationId, "place", placeId];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisationPlace" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.placeId
 * The place id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationPlaceInput(
  {
    organisationId,
    placeId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationPlace.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisationPlace;
  const __uriData = buildGetOrganisationPlaceURI(
    {
      organisationId,
      placeId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a specific place for a organisation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.placeId
 * The place id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisationPlace(
  {
    organisationId,
    placeId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationPlace.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisationPlace.Output> {
  const httpRequest = buildGetOrganisationPlaceInput(
    {
      organisationId,
      placeId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisationPlace.Output;
}

/**
 * Build the "getOrganisationPlaces" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationPlacesURI(
  {
    organisationId,
    accessToken,
  }: Pick<
    SencropTypes.GetOrganisationPlaces.Input,
    "organisationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = ["organisations", organisationId, "places"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisationPlaces" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationPlacesInput(
  {
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationPlaces.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisationPlaces;
  const __uriData = buildGetOrganisationPlacesURI(
    {
      organisationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get all places for an organisation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisationPlaces(
  {
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationPlaces.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisationPlaces.Output> {
  const httpRequest = buildGetOrganisationPlacesInput(
    {
      organisationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisationPlaces.Output;
}

/**
 * Build the "postOrganisationPlace" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostOrganisationPlaceURI(
  {
    organisationId,
    accessToken,
  }: Pick<
    SencropTypes.PostOrganisationPlace.Input,
    "organisationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = ["organisations", organisationId, "places"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postOrganisationPlace" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostOrganisationPlaceInput(
  {
    body,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisationPlace.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostOrganisationPlace.Input["body"]> {
  const __method = APIMethods.postOrganisationPlace;
  const __uriData = buildPostOrganisationPlaceURI(
    {
      organisationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Create a new place for an organisation.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postOrganisationPlace(
  {
    body,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisationPlace.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostOrganisationPlace.Output> {
  const httpRequest = buildPostOrganisationPlaceInput(
    {
      body,
      organisationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostOrganisationPlace.Output;
}

/**
 * Build the "getOrganisationPreferences" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationPreferencesURI(
  {
    organisationId,
    accessToken,
  }: Pick<
    SencropTypes.GetOrganisationPreferences.Input,
    "organisationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = ["organisations", organisationId, "preferences"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisationPreferences" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationPreferencesInput(
  {
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationPreferences.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisationPreferences;
  const __uriData = buildGetOrganisationPreferencesURI(
    {
      organisationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get an organisation's preferences.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisationPreferences(
  {
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationPreferences.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisationPreferences.Output> {
  const httpRequest = buildGetOrganisationPreferencesInput(
    {
      organisationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisationPreferences.Output;
}

/**
 * Build the "putOrganisationPreferences" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutOrganisationPreferencesURI(
  {
    organisationId,
    accessToken,
  }: Pick<
    SencropTypes.PutOrganisationPreferences.Input,
    "organisationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = ["organisations", organisationId, "preferences"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putOrganisationPreferences" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutOrganisationPreferencesInput(
  {
    body,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutOrganisationPreferences.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutOrganisationPreferences.Input["body"]> {
  const __method = APIMethods.putOrganisationPreferences;
  const __uriData = buildPutOrganisationPreferencesURI(
    {
      organisationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Update a organisation's preferences.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putOrganisationPreferences(
  {
    body,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutOrganisationPreferences.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutOrganisationPreferences.Output> {
  const httpRequest = buildPutOrganisationPreferencesInput(
    {
      body,
      organisationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutOrganisationPreferences.Output;
}

/**
 * Build the "getOrganisationShare" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.shareId
 * The share id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationShareURI(
  {
    organisationId,
    shareId,
    accessToken,
  }: Pick<
    SencropTypes.GetOrganisationShare.Input,
    "organisationId" | "shareId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (shareId == null) {
    throw new Error("Missing required parameter : shareId. Value : " + shareId);
  }

  const __pathParts = ["organisations", organisationId, "shares", shareId];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisationShare" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.shareId
 * The share id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationShareInput(
  {
    organisationId,
    shareId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationShare.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisationShare;
  const __uriData = buildGetOrganisationShareURI(
    {
      organisationId,
      shareId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get an organisation's shares.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} parameters.shareId
 * The share id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisationShare(
  {
    organisationId,
    shareId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationShare.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisationShare.Output> {
  const httpRequest = buildGetOrganisationShareInput(
    {
      organisationId,
      shareId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisationShare.Output;
}

/**
 * Build the "getOrganisationShares" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {array} [parameters.withTypes],
 * @param {boolean} [parameters.isTransmittable],
 * @param {array} [parameters.withModes],
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationSharesURI(
  {
    organisationId,
    limit,
    start,
    withTypes,
    isTransmittable,
    withModes,
    accessToken,
  }: Pick<
    SencropTypes.GetOrganisationShares.Input,
    | "organisationId"
    | "limit"
    | "start"
    | "withTypes"
    | "isTransmittable"
    | "withModes"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (limit == null) {
    throw new Error("Missing required parameter : limit. Value : " + limit);
  }
  if (start == null) {
    throw new Error("Missing required parameter : start. Value : " + start);
  }

  const __pathParts = ["organisations", organisationId, "shares"];
  const __qs = cleanQuery({
    limit: limit,
    start: start,
    withTypes: withTypes,
    isTransmittable: isTransmittable,
    withModes: withModes,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisationShares" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {array} [parameters.withTypes],
 * @param {boolean} [parameters.isTransmittable],
 * @param {array} [parameters.withModes],
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationSharesInput(
  {
    organisationId,
    limit,
    start,
    withTypes,
    isTransmittable,
    withModes,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationShares.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisationShares;
  const __uriData = buildGetOrganisationSharesURI(
    {
      organisationId,
      limit,
      start,
      withTypes,
      isTransmittable,
      withModes,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get an organisation's shares.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {array} [parameters.withTypes],
 * @param {boolean} [parameters.isTransmittable],
 * @param {array} [parameters.withModes],
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisationShares(
  {
    organisationId,
    limit,
    start,
    withTypes,
    isTransmittable,
    withModes,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationShares.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisationShares.Output> {
  const httpRequest = buildGetOrganisationSharesInput(
    {
      organisationId,
      limit,
      start,
      withTypes,
      isTransmittable,
      withModes,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisationShares.Output;
}

/**
 * Build the "getOrganisationsTrialOperations" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {boolean} [parameters.active]
 * If true, returns only active trial operations (not ended and not expired),
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetOrganisationsTrialOperationsURI(
  {
    organisationId,
    active,
    accessToken,
  }: Pick<
    SencropTypes.GetOrganisationsTrialOperations.Input,
    "organisationId" | "active" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = ["organisations", organisationId, "trialOperations"];
  const __qs = cleanQuery({
    active: active,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getOrganisationsTrialOperations" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {boolean} [parameters.active]
 * If true, returns only active trial operations (not ended and not expired),
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetOrganisationsTrialOperationsInput(
  {
    organisationId,
    active,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationsTrialOperations.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getOrganisationsTrialOperations;
  const __uriData = buildGetOrganisationsTrialOperationsURI(
    {
      organisationId,
      active,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get an organisation's trial operation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {boolean} [parameters.active]
 * If true, returns only active trial operations (not ended and not expired),
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getOrganisationsTrialOperations(
  {
    organisationId,
    active,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetOrganisationsTrialOperations.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetOrganisationsTrialOperations.Output> {
  const httpRequest = buildGetOrganisationsTrialOperationsInput(
    {
      organisationId,
      active,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetOrganisationsTrialOperations.Output;
}

/**
 * Build the "getPartnerDevices" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetPartnerDevicesURI(
  {
    partnerId,
    limit,
    start,
    accessToken,
  }: Pick<
    SencropTypes.GetPartnerDevices.Input,
    "partnerId" | "limit" | "start" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (partnerId == null) {
    throw new Error(
      "Missing required parameter : partnerId. Value : " + partnerId
    );
  }
  if (limit == null) {
    throw new Error("Missing required parameter : limit. Value : " + limit);
  }
  if (start == null) {
    throw new Error("Missing required parameter : start. Value : " + start);
  }

  const __pathParts = ["partners", partnerId, "devices"];
  const __qs = cleanQuery({
    limit: limit,
    start: start,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getPartnerDevices" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetPartnerDevicesInput(
  {
    partnerId,
    limit,
    start,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetPartnerDevices.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getPartnerDevices;
  const __uriData = buildGetPartnerDevicesURI(
    {
      partnerId,
      limit,
      start,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Retrieves the devices a partner has access to.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getPartnerDevices(
  {
    partnerId,
    limit,
    start,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetPartnerDevices.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetPartnerDevices.Output> {
  const httpRequest = buildGetPartnerDevicesInput(
    {
      partnerId,
      limit,
      start,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetPartnerDevices.Output;
}

/**
 * Build the "getPing" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetPingURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["ping"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getPing" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetPingInput(
  { xApiVersion, xSdkVersion, xAppVersion }: SencropTypes.GetPing.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getPing;
  const __uriData = buildGetPingURI({}, { baseURL: __baseURL });

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Checks API's availability.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getPing(
  { xApiVersion, xSdkVersion, xAppVersion }: SencropTypes.GetPing.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetPing.Output> {
  const httpRequest = buildGetPingInput(
    {
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetPing.Output;
}

/**
 * Build the "getPlans" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.countryCode
 * The country code,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetPlansURI(
  {
    countryCode,
    accessToken,
  }: Pick<SencropTypes.GetPlans.Input, "countryCode" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (countryCode == null) {
    throw new Error(
      "Missing required parameter : countryCode. Value : " + countryCode
    );
  }

  const __pathParts = ["plans"];
  const __qs = cleanQuery({
    countryCode: countryCode,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getPlans" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.countryCode
 * The country code,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetPlansInput(
  {
    countryCode,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetPlans.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getPlans;
  const __uriData = buildGetPlansURI(
    {
      countryCode,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Retrieve available chargebee plans for a specific country code
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.countryCode
 * The country code,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getPlans(
  {
    countryCode,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetPlans.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetPlans.Output> {
  const httpRequest = buildGetPlansInput(
    {
      countryCode,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetPlans.Output;
}

/**
 * Build the "getPublicWeatherLive" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.topLeftBoxX
 * X coordinate of the top left corner of the map,
 * @param {number} parameters.topLeftBoxY
 * Y coordinate of the top left corner of the map,
 * @param {number} parameters.bottomRightBoxX
 * X coordinate of the bottom right corner of the map,
 * @param {number} parameters.bottomRightBoxY
 * Y coordinate of the bottom right corner of the map,
 * @param {string} parameters.measureType
 * The type of measure to retrieve,
 * @param {string} parameters.aggregationType
 * The type of aggregation,
 * @param {number} parameters.precision
 * The precision of the map,
 * @param {string} [parameters.timeFrame]
 * The aggregatable measure,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetPublicWeatherLiveURI(
  {
    topLeftBoxX,
    topLeftBoxY,
    bottomRightBoxX,
    bottomRightBoxY,
    measureType,
    aggregationType,
    precision,
    timeFrame,
    accessToken,
  }: Pick<
    SencropTypes.GetPublicWeatherLive.Input,
    | "topLeftBoxX"
    | "topLeftBoxY"
    | "bottomRightBoxX"
    | "bottomRightBoxY"
    | "measureType"
    | "aggregationType"
    | "precision"
    | "timeFrame"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (topLeftBoxX == null) {
    throw new Error(
      "Missing required parameter : topLeftBoxX. Value : " + topLeftBoxX
    );
  }
  if (topLeftBoxY == null) {
    throw new Error(
      "Missing required parameter : topLeftBoxY. Value : " + topLeftBoxY
    );
  }
  if (bottomRightBoxX == null) {
    throw new Error(
      "Missing required parameter : bottomRightBoxX. Value : " + bottomRightBoxX
    );
  }
  if (bottomRightBoxY == null) {
    throw new Error(
      "Missing required parameter : bottomRightBoxY. Value : " + bottomRightBoxY
    );
  }
  if (measureType == null) {
    throw new Error(
      "Missing required parameter : measureType. Value : " + measureType
    );
  }
  if (aggregationType == null) {
    throw new Error(
      "Missing required parameter : aggregationType. Value : " + aggregationType
    );
  }
  if (precision == null) {
    throw new Error(
      "Missing required parameter : precision. Value : " + precision
    );
  }

  const __pathParts = ["weather", "public", "live"];
  const __qs = cleanQuery({
    topLeftBoxX: topLeftBoxX,
    topLeftBoxY: topLeftBoxY,
    bottomRightBoxX: bottomRightBoxX,
    bottomRightBoxY: bottomRightBoxY,
    measureType: measureType,
    aggregationType: aggregationType,
    precision: precision,
    timeFrame: timeFrame,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getPublicWeatherLive" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.topLeftBoxX
 * X coordinate of the top left corner of the map,
 * @param {number} parameters.topLeftBoxY
 * Y coordinate of the top left corner of the map,
 * @param {number} parameters.bottomRightBoxX
 * X coordinate of the bottom right corner of the map,
 * @param {number} parameters.bottomRightBoxY
 * Y coordinate of the bottom right corner of the map,
 * @param {string} parameters.measureType
 * The type of measure to retrieve,
 * @param {string} parameters.aggregationType
 * The type of aggregation,
 * @param {number} parameters.precision
 * The precision of the map,
 * @param {string} [parameters.timeFrame]
 * The aggregatable measure,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetPublicWeatherLiveInput(
  {
    topLeftBoxX,
    topLeftBoxY,
    bottomRightBoxX,
    bottomRightBoxY,
    measureType,
    aggregationType,
    precision,
    timeFrame,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetPublicWeatherLive.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getPublicWeatherLive;
  const __uriData = buildGetPublicWeatherLiveURI(
    {
      topLeftBoxX,
      topLeftBoxY,
      bottomRightBoxX,
      bottomRightBoxY,
      measureType,
      aggregationType,
      precision,
      timeFrame,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get last measure (for a specific type) from devices in a given area .
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.topLeftBoxX
 * X coordinate of the top left corner of the map,
 * @param {number} parameters.topLeftBoxY
 * Y coordinate of the top left corner of the map,
 * @param {number} parameters.bottomRightBoxX
 * X coordinate of the bottom right corner of the map,
 * @param {number} parameters.bottomRightBoxY
 * Y coordinate of the bottom right corner of the map,
 * @param {string} parameters.measureType
 * The type of measure to retrieve,
 * @param {string} parameters.aggregationType
 * The type of aggregation,
 * @param {number} parameters.precision
 * The precision of the map,
 * @param {string} [parameters.timeFrame]
 * The aggregatable measure,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getPublicWeatherLive(
  {
    topLeftBoxX,
    topLeftBoxY,
    bottomRightBoxX,
    bottomRightBoxY,
    measureType,
    aggregationType,
    precision,
    timeFrame,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetPublicWeatherLive.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetPublicWeatherLive.Output> {
  const httpRequest = buildGetPublicWeatherLiveInput(
    {
      topLeftBoxX,
      topLeftBoxY,
      bottomRightBoxX,
      bottomRightBoxY,
      measureType,
      aggregationType,
      precision,
      timeFrame,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetPublicWeatherLive.Output;
}

/**
 * Build the "getPublicWeatherLiveAggregations" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.geohash
 * The geohash to lookup,
 * @param {array} parameters.aggregations
 * The aggregations names,
 * @param {string} parameters.liveInterval
 * The interval to retrieve,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetPublicWeatherLiveAggregationsURI(
  {
    geohash,
    aggregations,
    liveInterval,
    timeZone,
    accessToken,
  }: Pick<
    SencropTypes.GetPublicWeatherLiveAggregations.Input,
    "geohash" | "aggregations" | "liveInterval" | "timeZone" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (geohash == null) {
    throw new Error("Missing required parameter : geohash. Value : " + geohash);
  }
  if (aggregations == null) {
    throw new Error(
      "Missing required parameter : aggregations. Value : " + aggregations
    );
  }
  if (liveInterval == null) {
    throw new Error(
      "Missing required parameter : liveInterval. Value : " + liveInterval
    );
  }

  const __pathParts = ["weather", "public", "liveAggregations"];
  const __qs = cleanQuery({
    geohash: geohash,
    aggregations: aggregations,
    liveInterval: liveInterval,
    timeZone: timeZone,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getPublicWeatherLiveAggregations" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.geohash
 * The geohash to lookup,
 * @param {array} parameters.aggregations
 * The aggregations names,
 * @param {string} parameters.liveInterval
 * The interval to retrieve,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetPublicWeatherLiveAggregationsInput(
  {
    geohash,
    aggregations,
    liveInterval,
    timeZone,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetPublicWeatherLiveAggregations.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getPublicWeatherLiveAggregations;
  const __uriData = buildGetPublicWeatherLiveAggregationsURI(
    {
      geohash,
      aggregations,
      liveInterval,
      timeZone,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get last measure (for a specifics measures and interval) from devices in a given area
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.geohash
 * The geohash to lookup,
 * @param {array} parameters.aggregations
 * The aggregations names,
 * @param {string} parameters.liveInterval
 * The interval to retrieve,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getPublicWeatherLiveAggregations(
  {
    geohash,
    aggregations,
    liveInterval,
    timeZone,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetPublicWeatherLiveAggregations.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetPublicWeatherLiveAggregations.Output> {
  const httpRequest = buildGetPublicWeatherLiveAggregationsInput(
    {
      geohash,
      aggregations,
      liveInterval,
      timeZone,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetPublicWeatherLiveAggregations.Output;
}

/**
 * Build the "getQuote" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.quoteId
 * The quote id
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetQuoteURI(
  { quoteId }: Pick<SencropTypes.GetQuote.Input, "quoteId">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (quoteId == null) {
    throw new Error("Missing required parameter : quoteId. Value : " + quoteId);
  }

  const __pathParts = ["quotes", quoteId];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getQuote" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.quoteId
 * The quote id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetQuoteInput(
  {
    quoteId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetQuote.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getQuote;
  const __uriData = buildGetQuoteURI(
    {
      quoteId,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a quote
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.quoteId
 * The quote id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getQuote(
  {
    quoteId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetQuote.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetQuote.Output> {
  const httpRequest = buildGetQuoteInput(
    {
      quoteId,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetQuote.Output;
}

/**
 * Build the "getQuoteAsPdf" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.quoteId
 * The quote id
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetQuoteAsPdfURI(
  { quoteId }: Pick<SencropTypes.GetQuoteAsPdf.Input, "quoteId">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (quoteId == null) {
    throw new Error("Missing required parameter : quoteId. Value : " + quoteId);
  }

  const __pathParts = ["quotes", quoteId, "pdf"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getQuoteAsPdf" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.quoteId
 * The quote id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetQuoteAsPdfInput(
  {
    quoteId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetQuoteAsPdf.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getQuoteAsPdf;
  const __uriData = buildGetQuoteAsPdfURI(
    {
      quoteId,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Retrieve a chargebee quote as pdf
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.quoteId
 * The quote id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getQuoteAsPdf(
  {
    quoteId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetQuoteAsPdf.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetQuoteAsPdf.Output> {
  const httpRequest = buildGetQuoteAsPdfInput(
    {
      quoteId,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetQuoteAsPdf.Output;
}

/**
 * Build the "getQuoteHostedPage" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.quoteId
 * The quote id,
 * @param {string} [parameters.tva],
 * @param {string} [parameters.paymentMethod],
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetQuoteHostedPageURI(
  {
    userId,
    quoteId,
    tva,
    paymentMethod,
    accessToken,
  }: Pick<
    SencropTypes.GetQuoteHostedPage.Input,
    "userId" | "quoteId" | "tva" | "paymentMethod" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (quoteId == null) {
    throw new Error("Missing required parameter : quoteId. Value : " + quoteId);
  }

  const __pathParts = ["users", userId, "quotes", quoteId, "hostedPage"];
  const __qs = cleanQuery({
    tva: tva,
    paymentMethod: paymentMethod,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getQuoteHostedPage" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.quoteId
 * The quote id,
 * @param {string} [parameters.tva],
 * @param {string} [parameters.paymentMethod],
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetQuoteHostedPageInput(
  {
    userId,
    quoteId,
    tva,
    paymentMethod,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetQuoteHostedPage.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getQuoteHostedPage;
  const __uriData = buildGetQuoteHostedPageURI(
    {
      userId,
      quoteId,
      tva,
      paymentMethod,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Create a chargebee hosted page for quote
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.quoteId
 * The quote id,
 * @param {string} [parameters.tva],
 * @param {string} [parameters.paymentMethod],
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getQuoteHostedPage(
  {
    userId,
    quoteId,
    tva,
    paymentMethod,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetQuoteHostedPage.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetQuoteHostedPage.Output> {
  const httpRequest = buildGetQuoteHostedPageInput(
    {
      userId,
      quoteId,
      tva,
      paymentMethod,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetQuoteHostedPage.Output;
}

/**
 * Build the "getQuoteInvoiceAsPdf" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.quoteId
 * The quote id
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetQuoteInvoiceAsPdfURI(
  { quoteId }: Pick<SencropTypes.GetQuoteInvoiceAsPdf.Input, "quoteId">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (quoteId == null) {
    throw new Error("Missing required parameter : quoteId. Value : " + quoteId);
  }

  const __pathParts = ["quotes", quoteId, "invoicePdf"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getQuoteInvoiceAsPdf" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.quoteId
 * The quote id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetQuoteInvoiceAsPdfInput(
  {
    quoteId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetQuoteInvoiceAsPdf.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getQuoteInvoiceAsPdf;
  const __uriData = buildGetQuoteInvoiceAsPdfURI(
    {
      quoteId,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Retrieve a chargebee invoice from a quote as pdf
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.quoteId
 * The quote id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getQuoteInvoiceAsPdf(
  {
    quoteId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetQuoteInvoiceAsPdf.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetQuoteInvoiceAsPdf.Output> {
  const httpRequest = buildGetQuoteInvoiceAsPdfInput(
    {
      quoteId,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetQuoteInvoiceAsPdf.Output;
}

/**
 * Build the "getRadarImage" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.type,
 * @param {string} parameters.country,
 * @param {string} parameters.date
 * The date of the radar image modulo 5 minutes UTC.,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetRadarImageURI(
  {
    type,
    country,
    date,
    accessToken,
  }: Pick<
    SencropTypes.GetRadarImage.Input,
    "type" | "country" | "date" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (type == null) {
    throw new Error("Missing required parameter : type. Value : " + type);
  }
  if (country == null) {
    throw new Error("Missing required parameter : country. Value : " + country);
  }
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["radar", type, "countries", country];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getRadarImage" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.type,
 * @param {string} parameters.country,
 * @param {string} parameters.date
 * The date of the radar image modulo 5 minutes UTC.,
 * @param {string} [parameters.accept]
 * Providing image/png avoird being redirected.,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetRadarImageInput(
  {
    type,
    country,
    date,
    accept,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetRadarImage.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getRadarImage;
  const __uriData = buildGetRadarImageURI(
    {
      type,
      country,
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        accept: accept,
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get radar image.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.type,
 * @param {string} parameters.country,
 * @param {string} parameters.date
 * The date of the radar image modulo 5 minutes UTC.,
 * @param {string} [parameters.accept]
 * Providing image/png avoird being redirected.,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getRadarImage(
  {
    type,
    country,
    date,
    accept,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetRadarImage.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetRadarImage.Output> {
  const httpRequest = buildGetRadarImageInput(
    {
      type,
      country,
      date,
      accept,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetRadarImage.Output;
}

/**
 * Build the "getSearchDevices" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetSearchDevicesURI(
  {
    latitude,
    longitude,
  }: Pick<SencropTypes.GetSearchDevices.Input, "latitude" | "longitude">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (latitude == null) {
    throw new Error(
      "Missing required parameter : latitude. Value : " + latitude
    );
  }
  if (longitude == null) {
    throw new Error(
      "Missing required parameter : longitude. Value : " + longitude
    );
  }

  const __pathParts = ["search", "devices"];
  const __qs = cleanQuery({
    latitude: latitude,
    longitude: longitude,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getSearchDevices" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetSearchDevicesInput(
  {
    latitude,
    longitude,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetSearchDevices.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getSearchDevices;
  const __uriData = buildGetSearchDevicesURI(
    {
      latitude,
      longitude,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Search devices around a geopoint (radius of 40000 meters)
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getSearchDevices(
  {
    latitude,
    longitude,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetSearchDevices.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetSearchDevices.Output> {
  const httpRequest = buildGetSearchDevicesInput(
    {
      latitude,
      longitude,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetSearchDevices.Output;
}

/**
 * Build the "getSearchNetworkDevices" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {string} parameters.networkId
 * The network id
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetSearchNetworkDevicesURI(
  {
    latitude,
    longitude,
    networkId,
  }: Pick<
    SencropTypes.GetSearchNetworkDevices.Input,
    "latitude" | "longitude" | "networkId"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (latitude == null) {
    throw new Error(
      "Missing required parameter : latitude. Value : " + latitude
    );
  }
  if (longitude == null) {
    throw new Error(
      "Missing required parameter : longitude. Value : " + longitude
    );
  }
  if (networkId == null) {
    throw new Error(
      "Missing required parameter : networkId. Value : " + networkId
    );
  }

  const __pathParts = ["search", "networks", networkId, "devices"];
  const __qs = cleanQuery({
    latitude: latitude,
    longitude: longitude,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getSearchNetworkDevices" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {string} parameters.networkId
 * The network id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetSearchNetworkDevicesInput(
  {
    latitude,
    longitude,
    networkId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetSearchNetworkDevices.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getSearchNetworkDevices;
  const __uriData = buildGetSearchNetworkDevicesURI(
    {
      latitude,
      longitude,
      networkId,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Search devices around a geopoint for a network
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {string} parameters.networkId
 * The network id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getSearchNetworkDevices(
  {
    latitude,
    longitude,
    networkId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetSearchNetworkDevices.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetSearchNetworkDevices.Output> {
  const httpRequest = buildGetSearchNetworkDevicesInput(
    {
      latitude,
      longitude,
      networkId,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetSearchNetworkDevices.Output;
}

/**
 * Build the "getSearchPublicOrganisation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.organisationId
 * Id of the organisation to search (can be uuid
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetSearchPublicOrganisationURI(
  {
    organisationId,
  }: Pick<SencropTypes.GetSearchPublicOrganisation.Input, "organisationId">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = ["search", "public", "organisations"];
  const __qs = cleanQuery({
    organisationId: organisationId,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getSearchPublicOrganisation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.organisationId
 * Id of the organisation to search (can be uuid,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetSearchPublicOrganisationInput(
  {
    organisationId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetSearchPublicOrganisation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getSearchPublicOrganisation;
  const __uriData = buildGetSearchPublicOrganisationURI(
    {
      organisationId,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Search for an organisation public info
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.organisationId
 * Id of the organisation to search (can be uuid,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getSearchPublicOrganisation(
  {
    organisationId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetSearchPublicOrganisation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetSearchPublicOrganisation.Output> {
  const httpRequest = buildGetSearchPublicOrganisationInput(
    {
      organisationId,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetSearchPublicOrganisation.Output;
}

/**
 * Build the "getSearchPublicUser" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.userId
 * Id or the user to search (can be uuid)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetSearchPublicUserURI(
  { userId }: Pick<SencropTypes.GetSearchPublicUser.Input, "userId">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }

  const __pathParts = ["search", "public", "users"];
  const __qs = cleanQuery({
    userId: userId,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getSearchPublicUser" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.userId
 * Id or the user to search (can be uuid),
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetSearchPublicUserInput(
  {
    userId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetSearchPublicUser.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getSearchPublicUser;
  const __uriData = buildGetSearchPublicUserURI(
    {
      userId,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Search for an user public info
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.userId
 * Id or the user to search (can be uuid),
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getSearchPublicUser(
  {
    userId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetSearchPublicUser.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetSearchPublicUser.Output> {
  const httpRequest = buildGetSearchPublicUserInput(
    {
      userId,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetSearchPublicUser.Output;
}

/**
 * Build the "getSearchUser" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.email
 * Email of the user to search,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetSearchUserURI(
  {
    email,
    accessToken,
  }: Pick<SencropTypes.GetSearchUser.Input, "email" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (email == null) {
    throw new Error("Missing required parameter : email. Value : " + email);
  }

  const __pathParts = ["search", "users"];
  const __qs = cleanQuery({
    email: email,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getSearchUser" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.email
 * Email of the user to search,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetSearchUserInput(
  {
    email,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetSearchUser.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getSearchUser;
  const __uriData = buildGetSearchUserURI(
    {
      email,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Search for an user
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.email
 * Email of the user to search,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getSearchUser(
  {
    email,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetSearchUser.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetSearchUser.Output> {
  const httpRequest = buildGetSearchUserInput(
    {
      email,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetSearchUser.Output;
}

/**
 * Build the "getSigfoxCoverage" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {number} [parameters.radius]
 * The radius of the area in meters.
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetSigfoxCoverageURI(
  {
    latitude,
    longitude,
    radius,
  }: Pick<
    SencropTypes.GetSigfoxCoverage.Input,
    "latitude" | "longitude" | "radius"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (latitude == null) {
    throw new Error(
      "Missing required parameter : latitude. Value : " + latitude
    );
  }
  if (longitude == null) {
    throw new Error(
      "Missing required parameter : longitude. Value : " + longitude
    );
  }

  const __pathParts = ["sigfox", "coverage"];
  const __qs = cleanQuery({
    latitude: latitude,
    longitude: longitude,
    radius: radius,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getSigfoxCoverage" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {number} [parameters.radius]
 * The radius of the area in meters.,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetSigfoxCoverageInput(
  {
    latitude,
    longitude,
    radius,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetSigfoxCoverage.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getSigfoxCoverage;
  const __uriData = buildGetSigfoxCoverageURI(
    {
      latitude,
      longitude,
      radius,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get sigfox coverage by location
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {number} [parameters.radius]
 * The radius of the area in meters.,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getSigfoxCoverage(
  {
    latitude,
    longitude,
    radius,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetSigfoxCoverage.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetSigfoxCoverage.Output> {
  const httpRequest = buildGetSigfoxCoverageInput(
    {
      latitude,
      longitude,
      radius,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetSigfoxCoverage.Output;
}

/**
 * Build the "getSigfoxMap" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.operator]
 * The sigfox operator map to retrieve,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetSigfoxMapURI(
  {
    deviceId,
    operator,
    accessToken,
  }: Pick<
    SencropTypes.GetSigfoxMap.Input,
    "deviceId" | "operator" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }

  const __pathParts = ["support", "devices", deviceId, "sigfoxMap"];
  const __qs = cleanQuery({
    operator: operator,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getSigfoxMap" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.operator]
 * The sigfox operator map to retrieve,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetSigfoxMapInput(
  {
    deviceId,
    operator,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetSigfoxMap.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getSigfoxMap;
  const __uriData = buildGetSigfoxMapURI(
    {
      deviceId,
      operator,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get the sigfox signal map
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.operator]
 * The sigfox operator map to retrieve,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getSigfoxMap(
  {
    deviceId,
    operator,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetSigfoxMap.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetSigfoxMap.Output> {
  const httpRequest = buildGetSigfoxMapInput(
    {
      deviceId,
      operator,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetSigfoxMap.Output;
}

/**
 * Build the "getStatusHead" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.locale,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetStatusHeadURI(
  {
    locale,
    accessToken,
  }: Pick<SencropTypes.GetStatusHead.Input, "locale" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (locale == null) {
    throw new Error("Missing required parameter : locale. Value : " + locale);
  }

  const __pathParts = ["status", "head"];
  const __qs = cleanQuery({
    locale: locale,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getStatusHead" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.locale,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetStatusHeadInput(
  {
    locale,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetStatusHead.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getStatusHead;
  const __uriData = buildGetStatusHeadURI(
    {
      locale,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a message status for the banner app in case of service downgrade
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.locale,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getStatusHead(
  {
    locale,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetStatusHead.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetStatusHead.Output> {
  const httpRequest = buildGetStatusHeadInput(
    {
      locale,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetStatusHead.Output;
}

/**
 * Build the "getTrialOperation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.trialOperationId
 * The trial operation id
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetTrialOperationURI(
  {
    trialOperationId,
  }: Pick<SencropTypes.GetTrialOperation.Input, "trialOperationId">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (trialOperationId == null) {
    throw new Error(
      "Missing required parameter : trialOperationId. Value : " +
        trialOperationId
    );
  }

  const __pathParts = ["trialOperations", trialOperationId];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getTrialOperation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.trialOperationId
 * The trial operation id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetTrialOperationInput(
  {
    trialOperationId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetTrialOperation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getTrialOperation;
  const __uriData = buildGetTrialOperationURI(
    {
      trialOperationId,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a trial operation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.trialOperationId
 * The trial operation id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getTrialOperation(
  {
    trialOperationId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetTrialOperation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetTrialOperation.Output> {
  const httpRequest = buildGetTrialOperationInput(
    {
      trialOperationId,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetTrialOperation.Output;
}

/**
 * Build the "getUser" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserURI(
  {
    userId,
    accessToken,
  }: Pick<SencropTypes.GetUser.Input, "userId" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }

  const __pathParts = ["users", userId];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUser" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserInput(
  {
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUser.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUser;
  const __uriData = buildGetUserURI(
    {
      userId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's profile.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUser(
  {
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUser.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUser.Output> {
  const httpRequest = buildGetUserInput(
    {
      userId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUser.Output;
}

/**
 * Build the "putUser" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutUserURI(
  {
    userId,
    accessToken,
  }: Pick<SencropTypes.PutUser.Input, "userId" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }

  const __pathParts = ["users", userId];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putUser" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutUserInput(
  {
    body,
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUser.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutUser.Input["body"]> {
  const __method = APIMethods.putUser;
  const __uriData = buildPutUserURI(
    {
      userId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Update a user's profile.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putUser(
  {
    body,
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUser.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutUser.Output> {
  const httpRequest = buildPutUserInput(
    {
      body,
      userId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutUser.Output;
}

/**
 * Build the "getUserAggregation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.aggregationId
 * The aggregation id,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserAggregationURI(
  {
    userId,
    aggregationId,
    patched,
    accessToken,
  }: Pick<
    SencropTypes.GetUserAggregation.Input,
    "userId" | "aggregationId" | "patched" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (aggregationId == null) {
    throw new Error(
      "Missing required parameter : aggregationId. Value : " + aggregationId
    );
  }

  const __pathParts = ["users", userId, "aggregations", aggregationId];
  const __qs = cleanQuery({
    patched: patched,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserAggregation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.aggregationId
 * The aggregation id,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserAggregationInput(
  {
    userId,
    aggregationId,
    patched,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserAggregation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserAggregation;
  const __uriData = buildGetUserAggregationURI(
    {
      userId,
      aggregationId,
      patched,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a single user aggregation with statistics for graphs
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.aggregationId
 * The aggregation id,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserAggregation(
  {
    userId,
    aggregationId,
    patched,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserAggregation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserAggregation.Output> {
  const httpRequest = buildGetUserAggregationInput(
    {
      userId,
      aggregationId,
      patched,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserAggregation.Output;
}

/**
 * Build the "getUserAggregations" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {boolean} [parameters.compute]
 * Wether the aggregations should be computed,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserAggregationsURI(
  {
    userId,
    compute,
    patched,
    accessToken,
  }: Pick<
    SencropTypes.GetUserAggregations.Input,
    "userId" | "compute" | "patched" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }

  const __pathParts = ["users", userId, "aggregations"];
  const __qs = cleanQuery({
    compute: compute,
    patched: patched,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserAggregations" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {boolean} [parameters.compute]
 * Wether the aggregations should be computed,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserAggregationsInput(
  {
    userId,
    compute,
    patched,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserAggregations.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserAggregations;
  const __uriData = buildGetUserAggregationsURI(
    {
      userId,
      compute,
      patched,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's aggregations.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {boolean} [parameters.compute]
 * Wether the aggregations should be computed,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserAggregations(
  {
    userId,
    compute,
    patched,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserAggregations.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserAggregations.Output> {
  const httpRequest = buildGetUserAggregationsInput(
    {
      userId,
      compute,
      patched,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserAggregations.Output;
}

/**
 * Build the "getUserAlert" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.alertId
 * The alert id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserAlertURI(
  {
    userId,
    alertId,
    accessToken,
  }: Pick<
    SencropTypes.GetUserAlert.Input,
    "userId" | "alertId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (alertId == null) {
    throw new Error("Missing required parameter : alertId. Value : " + alertId);
  }

  const __pathParts = ["users", userId, "alerts", alertId];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserAlert" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.alertId
 * The alert id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserAlertInput(
  {
    userId,
    alertId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserAlert.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserAlert;
  const __uriData = buildGetUserAlertURI(
    {
      userId,
      alertId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Retrieve a user's alert.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.alertId
 * The alert id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserAlert(
  {
    userId,
    alertId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserAlert.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserAlert.Output> {
  const httpRequest = buildGetUserAlertInput(
    {
      userId,
      alertId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserAlert.Output;
}

/**
 * Build the "getUserAlertVoiceMessage" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.alertId
 * The alert id,
 * @param {string} parameters.date
 * The check date id
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserAlertVoiceMessageURI(
  {
    userId,
    alertId,
    date,
  }: Pick<
    SencropTypes.GetUserAlertVoiceMessage.Input,
    "userId" | "alertId" | "date"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (alertId == null) {
    throw new Error("Missing required parameter : alertId. Value : " + alertId);
  }
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["users", userId, "alertsVoice", alertId];
  const __qs = cleanQuery({
    date: date,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserAlertVoiceMessage" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.alertId
 * The alert id,
 * @param {string} parameters.date
 * The check date id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserAlertVoiceMessageInput(
  {
    userId,
    alertId,
    date,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserAlertVoiceMessage.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserAlertVoiceMessage;
  const __uriData = buildGetUserAlertVoiceMessageURI(
    {
      userId,
      alertId,
      date,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's alert voice message.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.alertId
 * The alert id,
 * @param {string} parameters.date
 * The check date id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserAlertVoiceMessage(
  {
    userId,
    alertId,
    date,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserAlertVoiceMessage.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserAlertVoiceMessage.Output> {
  const httpRequest = buildGetUserAlertVoiceMessageInput(
    {
      userId,
      alertId,
      date,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserAlertVoiceMessage.Output;
}

/**
 * Build the "getUserAlerts" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserAlertsURI(
  {
    userId,
    accessToken,
  }: Pick<SencropTypes.GetUserAlerts.Input, "userId" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }

  const __pathParts = ["users", userId, "alerts"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserAlerts" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserAlertsInput(
  {
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserAlerts.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserAlerts;
  const __uriData = buildGetUserAlertsURI(
    {
      userId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's alerts.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserAlerts(
  {
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserAlerts.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserAlerts.Output> {
  const httpRequest = buildGetUserAlertsInput(
    {
      userId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserAlerts.Output;
}

/**
 * Build the "getUserDailyData" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {string} parameters.beforeDate
 * The date before which the data starts being retrieved,
 * @param {number} parameters.days
 * The number of days to retrieve,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {array} [parameters.ranges]
 * The ranges to get the data from,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserDailyDataURI(
  {
    userId,
    latitude,
    longitude,
    beforeDate,
    days,
    includeHistory,
    ranges,
    measures,
    withCalibration,
    timeZone,
    patched,
    accessToken,
  }: Pick<
    SencropTypes.GetUserDailyData.Input,
    | "userId"
    | "latitude"
    | "longitude"
    | "beforeDate"
    | "days"
    | "includeHistory"
    | "ranges"
    | "measures"
    | "withCalibration"
    | "timeZone"
    | "patched"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (latitude == null) {
    throw new Error(
      "Missing required parameter : latitude. Value : " + latitude
    );
  }
  if (longitude == null) {
    throw new Error(
      "Missing required parameter : longitude. Value : " + longitude
    );
  }
  if (beforeDate == null) {
    throw new Error(
      "Missing required parameter : beforeDate. Value : " + beforeDate
    );
  }
  if (days == null) {
    throw new Error("Missing required parameter : days. Value : " + days);
  }
  if (measures == null) {
    throw new Error(
      "Missing required parameter : measures. Value : " + measures
    );
  }

  const __pathParts = ["users", userId, "data", "daily"];
  const __qs = cleanQuery({
    latitude: latitude,
    longitude: longitude,
    beforeDate: beforeDate,
    days: days,
    includeHistory: includeHistory,
    ranges: ranges,
    measures: measures,
    withCalibration: withCalibration,
    timeZone: timeZone,
    patched: patched,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserDailyData" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {string} parameters.beforeDate
 * The date before which the data starts being retrieved,
 * @param {number} parameters.days
 * The number of days to retrieve,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {array} [parameters.ranges]
 * The ranges to get the data from,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserDailyDataInput(
  {
    userId,
    latitude,
    longitude,
    beforeDate,
    days,
    includeHistory,
    ranges,
    measures,
    withCalibration,
    timeZone,
    patched,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDailyData.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserDailyData;
  const __uriData = buildGetUserDailyDataURI(
    {
      userId,
      latitude,
      longitude,
      beforeDate,
      days,
      includeHistory,
      ranges,
      measures,
      withCalibration,
      timeZone,
      patched,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's daily data for a given geo location.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {string} parameters.beforeDate
 * The date before which the data starts being retrieved,
 * @param {number} parameters.days
 * The number of days to retrieve,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {array} [parameters.ranges]
 * The ranges to get the data from,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserDailyData(
  {
    userId,
    latitude,
    longitude,
    beforeDate,
    days,
    includeHistory,
    ranges,
    measures,
    withCalibration,
    timeZone,
    patched,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDailyData.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserDailyData.Output> {
  const httpRequest = buildGetUserDailyDataInput(
    {
      userId,
      latitude,
      longitude,
      beforeDate,
      days,
      includeHistory,
      ranges,
      measures,
      withCalibration,
      timeZone,
      patched,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserDailyData.Output;
}

/**
 * Build the "getUserDevice" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserDeviceURI(
  {
    userId,
    deviceId,
    includeHistory,
    accessToken,
  }: Pick<
    SencropTypes.GetUserDevice.Input,
    "userId" | "deviceId" | "includeHistory" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }

  const __pathParts = ["users", userId, "devices", deviceId];
  const __qs = cleanQuery({
    includeHistory: includeHistory,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserDevice" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserDeviceInput(
  {
    userId,
    deviceId,
    includeHistory,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDevice.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserDevice;
  const __uriData = buildGetUserDeviceURI(
    {
      userId,
      deviceId,
      includeHistory,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's device.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserDevice(
  {
    userId,
    deviceId,
    includeHistory,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDevice.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserDevice.Output> {
  const httpRequest = buildGetUserDeviceInput(
    {
      userId,
      deviceId,
      includeHistory,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserDevice.Output;
}

/**
 * Build the "getUserDeviceContinuousStatistics" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.startDate
 * The statistics start date,
 * @param {string} parameters.endDate
 * The statistics end date,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} [parameters.interval]
 * The interval of data (Accepted value : 31 days for 1h interval, 1 year for 1d interval, 5y for 1w interval),
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserDeviceContinuousStatisticsURI(
  {
    userId,
    deviceId,
    startDate,
    endDate,
    measures,
    patched,
    includeHistory,
    timeZone,
    interval,
    withCalibration,
    accessToken,
  }: Pick<
    SencropTypes.GetUserDeviceContinuousStatistics.Input,
    | "userId"
    | "deviceId"
    | "startDate"
    | "endDate"
    | "measures"
    | "patched"
    | "includeHistory"
    | "timeZone"
    | "interval"
    | "withCalibration"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }
  if (startDate == null) {
    throw new Error(
      "Missing required parameter : startDate. Value : " + startDate
    );
  }
  if (endDate == null) {
    throw new Error("Missing required parameter : endDate. Value : " + endDate);
  }
  if (measures == null) {
    throw new Error(
      "Missing required parameter : measures. Value : " + measures
    );
  }

  const __pathParts = [
    "users",
    userId,
    "devices",
    deviceId,
    "countinuousStatistics",
  ];
  const __qs = cleanQuery({
    startDate: startDate,
    endDate: endDate,
    measures: measures,
    patched: patched,
    includeHistory: includeHistory,
    timeZone: timeZone,
    interval: interval,
    withCalibration: withCalibration,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserDeviceContinuousStatistics" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.startDate
 * The statistics start date,
 * @param {string} parameters.endDate
 * The statistics end date,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} [parameters.interval]
 * The interval of data (Accepted value : 31 days for 1h interval, 1 year for 1d interval, 5y for 1w interval),
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserDeviceContinuousStatisticsInput(
  {
    userId,
    deviceId,
    startDate,
    endDate,
    measures,
    patched,
    includeHistory,
    timeZone,
    interval,
    withCalibration,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDeviceContinuousStatistics.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserDeviceContinuousStatistics;
  const __uriData = buildGetUserDeviceContinuousStatisticsURI(
    {
      userId,
      deviceId,
      startDate,
      endDate,
      measures,
      patched,
      includeHistory,
      timeZone,
      interval,
      withCalibration,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's device's continuous device's statistics.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.startDate
 * The statistics start date,
 * @param {string} parameters.endDate
 * The statistics end date,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} [parameters.interval]
 * The interval of data (Accepted value : 31 days for 1h interval, 1 year for 1d interval, 5y for 1w interval),
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserDeviceContinuousStatistics(
  {
    userId,
    deviceId,
    startDate,
    endDate,
    measures,
    patched,
    includeHistory,
    timeZone,
    interval,
    withCalibration,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDeviceContinuousStatistics.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserDeviceContinuousStatistics.Output> {
  const httpRequest = buildGetUserDeviceContinuousStatisticsInput(
    {
      userId,
      deviceId,
      startDate,
      endDate,
      measures,
      patched,
      includeHistory,
      timeZone,
      interval,
      withCalibration,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserDeviceContinuousStatistics.Output;
}

/**
 * Build the "getUserDeviceDailyData" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.beforeDate
 * The date before which the data starts being retrieved,
 * @param {number} parameters.days
 * The number of days to retrieve,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserDeviceDailyDataURI(
  {
    userId,
    deviceId,
    beforeDate,
    days,
    includeHistory,
    timeZone,
    measures,
    withCalibration,
    patched,
    accessToken,
  }: Pick<
    SencropTypes.GetUserDeviceDailyData.Input,
    | "userId"
    | "deviceId"
    | "beforeDate"
    | "days"
    | "includeHistory"
    | "timeZone"
    | "measures"
    | "withCalibration"
    | "patched"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }
  if (beforeDate == null) {
    throw new Error(
      "Missing required parameter : beforeDate. Value : " + beforeDate
    );
  }
  if (days == null) {
    throw new Error("Missing required parameter : days. Value : " + days);
  }
  if (measures == null) {
    throw new Error(
      "Missing required parameter : measures. Value : " + measures
    );
  }

  const __pathParts = ["users", userId, "devices", deviceId, "data", "daily"];
  const __qs = cleanQuery({
    beforeDate: beforeDate,
    days: days,
    includeHistory: includeHistory,
    timeZone: timeZone,
    measures: measures,
    withCalibration: withCalibration,
    patched: patched,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserDeviceDailyData" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.beforeDate
 * The date before which the data starts being retrieved,
 * @param {number} parameters.days
 * The number of days to retrieve,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserDeviceDailyDataInput(
  {
    userId,
    deviceId,
    beforeDate,
    days,
    includeHistory,
    timeZone,
    measures,
    withCalibration,
    patched,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDeviceDailyData.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserDeviceDailyData;
  const __uriData = buildGetUserDeviceDailyDataURI(
    {
      userId,
      deviceId,
      beforeDate,
      days,
      includeHistory,
      timeZone,
      measures,
      withCalibration,
      patched,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's device's daily data.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.beforeDate
 * The date before which the data starts being retrieved,
 * @param {number} parameters.days
 * The number of days to retrieve,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserDeviceDailyData(
  {
    userId,
    deviceId,
    beforeDate,
    days,
    includeHistory,
    timeZone,
    measures,
    withCalibration,
    patched,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDeviceDailyData.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserDeviceDailyData.Output> {
  const httpRequest = buildGetUserDeviceDailyDataInput(
    {
      userId,
      deviceId,
      beforeDate,
      days,
      includeHistory,
      timeZone,
      measures,
      withCalibration,
      patched,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserDeviceDailyData.Output;
}

/**
 * Build the "getUserDeviceForecasts" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.date
 * Date of the forecasts,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserDeviceForecastsURI(
  {
    userId,
    deviceId,
    date,
    accessToken,
  }: Pick<
    SencropTypes.GetUserDeviceForecasts.Input,
    "userId" | "deviceId" | "date" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["users", userId, "devices", deviceId, "forecasts"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserDeviceForecasts" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.date
 * Date of the forecasts,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserDeviceForecastsInput(
  {
    userId,
    deviceId,
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDeviceForecasts.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserDeviceForecasts;
  const __uriData = buildGetUserDeviceForecastsURI(
    {
      userId,
      deviceId,
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's device's forecasts.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.date
 * Date of the forecasts,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserDeviceForecasts(
  {
    userId,
    deviceId,
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDeviceForecasts.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserDeviceForecasts.Output> {
  const httpRequest = buildGetUserDeviceForecastsInput(
    {
      userId,
      deviceId,
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserDeviceForecasts.Output;
}

/**
 * Build the "getUserDeviceHourlyData" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.beforeDate
 * The date before which the data starts being retrieved,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {number} parameters.days
 * The number of days to retrieve,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserDeviceHourlyDataURI(
  {
    userId,
    deviceId,
    beforeDate,
    includeHistory,
    timeZone,
    days,
    measures,
    withCalibration,
    patched,
    accessToken,
  }: Pick<
    SencropTypes.GetUserDeviceHourlyData.Input,
    | "userId"
    | "deviceId"
    | "beforeDate"
    | "includeHistory"
    | "timeZone"
    | "days"
    | "measures"
    | "withCalibration"
    | "patched"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }
  if (beforeDate == null) {
    throw new Error(
      "Missing required parameter : beforeDate. Value : " + beforeDate
    );
  }
  if (days == null) {
    throw new Error("Missing required parameter : days. Value : " + days);
  }
  if (measures == null) {
    throw new Error(
      "Missing required parameter : measures. Value : " + measures
    );
  }

  const __pathParts = ["users", userId, "devices", deviceId, "data", "hourly"];
  const __qs = cleanQuery({
    beforeDate: beforeDate,
    includeHistory: includeHistory,
    timeZone: timeZone,
    days: days,
    measures: measures,
    withCalibration: withCalibration,
    patched: patched,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserDeviceHourlyData" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.beforeDate
 * The date before which the data starts being retrieved,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {number} parameters.days
 * The number of days to retrieve,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserDeviceHourlyDataInput(
  {
    userId,
    deviceId,
    beforeDate,
    includeHistory,
    timeZone,
    days,
    measures,
    withCalibration,
    patched,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDeviceHourlyData.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserDeviceHourlyData;
  const __uriData = buildGetUserDeviceHourlyDataURI(
    {
      userId,
      deviceId,
      beforeDate,
      includeHistory,
      timeZone,
      days,
      measures,
      withCalibration,
      patched,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's device's hourly data.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.beforeDate
 * The date before which the data starts being retrieved,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {number} parameters.days
 * The number of days to retrieve,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserDeviceHourlyData(
  {
    userId,
    deviceId,
    beforeDate,
    includeHistory,
    timeZone,
    days,
    measures,
    withCalibration,
    patched,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDeviceHourlyData.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserDeviceHourlyData.Output> {
  const httpRequest = buildGetUserDeviceHourlyDataInput(
    {
      userId,
      deviceId,
      beforeDate,
      includeHistory,
      timeZone,
      days,
      measures,
      withCalibration,
      patched,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserDeviceHourlyData.Output;
}

/**
 * Build the "getUserDeviceModules" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserDeviceModulesURI(
  {
    userId,
    deviceId,
    accessToken,
  }: Pick<
    SencropTypes.GetUserDeviceModules.Input,
    "userId" | "deviceId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }

  const __pathParts = ["users", userId, "devices", deviceId, "modules"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserDeviceModules" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserDeviceModulesInput(
  {
    userId,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDeviceModules.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserDeviceModules;
  const __uriData = buildGetUserDeviceModulesURI(
    {
      userId,
      deviceId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get the modules a user activated on its device
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserDeviceModules(
  {
    userId,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDeviceModules.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserDeviceModules.Output> {
  const httpRequest = buildGetUserDeviceModulesInput(
    {
      userId,
      deviceId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserDeviceModules.Output;
}

/**
 * Build the "getUserDevicePositions" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserDevicePositionsURI(
  {
    userId,
    deviceId,
    includeHistory,
    accessToken,
  }: Pick<
    SencropTypes.GetUserDevicePositions.Input,
    "userId" | "deviceId" | "includeHistory" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }

  const __pathParts = ["users", userId, "devices", deviceId, "positions"];
  const __qs = cleanQuery({
    includeHistory: includeHistory,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserDevicePositions" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserDevicePositionsInput(
  {
    userId,
    deviceId,
    includeHistory,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDevicePositions.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserDevicePositions;
  const __uriData = buildGetUserDevicePositionsURI(
    {
      userId,
      deviceId,
      includeHistory,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's device geographic positions historic.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserDevicePositions(
  {
    userId,
    deviceId,
    includeHistory,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDevicePositions.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserDevicePositions.Output> {
  const httpRequest = buildGetUserDevicePositionsInput(
    {
      userId,
      deviceId,
      includeHistory,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserDevicePositions.Output;
}

/**
 * Build the "getUserDeviceRawData" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.beforeDate
 * The date before which the data starts being retrieved,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {number} parameters.size
 * The number of measures to retrieve,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserDeviceRawDataURI(
  {
    userId,
    deviceId,
    beforeDate,
    includeHistory,
    size,
    measures,
    accessToken,
  }: Pick<
    SencropTypes.GetUserDeviceRawData.Input,
    | "userId"
    | "deviceId"
    | "beforeDate"
    | "includeHistory"
    | "size"
    | "measures"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }
  if (beforeDate == null) {
    throw new Error(
      "Missing required parameter : beforeDate. Value : " + beforeDate
    );
  }
  if (size == null) {
    throw new Error("Missing required parameter : size. Value : " + size);
  }
  if (measures == null) {
    throw new Error(
      "Missing required parameter : measures. Value : " + measures
    );
  }

  const __pathParts = ["users", userId, "devices", deviceId, "data", "raw"];
  const __qs = cleanQuery({
    beforeDate: beforeDate,
    includeHistory: includeHistory,
    size: size,
    measures: measures,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserDeviceRawData" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.beforeDate
 * The date before which the data starts being retrieved,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {number} parameters.size
 * The number of measures to retrieve,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserDeviceRawDataInput(
  {
    userId,
    deviceId,
    beforeDate,
    includeHistory,
    size,
    measures,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDeviceRawData.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserDeviceRawData;
  const __uriData = buildGetUserDeviceRawDataURI(
    {
      userId,
      deviceId,
      beforeDate,
      includeHistory,
      size,
      measures,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's device's raw data.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.beforeDate
 * The date before which the data starts being retrieved,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {number} parameters.size
 * The number of measures to retrieve,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserDeviceRawData(
  {
    userId,
    deviceId,
    beforeDate,
    includeHistory,
    size,
    measures,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDeviceRawData.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserDeviceRawData.Output> {
  const httpRequest = buildGetUserDeviceRawDataInput(
    {
      userId,
      deviceId,
      beforeDate,
      includeHistory,
      size,
      measures,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserDeviceRawData.Output;
}

/**
 * Build the "getUserDeviceStatistics" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.startDate
 * The statistics start date,
 * @param {string} parameters.endDate
 * The statistics end date,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} [parameters.interval]
 * The interval of data (Accepted value : 31 days for 1h interval, 1 year for 1d interval, 5y for 1w interval),
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserDeviceStatisticsURI(
  {
    userId,
    deviceId,
    startDate,
    endDate,
    measures,
    patched,
    includeHistory,
    timeZone,
    interval,
    withCalibration,
    accessToken,
  }: Pick<
    SencropTypes.GetUserDeviceStatistics.Input,
    | "userId"
    | "deviceId"
    | "startDate"
    | "endDate"
    | "measures"
    | "patched"
    | "includeHistory"
    | "timeZone"
    | "interval"
    | "withCalibration"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }
  if (startDate == null) {
    throw new Error(
      "Missing required parameter : startDate. Value : " + startDate
    );
  }
  if (endDate == null) {
    throw new Error("Missing required parameter : endDate. Value : " + endDate);
  }
  if (measures == null) {
    throw new Error(
      "Missing required parameter : measures. Value : " + measures
    );
  }

  const __pathParts = ["users", userId, "devices", deviceId, "statistics"];
  const __qs = cleanQuery({
    startDate: startDate,
    endDate: endDate,
    measures: measures,
    patched: patched,
    includeHistory: includeHistory,
    timeZone: timeZone,
    interval: interval,
    withCalibration: withCalibration,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserDeviceStatistics" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.startDate
 * The statistics start date,
 * @param {string} parameters.endDate
 * The statistics end date,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} [parameters.interval]
 * The interval of data (Accepted value : 31 days for 1h interval, 1 year for 1d interval, 5y for 1w interval),
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserDeviceStatisticsInput(
  {
    userId,
    deviceId,
    startDate,
    endDate,
    measures,
    patched,
    includeHistory,
    timeZone,
    interval,
    withCalibration,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDeviceStatistics.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserDeviceStatistics;
  const __uriData = buildGetUserDeviceStatisticsURI(
    {
      userId,
      deviceId,
      startDate,
      endDate,
      measures,
      patched,
      includeHistory,
      timeZone,
      interval,
      withCalibration,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's device's device's statistics.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.startDate
 * The statistics start date,
 * @param {string} parameters.endDate
 * The statistics end date,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} [parameters.interval]
 * The interval of data (Accepted value : 31 days for 1h interval, 1 year for 1d interval, 5y for 1w interval),
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserDeviceStatistics(
  {
    userId,
    deviceId,
    startDate,
    endDate,
    measures,
    patched,
    includeHistory,
    timeZone,
    interval,
    withCalibration,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDeviceStatistics.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserDeviceStatistics.Output> {
  const httpRequest = buildGetUserDeviceStatisticsInput(
    {
      userId,
      deviceId,
      startDate,
      endDate,
      measures,
      patched,
      includeHistory,
      timeZone,
      interval,
      withCalibration,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserDeviceStatistics.Output;
}

/**
 * Build the "getUserDeviceSummary" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} parameters.date
 * The summary date,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserDeviceSummaryURI(
  {
    userId,
    deviceId,
    includeHistory,
    date,
    measures,
    accessToken,
  }: Pick<
    SencropTypes.GetUserDeviceSummary.Input,
    | "userId"
    | "deviceId"
    | "includeHistory"
    | "date"
    | "measures"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }
  if (measures == null) {
    throw new Error(
      "Missing required parameter : measures. Value : " + measures
    );
  }

  const __pathParts = ["users", userId, "devices", deviceId, "summaries", date];
  const __qs = cleanQuery({
    includeHistory: includeHistory,
    measures: measures,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserDeviceSummary" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} parameters.date
 * The summary date,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserDeviceSummaryInput(
  {
    userId,
    deviceId,
    includeHistory,
    date,
    measures,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDeviceSummary.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserDeviceSummary;
  const __uriData = buildGetUserDeviceSummaryURI(
    {
      userId,
      deviceId,
      includeHistory,
      date,
      measures,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's device's summary at a given date.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} parameters.date
 * The summary date,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserDeviceSummary(
  {
    userId,
    deviceId,
    includeHistory,
    date,
    measures,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDeviceSummary.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserDeviceSummary.Output> {
  const httpRequest = buildGetUserDeviceSummaryInput(
    {
      userId,
      deviceId,
      includeHistory,
      date,
      measures,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserDeviceSummary.Output;
}

/**
 * Build the "getUserDevices" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.date]
 * The status date,
 * @param {array} [parameters.measures]
 * The measures to read,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {number} [parameters.limit]
 * The number of items to retrieve,
 * @param {number} [parameters.start]
 * The index in results,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserDevicesURI(
  {
    userId,
    includeHistory,
    date,
    measures,
    patched,
    limit,
    start,
    accessToken,
  }: Pick<
    SencropTypes.GetUserDevices.Input,
    | "userId"
    | "includeHistory"
    | "date"
    | "measures"
    | "patched"
    | "limit"
    | "start"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }

  const __pathParts = ["users", userId, "devices"];
  const __qs = cleanQuery({
    includeHistory: includeHistory,
    date: date,
    measures: measures,
    patched: patched,
    limit: limit,
    start: start,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserDevices" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.date]
 * The status date,
 * @param {array} [parameters.measures]
 * The measures to read,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {number} [parameters.limit]
 * The number of items to retrieve,
 * @param {number} [parameters.start]
 * The index in results,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserDevicesInput(
  {
    userId,
    includeHistory,
    date,
    measures,
    patched,
    limit,
    start,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDevices.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserDevices;
  const __uriData = buildGetUserDevicesURI(
    {
      userId,
      includeHistory,
      date,
      measures,
      patched,
      limit,
      start,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's devices.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.date]
 * The status date,
 * @param {array} [parameters.measures]
 * The measures to read,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {number} [parameters.limit]
 * The number of items to retrieve,
 * @param {number} [parameters.start]
 * The index in results,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserDevices(
  {
    userId,
    includeHistory,
    date,
    measures,
    patched,
    limit,
    start,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDevices.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserDevices.Output> {
  const httpRequest = buildGetUserDevicesInput(
    {
      userId,
      includeHistory,
      date,
      measures,
      patched,
      limit,
      start,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserDevices.Output;
}

/**
 * Build the "getUserDevicesLiveAggregations" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {array} parameters.devicesIds
 * List of id's of devices,
 * @param {array} parameters.aggregations
 * The aggregations names,
 * @param {array} [parameters.parameters]
 * The params to set,
 * @param {array} parameters.intervals
 * The intervals to retrieve,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} [parameters.date]
 * The status date,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserDevicesLiveAggregationsURI(
  {
    userId,
    devicesIds,
    aggregations,
    parameters,
    intervals,
    timeZone,
    date,
    patched,
    accessToken,
  }: Pick<
    SencropTypes.GetUserDevicesLiveAggregations.Input,
    | "userId"
    | "devicesIds"
    | "aggregations"
    | "parameters"
    | "intervals"
    | "timeZone"
    | "date"
    | "patched"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (devicesIds == null) {
    throw new Error(
      "Missing required parameter : devicesIds. Value : " + devicesIds
    );
  }
  if (aggregations == null) {
    throw new Error(
      "Missing required parameter : aggregations. Value : " + aggregations
    );
  }
  if (intervals == null) {
    throw new Error(
      "Missing required parameter : intervals. Value : " + intervals
    );
  }

  const __pathParts = ["users", userId, "devices", "liveAggregations"];
  const __qs = cleanQuery({
    devicesIds: devicesIds,
    aggregations: aggregations,
    parameters: parameters,
    intervals: intervals,
    timeZone: timeZone,
    date: date,
    patched: patched,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserDevicesLiveAggregations" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {array} parameters.devicesIds
 * List of id's of devices,
 * @param {array} parameters.aggregations
 * The aggregations names,
 * @param {array} [parameters.parameters]
 * The params to set,
 * @param {array} parameters.intervals
 * The intervals to retrieve,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} [parameters.date]
 * The status date,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserDevicesLiveAggregationsInput(
  {
    userId,
    devicesIds,
    aggregations,
    parameters,
    intervals,
    timeZone,
    date,
    patched,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDevicesLiveAggregations.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserDevicesLiveAggregations;
  const __uriData = buildGetUserDevicesLiveAggregationsURI(
    {
      userId,
      devicesIds,
      aggregations,
      parameters,
      intervals,
      timeZone,
      date,
      patched,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Allow to run live aggregation queries against a user devices.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {array} parameters.devicesIds
 * List of id's of devices,
 * @param {array} parameters.aggregations
 * The aggregations names,
 * @param {array} [parameters.parameters]
 * The params to set,
 * @param {array} parameters.intervals
 * The intervals to retrieve,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} [parameters.date]
 * The status date,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserDevicesLiveAggregations(
  {
    userId,
    devicesIds,
    aggregations,
    parameters,
    intervals,
    timeZone,
    date,
    patched,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserDevicesLiveAggregations.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserDevicesLiveAggregations.Output> {
  const httpRequest = buildGetUserDevicesLiveAggregationsInput(
    {
      userId,
      devicesIds,
      aggregations,
      parameters,
      intervals,
      timeZone,
      date,
      patched,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserDevicesLiveAggregations.Output;
}

/**
 * Build the "getUserForecasts" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {string} [parameters.date]
 * Date of the forecasts,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserForecastsURI(
  {
    userId,
    latitude,
    longitude,
    date,
    accessToken,
  }: Pick<
    SencropTypes.GetUserForecasts.Input,
    "userId" | "latitude" | "longitude" | "date" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (latitude == null) {
    throw new Error(
      "Missing required parameter : latitude. Value : " + latitude
    );
  }
  if (longitude == null) {
    throw new Error(
      "Missing required parameter : longitude. Value : " + longitude
    );
  }

  const __pathParts = ["users", userId, "forecasts"];
  const __qs = cleanQuery({
    latitude: latitude,
    longitude: longitude,
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserForecasts" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {string} [parameters.date]
 * Date of the forecasts,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserForecastsInput(
  {
    userId,
    latitude,
    longitude,
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserForecasts.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserForecasts;
  const __uriData = buildGetUserForecastsURI(
    {
      userId,
      latitude,
      longitude,
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's forecasts.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {string} [parameters.date]
 * Date of the forecasts,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserForecasts(
  {
    userId,
    latitude,
    longitude,
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserForecasts.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserForecasts.Output> {
  const httpRequest = buildGetUserForecastsInput(
    {
      userId,
      latitude,
      longitude,
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserForecasts.Output;
}

/**
 * Build the "getUserHourlyData" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {string} parameters.beforeDate
 * The date before which the data starts being retrieved,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {number} parameters.days
 * The number of days to retrieve,
 * @param {array} [parameters.ranges]
 * The ranges to get the data from,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserHourlyDataURI(
  {
    userId,
    latitude,
    longitude,
    beforeDate,
    includeHistory,
    days,
    ranges,
    measures,
    withCalibration,
    timeZone,
    patched,
    accessToken,
  }: Pick<
    SencropTypes.GetUserHourlyData.Input,
    | "userId"
    | "latitude"
    | "longitude"
    | "beforeDate"
    | "includeHistory"
    | "days"
    | "ranges"
    | "measures"
    | "withCalibration"
    | "timeZone"
    | "patched"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (latitude == null) {
    throw new Error(
      "Missing required parameter : latitude. Value : " + latitude
    );
  }
  if (longitude == null) {
    throw new Error(
      "Missing required parameter : longitude. Value : " + longitude
    );
  }
  if (beforeDate == null) {
    throw new Error(
      "Missing required parameter : beforeDate. Value : " + beforeDate
    );
  }
  if (days == null) {
    throw new Error("Missing required parameter : days. Value : " + days);
  }
  if (measures == null) {
    throw new Error(
      "Missing required parameter : measures. Value : " + measures
    );
  }

  const __pathParts = ["users", userId, "data", "hourly"];
  const __qs = cleanQuery({
    latitude: latitude,
    longitude: longitude,
    beforeDate: beforeDate,
    includeHistory: includeHistory,
    days: days,
    ranges: ranges,
    measures: measures,
    withCalibration: withCalibration,
    timeZone: timeZone,
    patched: patched,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserHourlyData" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {string} parameters.beforeDate
 * The date before which the data starts being retrieved,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {number} parameters.days
 * The number of days to retrieve,
 * @param {array} [parameters.ranges]
 * The ranges to get the data from,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserHourlyDataInput(
  {
    userId,
    latitude,
    longitude,
    beforeDate,
    includeHistory,
    days,
    ranges,
    measures,
    withCalibration,
    timeZone,
    patched,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserHourlyData.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserHourlyData;
  const __uriData = buildGetUserHourlyDataURI(
    {
      userId,
      latitude,
      longitude,
      beforeDate,
      includeHistory,
      days,
      ranges,
      measures,
      withCalibration,
      timeZone,
      patched,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's hourly data for a given geo location.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {string} parameters.beforeDate
 * The date before which the data starts being retrieved,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {number} parameters.days
 * The number of days to retrieve,
 * @param {array} [parameters.ranges]
 * The ranges to get the data from,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserHourlyData(
  {
    userId,
    latitude,
    longitude,
    beforeDate,
    includeHistory,
    days,
    ranges,
    measures,
    withCalibration,
    timeZone,
    patched,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserHourlyData.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserHourlyData.Output> {
  const httpRequest = buildGetUserHourlyDataInput(
    {
      userId,
      latitude,
      longitude,
      beforeDate,
      includeHistory,
      days,
      ranges,
      measures,
      withCalibration,
      timeZone,
      patched,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserHourlyData.Output;
}

/**
 * Build the "getUserIntercomHash" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.platform
 * Type of the platform,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserIntercomHashURI(
  {
    userId,
    platform,
    accessToken,
  }: Pick<
    SencropTypes.GetUserIntercomHash.Input,
    "userId" | "platform" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (platform == null) {
    throw new Error(
      "Missing required parameter : platform. Value : " + platform
    );
  }

  const __pathParts = ["users", userId, "intercomHash"];
  const __qs = cleanQuery({
    platform: platform,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserIntercomHash" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.platform
 * Type of the platform,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserIntercomHashInput(
  {
    userId,
    platform,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserIntercomHash.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserIntercomHash;
  const __uriData = buildGetUserIntercomHashURI(
    {
      userId,
      platform,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * HMAC Digest for the userId, used for the identity verification of intercom
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.platform
 * Type of the platform,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserIntercomHash(
  {
    userId,
    platform,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserIntercomHash.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserIntercomHash.Output> {
  const httpRequest = buildGetUserIntercomHashInput(
    {
      userId,
      platform,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserIntercomHash.Output;
}

/**
 * Build the "getUserInvitation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserInvitationURI(
  {
    userId,
    invitationId,
    accessToken,
  }: Pick<
    SencropTypes.GetUserInvitation.Input,
    "userId" | "invitationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (invitationId == null) {
    throw new Error(
      "Missing required parameter : invitationId. Value : " + invitationId
    );
  }

  const __pathParts = ["users", userId, "invitations", invitationId];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserInvitation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserInvitationInput(
  {
    userId,
    invitationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserInvitation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserInvitation;
  const __uriData = buildGetUserInvitationURI(
    {
      userId,
      invitationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's invitation.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserInvitation(
  {
    userId,
    invitationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserInvitation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserInvitation.Output> {
  const httpRequest = buildGetUserInvitationInput(
    {
      userId,
      invitationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserInvitation.Output;
}

/**
 * Build the "getUserInvitations" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {array} [parameters.withTypes],
 * @param {array} [parameters.withStatuses],
 * @param {array} [parameters.withModes],
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserInvitationsURI(
  {
    userId,
    limit,
    start,
    withTypes,
    withStatuses,
    withModes,
    accessToken,
  }: Pick<
    SencropTypes.GetUserInvitations.Input,
    | "userId"
    | "limit"
    | "start"
    | "withTypes"
    | "withStatuses"
    | "withModes"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (limit == null) {
    throw new Error("Missing required parameter : limit. Value : " + limit);
  }
  if (start == null) {
    throw new Error("Missing required parameter : start. Value : " + start);
  }

  const __pathParts = ["users", userId, "invitations"];
  const __qs = cleanQuery({
    limit: limit,
    start: start,
    withTypes: withTypes,
    withStatuses: withStatuses,
    withModes: withModes,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserInvitations" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {array} [parameters.withTypes],
 * @param {array} [parameters.withStatuses],
 * @param {array} [parameters.withModes],
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserInvitationsInput(
  {
    userId,
    limit,
    start,
    withTypes,
    withStatuses,
    withModes,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserInvitations.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserInvitations;
  const __uriData = buildGetUserInvitationsURI(
    {
      userId,
      limit,
      start,
      withTypes,
      withStatuses,
      withModes,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's invitations.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {array} [parameters.withTypes],
 * @param {array} [parameters.withStatuses],
 * @param {array} [parameters.withModes],
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserInvitations(
  {
    userId,
    limit,
    start,
    withTypes,
    withStatuses,
    withModes,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserInvitations.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserInvitations.Output> {
  const httpRequest = buildGetUserInvitationsInput(
    {
      userId,
      limit,
      start,
      withTypes,
      withStatuses,
      withModes,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserInvitations.Output;
}

/**
 * Build the "getUserOrganisations" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserOrganisationsURI(
  {
    userId,
    accessToken,
  }: Pick<SencropTypes.GetUserOrganisations.Input, "userId" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }

  const __pathParts = ["users", userId, "organisations"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserOrganisations" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserOrganisationsInput(
  {
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserOrganisations.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserOrganisations;
  const __uriData = buildGetUserOrganisationsURI(
    {
      userId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get all user's organisations.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserOrganisations(
  {
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserOrganisations.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserOrganisations.Output> {
  const httpRequest = buildGetUserOrganisationsInput(
    {
      userId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserOrganisations.Output;
}

/**
 * Build the "postUserOrganisation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostUserOrganisationURI(
  {
    userId,
    accessToken,
  }: Pick<SencropTypes.PostUserOrganisation.Input, "userId" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }

  const __pathParts = ["users", userId, "organisations"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postUserOrganisation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostUserOrganisationInput(
  {
    body,
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserOrganisation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostUserOrganisation.Input["body"]> {
  const __method = APIMethods.postUserOrganisation;
  const __uriData = buildPostUserOrganisationURI(
    {
      userId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Creates a new organisation for a user (add him as owner).
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postUserOrganisation(
  {
    body,
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserOrganisation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostUserOrganisation.Output> {
  const httpRequest = buildPostUserOrganisationInput(
    {
      body,
      userId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostUserOrganisation.Output;
}

/**
 * Build the "getUserPendingInvitation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserPendingInvitationURI(
  {
    userId,
    invitationId,
    accessToken,
  }: Pick<
    SencropTypes.GetUserPendingInvitation.Input,
    "userId" | "invitationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (invitationId == null) {
    throw new Error(
      "Missing required parameter : invitationId. Value : " + invitationId
    );
  }

  const __pathParts = ["users", userId, "pendingInvitations", invitationId];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserPendingInvitation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserPendingInvitationInput(
  {
    userId,
    invitationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserPendingInvitation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserPendingInvitation;
  const __uriData = buildGetUserPendingInvitationURI(
    {
      userId,
      invitationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's pending invitation.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserPendingInvitation(
  {
    userId,
    invitationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserPendingInvitation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserPendingInvitation.Output> {
  const httpRequest = buildGetUserPendingInvitationInput(
    {
      userId,
      invitationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserPendingInvitation.Output;
}

/**
 * Build the "getUserPendingInvitations" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserPendingInvitationsURI(
  {
    userId,
    limit,
    start,
    accessToken,
  }: Pick<
    SencropTypes.GetUserPendingInvitations.Input,
    "userId" | "limit" | "start" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (limit == null) {
    throw new Error("Missing required parameter : limit. Value : " + limit);
  }
  if (start == null) {
    throw new Error("Missing required parameter : start. Value : " + start);
  }

  const __pathParts = ["users", userId, "pendingInvitations"];
  const __qs = cleanQuery({
    limit: limit,
    start: start,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserPendingInvitations" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserPendingInvitationsInput(
  {
    userId,
    limit,
    start,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserPendingInvitations.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserPendingInvitations;
  const __uriData = buildGetUserPendingInvitationsURI(
    {
      userId,
      limit,
      start,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's pending invitations.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.limit
 * The number of items to retrieve,
 * @param {number} parameters.start
 * The index in results,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserPendingInvitations(
  {
    userId,
    limit,
    start,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserPendingInvitations.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserPendingInvitations.Output> {
  const httpRequest = buildGetUserPendingInvitationsInput(
    {
      userId,
      limit,
      start,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserPendingInvitations.Output;
}

/**
 * Build the "getUserPreferences" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserPreferencesURI(
  {
    userId,
    accessToken,
  }: Pick<SencropTypes.GetUserPreferences.Input, "userId" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }

  const __pathParts = ["users", userId, "preferences"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserPreferences" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserPreferencesInput(
  {
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserPreferences.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserPreferences;
  const __uriData = buildGetUserPreferencesURI(
    {
      userId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get an user's preferences.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserPreferences(
  {
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserPreferences.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserPreferences.Output> {
  const httpRequest = buildGetUserPreferencesInput(
    {
      userId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserPreferences.Output;
}

/**
 * Build the "putUserPreferences" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutUserPreferencesURI(
  {
    userId,
    accessToken,
  }: Pick<SencropTypes.PutUserPreferences.Input, "userId" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }

  const __pathParts = ["users", userId, "preferences"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putUserPreferences" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutUserPreferencesInput(
  {
    body,
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserPreferences.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutUserPreferences.Input["body"]> {
  const __method = APIMethods.putUserPreferences;
  const __uriData = buildPutUserPreferencesURI(
    {
      userId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Update a user's preferences.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putUserPreferences(
  {
    body,
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserPreferences.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutUserPreferences.Output> {
  const httpRequest = buildPutUserPreferencesInput(
    {
      body,
      userId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutUserPreferences.Output;
}

/**
 * Build the "getUserStatistics" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {string} parameters.startDate
 * The statistics start date,
 * @param {string} parameters.endDate
 * The statistics end date,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {array} parameters.ranges
 * The ranges to get the data from,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.interval]
 * The interval of data (Accepted value : 31 days for 1h interval, 1 year for 1d interval, 5y for 1w interval),
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserStatisticsURI(
  {
    userId,
    latitude,
    longitude,
    startDate,
    endDate,
    measures,
    ranges,
    patched,
    includeHistory,
    interval,
    withCalibration,
    timeZone,
    accessToken,
  }: Pick<
    SencropTypes.GetUserStatistics.Input,
    | "userId"
    | "latitude"
    | "longitude"
    | "startDate"
    | "endDate"
    | "measures"
    | "ranges"
    | "patched"
    | "includeHistory"
    | "interval"
    | "withCalibration"
    | "timeZone"
    | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (latitude == null) {
    throw new Error(
      "Missing required parameter : latitude. Value : " + latitude
    );
  }
  if (longitude == null) {
    throw new Error(
      "Missing required parameter : longitude. Value : " + longitude
    );
  }
  if (startDate == null) {
    throw new Error(
      "Missing required parameter : startDate. Value : " + startDate
    );
  }
  if (endDate == null) {
    throw new Error("Missing required parameter : endDate. Value : " + endDate);
  }
  if (measures == null) {
    throw new Error(
      "Missing required parameter : measures. Value : " + measures
    );
  }
  if (ranges == null) {
    throw new Error("Missing required parameter : ranges. Value : " + ranges);
  }

  const __pathParts = ["users", userId, "statistics"];
  const __qs = cleanQuery({
    latitude: latitude,
    longitude: longitude,
    startDate: startDate,
    endDate: endDate,
    measures: measures,
    ranges: ranges,
    patched: patched,
    includeHistory: includeHistory,
    interval: interval,
    withCalibration: withCalibration,
    timeZone: timeZone,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserStatistics" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {string} parameters.startDate
 * The statistics start date,
 * @param {string} parameters.endDate
 * The statistics end date,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {array} parameters.ranges
 * The ranges to get the data from,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.interval]
 * The interval of data (Accepted value : 31 days for 1h interval, 1 year for 1d interval, 5y for 1w interval),
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserStatisticsInput(
  {
    userId,
    latitude,
    longitude,
    startDate,
    endDate,
    measures,
    ranges,
    patched,
    includeHistory,
    interval,
    withCalibration,
    timeZone,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserStatistics.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserStatistics;
  const __uriData = buildGetUserStatisticsURI(
    {
      userId,
      latitude,
      longitude,
      startDate,
      endDate,
      measures,
      ranges,
      patched,
      includeHistory,
      interval,
      withCalibration,
      timeZone,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get a user's statistics for a given location.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {string} parameters.startDate
 * The statistics start date,
 * @param {string} parameters.endDate
 * The statistics end date,
 * @param {array} parameters.measures
 * The measures to read,
 * @param {array} parameters.ranges
 * The ranges to get the data from,
 * @param {boolean} [parameters.patched]
 * Patched data are augmented data with internal algorithms. Setting patched to false, will return raw data from device only.,
 * @param {boolean} [parameters.includeHistory]
 * If must include device replacements or not,
 * @param {string} [parameters.interval]
 * The interval of data (Accepted value : 31 days for 1h interval, 1 year for 1d interval, 5y for 1w interval),
 * @param {boolean} [parameters.withCalibration]
 * Wether the calibration of rain falls must be taken in count,
 * @param {string} [parameters.timeZone]
 * The timezone of the data,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserStatistics(
  {
    userId,
    latitude,
    longitude,
    startDate,
    endDate,
    measures,
    ranges,
    patched,
    includeHistory,
    interval,
    withCalibration,
    timeZone,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserStatistics.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserStatistics.Output> {
  const httpRequest = buildGetUserStatisticsInput(
    {
      userId,
      latitude,
      longitude,
      startDate,
      endDate,
      measures,
      ranges,
      patched,
      includeHistory,
      interval,
      withCalibration,
      timeZone,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserStatistics.Output;
}

/**
 * Build the "getUserTestingSessions" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetUserTestingSessionsURI(
  {
    userId,
    accessToken,
  }: Pick<SencropTypes.GetUserTestingSessions.Input, "userId" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }

  const __pathParts = ["users", userId, "testingSessions"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getUserTestingSessions" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetUserTestingSessionsInput(
  {
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserTestingSessions.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getUserTestingSessions;
  const __uriData = buildGetUserTestingSessionsURI(
    {
      userId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Gets informations for all the currently tested devices
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getUserTestingSessions(
  {
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetUserTestingSessions.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetUserTestingSessions.Output> {
  const httpRequest = buildGetUserTestingSessionsInput(
    {
      userId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetUserTestingSessions.Output;
}

/**
 * Build the "putUserTestingSessions" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutUserTestingSessionsURI(
  {
    userId,
    accessToken,
  }: Pick<SencropTypes.PutUserTestingSessions.Input, "userId" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }

  const __pathParts = ["users", userId, "testingSessions"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putUserTestingSessions" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutUserTestingSessionsInput(
  {
    body,
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserTestingSessions.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutUserTestingSessions.Input["body"]> {
  const __method = APIMethods.putUserTestingSessions;
  const __uriData = buildPutUserTestingSessionsURI(
    {
      userId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Historize the tested devices and measures from assembly lines
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putUserTestingSessions(
  {
    body,
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserTestingSessions.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutUserTestingSessions.Output> {
  const httpRequest = buildPutUserTestingSessionsInput(
    {
      body,
      userId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutUserTestingSessions.Output;
}

/**
 * Build the "getWeatherForecastModel" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {boolean} [parameters.marketingConsent]
 * Marketing consent,
 * @param {string} [parameters.email]
 * Customer email
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetWeatherForecastModelURI(
  {
    latitude,
    longitude,
    marketingConsent,
    email,
  }: Pick<
    SencropTypes.GetWeatherForecastModel.Input,
    "latitude" | "longitude" | "marketingConsent" | "email"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (latitude == null) {
    throw new Error(
      "Missing required parameter : latitude. Value : " + latitude
    );
  }
  if (longitude == null) {
    throw new Error(
      "Missing required parameter : longitude. Value : " + longitude
    );
  }

  const __pathParts = ["weatherForecastModel"];
  const __qs = cleanQuery({
    latitude: latitude,
    longitude: longitude,
    marketingConsent: marketingConsent,
    email: email,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getWeatherForecastModel" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {boolean} [parameters.marketingConsent]
 * Marketing consent,
 * @param {string} [parameters.email]
 * Customer email,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetWeatherForecastModelInput(
  {
    latitude,
    longitude,
    marketingConsent,
    email,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetWeatherForecastModel.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getWeatherForecastModel;
  const __uriData = buildGetWeatherForecastModelURI(
    {
      latitude,
      longitude,
      marketingConsent,
      email,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get the best weather forecast model for a location.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.latitude
 * The latitude of the data,
 * @param {number} parameters.longitude
 * The longitude of the data,
 * @param {boolean} [parameters.marketingConsent]
 * Marketing consent,
 * @param {string} [parameters.email]
 * Customer email,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getWeatherForecastModel(
  {
    latitude,
    longitude,
    marketingConsent,
    email,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetWeatherForecastModel.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetWeatherForecastModel.Output> {
  const httpRequest = buildGetWeatherForecastModelInput(
    {
      latitude,
      longitude,
      marketingConsent,
      email,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetWeatherForecastModel.Output;
}

/**
 * Build the "getWeatherLive" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.geohash
 * The geohash to lookup,
 * @param {string} parameters.measureType
 * The measures to read,
 * @param {string} [parameters.timeFrame]
 * The aggregatable measure,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetWeatherLiveURI(
  {
    geohash,
    measureType,
    timeFrame,
    accessToken,
  }: Pick<
    SencropTypes.GetWeatherLive.Input,
    "geohash" | "measureType" | "timeFrame" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (geohash == null) {
    throw new Error("Missing required parameter : geohash. Value : " + geohash);
  }
  if (measureType == null) {
    throw new Error(
      "Missing required parameter : measureType. Value : " + measureType
    );
  }

  const __pathParts = ["weather", "live"];
  const __qs = cleanQuery({
    geohash: geohash,
    measureType: measureType,
    timeFrame: timeFrame,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getWeatherLive" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.geohash
 * The geohash to lookup,
 * @param {string} parameters.measureType
 * The measures to read,
 * @param {string} [parameters.timeFrame]
 * The aggregatable measure,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetWeatherLiveInput(
  {
    geohash,
    measureType,
    timeFrame,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetWeatherLive.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getWeatherLive;
  const __uriData = buildGetWeatherLiveURI(
    {
      geohash,
      measureType,
      timeFrame,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Get last measure (for a specific type) from devices in a given area (1 hour for RAIN_FALL).
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.geohash
 * The geohash to lookup,
 * @param {string} parameters.measureType
 * The measures to read,
 * @param {string} [parameters.timeFrame]
 * The aggregatable measure,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getWeatherLive(
  {
    geohash,
    measureType,
    timeFrame,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetWeatherLive.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetWeatherLive.Output> {
  const httpRequest = buildGetWeatherLiveInput(
    {
      geohash,
      measureType,
      timeFrame,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetWeatherLive.Output;
}

/**
 * Build the "handleAggregationsCompute" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildHandleAggregationsComputeURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.HandleAggregationsCompute.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["crons", "handleAggregationsCompute"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "handleAggregationsCompute" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildHandleAggregationsComputeInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.HandleAggregationsCompute.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.handleAggregationsCompute;
  const __uriData = buildHandleAggregationsComputeURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Pre-compute date aggregations
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function handleAggregationsCompute(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.HandleAggregationsCompute.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.HandleAggregationsCompute.Output> {
  const httpRequest = buildHandleAggregationsComputeInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.HandleAggregationsCompute.Output;
}

/**
 * Build the "handleAlertCheck" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildHandleAlertCheckURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.HandleAlertCheck.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["crons", "handleAlertCheck"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "handleAlertCheck" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildHandleAlertCheckInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.HandleAlertCheck.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.handleAlertCheck;
  const __uriData = buildHandleAlertCheckURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Check all alerts and and notify
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function handleAlertCheck(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.HandleAlertCheck.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.HandleAlertCheck.Output> {
  const httpRequest = buildHandleAlertCheckInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.HandleAlertCheck.Output;
}

/**
 * Build the "handleAlertRepetition" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildHandleAlertRepetitionURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.HandleAlertRepetition.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["crons", "handleAlertRepetition"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "handleAlertRepetition" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildHandleAlertRepetitionInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.HandleAlertRepetition.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.handleAlertRepetition;
  const __uriData = buildHandleAlertRepetitionURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Checking alerts who need to be repeated and trigger it (without checking live conditions)
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function handleAlertRepetition(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.HandleAlertRepetition.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.HandleAlertRepetition.Output> {
  const httpRequest = buildHandleAlertRepetitionInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.HandleAlertRepetition.Output;
}

/**
 * Build the "postAircallIncomingCalls" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostAircallIncomingCallsURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["aircall", "calls"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postAircallIncomingCalls" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostAircallIncomingCallsInput(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostAircallIncomingCalls.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostAircallIncomingCalls.Input["body"]> {
  const __method = APIMethods.postAircallIncomingCalls;
  const __uriData = buildPostAircallIncomingCallsURI(
    {},
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * undefined
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postAircallIncomingCalls(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostAircallIncomingCalls.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostAircallIncomingCalls.Output> {
  const httpRequest = buildPostAircallIncomingCallsInput(
    {
      body,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostAircallIncomingCalls.Output;
}

/**
 * Build the "postAlertsTrialDisable" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostAlertsTrialDisableURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.PostAlertsTrialDisable.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["crons", "disableTrialAlerts"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postAlertsTrialDisable" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostAlertsTrialDisableInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostAlertsTrialDisable.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postAlertsTrialDisable;
  const __uriData = buildPostAlertsTrialDisableURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Disable alerts for organisations who trial is ended
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postAlertsTrialDisable(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostAlertsTrialDisable.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostAlertsTrialDisable.Output> {
  const httpRequest = buildPostAlertsTrialDisableInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostAlertsTrialDisable.Output;
}

/**
 * Build the "postApplicationEvent" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the message consumption,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostApplicationEventURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.PostApplicationEvent.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["consumers", "applicationEvent"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postApplicationEvent" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the message consumption,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostApplicationEventInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostApplicationEvent.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postApplicationEvent;
  const __uriData = buildPostApplicationEventURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Handle all applications events.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the message consumption,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postApplicationEvent(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostApplicationEvent.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostApplicationEvent.Output> {
  const httpRequest = buildPostApplicationEventInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostApplicationEvent.Output;
}

/**
 * Build the "postBulkExportsSupport" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * User account to retrieve,
 * @param {string} parameters.emailRecipient
 * Recipient email of the export,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostBulkExportsSupportURI(
  {
    userId,
    emailRecipient,
    accessToken,
  }: Pick<
    SencropTypes.PostBulkExportsSupport.Input,
    "userId" | "emailRecipient" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (emailRecipient == null) {
    throw new Error(
      "Missing required parameter : emailRecipient. Value : " + emailRecipient
    );
  }

  const __pathParts = ["support", "bulkExports"];
  const __qs = cleanQuery({
    userId: userId,
    emailRecipient: emailRecipient,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postBulkExportsSupport" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * User account to retrieve,
 * @param {string} parameters.emailRecipient
 * Recipient email of the export,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostBulkExportsSupportInput(
  {
    body,
    userId,
    emailRecipient,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostBulkExportsSupport.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostBulkExportsSupport.Input["body"]> {
  const __method = APIMethods.postBulkExportsSupport;
  const __uriData = buildPostBulkExportsSupportURI(
    {
      userId,
      emailRecipient,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Create a batch of exports job
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * User account to retrieve,
 * @param {string} parameters.emailRecipient
 * Recipient email of the export,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postBulkExportsSupport(
  {
    body,
    userId,
    emailRecipient,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostBulkExportsSupport.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostBulkExportsSupport.Output> {
  const httpRequest = buildPostBulkExportsSupportInput(
    {
      body,
      userId,
      emailRecipient,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostBulkExportsSupport.Output;
}

/**
 * Build the "postCSVArvalisMileosData" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostCSVArvalisMileosDataURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.PostCSVArvalisMileosData.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["crons", "arvalisMileosData"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postCSVArvalisMileosData" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostCSVArvalisMileosDataInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCSVArvalisMileosData.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postCSVArvalisMileosData;
  const __uriData = buildPostCSVArvalisMileosDataURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
  * Arvalis-Mileos allows users to get some insights into
 plants health through our data. This bridge brings them those data.
 Data should be sent at 6, 11 and 18 each day.

  * @return {Object}
  * The object describing the built parameters
  * @param {Object} parameters
  * The parameters provided to build them (destructured)
 * @param {string} parameters.date
  * The date of the cron execution,
 * @param {string} [parameters.authorization]
  * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
  * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
  * @param {Object} options
  * Options to override Ky request configuration
  * @return {Object}
  * The HTTP response
  */
async function postCSVArvalisMileosData(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCSVArvalisMileosData.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostCSVArvalisMileosData.Output> {
  const httpRequest = buildPostCSVArvalisMileosDataInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostCSVArvalisMileosData.Output;
}

/**
 * Build the "postCSVArvalisMileosInitialData" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostCSVArvalisMileosInitialDataURI(
  {
    date,
    accessToken,
  }: Pick<
    SencropTypes.PostCSVArvalisMileosInitialData.Input,
    "date" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["crons", "arvalisMileosInitialData"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postCSVArvalisMileosInitialData" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostCSVArvalisMileosInitialDataInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCSVArvalisMileosInitialData.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postCSVArvalisMileosInitialData;
  const __uriData = buildPostCSVArvalisMileosInitialDataURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Put refill data for recent activations.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postCSVArvalisMileosInitialData(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCSVArvalisMileosInitialData.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostCSVArvalisMileosInitialData.Output> {
  const httpRequest = buildPostCSVArvalisMileosInitialDataInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostCSVArvalisMileosInitialData.Output;
}

/**
 * Build the "postCSVBatchProd" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostCSVBatchProdURI(
  { accessToken }: Pick<SencropTypes.PostCSVBatchProd.Input, "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["batchProd"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postCSVBatchProd" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostCSVBatchProdInput(
  {
    body,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCSVBatchProd.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostCSVBatchProd.Input["body"]> {
  const __method = APIMethods.postCSVBatchProd;
  const __uriData = buildPostCSVBatchProdURI(
    {
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Process batch prod into database
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postCSVBatchProd(
  {
    body,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCSVBatchProd.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostCSVBatchProd.Output> {
  const httpRequest = buildPostCSVBatchProdInput(
    {
      body,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostCSVBatchProd.Output;
}

/**
 * Build the "postCSVCTIFLEmail" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostCSVCTIFLEmailURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.PostCSVCTIFLEmail.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["crons", "ctiflData"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postCSVCTIFLEmail" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostCSVCTIFLEmailInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCSVCTIFLEmail.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postCSVCTIFLEmail;
  const __uriData = buildPostCSVCTIFLEmailURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
  * CTIFL allows users to get some insights into
 plants health through our data. This bridge brings them those data.
 Data should be sent at 1 or 2 (depending on offsets) each day.
  * @return {Object}
  * The object describing the built parameters
  * @param {Object} parameters
  * The parameters provided to build them (destructured)
 * @param {string} parameters.date
  * The date of the cron execution,
 * @param {string} [parameters.authorization]
  * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
  * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
  * @param {Object} options
  * Options to override Ky request configuration
  * @return {Object}
  * The HTTP response
  */
async function postCSVCTIFLEmail(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCSVCTIFLEmail.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostCSVCTIFLEmail.Output> {
  const httpRequest = buildPostCSVCTIFLEmailInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostCSVCTIFLEmail.Output;
}

/**
 * Build the "postCSVMovidaEmail" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostCSVMovidaEmailURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.PostCSVMovidaEmail.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["crons", "movidaEmail"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postCSVMovidaEmail" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostCSVMovidaEmailInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCSVMovidaEmail.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postCSVMovidaEmail;
  const __uriData = buildPostCSVMovidaEmailURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
  * Movida allows users to get some insights into
 plants health through our data. This bridge brings them those data.
 Data should be sent at 1 or 2 (depending on offsets) each day.
  * @return {Object}
  * The object describing the built parameters
  * @param {Object} parameters
  * The parameters provided to build them (destructured)
 * @param {string} parameters.date
  * The date of the cron execution,
 * @param {string} [parameters.authorization]
  * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
  * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
  * @param {Object} options
  * Options to override Ky request configuration
  * @return {Object}
  * The HTTP response
  */
async function postCSVMovidaEmail(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCSVMovidaEmail.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostCSVMovidaEmail.Output> {
  const httpRequest = buildPostCSVMovidaEmailInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostCSVMovidaEmail.Output;
}

/**
 * Build the "postCSVMovidaInitialEmail" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostCSVMovidaInitialEmailURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.PostCSVMovidaInitialEmail.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["crons", "movidaInitialEmail"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postCSVMovidaInitialEmail" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostCSVMovidaInitialEmailInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCSVMovidaInitialEmail.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postCSVMovidaInitialEmail;
  const __uriData = buildPostCSVMovidaInitialEmailURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Movida initial run.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postCSVMovidaInitialEmail(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCSVMovidaInitialEmail.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostCSVMovidaInitialEmail.Output> {
  const httpRequest = buildPostCSVMovidaInitialEmailInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostCSVMovidaInitialEmail.Output;
}

/**
 * Build the "postCSVRimproData" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostCSVRimproDataURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.PostCSVRimproData.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["crons", "rimproData"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postCSVRimproData" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostCSVRimproDataInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCSVRimproData.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postCSVRimproData;
  const __uriData = buildPostCSVRimproDataURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Rimpro OAD bridge.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postCSVRimproData(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCSVRimproData.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostCSVRimproData.Output> {
  const httpRequest = buildPostCSVRimproDataInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostCSVRimproData.Output;
}

/**
 * Build the "postCSVTTWData" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostCSVTTWDataURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.PostCSVTTWData.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["crons", "ttwData"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postCSVTTWData" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostCSVTTWDataInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCSVTTWData.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postCSVTTWData;
  const __uriData = buildPostCSVTTWDataURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * TTW bridge.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postCSVTTWData(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCSVTTWData.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostCSVTTWData.Output> {
  const httpRequest = buildPostCSVTTWDataInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostCSVTTWData.Output;
}

/**
 * Build the "postChargebeeEvents" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostChargebeeEventsURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["chargebee", "events"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postChargebeeEvents" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Basic mecanism,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostChargebeeEventsInput(
  {
    body,
    authorization,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostChargebeeEvents.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostChargebeeEvents.Input["body"]> {
  const __method = APIMethods.postChargebeeEvents;
  const __uriData = buildPostChargebeeEventsURI({}, { baseURL: __baseURL });

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Queue chargebee event for processing.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Basic mecanism,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postChargebeeEvents(
  {
    body,
    authorization,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostChargebeeEvents.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostChargebeeEvents.Output> {
  const httpRequest = buildPostChargebeeEventsInput(
    {
      body,
      authorization,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostChargebeeEvents.Output;
}

/**
 * Build the "postCloudwatchEvents" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the message consumption,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostCloudwatchEventsURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.PostCloudwatchEvents.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["consumers", "cloudwatchEvents"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postCloudwatchEvents" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the message consumption,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostCloudwatchEventsInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCloudwatchEvents.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postCloudwatchEvents;
  const __uriData = buildPostCloudwatchEventsURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Handle cloudwatch events.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the message consumption,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postCloudwatchEvents(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCloudwatchEvents.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostCloudwatchEvents.Output> {
  const httpRequest = buildPostCloudwatchEventsInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostCloudwatchEvents.Output;
}

/**
 * Build the "postCropsHits" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostCropsHitsURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.PostCropsHits.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["crons", "cropsHits"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postCropsHits" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostCropsHitsInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCropsHits.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postCropsHits;
  const __uriData = buildPostCropsHitsURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Update crops hits regurlarly.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postCropsHits(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostCropsHits.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostCropsHits.Output> {
  const httpRequest = buildPostCropsHitsInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostCropsHits.Output;
}

/**
 * Build the "postDataDeviceSituation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.deviceId
 * The device id
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostDataDeviceSituationURI(
  { deviceId }: Pick<SencropTypes.PostDataDeviceSituation.Input, "deviceId">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }

  const __pathParts = ["data", "devices", deviceId, "situation"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postDataDeviceSituation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Basic mecanism,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostDataDeviceSituationInput(
  {
    body,
    deviceId,
    authorization,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostDataDeviceSituation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostDataDeviceSituation.Input["body"]> {
  const __method = APIMethods.postDataDeviceSituation;
  const __uriData = buildPostDataDeviceSituationURI(
    {
      deviceId,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Add a new situation to a device
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Basic mecanism,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postDataDeviceSituation(
  {
    body,
    deviceId,
    authorization,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostDataDeviceSituation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostDataDeviceSituation.Output> {
  const httpRequest = buildPostDataDeviceSituationInput(
    {
      body,
      deviceId,
      authorization,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostDataDeviceSituation.Output;
}

/**
 * Build the "postDataWeatherForecastModel" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostDataWeatherForecastModelURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["data", "weatherForecastModel"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postDataWeatherForecastModel" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Basic mecanism,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostDataWeatherForecastModelInput(
  {
    body,
    authorization,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostDataWeatherForecastModel.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostDataWeatherForecastModel.Input["body"]> {
  const __method = APIMethods.postDataWeatherForecastModel;
  const __uriData = buildPostDataWeatherForecastModelURI(
    {},
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Add/Update a new forecast model associated to a location
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Basic mecanism,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postDataWeatherForecastModel(
  {
    body,
    authorization,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostDataWeatherForecastModel.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostDataWeatherForecastModel.Output> {
  const httpRequest = buildPostDataWeatherForecastModelInput(
    {
      body,
      authorization,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostDataWeatherForecastModel.Output;
}

/**
 * Build the "postDeviceCleanIngestionCacheSupport" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostDeviceCleanIngestionCacheSupportURI(
  {
    deviceId,
    accessToken,
  }: Pick<
    SencropTypes.PostDeviceCleanIngestionCacheSupport.Input,
    "deviceId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }

  const __pathParts = ["support", "devices", deviceId, "cleanIngestionCache"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postDeviceCleanIngestionCacheSupport" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostDeviceCleanIngestionCacheSupportInput(
  {
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostDeviceCleanIngestionCacheSupport.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postDeviceCleanIngestionCacheSupport;
  const __uriData = buildPostDeviceCleanIngestionCacheSupportURI(
    {
      deviceId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Clean the ingestion cache for one device
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postDeviceCleanIngestionCacheSupport(
  {
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostDeviceCleanIngestionCacheSupport.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostDeviceCleanIngestionCacheSupport.Output> {
  const httpRequest = buildPostDeviceCleanIngestionCacheSupportInput(
    {
      deviceId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostDeviceCleanIngestionCacheSupport.Output;
}

/**
 * Build the "postDeviceReplacement" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {number} parameters.newDeviceId
 * The device id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostDeviceReplacementURI(
  {
    deviceId,
    newDeviceId,
    accessToken,
  }: Pick<
    SencropTypes.PostDeviceReplacement.Input,
    "deviceId" | "newDeviceId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }
  if (newDeviceId == null) {
    throw new Error(
      "Missing required parameter : newDeviceId. Value : " + newDeviceId
    );
  }

  const __pathParts = [
    "support",
    "devices",
    deviceId,
    "replacement",
    newDeviceId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postDeviceReplacement" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {number} parameters.newDeviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostDeviceReplacementInput(
  {
    body,
    deviceId,
    newDeviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostDeviceReplacement.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostDeviceReplacement.Input["body"]> {
  const __method = APIMethods.postDeviceReplacement;
  const __uriData = buildPostDeviceReplacementURI(
    {
      deviceId,
      newDeviceId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Replace a device for another
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {number} parameters.newDeviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postDeviceReplacement(
  {
    body,
    deviceId,
    newDeviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostDeviceReplacement.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostDeviceReplacement.Output> {
  const httpRequest = buildPostDeviceReplacementInput(
    {
      body,
      deviceId,
      newDeviceId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostDeviceReplacement.Output;
}

/**
 * Build the "postFirehoseRecordPerLine" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostFirehoseRecordPerLineURI(
  {
    accessToken,
  }: Pick<SencropTypes.PostFirehoseRecordPerLine.Input, "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["transformers", "firehoseRecordPerLine"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postFirehoseRecordPerLine" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostFirehoseRecordPerLineInput(
  {
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostFirehoseRecordPerLine.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postFirehoseRecordPerLine;
  const __uriData = buildPostFirehoseRecordPerLineURI(
    {
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Sends records to S3.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postFirehoseRecordPerLine(
  {
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostFirehoseRecordPerLine.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostFirehoseRecordPerLine.Output> {
  const httpRequest = buildPostFirehoseRecordPerLineInput(
    {
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostFirehoseRecordPerLine.Output;
}

/**
 * Build the "postFirehoseStream" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the message consumption,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostFirehoseStreamURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.PostFirehoseStream.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["transformers", "firehoseStream"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postFirehoseStream" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the message consumption,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostFirehoseStreamInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostFirehoseStream.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postFirehoseStream;
  const __uriData = buildPostFirehoseStreamURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Sends devices data to S3.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the message consumption,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postFirehoseStream(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostFirehoseStream.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostFirehoseStream.Output> {
  const httpRequest = buildPostFirehoseStreamInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostFirehoseStream.Output;
}

/**
 * Build the "postForecastStorage" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the message consumption,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostForecastStorageURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.PostForecastStorage.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["consumers", "forecasts"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postForecastStorage" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the message consumption,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostForecastStorageInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostForecastStorage.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postForecastStorage;
  const __uriData = buildPostForecastStorageURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Store forecast event into s3 for futur analysis.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the message consumption,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postForecastStorage(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostForecastStorage.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostForecastStorage.Output> {
  const httpRequest = buildPostForecastStorageInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostForecastStorage.Output;
}

/**
 * Build the "postHubSpotContactSyncHook" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostHubSpotContactSyncHookURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["hubspot", "hooks", "contacts"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postHubSpotContactSyncHook" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostHubSpotContactSyncHookInput(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostHubSpotContactSyncHook.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostHubSpotContactSyncHook.Input["body"]> {
  const __method = APIMethods.postHubSpotContactSyncHook;
  const __uriData = buildPostHubSpotContactSyncHookURI(
    {},
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Hook for HubSpot contacts synchronization.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postHubSpotContactSyncHook(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostHubSpotContactSyncHook.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostHubSpotContactSyncHook.Output> {
  const httpRequest = buildPostHubSpotContactSyncHookInput(
    {
      body,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostHubSpotContactSyncHook.Output;
}

/**
 * Build the "postHubSpotContactTrialReactivation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostHubSpotContactTrialReactivationURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["hubspot", "hooks", "contacts", "trialReactivation"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postHubSpotContactTrialReactivation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostHubSpotContactTrialReactivationInput(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostHubSpotContactTrialReactivation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostHubSpotContactTrialReactivation.Input["body"]> {
  const __method = APIMethods.postHubSpotContactTrialReactivation;
  const __uriData = buildPostHubSpotContactTrialReactivationURI(
    {},
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Hook for HubSpot contacts trial reactivation.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postHubSpotContactTrialReactivation(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostHubSpotContactTrialReactivation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostHubSpotContactTrialReactivation.Output> {
  const httpRequest = buildPostHubSpotContactTrialReactivationInput(
    {
      body,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostHubSpotContactTrialReactivation.Output;
}

/**
 * Build the "postHubSpotDealSyncHook" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostHubSpotDealSyncHookURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["hubspot", "hooks", "deals"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postHubSpotDealSyncHook" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostHubSpotDealSyncHookInput(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostHubSpotDealSyncHook.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostHubSpotDealSyncHook.Input["body"]> {
  const __method = APIMethods.postHubSpotDealSyncHook;
  const __uriData = buildPostHubSpotDealSyncHookURI({}, { baseURL: __baseURL });

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Hook for HubSpot deal synchronization with Chargebee/Quotator
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postHubSpotDealSyncHook(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostHubSpotDealSyncHook.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostHubSpotDealSyncHook.Output> {
  const httpRequest = buildPostHubSpotDealSyncHookInput(
    {
      body,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostHubSpotDealSyncHook.Output;
}

/**
 * Build the "postHubSpotDeleteDealHook" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostHubSpotDeleteDealHookURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["hubspot", "hooks", "deals", "delete"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postHubSpotDeleteDealHook" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostHubSpotDeleteDealHookInput(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostHubSpotDeleteDealHook.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostHubSpotDeleteDealHook.Input["body"]> {
  const __method = APIMethods.postHubSpotDeleteDealHook;
  const __uriData = buildPostHubSpotDeleteDealHookURI(
    {},
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Hook for HubSpot deleted deals synchronization.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postHubSpotDeleteDealHook(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostHubSpotDeleteDealHook.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostHubSpotDeleteDealHook.Output> {
  const httpRequest = buildPostHubSpotDeleteDealHookInput(
    {
      body,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostHubSpotDeleteDealHook.Output;
}

/**
 * Build the "postIngestionData" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the message consumption,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostIngestionDataURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.PostIngestionData.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["consumers", "ingestionData"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postIngestionData" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the message consumption,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostIngestionDataInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostIngestionData.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postIngestionData;
  const __uriData = buildPostIngestionDataURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Threat Sigfox events..
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the message consumption,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postIngestionData(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostIngestionData.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostIngestionData.Output> {
  const httpRequest = buildPostIngestionDataInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostIngestionData.Output;
}

/**
 * Build the "postInvitationResend" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostInvitationResendURI(
  {
    invitationId,
    accessToken,
  }: Pick<
    SencropTypes.PostInvitationResend.Input,
    "invitationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (invitationId == null) {
    throw new Error(
      "Missing required parameter : invitationId. Value : " + invitationId
    );
  }

  const __pathParts = ["invitations", invitationId, "resend"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postInvitationResend" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostInvitationResendInput(
  {
    invitationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostInvitationResend.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postInvitationResend;
  const __uriData = buildPostInvitationResendURI(
    {
      invitationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Resend the invitation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postInvitationResend(
  {
    invitationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostInvitationResend.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostInvitationResend.Output> {
  const httpRequest = buildPostInvitationResendInput(
    {
      invitationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostInvitationResend.Output;
}

/**
 * Build the "postIrrelisData" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostIrrelisDataURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.PostIrrelisData.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["crons", "irrelisData"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postIrrelisData" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostIrrelisDataInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostIrrelisData.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postIrrelisData;
  const __uriData = buildPostIrrelisDataURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Sync datas from devices attached to irrelis module
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postIrrelisData(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostIrrelisData.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostIrrelisData.Output> {
  const httpRequest = buildPostIrrelisDataInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostIrrelisData.Output;
}

/**
 * Build the "postLambdaLogs" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the message consumption,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostLambdaLogsURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.PostLambdaLogs.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["consumers", "lambdaLogs"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postLambdaLogs" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the message consumption,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostLambdaLogsInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostLambdaLogs.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postLambdaLogs;
  const __uriData = buildPostLambdaLogsURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Sends Lambdas logs to ElasticSearch.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the message consumption,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postLambdaLogs(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostLambdaLogs.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostLambdaLogs.Output> {
  const httpRequest = buildPostLambdaLogsInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostLambdaLogs.Output;
}

/**
 * Build the "postLogin" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {boolean} [parameters.useJwt]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostLoginURI(
  { useJwt }: Pick<SencropTypes.PostLogin.Input, "useJwt">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["users", "sign_in"];
  const __qs = cleanQuery({
    useJWT: useJwt,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postLogin" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {boolean} [parameters.useJwt],
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostLoginInput(
  {
    body,
    useJwt,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostLogin.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostLogin.Input["body"]> {
  const __method = APIMethods.postLogin;
  const __uriData = buildPostLoginURI(
    {
      useJwt,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Log a user in
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {boolean} [parameters.useJwt],
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postLogin(
  {
    body,
    useJwt,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostLogin.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostLogin.Output> {
  const httpRequest = buildPostLoginInput(
    {
      body,
      useJwt,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostLogin.Output;
}

/**
 * Build the "postLostPassword" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostLostPasswordURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["password", "lost"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postLostPassword" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostLostPasswordInput(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostLostPassword.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostLostPassword.Input["body"]> {
  const __method = APIMethods.postLostPassword;
  const __uriData = buildPostLostPasswordURI({}, { baseURL: __baseURL });

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Request a password change e-mail.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postLostPassword(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostLostPassword.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostLostPassword.Output> {
  const httpRequest = buildPostLostPasswordInput(
    {
      body,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostLostPassword.Output;
}

/**
 * Build the "postMailgunWebHook" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.mailgunWebHookType
 * Type of webhook
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostMailgunWebHookURI(
  {
    mailgunWebHookType,
  }: Pick<SencropTypes.PostMailgunWebHook.Input, "mailgunWebHookType">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (mailgunWebHookType == null) {
    throw new Error(
      "Missing required parameter : mailgunWebHookType. Value : " +
        mailgunWebHookType
    );
  }

  const __pathParts = ["mailgun", "webhooks", mailgunWebHookType];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postMailgunWebHook" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.mailgunWebHookType
 * Type of webhook,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostMailgunWebHookInput(
  {
    body,
    mailgunWebHookType,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostMailgunWebHook.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostMailgunWebHook.Input["body"]> {
  const __method = APIMethods.postMailgunWebHook;
  const __uriData = buildPostMailgunWebHookURI(
    {
      mailgunWebHookType,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Queue Mailgun event for processing.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.mailgunWebHookType
 * Type of webhook,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postMailgunWebHook(
  {
    body,
    mailgunWebHookType,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostMailgunWebHook.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostMailgunWebHook.Output> {
  const httpRequest = buildPostMailgunWebHookInput(
    {
      body,
      mailgunWebHookType,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostMailgunWebHook.Output;
}

/**
 * Build the "postMailjetEvents" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostMailjetEventsURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["mailjet", "events"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postMailjetEvents" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostMailjetEventsInput(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostMailjetEvents.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostMailjetEvents.Input["body"]> {
  const __method = APIMethods.postMailjetEvents;
  const __uriData = buildPostMailjetEventsURI({}, { baseURL: __baseURL });

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Queue mailjet event for processing.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postMailjetEvents(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostMailjetEvents.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostMailjetEvents.Output> {
  const httpRequest = buildPostMailjetEventsInput(
    {
      body,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostMailjetEvents.Output;
}

/**
 * Build the "postMeteoGroupData" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostMeteoGroupDataURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.PostMeteoGroupData.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["crons", "meteoGroupData"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postMeteoGroupData" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostMeteoGroupDataInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostMeteoGroupData.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postMeteoGroupData;
  const __uriData = buildPostMeteoGroupDataURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Retrieve and store the Meteo Group data.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postMeteoGroupData(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostMeteoGroupData.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostMeteoGroupData.Output> {
  const httpRequest = buildPostMeteoGroupDataInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostMeteoGroupData.Output;
}

/**
 * Build the "postMovidaSetup" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostMovidaSetupURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.PostMovidaSetup.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["crons", "movidaSetup"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postMovidaSetup" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostMovidaSetupInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostMovidaSetup.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postMovidaSetup;
  const __uriData = buildPostMovidaSetupURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
  * Movida activations must be validated,
    this cron sends an email to them allowing a click
    and activate behavior.
  * @return {Object}
  * The object describing the built parameters
  * @param {Object} parameters
  * The parameters provided to build them (destructured)
 * @param {string} parameters.date
  * The date of the cron execution,
 * @param {string} [parameters.authorization]
  * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
  * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
  * @param {Object} options
  * Options to override Ky request configuration
  * @return {Object}
  * The HTTP response
  */
async function postMovidaSetup(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostMovidaSetup.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostMovidaSetup.Output> {
  const httpRequest = buildPostMovidaSetupInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostMovidaSetup.Output;
}

/**
 * Build the "postNetworkJoinRequest" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.networkId
 * The network id
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostNetworkJoinRequestURI(
  { networkId }: Pick<SencropTypes.PostNetworkJoinRequest.Input, "networkId">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (networkId == null) {
    throw new Error(
      "Missing required parameter : networkId. Value : " + networkId
    );
  }

  const __pathParts = ["networks", networkId, "invitations"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postNetworkJoinRequest" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.networkId
 * The network id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostNetworkJoinRequestInput(
  {
    body,
    networkId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostNetworkJoinRequest.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostNetworkJoinRequest.Input["body"]> {
  const __method = APIMethods.postNetworkJoinRequest;
  const __uriData = buildPostNetworkJoinRequestURI(
    {
      networkId,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Create an invitation for a network
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.networkId
 * The network id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postNetworkJoinRequest(
  {
    body,
    networkId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostNetworkJoinRequest.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostNetworkJoinRequest.Output> {
  const httpRequest = buildPostNetworkJoinRequestInput(
    {
      body,
      networkId,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostNetworkJoinRequest.Output;
}

/**
 * Build the "postNexmoEvents" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostNexmoEventsURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["nexmo", "events"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postNexmoEvents" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostNexmoEventsInput(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostNexmoEvents.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostNexmoEvents.Input["body"]> {
  const __method = APIMethods.postNexmoEvents;
  const __uriData = buildPostNexmoEventsURI({}, { baseURL: __baseURL });

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * undefined
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postNexmoEvents(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostNexmoEvents.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostNexmoEvents.Output> {
  const httpRequest = buildPostNexmoEventsInput(
    {
      body,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostNexmoEvents.Output;
}

/**
 * Build the "postOAuth2Acknowledge" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.grantType
 * The grant type to acknowledge,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostOAuth2AcknowledgeURI(
  {
    grantType,
    accessToken,
  }: Pick<
    SencropTypes.PostOAuth2Acknowledge.Input,
    "grantType" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (grantType == null) {
    throw new Error(
      "Missing required parameter : grantType. Value : " + grantType
    );
  }

  const __pathParts = ["oauth2", "acknowledge", grantType];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postOAuth2Acknowledge" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.grantType
 * The grant type to acknowledge,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostOAuth2AcknowledgeInput(
  {
    body,
    grantType,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOAuth2Acknowledge.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostOAuth2Acknowledge.Input["body"]> {
  const __method = APIMethods.postOAuth2Acknowledge;
  const __uriData = buildPostOAuth2AcknowledgeURI(
    {
      grantType,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
  * Implements the logic that allow the authentication frontend
 to get the [Redirection Endpoint](https://tools.ietf.org/html/rfc6749#section-3.1.2).
  * @return {Object}
  * The object describing the built parameters
  * @param {Object} body
  * The request body
  * @param {Object} parameters
  * The parameters provided to build them (destructured)
 * @param {string} parameters.grantType
  * The grant type to acknowledge,
 * @param {string} [parameters.authorization]
  * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
  * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
  * @param {Object} options
  * Options to override Ky request configuration
  * @return {Object}
  * The HTTP response
  */
async function postOAuth2Acknowledge(
  {
    body,
    grantType,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOAuth2Acknowledge.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostOAuth2Acknowledge.Output> {
  const httpRequest = buildPostOAuth2AcknowledgeInput(
    {
      body,
      grantType,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostOAuth2Acknowledge.Output;
}

/**
 * Build the "postOAuth2Token" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostOAuth2TokenURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["oauth2", "token"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postOAuth2Token" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Basic mecanism,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostOAuth2TokenInput(
  {
    body,
    authorization,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOAuth2Token.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostOAuth2Token.Input["body"]> {
  const __method = APIMethods.postOAuth2Token;
  const __uriData = buildPostOAuth2TokenURI({}, { baseURL: __baseURL });

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
  * Implements the [Token Endpoint](https://tools.ietf.org/html/rfc6749#section-3.2)
 as defined per the OAuth2 RFC.
  * @return {Object}
  * The object describing the built parameters
  * @param {Object} body
  * The request body
  * @param {Object} parameters
  * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
  * Authorization with Basic mecanism,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
  * @param {Object} options
  * Options to override Ky request configuration
  * @return {Object}
  * The HTTP response
  */
async function postOAuth2Token(
  {
    body,
    authorization,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOAuth2Token.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostOAuth2Token.Output> {
  const httpRequest = buildPostOAuth2TokenInput(
    {
      body,
      authorization,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostOAuth2Token.Output;
}

/**
 * Build the "postOrganisation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostOrganisationURI(
  { accessToken }: Pick<SencropTypes.PostOrganisation.Input, "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["organisations"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postOrganisation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostOrganisationInput(
  {
    body,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostOrganisation.Input["body"]> {
  const __method = APIMethods.postOrganisation;
  const __uriData = buildPostOrganisationURI(
    {
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Creates a new organisation.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postOrganisation(
  {
    body,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostOrganisation.Output> {
  const httpRequest = buildPostOrganisationInput(
    {
      body,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostOrganisation.Output;
}

/**
 * Build the "postOrganisationDeviceTransfer" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.authorId
 * Author of the new/updated accesses/shares,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostOrganisationDeviceTransferURI(
  {
    organisationId,
    deviceId,
    authorId,
    accessToken,
  }: Pick<
    SencropTypes.PostOrganisationDeviceTransfer.Input,
    "organisationId" | "deviceId" | "authorId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }
  if (authorId == null) {
    throw new Error(
      "Missing required parameter : authorId. Value : " + authorId
    );
  }

  const __pathParts = [
    "support",
    "organisations",
    organisationId,
    "devices",
    deviceId,
    "transfer",
  ];
  const __qs = cleanQuery({
    authorId: authorId,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postOrganisationDeviceTransfer" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.authorId
 * Author of the new/updated accesses/shares,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostOrganisationDeviceTransferInput(
  {
    organisationId,
    deviceId,
    authorId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisationDeviceTransfer.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postOrganisationDeviceTransfer;
  const __uriData = buildPostOrganisationDeviceTransferURI(
    {
      organisationId,
      deviceId,
      authorId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Transfer ownership of a device
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.authorId
 * Author of the new/updated accesses/shares,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postOrganisationDeviceTransfer(
  {
    organisationId,
    deviceId,
    authorId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisationDeviceTransfer.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostOrganisationDeviceTransfer.Output> {
  const httpRequest = buildPostOrganisationDeviceTransferInput(
    {
      organisationId,
      deviceId,
      authorId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostOrganisationDeviceTransfer.Output;
}

/**
 * Build the "postOrganisationEndTrialSupport" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostOrganisationEndTrialSupportURI(
  {
    organisationId,
    accessToken,
  }: Pick<
    SencropTypes.PostOrganisationEndTrialSupport.Input,
    "organisationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = ["support", "organisations", organisationId, "endTrial"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postOrganisationEndTrialSupport" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostOrganisationEndTrialSupportInput(
  {
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisationEndTrialSupport.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postOrganisationEndTrialSupport;
  const __uriData = buildPostOrganisationEndTrialSupportURI(
    {
      organisationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Remove the trial of an organisation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postOrganisationEndTrialSupport(
  {
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisationEndTrialSupport.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostOrganisationEndTrialSupport.Output> {
  const httpRequest = buildPostOrganisationEndTrialSupportInput(
    {
      organisationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostOrganisationEndTrialSupport.Output;
}

/**
 * Build the "postOrganisationNetworkMemberPlan" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.networkMemberId
 * The organisation id of the network member,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostOrganisationNetworkMemberPlanURI(
  {
    organisationId,
    networkMemberId,
    accessToken,
  }: Pick<
    SencropTypes.PostOrganisationNetworkMemberPlan.Input,
    "organisationId" | "networkMemberId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (networkMemberId == null) {
    throw new Error(
      "Missing required parameter : networkMemberId. Value : " + networkMemberId
    );
  }

  const __pathParts = [
    "organisations",
    organisationId,
    "networkMembers",
    networkMemberId,
    "plan",
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postOrganisationNetworkMemberPlan" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.networkMemberId
 * The organisation id of the network member,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostOrganisationNetworkMemberPlanInput(
  {
    body,
    organisationId,
    networkMemberId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisationNetworkMemberPlan.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostOrganisationNetworkMemberPlan.Input["body"]> {
  const __method = APIMethods.postOrganisationNetworkMemberPlan;
  const __uriData = buildPostOrganisationNetworkMemberPlanURI(
    {
      organisationId,
      networkMemberId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Add a plan to a organisation of a sponsored network
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.networkMemberId
 * The organisation id of the network member,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postOrganisationNetworkMemberPlan(
  {
    body,
    organisationId,
    networkMemberId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisationNetworkMemberPlan.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostOrganisationNetworkMemberPlan.Output> {
  const httpRequest = buildPostOrganisationNetworkMemberPlanInput(
    {
      body,
      organisationId,
      networkMemberId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostOrganisationNetworkMemberPlan.Output;
}

/**
 * Build the "postOrganisationPaymentComplete" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostOrganisationPaymentCompleteURI(
  {
    organisationId,
    accessToken,
  }: Pick<
    SencropTypes.PostOrganisationPaymentComplete.Input,
    "organisationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = ["organisations", organisationId, "paymentComplete"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postOrganisationPaymentComplete" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostOrganisationPaymentCompleteInput(
  {
    body,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisationPaymentComplete.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostOrganisationPaymentComplete.Input["body"]> {
  const __method = APIMethods.postOrganisationPaymentComplete;
  const __uriData = buildPostOrganisationPaymentCompleteURI(
    {
      organisationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Complete the payment flow of an organisation, if chargebee hook is delayed
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postOrganisationPaymentComplete(
  {
    body,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisationPaymentComplete.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostOrganisationPaymentComplete.Output> {
  const httpRequest = buildPostOrganisationPaymentCompleteInput(
    {
      body,
      organisationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostOrganisationPaymentComplete.Output;
}

/**
 * Build the "postOrganisationToNetworkSupport" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostOrganisationToNetworkSupportURI(
  {
    organisationId,
    accessToken,
  }: Pick<
    SencropTypes.PostOrganisationToNetworkSupport.Input,
    "organisationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = ["support", "organisations", organisationId, "toNetwork"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postOrganisationToNetworkSupport" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostOrganisationToNetworkSupportInput(
  {
    body,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisationToNetworkSupport.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostOrganisationToNetworkSupport.Input["body"]> {
  const __method = APIMethods.postOrganisationToNetworkSupport;
  const __uriData = buildPostOrganisationToNetworkSupportURI(
    {
      organisationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Convert an organisation to a network
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postOrganisationToNetworkSupport(
  {
    body,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisationToNetworkSupport.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostOrganisationToNetworkSupport.Output> {
  const httpRequest = buildPostOrganisationToNetworkSupportInput(
    {
      body,
      organisationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostOrganisationToNetworkSupport.Output;
}

/**
 * Build the "postOrganisationTrialReactivation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostOrganisationTrialReactivationURI(
  {
    organisationId,
    accessToken,
  }: Pick<
    SencropTypes.PostOrganisationTrialReactivation.Input,
    "organisationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = ["organisations", organisationId, "trialReactivation"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postOrganisationTrialReactivation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostOrganisationTrialReactivationInput(
  {
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisationTrialReactivation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postOrganisationTrialReactivation;
  const __uriData = buildPostOrganisationTrialReactivationURI(
    {
      organisationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Reactivate a trial organisation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postOrganisationTrialReactivation(
  {
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisationTrialReactivation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostOrganisationTrialReactivation.Output> {
  const httpRequest = buildPostOrganisationTrialReactivationInput(
    {
      organisationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostOrganisationTrialReactivation.Output;
}

/**
 * Build the "postOrganisationsMerge" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationTargetId
 * The id of the merged organisation which will swallow the other one,
 * @param {number} parameters.organisationSourceId
 * The id of the merged organisation which will be deleted,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostOrganisationsMergeURI(
  {
    organisationTargetId,
    organisationSourceId,
    accessToken,
  }: Pick<
    SencropTypes.PostOrganisationsMerge.Input,
    "organisationTargetId" | "organisationSourceId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationTargetId == null) {
    throw new Error(
      "Missing required parameter : organisationTargetId. Value : " +
        organisationTargetId
    );
  }
  if (organisationSourceId == null) {
    throw new Error(
      "Missing required parameter : organisationSourceId. Value : " +
        organisationSourceId
    );
  }

  const __pathParts = ["support", "organisations", "merge"];
  const __qs = cleanQuery({
    organisationTargetId: organisationTargetId,
    organisationSourceId: organisationSourceId,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postOrganisationsMerge" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationTargetId
 * The id of the merged organisation which will swallow the other one,
 * @param {number} parameters.organisationSourceId
 * The id of the merged organisation which will be deleted,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostOrganisationsMergeInput(
  {
    organisationTargetId,
    organisationSourceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisationsMerge.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postOrganisationsMerge;
  const __uriData = buildPostOrganisationsMergeURI(
    {
      organisationTargetId,
      organisationSourceId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Merge one organisation in another one
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationTargetId
 * The id of the merged organisation which will swallow the other one,
 * @param {number} parameters.organisationSourceId
 * The id of the merged organisation which will be deleted,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postOrganisationsMerge(
  {
    organisationTargetId,
    organisationSourceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostOrganisationsMerge.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostOrganisationsMerge.Output> {
  const httpRequest = buildPostOrganisationsMergeInput(
    {
      organisationTargetId,
      organisationSourceId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostOrganisationsMerge.Output;
}

/**
 * Build the "postPartnerApplication" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostPartnerApplicationURI(
  {
    partnerId,
    accessToken,
  }: Pick<
    SencropTypes.PostPartnerApplication.Input,
    "partnerId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (partnerId == null) {
    throw new Error(
      "Missing required parameter : partnerId. Value : " + partnerId
    );
  }

  const __pathParts = ["partners", partnerId, "applications"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postPartnerApplication" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostPartnerApplicationInput(
  {
    body,
    partnerId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostPartnerApplication.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostPartnerApplication.Input["body"]> {
  const __method = APIMethods.postPartnerApplication;
  const __uriData = buildPostPartnerApplicationURI(
    {
      partnerId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Create an application
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postPartnerApplication(
  {
    body,
    partnerId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostPartnerApplication.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostPartnerApplication.Output> {
  const httpRequest = buildPostPartnerApplicationInput(
    {
      body,
      partnerId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostPartnerApplication.Output;
}

/**
 * Build the "postPartnerModule" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostPartnerModuleURI(
  {
    partnerId,
    accessToken,
  }: Pick<SencropTypes.PostPartnerModule.Input, "partnerId" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (partnerId == null) {
    throw new Error(
      "Missing required parameter : partnerId. Value : " + partnerId
    );
  }

  const __pathParts = ["partners", partnerId, "modules"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postPartnerModule" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostPartnerModuleInput(
  {
    body,
    partnerId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostPartnerModule.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostPartnerModule.Input["body"]> {
  const __method = APIMethods.postPartnerModule;
  const __uriData = buildPostPartnerModuleURI(
    {
      partnerId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Create a partner module
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postPartnerModule(
  {
    body,
    partnerId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostPartnerModule.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostPartnerModule.Output> {
  const httpRequest = buildPostPartnerModuleInput(
    {
      body,
      partnerId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostPartnerModule.Output;
}

/**
 * Build the "postPartnerToken" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostPartnerTokenURI(
  { partnerId }: Pick<SencropTypes.PostPartnerToken.Input, "partnerId">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (partnerId == null) {
    throw new Error(
      "Missing required parameter : partnerId. Value : " + partnerId
    );
  }

  const __pathParts = ["partners", partnerId, "tokens"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postPartnerToken" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Basic mecanism,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostPartnerTokenInput(
  {
    body,
    partnerId,
    authorization,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostPartnerToken.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostPartnerToken.Input["body"]> {
  const __method = APIMethods.postPartnerToken;
  const __uriData = buildPostPartnerTokenURI(
    {
      partnerId,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Create a user token (deprecated, use OAuth2)
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Basic mecanism,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postPartnerToken(
  {
    body,
    partnerId,
    authorization,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostPartnerToken.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostPartnerToken.Output> {
  const httpRequest = buildPostPartnerTokenInput(
    {
      body,
      partnerId,
      authorization,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostPartnerToken.Output;
}

/**
 * Build the "postPartnerTokenRequest" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostPartnerTokenRequestURI(
  { partnerId }: Pick<SencropTypes.PostPartnerTokenRequest.Input, "partnerId">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (partnerId == null) {
    throw new Error(
      "Missing required parameter : partnerId. Value : " + partnerId
    );
  }

  const __pathParts = ["partners", partnerId, "tokenRequests"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postPartnerTokenRequest" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Basic mecanism,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostPartnerTokenRequestInput(
  {
    body,
    partnerId,
    authorization,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostPartnerTokenRequest.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostPartnerTokenRequest.Input["body"]> {
  const __method = APIMethods.postPartnerTokenRequest;
  const __uriData = buildPostPartnerTokenRequestURI(
    {
      partnerId,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Request a user token
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Basic mecanism,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postPartnerTokenRequest(
  {
    body,
    partnerId,
    authorization,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostPartnerTokenRequest.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostPartnerTokenRequest.Output> {
  const httpRequest = buildPostPartnerTokenRequestInput(
    {
      body,
      partnerId,
      authorization,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostPartnerTokenRequest.Output;
}

/**
 * Build the "postPlace" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostPlaceURI(
  { accessToken }: Pick<SencropTypes.PostPlace.Input, "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["places"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postPlace" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostPlaceInput(
  {
    body,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostPlace.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostPlace.Input["body"]> {
  const __method = APIMethods.postPlace;
  const __uriData = buildPostPlaceURI(
    {
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Create a new place
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postPlace(
  {
    body,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostPlace.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostPlace.Output> {
  const httpRequest = buildPostPlaceInput(
    {
      body,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostPlace.Output;
}

/**
 * Build the "postPrevilisData" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostPrevilisDataURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.PostPrevilisData.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["crons", "previlisData"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postPrevilisData" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostPrevilisDataInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostPrevilisData.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postPrevilisData;
  const __uriData = buildPostPrevilisDataURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
  * The Previlis bridge is currently done only for
 one customer (Axereal). It sends the Rain data to the Arvalis
 servers between day at 6h and day-1 6h.
  * @return {Object}
  * The object describing the built parameters
  * @param {Object} parameters
  * The parameters provided to build them (destructured)
 * @param {string} parameters.date
  * The date of the cron execution,
 * @param {string} [parameters.authorization]
  * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
  * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
  * @param {Object} options
  * Options to override Ky request configuration
  * @return {Object}
  * The HTTP response
  */
async function postPrevilisData(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostPrevilisData.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostPrevilisData.Output> {
  const httpRequest = buildPostPrevilisDataInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostPrevilisData.Output;
}

/**
 * Build the "postQuote" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostQuoteURI(
  { accessToken }: Pick<SencropTypes.PostQuote.Input, "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["quotes"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postQuote" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostQuoteInput(
  {
    body,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostQuote.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostQuote.Input["body"]> {
  const __method = APIMethods.postQuote;
  const __uriData = buildPostQuoteURI(
    {
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Create a new quote (with chargebee)
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postQuote(
  {
    body,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostQuote.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostQuote.Output> {
  const httpRequest = buildPostQuoteInput(
    {
      body,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostQuote.Output;
}

/**
 * Build the "postQuoteOffline" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.quoteId
 * The quote id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostQuoteOfflineURI(
  {
    userId,
    quoteId,
    accessToken,
  }: Pick<
    SencropTypes.PostQuoteOffline.Input,
    "userId" | "quoteId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (quoteId == null) {
    throw new Error("Missing required parameter : quoteId. Value : " + quoteId);
  }

  const __pathParts = ["users", userId, "quotes", quoteId, "offline"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postQuoteOffline" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.quoteId
 * The quote id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostQuoteOfflineInput(
  {
    userId,
    quoteId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostQuoteOffline.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postQuoteOffline;
  const __uriData = buildPostQuoteOfflineURI(
    {
      userId,
      quoteId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Set offline mode for a quote
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.quoteId
 * The quote id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postQuoteOffline(
  {
    userId,
    quoteId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostQuoteOffline.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostQuoteOffline.Output> {
  const httpRequest = buildPostQuoteOfflineInput(
    {
      userId,
      quoteId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostQuoteOffline.Output;
}

/**
 * Build the "postQuoteRequest" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostQuoteRequestURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["quotes-request"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postQuoteRequest" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostQuoteRequestInput(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostQuoteRequest.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostQuoteRequest.Input["body"]> {
  const __method = APIMethods.postQuoteRequest;
  const __uriData = buildPostQuoteRequestURI({}, { baseURL: __baseURL });

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Request a new quote (with hubspot)
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postQuoteRequest(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostQuoteRequest.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostQuoteRequest.Output> {
  const httpRequest = buildPostQuoteRequestInput(
    {
      body,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostQuoteRequest.Output;
}

/**
 * Build the "postRegister" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} [parameters.trialOperationId]
 * Operation trial id used to compute trialEndDate of organisation,
 * @param {string} [parameters.referralId]
 * UUID of the godfather organisation (through a referral program),
 * @param {string} [parameters.referralAuthorId]
 * ID of the godfather user (through a referral program),
 * @param {boolean} [parameters.marketingOptin]
 * Tells if the user has opt-in for marketing communication (RGPD related)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostRegisterURI(
  {
    trialOperationId,
    referralId,
    referralAuthorId,
    marketingOptin,
  }: Pick<
    SencropTypes.PostRegister.Input,
    "trialOperationId" | "referralId" | "referralAuthorId" | "marketingOptin"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["register"];
  const __qs = cleanQuery({
    trialOperationId: trialOperationId,
    referralId: referralId,
    referralAuthorId: referralAuthorId,
    marketingOptin: marketingOptin,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postRegister" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.trialOperationId]
 * Operation trial id used to compute trialEndDate of organisation,
 * @param {string} [parameters.referralId]
 * UUID of the godfather organisation (through a referral program),
 * @param {string} [parameters.referralAuthorId]
 * ID of the godfather user (through a referral program),
 * @param {boolean} [parameters.marketingOptin]
 * Tells if the user has opt-in for marketing communication (RGPD related),
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostRegisterInput(
  {
    body,
    trialOperationId,
    referralId,
    referralAuthorId,
    marketingOptin,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostRegister.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostRegister.Input["body"]> {
  const __method = APIMethods.postRegister;
  const __uriData = buildPostRegisterURI(
    {
      trialOperationId,
      referralId,
      referralAuthorId,
      marketingOptin,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Register a new user and his organisation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.trialOperationId]
 * Operation trial id used to compute trialEndDate of organisation,
 * @param {string} [parameters.referralId]
 * UUID of the godfather organisation (through a referral program),
 * @param {string} [parameters.referralAuthorId]
 * ID of the godfather user (through a referral program),
 * @param {boolean} [parameters.marketingOptin]
 * Tells if the user has opt-in for marketing communication (RGPD related),
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postRegister(
  {
    body,
    trialOperationId,
    referralId,
    referralAuthorId,
    marketingOptin,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostRegister.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostRegister.Output> {
  const httpRequest = buildPostRegisterInput(
    {
      body,
      trialOperationId,
      referralId,
      referralAuthorId,
      marketingOptin,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostRegister.Output;
}

/**
 * Build the "postRegisterAuth0" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostRegisterAuth0URI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["registerAuth0"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postRegisterAuth0" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostRegisterAuth0Input(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostRegisterAuth0.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostRegisterAuth0.Input["body"]> {
  const __method = APIMethods.postRegisterAuth0;
  const __uriData = buildPostRegisterAuth0URI({}, { baseURL: __baseURL });

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Register a new user in auth0 (will be replaced by auth0 popup)
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postRegisterAuth0(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostRegisterAuth0.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostRegisterAuth0.Output> {
  const httpRequest = buildPostRegisterAuth0Input(
    {
      body,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostRegisterAuth0.Output;
}

/**
 * Build the "postSigfoxData" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.key
 * Sigfox key to protect the endpoint
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostSigfoxDataURI(
  { key }: Pick<SencropTypes.PostSigfoxData.Input, "key">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (key == null) {
    throw new Error("Missing required parameter : key. Value : " + key);
  }

  const __pathParts = ["sigfox"];
  const __qs = cleanQuery({
    key: key,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postSigfoxData" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.key
 * Sigfox key to protect the endpoint,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostSigfoxDataInput(
  {
    body,
    key,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostSigfoxData.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostSigfoxData.Input["body"]> {
  const __method = APIMethods.postSigfoxData;
  const __uriData = buildPostSigfoxDataURI(
    {
      key,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Queue Sigfox data for processing.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.key
 * Sigfox key to protect the endpoint,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postSigfoxData(
  {
    body,
    key,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostSigfoxData.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostSigfoxData.Output> {
  const httpRequest = buildPostSigfoxDataInput(
    {
      body,
      key,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostSigfoxData.Output;
}

/**
 * Build the "postSigfoxReshape" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostSigfoxReshapeURI(
  {
    date,
    accessToken,
  }: Pick<SencropTypes.PostSigfoxReshape.Input, "date" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (date == null) {
    throw new Error("Missing required parameter : date. Value : " + date);
  }

  const __pathParts = ["crons", "sigfoxReshape"];
  const __qs = cleanQuery({
    date: date,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postSigfoxReshape" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostSigfoxReshapeInput(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostSigfoxReshape.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postSigfoxReshape;
  const __uriData = buildPostSigfoxReshapeURI(
    {
      date,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Reshape the Sigfox S3 backup to be grouped and sorted every hour.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.date
 * The date of the cron execution,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postSigfoxReshape(
  {
    date,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostSigfoxReshape.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostSigfoxReshape.Output> {
  const httpRequest = buildPostSigfoxReshapeInput(
    {
      date,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostSigfoxReshape.Output;
}

/**
 * Build the "postSignatureCloudinary" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostSignatureCloudinaryURI(
  {
    accessToken,
  }: Pick<SencropTypes.PostSignatureCloudinary.Input, "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["cloudinary", "signature"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postSignatureCloudinary" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostSignatureCloudinaryInput(
  {
    body,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostSignatureCloudinary.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostSignatureCloudinary.Input["body"]> {
  const __method = APIMethods.postSignatureCloudinary;
  const __uriData = buildPostSignatureCloudinaryURI(
    {
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Get a cloudinary signature to make a signed upload
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postSignatureCloudinary(
  {
    body,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostSignatureCloudinary.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostSignatureCloudinary.Output> {
  const httpRequest = buildPostSignatureCloudinaryInput(
    {
      body,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostSignatureCloudinary.Output;
}

/**
 * Build the "postSignupCheck" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostSignupCheckURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["utils", "checkSignup"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postSignupCheck" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mechanism,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostSignupCheckInput(
  {
    body,
    authorization,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostSignupCheck.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostSignupCheck.Input["body"]> {
  const __method = APIMethods.postSignupCheck;
  const __uriData = buildPostSignupCheckURI({}, { baseURL: __baseURL });

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Hit the server to check the email can be used to signup.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mechanism,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postSignupCheck(
  {
    body,
    authorization,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostSignupCheck.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostSignupCheck.Output> {
  const httpRequest = buildPostSignupCheckInput(
    {
      body,
      authorization,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostSignupCheck.Output;
}

/**
 * Build the "postSlackBotCommand" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostSlackBotCommandURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["slack", "bot", "commands"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postSlackBotCommand" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostSlackBotCommandInput(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostSlackBotCommand.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostSlackBotCommand.Input["body"]> {
  const __method = APIMethods.postSlackBotCommand;
  const __uriData = buildPostSlackBotCommandURI({}, { baseURL: __baseURL });

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Send a command to the slack bot.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postSlackBotCommand(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostSlackBotCommand.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostSlackBotCommand.Output> {
  const httpRequest = buildPostSlackBotCommandInput(
    {
      body,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostSlackBotCommand.Output;
}

/**
 * Build the "postSocialImage" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostSocialImageURI(
  { accessToken }: Pick<SencropTypes.PostSocialImage.Input, "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["socialImage"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postSocialImage" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostSocialImageInput(
  {
    body,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostSocialImage.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostSocialImage.Input["body"]> {
  const __method = APIMethods.postSocialImage;
  const __uriData = buildPostSocialImageURI(
    {
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Generate a new image to share it on social media
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postSocialImage(
  {
    body,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostSocialImage.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostSocialImage.Output> {
  const httpRequest = buildPostSocialImageInput(
    {
      body,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostSocialImage.Output;
}

/**
 * Build the "postTelitData" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostTelitDataURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["telit"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postTelitData" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostTelitDataInput(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostTelitData.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostTelitData.Input["body"]> {
  const __method = APIMethods.postTelitData;
  const __uriData = buildPostTelitDataURI({}, { baseURL: __baseURL });

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Queue Telit data for processing.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postTelitData(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostTelitData.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostTelitData.Output> {
  const httpRequest = buildPostTelitDataInput(
    {
      body,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostTelitData.Output;
}

/**
 * Build the "postTwilioEvents" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostTwilioEventsURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["twilio", "events"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postTwilioEvents" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostTwilioEventsInput(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostTwilioEvents.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostTwilioEvents.Input["body"]> {
  const __method = APIMethods.postTwilioEvents;
  const __uriData = buildPostTwilioEventsURI({}, { baseURL: __baseURL });

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Queue twilio event for processing.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postTwilioEvents(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostTwilioEvents.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostTwilioEvents.Output> {
  const httpRequest = buildPostTwilioEventsInput(
    {
      body,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostTwilioEvents.Output;
}

/**
 * Build the "postUser" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostUserURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["users"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postUser" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostUserInput(
  { body, xApiVersion, xSdkVersion, xAppVersion }: SencropTypes.PostUser.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostUser.Input["body"]> {
  const __method = APIMethods.postUser;
  const __uriData = buildPostUserURI({}, { baseURL: __baseURL });

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Create a new user.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postUser(
  { body, xApiVersion, xSdkVersion, xAppVersion }: SencropTypes.PostUser.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostUser.Output> {
  const httpRequest = buildPostUserInput(
    {
      body,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostUser.Output;
}

/**
 * Build the "postUserClearSessions" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostUserClearSessionsURI(
  {
    userId,
    accessToken,
  }: Pick<SencropTypes.PostUserClearSessions.Input, "userId" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }

  const __pathParts = ["users", userId, "testingSessions", "clear"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postUserClearSessions" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostUserClearSessionsInput(
  {
    body,
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserClearSessions.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostUserClearSessions.Input["body"]> {
  const __method = APIMethods.postUserClearSessions;
  const __uriData = buildPostUserClearSessionsURI(
    {
      userId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Removes devices rows from user testing sessions
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postUserClearSessions(
  {
    body,
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserClearSessions.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostUserClearSessions.Output> {
  const httpRequest = buildPostUserClearSessionsInput(
    {
      body,
      userId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostUserClearSessions.Output;
}

/**
 * Build the "postUserDeviceExport" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.emailRecipient]
 * Recipient email of the export (override default user email),
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostUserDeviceExportURI(
  {
    userId,
    deviceId,
    emailRecipient,
    accessToken,
  }: Pick<
    SencropTypes.PostUserDeviceExport.Input,
    "userId" | "deviceId" | "emailRecipient" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }

  const __pathParts = ["users", userId, "devices", deviceId, "export"];
  const __qs = cleanQuery({
    emailRecipient: emailRecipient,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postUserDeviceExport" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.emailRecipient]
 * Recipient email of the export (override default user email),
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostUserDeviceExportInput(
  {
    body,
    userId,
    deviceId,
    emailRecipient,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserDeviceExport.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostUserDeviceExport.Input["body"]> {
  const __method = APIMethods.postUserDeviceExport;
  const __uriData = buildPostUserDeviceExportURI(
    {
      userId,
      deviceId,
      emailRecipient,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Create a new export job
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.emailRecipient]
 * Recipient email of the export (override default user email),
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postUserDeviceExport(
  {
    body,
    userId,
    deviceId,
    emailRecipient,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserDeviceExport.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostUserDeviceExport.Output> {
  const httpRequest = buildPostUserDeviceExportInput(
    {
      body,
      userId,
      deviceId,
      emailRecipient,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostUserDeviceExport.Output;
}

/**
 * Build the "postUserNetworkInvitation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.networkId
 * The network id,
 * @param {string} [parameters.trialOperationId]
 * Operation trial id used to compute trialEndDate of organisation
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostUserNetworkInvitationURI(
  {
    userId,
    networkId,
    trialOperationId,
  }: Pick<
    SencropTypes.PostUserNetworkInvitation.Input,
    "userId" | "networkId" | "trialOperationId"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (networkId == null) {
    throw new Error(
      "Missing required parameter : networkId. Value : " + networkId
    );
  }

  const __pathParts = ["users", userId, "networks", networkId, "invitations"];
  const __qs = cleanQuery({
    trialOperationId: trialOperationId,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postUserNetworkInvitation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.networkId
 * The network id,
 * @param {string} [parameters.trialOperationId]
 * Operation trial id used to compute trialEndDate of organisation,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostUserNetworkInvitationInput(
  {
    body,
    userId,
    networkId,
    trialOperationId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserNetworkInvitation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostUserNetworkInvitation.Input["body"]> {
  const __method = APIMethods.postUserNetworkInvitation;
  const __uriData = buildPostUserNetworkInvitationURI(
    {
      userId,
      networkId,
      trialOperationId,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Invite a user to reach the Network page
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.networkId
 * The network id,
 * @param {string} [parameters.trialOperationId]
 * Operation trial id used to compute trialEndDate of organisation,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postUserNetworkInvitation(
  {
    body,
    userId,
    networkId,
    trialOperationId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserNetworkInvitation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostUserNetworkInvitation.Output> {
  const httpRequest = buildPostUserNetworkInvitationInput(
    {
      body,
      userId,
      networkId,
      trialOperationId,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostUserNetworkInvitation.Output;
}

/**
 * Build the "postUserOrganisationAggregation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostUserOrganisationAggregationURI(
  {
    userId,
    organisationId,
    accessToken,
  }: Pick<
    SencropTypes.PostUserOrganisationAggregation.Input,
    "userId" | "organisationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = [
    "users",
    userId,
    "organisations",
    organisationId,
    "aggregations",
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postUserOrganisationAggregation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostUserOrganisationAggregationInput(
  {
    body,
    userId,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserOrganisationAggregation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostUserOrganisationAggregation.Input["body"]> {
  const __method = APIMethods.postUserOrganisationAggregation;
  const __uriData = buildPostUserOrganisationAggregationURI(
    {
      userId,
      organisationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Create a new organisation's aggregation.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postUserOrganisationAggregation(
  {
    body,
    userId,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserOrganisationAggregation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostUserOrganisationAggregation.Output> {
  const httpRequest = buildPostUserOrganisationAggregationInput(
    {
      body,
      userId,
      organisationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostUserOrganisationAggregation.Output;
}

/**
 * Build the "postUserOrganisationAlert" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostUserOrganisationAlertURI(
  {
    userId,
    organisationId,
    accessToken,
  }: Pick<
    SencropTypes.PostUserOrganisationAlert.Input,
    "userId" | "organisationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = [
    "users",
    userId,
    "organisations",
    organisationId,
    "alerts",
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postUserOrganisationAlert" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostUserOrganisationAlertInput(
  {
    body,
    userId,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserOrganisationAlert.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostUserOrganisationAlert.Input["body"]> {
  const __method = APIMethods.postUserOrganisationAlert;
  const __uriData = buildPostUserOrganisationAlertURI(
    {
      userId,
      organisationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Create a new organisation's alert.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postUserOrganisationAlert(
  {
    body,
    userId,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserOrganisationAlert.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostUserOrganisationAlert.Output> {
  const httpRequest = buildPostUserOrganisationAlertInput(
    {
      body,
      userId,
      organisationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostUserOrganisationAlert.Output;
}

/**
 * Build the "postUserOrganisationDevice" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostUserOrganisationDeviceURI(
  {
    organisationId,
    userId,
    accessToken,
  }: Pick<
    SencropTypes.PostUserOrganisationDevice.Input,
    "organisationId" | "userId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }

  const __pathParts = [
    "users",
    userId,
    "organisations",
    organisationId,
    "devices",
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postUserOrganisationDevice" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostUserOrganisationDeviceInput(
  {
    body,
    organisationId,
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserOrganisationDevice.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostUserOrganisationDevice.Input["body"]> {
  const __method = APIMethods.postUserOrganisationDevice;
  const __uriData = buildPostUserOrganisationDeviceURI(
    {
      organisationId,
      userId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Setup a organisation's device.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postUserOrganisationDevice(
  {
    body,
    organisationId,
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserOrganisationDevice.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostUserOrganisationDevice.Output> {
  const httpRequest = buildPostUserOrganisationDeviceInput(
    {
      body,
      organisationId,
      userId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostUserOrganisationDevice.Output;
}

/**
 * Build the "postUserOrganisationDeviceSupport" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostUserOrganisationDeviceSupportURI(
  {
    userId,
    organisationId,
    deviceId,
    accessToken,
  }: Pick<
    SencropTypes.PostUserOrganisationDeviceSupport.Input,
    "userId" | "organisationId" | "deviceId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }

  const __pathParts = [
    "support",
    "users",
    userId,
    "organisations",
    organisationId,
    "devices",
    deviceId,
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postUserOrganisationDeviceSupport" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostUserOrganisationDeviceSupportInput(
  {
    userId,
    organisationId,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserOrganisationDeviceSupport.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postUserOrganisationDeviceSupport;
  const __uriData = buildPostUserOrganisationDeviceSupportURI(
    {
      userId,
      organisationId,
      deviceId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Setup a user's device.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postUserOrganisationDeviceSupport(
  {
    userId,
    organisationId,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserOrganisationDeviceSupport.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostUserOrganisationDeviceSupport.Output> {
  const httpRequest = buildPostUserOrganisationDeviceSupportInput(
    {
      userId,
      organisationId,
      deviceId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostUserOrganisationDeviceSupport.Output;
}

/**
 * Build the "postUserOrganisationDevicesSupport" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostUserOrganisationDevicesSupportURI(
  {
    userId,
    organisationId,
    accessToken,
  }: Pick<
    SencropTypes.PostUserOrganisationDevicesSupport.Input,
    "userId" | "organisationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = [
    "support",
    "users",
    userId,
    "organisations",
    organisationId,
    "devices",
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postUserOrganisationDevicesSupport" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostUserOrganisationDevicesSupportInput(
  {
    body,
    userId,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserOrganisationDevicesSupport.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostUserOrganisationDevicesSupport.Input["body"]> {
  const __method = APIMethods.postUserOrganisationDevicesSupport;
  const __uriData = buildPostUserOrganisationDevicesSupportURI(
    {
      userId,
      organisationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Setup a list of user's device.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postUserOrganisationDevicesSupport(
  {
    body,
    userId,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserOrganisationDevicesSupport.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostUserOrganisationDevicesSupport.Output> {
  const httpRequest = buildPostUserOrganisationDevicesSupportInput(
    {
      body,
      userId,
      organisationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostUserOrganisationDevicesSupport.Output;
}

/**
 * Build the "postUserOrganisationInvitation" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostUserOrganisationInvitationURI(
  {
    userId,
    organisationId,
    accessToken,
  }: Pick<
    SencropTypes.PostUserOrganisationInvitation.Input,
    "userId" | "organisationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = [
    "users",
    userId,
    "organisations",
    organisationId,
    "invitations",
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postUserOrganisationInvitation" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostUserOrganisationInvitationInput(
  {
    body,
    userId,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserOrganisationInvitation.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostUserOrganisationInvitation.Input["body"]> {
  const __method = APIMethods.postUserOrganisationInvitation;
  const __uriData = buildPostUserOrganisationInvitationURI(
    {
      userId,
      organisationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Create an organisation's invitation.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postUserOrganisationInvitation(
  {
    body,
    userId,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserOrganisationInvitation.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostUserOrganisationInvitation.Output> {
  const httpRequest = buildPostUserOrganisationInvitationInput(
    {
      body,
      userId,
      organisationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostUserOrganisationInvitation.Output;
}

/**
 * Build the "postUserOrganisationMember" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostUserOrganisationMemberURI(
  {
    userId,
    organisationId,
    accessToken,
  }: Pick<
    SencropTypes.PostUserOrganisationMember.Input,
    "userId" | "organisationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = [
    "users",
    userId,
    "organisations",
    organisationId,
    "members",
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postUserOrganisationMember" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostUserOrganisationMemberInput(
  {
    body,
    userId,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserOrganisationMember.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostUserOrganisationMember.Input["body"]> {
  const __method = APIMethods.postUserOrganisationMember;
  const __uriData = buildPostUserOrganisationMemberURI(
    {
      userId,
      organisationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Add an member to an organisation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postUserOrganisationMember(
  {
    body,
    userId,
    organisationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserOrganisationMember.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostUserOrganisationMember.Output> {
  const httpRequest = buildPostUserOrganisationMemberInput(
    {
      body,
      userId,
      organisationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostUserOrganisationMember.Output;
}

/**
 * Build the "postUserPassword" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostUserPasswordURI(
  { userId }: Pick<SencropTypes.PostUserPassword.Input, "userId">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }

  const __pathParts = ["users", userId, "password"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postUserPassword" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostUserPasswordInput(
  {
    body,
    userId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserPassword.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostUserPassword.Input["body"]> {
  const __method = APIMethods.postUserPassword;
  const __uriData = buildPostUserPasswordURI(
    {
      userId,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Update user password
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postUserPassword(
  {
    body,
    userId,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserPassword.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostUserPassword.Output> {
  const httpRequest = buildPostUserPasswordInput(
    {
      body,
      userId,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostUserPassword.Output;
}

/**
 * Build the "postUserTerminateTokensSupport" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostUserTerminateTokensSupportURI(
  {
    userId,
    accessToken,
  }: Pick<
    SencropTypes.PostUserTerminateTokensSupport.Input,
    "userId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }

  const __pathParts = ["support", "users", userId, "terminateTokens"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postUserTerminateTokensSupport" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostUserTerminateTokensSupportInput(
  {
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserTerminateTokensSupport.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.postUserTerminateTokensSupport;
  const __uriData = buildPostUserTerminateTokensSupportURI(
    {
      userId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Terminate all tokens for a user
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postUserTerminateTokensSupport(
  {
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserTerminateTokensSupport.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostUserTerminateTokensSupport.Output> {
  const httpRequest = buildPostUserTerminateTokensSupportInput(
    {
      userId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostUserTerminateTokensSupport.Output;
}

/**
 * Build the "postUserTerminatedSessions" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostUserTerminatedSessionsURI(
  {
    userId,
    accessToken,
  }: Pick<
    SencropTypes.PostUserTerminatedSessions.Input,
    "userId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }

  const __pathParts = ["users", userId, "testingSessions", "terminate"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postUserTerminatedSessions" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostUserTerminatedSessionsInput(
  {
    body,
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserTerminatedSessions.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostUserTerminatedSessions.Input["body"]> {
  const __method = APIMethods.postUserTerminatedSessions;
  const __uriData = buildPostUserTerminatedSessionsURI(
    {
      userId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Dumps successfully tested devices into the devices table
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postUserTerminatedSessions(
  {
    body,
    userId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostUserTerminatedSessions.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostUserTerminatedSessions.Output> {
  const httpRequest = buildPostUserTerminatedSessionsInput(
    {
      body,
      userId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostUserTerminatedSessions.Output;
}

/**
 * Build the "postVerify" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostVerifyURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["users", "verify"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postVerify" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostVerifyInput(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostVerify.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostVerify.Input["body"]> {
  const __method = APIMethods.postVerify;
  const __uriData = buildPostVerifyURI({}, { baseURL: __baseURL });

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Verify a user
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postVerify(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostVerify.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostVerify.Output> {
  const httpRequest = buildPostVerifyInput(
    {
      body,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostVerify.Output;
}

/**
 * Build the "putBatchDevices" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutBatchDevicesURI(
  { accessToken }: Pick<SencropTypes.PutBatchDevices.Input, "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["devicesBatch"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putBatchDevices" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutBatchDevicesInput(
  {
    body,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutBatchDevices.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutBatchDevices.Input["body"]> {
  const __method = APIMethods.putBatchDevices;
  const __uriData = buildPutBatchDevicesURI(
    {
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Edit production batch associated to devices
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putBatchDevices(
  {
    body,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutBatchDevices.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutBatchDevices.Output> {
  const httpRequest = buildPutBatchDevicesInput(
    {
      body,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutBatchDevices.Output;
}

/**
 * Build the "putDataDeviceCity" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.deviceId
 * The device id
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutDataDeviceCityURI(
  { deviceId }: Pick<SencropTypes.PutDataDeviceCity.Input, "deviceId">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }

  const __pathParts = ["data", "devices", deviceId, "city"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putDataDeviceCity" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Basic mecanism,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutDataDeviceCityInput(
  {
    body,
    deviceId,
    authorization,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutDataDeviceCity.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutDataDeviceCity.Input["body"]> {
  const __method = APIMethods.putDataDeviceCity;
  const __uriData = buildPutDataDeviceCityURI(
    {
      deviceId,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Edit the city name of the device
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Basic mecanism,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putDataDeviceCity(
  {
    body,
    deviceId,
    authorization,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutDataDeviceCity.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutDataDeviceCity.Output> {
  const httpRequest = buildPutDataDeviceCityInput(
    {
      body,
      deviceId,
      authorization,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutDataDeviceCity.Output;
}

/**
 * Build the "putDevicePosition" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutDevicePositionURI(
  {
    deviceId,
    accessToken,
  }: Pick<SencropTypes.PutDevicePosition.Input, "deviceId" | "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }

  const __pathParts = ["support", "devices", deviceId, "position"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putDevicePosition" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutDevicePositionInput(
  {
    body,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutDevicePosition.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutDevicePosition.Input["body"]> {
  const __method = APIMethods.putDevicePosition;
  const __uriData = buildPutDevicePositionURI(
    {
      deviceId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Change device position
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putDevicePosition(
  {
    body,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutDevicePosition.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutDevicePosition.Output> {
  const httpRequest = buildPutDevicePositionInput(
    {
      body,
      deviceId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutDevicePosition.Output;
}

/**
 * Build the "putIrradianceSensorCalibration" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.sensorId
 * The sensor id,
 * @param {string} parameters.key
 * Manufacturer key to protect the endpoint
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutIrradianceSensorCalibrationURI(
  {
    sensorId,
    key,
  }: Pick<
    SencropTypes.PutIrradianceSensorCalibration.Input,
    "sensorId" | "key"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (sensorId == null) {
    throw new Error(
      "Missing required parameter : sensorId. Value : " + sensorId
    );
  }
  if (key == null) {
    throw new Error("Missing required parameter : key. Value : " + key);
  }

  const __pathParts = ["irradiance-sensors", sensorId, "calibration"];
  const __qs = cleanQuery({
    key: key,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putIrradianceSensorCalibration" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.sensorId
 * The sensor id,
 * @param {string} parameters.key
 * Manufacturer key to protect the endpoint,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutIrradianceSensorCalibrationInput(
  {
    body,
    sensorId,
    key,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutIrradianceSensorCalibration.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutIrradianceSensorCalibration.Input["body"]> {
  const __method = APIMethods.putIrradianceSensorCalibration;
  const __uriData = buildPutIrradianceSensorCalibrationURI(
    {
      sensorId,
      key,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Upsert a calibration for an irradiance sensor
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.sensorId
 * The sensor id,
 * @param {string} parameters.key
 * Manufacturer key to protect the endpoint,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putIrradianceSensorCalibration(
  {
    body,
    sensorId,
    key,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutIrradianceSensorCalibration.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutIrradianceSensorCalibration.Output> {
  const httpRequest = buildPutIrradianceSensorCalibrationInput(
    {
      body,
      sensorId,
      key,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutIrradianceSensorCalibration.Output;
}

/**
 * Build the "putOrganisationDeviceCalibration" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.calibrationName
 * Calibration to set,
 * @param {number} parameters.ratio
 * Ratio to set,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutOrganisationDeviceCalibrationURI(
  {
    organisationId,
    deviceId,
    calibrationName,
    ratio,
    accessToken,
  }: Pick<
    SencropTypes.PutOrganisationDeviceCalibration.Input,
    "organisationId" | "deviceId" | "calibrationName" | "ratio" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }
  if (calibrationName == null) {
    throw new Error(
      "Missing required parameter : calibrationName. Value : " + calibrationName
    );
  }
  if (ratio == null) {
    throw new Error("Missing required parameter : ratio. Value : " + ratio);
  }

  const __pathParts = [
    "organisations",
    organisationId,
    "devices",
    deviceId,
    "calibration",
    calibrationName,
  ];
  const __qs = cleanQuery({
    ratio: ratio,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putOrganisationDeviceCalibration" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.calibrationName
 * Calibration to set,
 * @param {number} parameters.ratio
 * Ratio to set,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutOrganisationDeviceCalibrationInput(
  {
    organisationId,
    deviceId,
    calibrationName,
    ratio,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutOrganisationDeviceCalibration.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.putOrganisationDeviceCalibration;
  const __uriData = buildPutOrganisationDeviceCalibrationURI(
    {
      organisationId,
      deviceId,
      calibrationName,
      ratio,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Update an organisation's device calibration.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} parameters.calibrationName
 * Calibration to set,
 * @param {number} parameters.ratio
 * Ratio to set,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putOrganisationDeviceCalibration(
  {
    organisationId,
    deviceId,
    calibrationName,
    ratio,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutOrganisationDeviceCalibration.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutOrganisationDeviceCalibration.Output> {
  const httpRequest = buildPutOrganisationDeviceCalibrationInput(
    {
      organisationId,
      deviceId,
      calibrationName,
      ratio,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutOrganisationDeviceCalibration.Output;
}

/**
 * Build the "putOrganisationDeviceSettings" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutOrganisationDeviceSettingsURI(
  {
    organisationId,
    deviceId,
    accessToken,
  }: Pick<
    SencropTypes.PutOrganisationDeviceSettings.Input,
    "organisationId" | "deviceId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }

  const __pathParts = [
    "organisations",
    organisationId,
    "devices",
    deviceId,
    "settings",
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putOrganisationDeviceSettings" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutOrganisationDeviceSettingsInput(
  {
    body,
    organisationId,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutOrganisationDeviceSettings.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutOrganisationDeviceSettings.Input["body"]> {
  const __method = APIMethods.putOrganisationDeviceSettings;
  const __uriData = buildPutOrganisationDeviceSettingsURI(
    {
      organisationId,
      deviceId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Update an organisation's device's settings.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putOrganisationDeviceSettings(
  {
    body,
    organisationId,
    deviceId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutOrganisationDeviceSettings.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutOrganisationDeviceSettings.Output> {
  const httpRequest = buildPutOrganisationDeviceSettingsInput(
    {
      body,
      organisationId,
      deviceId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutOrganisationDeviceSettings.Output;
}

/**
 * Build the "putOrganisationPlace" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.placeId
 * The place id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutOrganisationPlaceURI(
  {
    organisationId,
    placeId,
    accessToken,
  }: Pick<
    SencropTypes.PutOrganisationPlace.Input,
    "organisationId" | "placeId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }
  if (placeId == null) {
    throw new Error("Missing required parameter : placeId. Value : " + placeId);
  }

  const __pathParts = ["organisations", organisationId, "places", placeId];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putOrganisationPlace" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.placeId
 * The place id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutOrganisationPlaceInput(
  {
    body,
    organisationId,
    placeId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutOrganisationPlace.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutOrganisationPlace.Input["body"]> {
  const __method = APIMethods.putOrganisationPlace;
  const __uriData = buildPutOrganisationPlaceURI(
    {
      organisationId,
      placeId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Update a place for an organisation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {number} parameters.placeId
 * The place id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putOrganisationPlace(
  {
    body,
    organisationId,
    placeId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutOrganisationPlace.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutOrganisationPlace.Output> {
  const httpRequest = buildPutOrganisationPlaceInput(
    {
      body,
      organisationId,
      placeId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutOrganisationPlace.Output;
}

/**
 * Build the "putOrganisationTrial" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {boolean} [parameters.sendEmail]
 * If a trial reactivation should send an email,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutOrganisationTrialURI(
  {
    organisationId,
    sendEmail,
    accessToken,
  }: Pick<
    SencropTypes.PutOrganisationTrial.Input,
    "organisationId" | "sendEmail" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (organisationId == null) {
    throw new Error(
      "Missing required parameter : organisationId. Value : " + organisationId
    );
  }

  const __pathParts = ["organisations", organisationId, "trial"];
  const __qs = cleanQuery({
    sendEmail: sendEmail,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putOrganisationTrial" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {boolean} [parameters.sendEmail]
 * If a trial reactivation should send an email,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutOrganisationTrialInput(
  {
    body,
    organisationId,
    sendEmail,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutOrganisationTrial.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutOrganisationTrial.Input["body"]> {
  const __method = APIMethods.putOrganisationTrial;
  const __uriData = buildPutOrganisationTrialURI(
    {
      organisationId,
      sendEmail,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Change trial for an organisation
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.organisationId
 * The organisation id,
 * @param {boolean} [parameters.sendEmail]
 * If a trial reactivation should send an email,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putOrganisationTrial(
  {
    body,
    organisationId,
    sendEmail,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutOrganisationTrial.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutOrganisationTrial.Output> {
  const httpRequest = buildPutOrganisationTrialInput(
    {
      body,
      organisationId,
      sendEmail,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutOrganisationTrial.Output;
}

/**
 * Build the "putPartnerApplication" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id,
 * @param {string} parameters.applicationId
 * The application uuid,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutPartnerApplicationURI(
  {
    partnerId,
    applicationId,
    accessToken,
  }: Pick<
    SencropTypes.PutPartnerApplication.Input,
    "partnerId" | "applicationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (partnerId == null) {
    throw new Error(
      "Missing required parameter : partnerId. Value : " + partnerId
    );
  }
  if (applicationId == null) {
    throw new Error(
      "Missing required parameter : applicationId. Value : " + applicationId
    );
  }

  const __pathParts = ["partners", partnerId, "applications", applicationId];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putPartnerApplication" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id,
 * @param {string} parameters.applicationId
 * The application uuid,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutPartnerApplicationInput(
  {
    body,
    partnerId,
    applicationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutPartnerApplication.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutPartnerApplication.Input["body"]> {
  const __method = APIMethods.putPartnerApplication;
  const __uriData = buildPutPartnerApplicationURI(
    {
      partnerId,
      applicationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Update an application
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id,
 * @param {string} parameters.applicationId
 * The application uuid,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putPartnerApplication(
  {
    body,
    partnerId,
    applicationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutPartnerApplication.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutPartnerApplication.Output> {
  const httpRequest = buildPutPartnerApplicationInput(
    {
      body,
      partnerId,
      applicationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutPartnerApplication.Output;
}

/**
 * Build the "putPartnerModuleParameters" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id,
 * @param {number} parameters.partnerUserId
 * The partner user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {number} parameters.moduleId
 * The module id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutPartnerModuleParametersURI(
  {
    partnerId,
    partnerUserId,
    deviceId,
    moduleId,
    accessToken,
  }: Pick<
    SencropTypes.PutPartnerModuleParameters.Input,
    "partnerId" | "partnerUserId" | "deviceId" | "moduleId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (partnerId == null) {
    throw new Error(
      "Missing required parameter : partnerId. Value : " + partnerId
    );
  }
  if (partnerUserId == null) {
    throw new Error(
      "Missing required parameter : partnerUserId. Value : " + partnerUserId
    );
  }
  if (deviceId == null) {
    throw new Error(
      "Missing required parameter : deviceId. Value : " + deviceId
    );
  }
  if (moduleId == null) {
    throw new Error(
      "Missing required parameter : moduleId. Value : " + moduleId
    );
  }

  const __pathParts = [
    "partners",
    partnerId,
    "users",
    partnerUserId,
    "devices",
    deviceId,
    "modules",
    moduleId,
    "parameters",
  ];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putPartnerModuleParameters" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id,
 * @param {number} parameters.partnerUserId
 * The partner user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {number} parameters.moduleId
 * The module id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutPartnerModuleParametersInput(
  {
    body,
    partnerId,
    partnerUserId,
    deviceId,
    moduleId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutPartnerModuleParameters.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutPartnerModuleParameters.Input["body"]> {
  const __method = APIMethods.putPartnerModuleParameters;
  const __uriData = buildPutPartnerModuleParametersURI(
    {
      partnerId,
      partnerUserId,
      deviceId,
      moduleId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Update an partner module parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.partnerId
 * The partner organisation id,
 * @param {number} parameters.partnerUserId
 * The partner user id,
 * @param {number} parameters.deviceId
 * The device id,
 * @param {number} parameters.moduleId
 * The module id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putPartnerModuleParameters(
  {
    body,
    partnerId,
    partnerUserId,
    deviceId,
    moduleId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutPartnerModuleParameters.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutPartnerModuleParameters.Output> {
  const httpRequest = buildPutPartnerModuleParametersInput(
    {
      body,
      partnerId,
      partnerUserId,
      deviceId,
      moduleId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutPartnerModuleParameters.Output;
}

/**
 * Build the "putPassword" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutPasswordURI(
  _: unknown,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["password"];
  const __qs = cleanQuery({});

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putPassword" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutPasswordInput(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutPassword.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutPassword.Input["body"]> {
  const __method = APIMethods.putPassword;
  const __uriData = buildPutPasswordURI({}, { baseURL: __baseURL });

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Change a user password
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putPassword(
  {
    body,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutPassword.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutPassword.Output> {
  const httpRequest = buildPutPasswordInput(
    {
      body,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutPassword.Output;
}

/**
 * Build the "putQuoteStatus" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.quoteId
 * The quote id,
 * @param {string} parameters.status
 * New status of the quote
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutQuoteStatusURI(
  {
    quoteId,
    status,
  }: Pick<SencropTypes.PutQuoteStatus.Input, "quoteId" | "status">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (quoteId == null) {
    throw new Error("Missing required parameter : quoteId. Value : " + quoteId);
  }
  if (status == null) {
    throw new Error("Missing required parameter : status. Value : " + status);
  }

  const __pathParts = ["quotes", quoteId, "status"];
  const __qs = cleanQuery({
    status: status,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putQuoteStatus" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.quoteId
 * The quote id,
 * @param {string} parameters.status
 * New status of the quote,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutQuoteStatusInput(
  {
    quoteId,
    status,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutQuoteStatus.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.putQuoteStatus;
  const __uriData = buildPutQuoteStatusURI(
    {
      quoteId,
      status,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Update the status of a quote
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.quoteId
 * The quote id,
 * @param {string} parameters.status
 * New status of the quote,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putQuoteStatus(
  {
    quoteId,
    status,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutQuoteStatus.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutQuoteStatus.Output> {
  const httpRequest = buildPutQuoteStatusInput(
    {
      quoteId,
      status,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutQuoteStatus.Output;
}

/**
 * Build the "putUserFCMToken" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {boolean} [parameters.isApnToken]
 * Precise if the token is an APN token (need a conversion to FCM token),
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutUserFCMTokenURI(
  {
    userId,
    isApnToken,
    accessToken,
  }: Pick<
    SencropTypes.PutUserFCMToken.Input,
    "userId" | "isApnToken" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }

  const __pathParts = ["users", userId, "FCMToken"];
  const __qs = cleanQuery({
    isAPNToken: isApnToken,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putUserFCMToken" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {boolean} [parameters.isApnToken]
 * Precise if the token is an APN token (need a conversion to FCM token),
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutUserFCMTokenInput(
  {
    body,
    userId,
    isApnToken,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserFCMToken.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutUserFCMToken.Input["body"]> {
  const __method = APIMethods.putUserFCMToken;
  const __uriData = buildPutUserFCMTokenURI(
    {
      userId,
      isApnToken,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Update Firebase Cloud Messaging token for an user
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {boolean} [parameters.isApnToken]
 * Precise if the token is an APN token (need a conversion to FCM token),
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putUserFCMToken(
  {
    body,
    userId,
    isApnToken,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserFCMToken.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutUserFCMToken.Output> {
  const httpRequest = buildPutUserFCMTokenInput(
    {
      body,
      userId,
      isApnToken,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutUserFCMToken.Output;
}

/**
 * Build the "putUserInvitationAnswer" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPutUserInvitationAnswerURI(
  {
    userId,
    invitationId,
    accessToken,
  }: Pick<
    SencropTypes.PutUserInvitationAnswer.Input,
    "userId" | "invitationId" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (userId == null) {
    throw new Error("Missing required parameter : userId. Value : " + userId);
  }
  if (invitationId == null) {
    throw new Error(
      "Missing required parameter : invitationId. Value : " + invitationId
    );
  }

  const __pathParts = ["users", userId, "invitations", invitationId, "answer"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "putUserInvitationAnswer" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPutUserInvitationAnswerInput(
  {
    body,
    userId,
    invitationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserInvitationAnswer.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PutUserInvitationAnswer.Input["body"]> {
  const __method = APIMethods.putUserInvitationAnswer;
  const __uriData = buildPutUserInvitationAnswerURI(
    {
      userId,
      invitationId,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Set a user's invitation answer.
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {number} parameters.userId
 * The user id,
 * @param {string} parameters.invitationId
 * The invitation id,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function putUserInvitationAnswer(
  {
    body,
    userId,
    invitationId,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PutUserInvitationAnswer.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PutUserInvitationAnswer.Output> {
  const httpRequest = buildPutUserInvitationAnswerInput(
    {
      body,
      userId,
      invitationId,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PutUserInvitationAnswer.Output;
}

/**
 * Build the "getGraphQL" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} parameters.query
 * The GraphQL query,
 * @param {string} [parameters.variables]
 * The GraphQL variables,
 * @param {string} [parameters.operationName]
 * The GraphQL operation name,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildGetGraphQLURI(
  {
    query,
    variables,
    operationName,
    accessToken,
  }: Pick<
    SencropTypes.GetGraphQL.Input,
    "query" | "variables" | "operationName" | "accessToken"
  >,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  if (query == null) {
    throw new Error("Missing required parameter : query. Value : " + query);
  }

  const __pathParts = ["graphql"];
  const __qs = cleanQuery({
    query: query,
    variables: variables,
    operationName: operationName,
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "getGraphQL" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.query
 * The GraphQL query,
 * @param {string} [parameters.variables]
 * The GraphQL variables,
 * @param {string} [parameters.operationName]
 * The GraphQL operation name,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildGetGraphQLInput(
  {
    query,
    variables,
    operationName,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetGraphQL.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<undefined> {
  const __method = APIMethods.getGraphQL;
  const __uriData = buildGetGraphQLURI(
    {
      query,
      variables,
      operationName,
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: undefined,
  };
}

/**
 * Graphql endpoint
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} parameters.query
 * The GraphQL query,
 * @param {string} [parameters.variables]
 * The GraphQL variables,
 * @param {string} [parameters.operationName]
 * The GraphQL operation name,
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function getGraphQL(
  {
    query,
    variables,
    operationName,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.GetGraphQL.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.GetGraphQL.Output> {
  const httpRequest = buildGetGraphQLInput(
    {
      query,
      variables,
      operationName,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.GetGraphQL.Output;
}

/**
 * Build the "postGraphQL" URI parameters$
 * @return {Object}
 * The object describing the built URI
 * @param {Object} parameters
 * The parameters provided to build the URI (destructured)
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 */
function buildPostGraphQLURI(
  { accessToken }: Pick<SencropTypes.PostGraphQL.Input, "accessToken">,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
  }: URIBuilderOptions = URI_BUILDER_DEFAULTS
): URIData {
  const __pathParts = ["graphql"];
  const __qs = cleanQuery({
    access_token: accessToken,
  });

  return {
    baseURL: __baseURL,
    path: __pathParts.join("/"),
    params: __qs,
  };
}

/**
 * Build all the "postGraphQL" parameters
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * The options (destructured)
 * @param {string} options.baseURL
 * The base URL of the API
 * @param {string} options.headers
 * Any additional headers to append
 */
function buildPostGraphQLInput(
  {
    body,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostGraphQL.Input,
  {
    baseURL: __baseURL = DEFAULT_BASE_URL,
    headers: __headers = {},
  }: InputBuilderOptions = INPUT_BUILDER_DEFAULTS
): HTTPRequest<SencropTypes.PostGraphQL.Input["body"]> {
  const __method = APIMethods.postGraphQL;
  const __uriData = buildPostGraphQLURI(
    {
      accessToken,
    },
    { baseURL: __baseURL }
  );

  return {
    method: __method,
    ...__uriData,
    headers: cleanHeaders(
      Object.assign(__headers, {
        "X-API-Version": "1.72.19",
        "X-SDK-Version": "4.30.10",
        Authorization: authorization,
        "X-APP-Version": xAppVersion,
      })
    ),
    body: body,
  };
}

/**
 * Graphql endpoint
 * @return {Object}
 * The object describing the built parameters
 * @param {Object} body
 * The request body
 * @param {Object} parameters
 * The parameters provided to build them (destructured)
 * @param {string} [parameters.authorization]
 * Authorization with Bearer mecanism,
 * @param {string} [parameters.accessToken]
 * Token provided through query parameters,
 * @param {string} [parameters.xApiVersion],
 * @param {string} [parameters.xSdkVersion],
 * @param {string} [parameters.xAppVersion]
 * @param {Object} options
 * Options to override Ky request configuration
 * @return {Object}
 * The HTTP response
 */
async function postGraphQL(
  {
    body,
    authorization,
    accessToken,
    xApiVersion,
    xSdkVersion,
    xAppVersion,
  }: SencropTypes.PostGraphQL.Input,
  options: InputBuilderOptions & Partial<KyOptions> = {}
): Promise<SencropTypes.PostGraphQL.Output> {
  const httpRequest = buildPostGraphQLInput(
    {
      body,
      authorization,
      accessToken,
      xApiVersion,
      xSdkVersion,
      xAppVersion,
    },
    options
  );

  const callOptions = {
    baseURL: httpRequest.baseURL,
    method: httpRequest.method,
    url: httpRequest.path,
    headers: httpRequest.headers,
    params: httpRequest.params,
    data: httpRequest.body,
  };

  const response = await ky(callOptions.url, {
    prefixUrl: callOptions.baseURL,
    method: callOptions.method,
    headers: cleanHeaders(callOptions.headers),
    searchParams: callOptions.params,
    json: callOptions.data,
    ...options,
  });

  let responseBody;
  if (!response.bodyUsed && response.status !== 204) {
    responseBody = await response.json();
  }

  const responseHeaders = formatReponseHeaders(response.headers);
  return {
    status: response.status,
    headers: responseHeaders,
    body: responseBody,
  } as SencropTypes.PostGraphQL.Output;
}

function cleanQuery(query: QueryParams): SearchParamsOption {
  return Object.keys(query)
    .filter((key) => "undefined" !== typeof query[key])
    .filter(
      (key) =>
        !(query[key] instanceof Array) || 0 !== (query[key] as any[]).length
    )
    .reduce((newQuery, key) => {
      // @ts-ignore
      newQuery[key] = query[key];
      return newQuery;
    }, {} as SearchParamsOption);
}

export function cleanHeaders(headers: Headers): Headers {
  return Object.keys(headers)
    .filter((key) => "undefined" !== typeof headers[key])
    .reduce((newHeaders, key) => {
      newHeaders[key] = headers[key];
      return newHeaders;
    }, {} as Headers);
}

export function sortMultipleQuery(a: number, b: number): number {
  return a > b ? 1 : -1;
}

function formatReponseHeaders(responseHeaders: globalThis.Headers) {
  const headers: Headers = {};
  for (let [key, value] of responseHeaders.entries()) {
    headers[key] = value;
  }
  return headers;
}

export default API;
