import {
  BreakpointProvider,
  GlobalStyle,
  I18nProvider,
  Locale,
  SnackProvider,
  ThemeProvider,
} from "@sencrop/ui-components";
import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AppGlobalStyle from "../components/GlobalStyle";
import { getBrowserLocale } from "../helpers/navigator";
import store, { persistor } from "../store";
import { DICTIONARY } from "../utils/i18n";
import ErrorBoundary from "./error/ErrorBoundary";
import MainRoutes from "./Routes";
import { AUTH_BASE_URL, AUTH_CLIENT_ID } from "../api";
import { AuthProvider } from "react-oidc-context";

export const helmetContext = {};

const userManager: UserManager = new UserManager({
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  authority: AUTH_BASE_URL,
  client_id: AUTH_CLIENT_ID,
  redirect_uri: window.location.origin,
});

const Main = () => {
  return (
    <AuthProvider userManager={userManager}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Providers />
        </PersistGate>
      </Provider>
    </AuthProvider>
  );
};

export const useLocale = (): Locale => {
  return getBrowserLocale();
};

const Providers = () => {
  const locale = useLocale();

  return (
    <ThemeProvider>
      <BreakpointProvider>
        <>
          <GlobalStyle />
          <AppGlobalStyle />
          <I18nProvider
            // @ts-ignore: network admin doesn't implement all UI locales
            dictionary={DICTIONARY}
            locale={locale}
          >
            <ErrorBoundary>
              <SnackProvider template={undefined}>
                <MainRoutes />
              </SnackProvider>
            </ErrorBoundary>
          </I18nProvider>
        </>
      </BreakpointProvider>
    </ThemeProvider>
  );
};

export default Main;
