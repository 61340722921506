const ORY_TOKEN_KEY = `auth-ory::factory-monitoring`;
class AuthTokenManager {
  private static _instance: AuthTokenManager;

  private _token: string | undefined = undefined;

  constructor() {
    this._token = window.localStorage.getItem(ORY_TOKEN_KEY) ?? undefined;
  }

  static getInstance(): AuthTokenManager {
    if (!AuthTokenManager._instance) {
      AuthTokenManager._instance = new AuthTokenManager();
    }
    return AuthTokenManager._instance;
  }

  setToken(token?: string): void {
    this._token = token;
    if (token) {
      window.localStorage.setItem(ORY_TOKEN_KEY, token);
    } else {
      window.localStorage.removeItem(ORY_TOKEN_KEY);
    }
  }

  getToken(): string | undefined {
    return this._token;
  }
}

export default AuthTokenManager;
