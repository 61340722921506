import React, { useEffect } from "react";
import { Outlet } from "react-router";
import { useAppDispatch, useAppSelector } from "../store";
import LazyLoader from "./LazyLoader";
import { PATH_DASHBOARD } from "../utils/path";
import { useNavigate } from "react-router-dom";
import { login } from "../store/auth/auth.thunk";

const ProfileOutlet = () => {
  const userId = useAppSelector((s) => s.session.userId);
  const isLogged = userId != null;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const asynclogin = async () => {
      await dispatch(login());
      navigate(PATH_DASHBOARD);
    };
    asynclogin();
  }, [isLogged]);

  if (!isLogged) {
    return <LazyLoader />;
  }

  return <Outlet />;
};

export default ProfileOutlet;
