import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthTokenManager from "../helpers/AuthTokenManager";
import { PATH_LOGIN } from "../utils/path";
import LazyLoader from "../components/LazyLoader";

const LogoutCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AuthTokenManager.getInstance().setToken();
    navigate(PATH_LOGIN);
  }, []);

  return <LazyLoader />;
};

export default LogoutCallback;
