export type SencropAPIRequest = {
  action?: string | number | symbol;
  url?: string;
  data?: Record<string, any>;
  method?: string;
  params?: Record<string, any>;
};

export class SencropAPIError extends Error {
  public readonly cause: Error;

  public readonly code: string;

  public readonly message: string;

  public readonly httpStatus?: number;

  public readonly request?: SencropAPIRequest;

  constructor(
    cause: Error,
    code: string,
    message: string,
    httpStatus?: number,
    request?: SencropAPIRequest
  ) {
    super();

    this.cause = cause;
    this.code = code;
    this.message = message;
    this.httpStatus = httpStatus;
    this.request = request;
  }
}

export function isSencropAPIError(
  candidate: unknown
): candidate is SencropAPIError {
  return candidate instanceof SencropAPIError;
}
