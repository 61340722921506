import { createReducer } from "@reduxjs/toolkit";
import * as actions from "../actions";

export type SessionState = {
  userId?: number;
};

const initialState: SessionState = {
  userId: undefined,

};

const session = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.setUserSession, (state, { payload }) => {
      return {
        ...state,
        userId: payload.userId,
    
      };
    })
    .addCase(actions.setNetworkSession, (state, { payload }) => {
      return {
        ...state,
        networkId: payload.networkId,
      };
    })
    .addCase(actions.clearSession, () => initialState)
);

export default session;
