import { createAction } from "@reduxjs/toolkit";
import { TestingSession } from "../../models/testingSessions";

export const addTestingSessionsRequest = createAction<void>(
  "ADD_TESTING_SESSIONS_REQUEST"
);
export const addTestingSessionsSuccess = createAction<TestingSession[]>(
  "ADD_TESTING_SESSIONS_SUCCESS"
);
export const addTestingSessionsError = createAction<string>(
  "ADD_TESTING_SESSIONS_ERROR"
);

export const refreshDataRequest = createAction<void>("REFRESH_DATA_REQUEST");
export const refreshDataSuccess = createAction<TestingSession[]>(
  "REFRESH_DATA_SUCCESS"
);
export const refreshDataError = createAction<string>("REFRESH_DATA_ERROR");

export const removeTestingSessionsRequest = createAction<void>(
  "REMOVE_TESTING_SESSION_REQUEST"
);
export const removeTestingSessionsSuccess = createAction<TestingSession[]>(
  "REMOVE_TESTING_SESSION_SUCCESS"
);
export const removeTestingSessionsError = createAction<string>(
  "REMOVE_TESTING_SESSION_ERROR"
);

export const clearTestingSessionsRequest = createAction<void>(
  "CLEAR_TESTING_SESSIONS_REQUEST"
);
export const clearTestingSessionsSuccess = createAction<void>(
  "CLEAR_TESTING_SESSIONS_SUCCESS"
);
export const clearTestingSessionsError = createAction<string>(
  "CLEAR_TESTING_SESSIONS_ERROR"
);

export const terminateSessionsRequest = createAction<void>(
  "TERMINATE_SESSIONS_REQUEST"
);
export const terminateSessionsSuccess = createAction<TestingSession[]>(
  "TERMINATE_SESSIONS_SUCCESS"
);
export const terminateSessionsError = createAction<string>(
  "TERMINATE_SESSIONS_ERROR"
);

export const retrieveCurrentSessionsRequest = createAction<void>(
  "RETRIEVE_SESSIONS_REQUEST"
);
export const retrieveCurrentSessionsSuccess = createAction<TestingSession[]>(
  "RETRIEVE_SESSIONS_SUCCESS"
);
export const retrieveCurrentSessionsError = createAction<string>(
  "RETRIEVE_SESSIONS_ERROR"
);
