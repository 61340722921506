import { createReducer } from "@reduxjs/toolkit";
import * as actions from "../actions";

export type AuthState = {
  userId?: number;
  isAdmin: boolean;
};

const initialState: AuthState = {
  userId: undefined,
  isAdmin: false,
};

const identity = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.loginSuccess, (state, { payload }) => {
      return {
        ...state,
        userId: payload.userId,
        isAdmin: payload.isAdmin,
      };
    })
    .addCase(actions.logoutSuccess, () => {
      return initialState;
    })
);

export default identity;
