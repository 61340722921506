import styled, { css } from "styled-components";
import { Color, color } from "@sencrop/ui-components";

type BackgroundProps = {
  gradient?: boolean;
  image?: string;
  color?: Color;
  fixed?: boolean;
  height?: string | number;
  imagePosition?: string;
};

const Background = styled.div<BackgroundProps>`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;

  ${(props) =>
    props.color &&
    css`
      & {
        background-color: ${color(props.color)};
      }
    `};
  ${(props) =>
    (props.gradient || props.image) &&
    css`
      &:before {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        ${props.fixed &&
        css`
          & {
            position: fixed;
            width: 100%;
            height: 100%;
          }
        `};

        ${(props.height || props.height === 0) &&
        css`
          ${typeof props.height === "string" &&
          css`
            & {
              height: ${props.height};
            }
          `};
          ${typeof props.height === "number" &&
          css`
            & {
              height: ${props.height}px;
            }
          `};
        `};

        ${props.gradient &&
        // @ts-ignore
        css`
          & {
            background-image: ${props.theme.gradient("dark")};
          }
        `};

        ${props.image &&
        css`
          & {
            background-image: url(${props.image});
            background-size: cover;
            background-position: center;
            ${props.imagePosition &&
            css`
              & {
                background-position: ${props.imagePosition};
              }
            `};
          }
        `};
      }
    `};
`;

export default Background;
