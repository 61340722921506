import { combineReducers } from "@reduxjs/toolkit";

import auth from "./auth/auth.reducer";
import session from "./session/session.reducer";
import testingSession from "./testingSession/testingSession.reducer";

const reducers = {
  auth,
  session,
  testingSession,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
