import React, { Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LazyLoader from "../components/LazyLoader";
import {
  PATH_DASHBOARD,
  PATH_LOGIN,
  PATH_LOGOUT,
  PATH_LOGOUT_CALLBACK,
} from "../utils/path";
import AuthOutlet from "../components/AuthOutlet";
import ProfileOutlet from "../components/ProfileOutlet";
import LogoutCallback from "./LogoutCallback";

const ScreenLogin = React.lazy(
  () => import("./LoginWithOry" /* webpackChunkName: "login" */)
);

const ScreenLogout = React.lazy(
  () => import("./Logout" /* webpackChunkName: "logout" */)
);

const ScreenDashboard = React.lazy(
  () => import("./Dashboard" /* webpackChunkName: "dashboard" */)
);

const MainRoutes = () => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <BrowserRouter>
        <Routes>
          <Route path={PATH_LOGIN} element={<ScreenLogin />} />
          <Route path={PATH_LOGOUT_CALLBACK} element={<LogoutCallback />} />
          <Route element={<AuthOutlet />}>
            <Route path={PATH_LOGOUT} element={<ScreenLogout />} />
            <Route element={<ProfileOutlet />}>
              <Route path={PATH_DASHBOARD} element={<ScreenDashboard />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to={PATH_DASHBOARD} />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default MainRoutes;
