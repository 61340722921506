import {
  Button,
  color,
  media,
  Text,
  Title,
  useI18n,
} from "@sencrop/ui-components";
import Lottie from "lottie-react";
import React from "react";
import styled from "styled-components";
import Background from "../../components/Background";
import animation from "../../lotties/main-error.json";

const ScreensError = () => {
  const { t } = useI18n();

  const handleReload = () => {
    console.log("");
  };
  return (
    <Wrapper>
      <Background gradient>
        <Container>
          <span />
          <Content>
            <Lottie
              loop={true}
              autoplay={true}
              animationData={animation}
              style={{
                height: 120,
                width: 120,
                margin: "auto",
                marginBottom: "1.5rem",
              }}
            />
            <Title size="xl" color="light">
              {t("error_page.title")}
            </Title>
            <Text color="light">{t("error_page.subtitle")}</Text>
          </Content>
          <Actions>
            <Button variant="primary" size="l" full onClick={handleReload}>
              {t("error_page.action")}
            </Button>
          </Actions>
        </Container>
      </Background>
    </Wrapper>
  );
};

export default ScreensError;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  ${media.greaterThan("tablet")`
    justify-content: center;
    padding: 0;
    max-width: 640px;
  `}
`;

const Content = styled.div`
  width: 100%;
  text-align: center;
  color: ${color("text-light")};
  padding-bottom: 7px;
  p {
    margin-bottom: 9px;
  }
`;
const Actions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > * {
    margin: 1rem 0.5rem 0 0.5rem;
  }
  ${media.greaterThan("tablet")`
    flex-direction: row;
    > * {
      width: 220px;
    }
  `}
`;
