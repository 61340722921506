import styled from "styled-components";
import Loader from "./Loader";

const LazyLoader = () => (
  <LoaderContainer>
    <Loader size={100} />
  </LoaderContainer>
);

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  height: 100%;
  width: 100%;
`;

export default LazyLoader;
