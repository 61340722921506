import { LOCALES, Locale, DEFAULT_LOCALE, toLocale } from "./../utils/i18n";
import preferredLocale from "preferred-locale";

export const getBrowserLocale = (): Locale => {
  const locale = preferredLocale([...LOCALES], DEFAULT_LOCALE, {
    regionLowerCase: false,
    languageOnly: false,
  });

  return toLocale(locale);
};
